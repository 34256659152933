import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { defaultMentionStyle, defaultStyle } from "./Notes";
import { single_project_notes_project_comments_replies } from "../../../../__generated__/single_project_notes";
import { DELETE_REPLY_IN_PROJECT, REPLY_TO_REPLY_IN_PROJECT, UPDATE_REPLY_IN_PROJECT } from "../../../../graphql/mutationDefProjects";
import { replyToReply, replyToReplyVariables } from "../../../../__generated__/replyToReply";
import { useMutation } from "@apollo/client";
import { SINGLE_PROJECT, SINGLE_PROJECT_NOTES } from "../../../../graphql/queryDefProjects";
import { Clear, Close, Edit, Reply } from "@mui/icons-material";
import EditExistingComment from "./EditExistingComment";
import { updateReply, updateReplyVariables } from "../../../../__generated__/updateReply";
import { useTranslation } from "react-i18next";
import { Disable } from "react-disable";
import { deleteReply, deleteReplyVariables } from "../../../../__generated__/deleteReply";
import { parseComment } from "../../../../hooks/commentParser";
import MentionButton from "./MentionButton";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../../models/MatomoEvent";
import { sanitizeInput } from "../../../../utils/sanitizeInput";

interface Props {
    initials: string;
    reply_id: string;
    comment: string;
    updated_at: string;
    deleted_at: string;
    owner: string;
    user: any;
    created_at: string;
    mentionList: { id: string; display: string }[];
    replies: (single_project_notes_project_comments_replies | null)[] | null;
    userRemoved: boolean;
    project_id: string;
}

const SingleReply: React.FC<Props> = ({
    initials,
    comment,
    created_at,
    mentionList,
    replies,
    reply_id,
    updated_at,
    deleted_at,
    user,
    owner,
    userRemoved,
    project_id,
}) => {
    const [showReply, setShowReply] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [commentValue, setCommentValue] = useState<string>("");
    const [hoverState, setHoverState] = useState<string | boolean>(false);
    const { trackEvent } = useMatomo();
    // track navigation items
    const trackClick = (event: MatomoEvent) => {
        trackEvent(event);
    };
    const { t } = useTranslation();

    // Create new comment
    const [saveNewReply] = useMutation<replyToReply, replyToReplyVariables>(REPLY_TO_REPLY_IN_PROJECT);

    const replyAndClose = () => {
        onSaveNewComment();
        trackClick({ category: "Project", action: "Reply-to-reply" });
        setCommentValue("");
        setShowReply(false);
    };

    const [updateReply, { loading }] = useMutation<updateReply, updateReplyVariables>(UPDATE_REPLY_IN_PROJECT);
    const [deleteReply, { loading: loadDelete }] = useMutation<deleteReply, deleteReplyVariables>(DELETE_REPLY_IN_PROJECT);

    /**
     * Delete reply
     */
    const deleteCurrentReply = async () => {
        try {
            deleteReply({
                variables: {
                    reply_id: reply_id,
                },

                refetchQueries: [SINGLE_PROJECT, SINGLE_PROJECT_NOTES],
                awaitRefetchQueries: true,
            });
        } catch (e) {}
    };

    /**
     * Create new note
     * @param taskId id of task
     * @param priority priority of task
     */
    const onUpdateReply = async (str: string) => {
        if (str.length > 0) {
            try {
                updateReply({
                    variables: {
                        reply_id: reply_id,
                        comment: str,
                    },

                    refetchQueries: [SINGLE_PROJECT, SINGLE_PROJECT_NOTES],
                    awaitRefetchQueries: true,
                });
            } catch (e) {}
        }
    };

    /**
     * Create new note
     * @param taskId id of task
     * @param priority priority of task
     */
    const onSaveNewComment = async () => {
        if (commentValue.length > 0) {
            try {
                saveNewReply({
                    variables: {
                        project_id: project_id,
                        reply_id: reply_id,
                        comment: commentValue,
                    },
                    refetchQueries: [SINGLE_PROJECT, SINGLE_PROJECT_NOTES],
                });
            } catch (e) {}
        }
    };

    const text = parseComment(comment);

    const textToShowOnDelete = comment === "replyIsDeleted" ? `${t("replyIsDeleted")} ${user.employee.name}` : text;

    // Mention button
    const handleAddAtSymbol = () => {
        setCommentValue(commentValue + " @");
        trackClick({ category: "Project", action: "Use-mention-button" });

        const textField = document.getElementById(`showreplyReply${reply_id}`);
        if (textField) {
            textField.focus();
        }
    };
    return (
        <div>
            <Disable disabled={loading || comment === "replyIsDeleted" || loadDelete || userRemoved}>
                <ListItem dense disablePadding disableGutters onMouseEnter={() => setHoverState(reply_id)} onMouseLeave={() => setHoverState(false)}>
                    <ListItemAvatar sx={{ minWidth: 0 }}>
                        <Avatar
                            sx={{
                                width: "24px",
                                height: "24px",
                                marginRight: "12px",
                            }}
                        >
                            {/*
                             *
                             */}
                            <Typography sx={{ fontSize: "0.7rem" }}>{initials}</Typography>
                        </Avatar>
                    </ListItemAvatar>
                    {/* Note
                     * primary: Text with highlighting if user is mentioned
                     * secondary: Timestamp
                     */}
                    <ListItemText
                        primary={
                            editMode ? (
                                <EditExistingComment
                                    currentComment={comment}
                                    updateAndReset={(str) => {
                                        onUpdateReply(str);
                                        trackClick({ category: "Project", action: "Update-reply" });
                                        setEditMode(false);
                                    }}
                                    mentionList={mentionList}
                                    cancelEdit={setEditMode}
                                />
                            ) : (
                                <Typography
                                    sx={{ marginBottom: "-4px" }}
                                    dangerouslySetInnerHTML={{
                                        __html: textToShowOnDelete.replace(/\n\r?/g, "<br />"),
                                    }}
                                />
                            )
                        }
                        secondary={
                            <div style={{ display: "flex" }}>
                                <Typography variant="caption" sx={{ color: "#707070", fontSize: "0.65rem" }}>
                                    {comment === "replyIsDeleted"
                                        ? `${t("Deleted")}: ${moment(deleted_at).format("LLL")}`
                                        : updated_at !== created_at
                                        ? `${t("Changed")}: ${moment(updated_at).format("LLL")}`
                                        : moment(created_at).format("LLL")}
                                </Typography>
                                {hoverState === reply_id && (
                                    <>
                                        <Tooltip disableInteractive placement="right" title={showReply ? "Annuleren" : "Beantwoorden"}>
                                            <IconButton
                                                onClick={() => {
                                                    setShowReply(!showReply);
                                                }}
                                                sx={{ padding: 0, margin: "0px 8px" }}
                                                size="small"
                                            >
                                                <Reply sx={{ height: "16px", width: "16px" }} />
                                            </IconButton>
                                        </Tooltip>

                                        {/* editmode */}
                                        {owner === localStorage.getItem("user_id") && (
                                            <Tooltip disableInteractive placement="right" title={editMode ? "Annuleren" : "Bewerken"}>
                                                <IconButton
                                                    onClick={() => {
                                                        setEditMode(!editMode);
                                                    }}
                                                    sx={{ padding: 0, marginRight: "8px" }}
                                                    size="small"
                                                >
                                                    <Edit sx={{ height: "16px", width: "16px" }} />
                                                </IconButton>
                                            </Tooltip>
                                        )}

                                        {/* delete */}
                                        {owner === localStorage.getItem("user_id") && (
                                            <Tooltip disableInteractive placement="right" title={"Verwijderen"}>
                                                <IconButton
                                                    onClick={() => {
                                                        deleteCurrentReply();
                                                        trackClick({ category: "Project", action: "Delete-reply" });
                                                    }}
                                                    sx={{ padding: 0 }}
                                                    size="small"
                                                >
                                                    <Close sx={{ height: "16px", width: "16px" }} />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </>
                                )}
                            </div>
                        }
                    />
                </ListItem>
            </Disable>
            {showReply && (
                <div>
                    <div style={{ width: "100%", paddingLeft: "40px", display: "flex", alignItems: "center" }}>
                        <MentionsInput
                            id={`showreplyReply${reply_id}`}
                            singleLine
                            style={defaultStyle}
                            placeholder="Reageren..."
                            value={commentValue}
                            onChange={(e) => {
                                const sanitizedValue = sanitizeInput(e.target.value, true);
                                setCommentValue(sanitizedValue);
                            }}
                            onKeyPress={(event) => {
                                if (event.key === "Enter" && commentValue.length > 0) {
                                    replyAndClose();
                                }
                            }}
                        >
                            <Mention style={defaultMentionStyle} trigger="@" data={mentionList} appendSpaceOnAdd />
                        </MentionsInput>
                        {/* cancel */}
                        <Tooltip title="Annuleren" placement="top">
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setShowReply(false);
                                    setCommentValue("");
                                }}
                            >
                                <Clear fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "40px", height: "8px" }} />
                        <MentionButton handleAddAtSymbol={handleAddAtSymbol}>@</MentionButton>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SingleReply;
