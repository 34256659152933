import React from "react";
import { MessageList, Message, MessageSeparator } from "@chatscope/chat-ui-kit-react";
import { GetConversation_conversation_chatMessages } from "../../__generated__/GetConversation";
import "./conversationChats.scss";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { colorOfDialog } from "../../utils/messenger";
import { Disable } from "react-disable";

interface Props {
    messages: GetConversation_conversation_chatMessages[];
    modelType: string;
}

const ConversationChats: React.FC<Props> = ({ messages, modelType }) => {
    const { t } = useTranslation();
    // const [loadingMore, setLoadingMore] = useState(false);
    // const [loadedMessages, setLoadedMessages] = useState([] as any[]);

    // const [counter, setCounter] = useState(0);
    const sortingOrder = [...messages].sort((a, b) => (moment(a.send_at) > moment(b.send_at) ? 1 : -1));
    const seen = new Set();

    const filteredArr = sortingOrder.filter((el) => {
        const duplicate = seen.has(moment(el.send_at).format("L"));
        seen.add(moment(el.send_at).format("L"));
        return !duplicate;
    });

    /**
     * TODO: create function scrolltop to fetch older messages
     */
    // const onYReachStart = () => {
    //     if (loadingMore === true) {
    //         return;
    //     }
    //     setLoadingMore(true);
    //     /* Fake fetch from API */
    //     setTimeout(() => {
    //         const messages = [];
    //         /* Add 10 messages */
    //         const maxCounter = counter + 10;
    //         let i = counter;
    //         for (; i < maxCounter; i++) {
    //             messages.push(
    //                 <Message
    //                     key={i}
    //                     model={{
    //                         message: `Message ${i}`,
    //                         sender: "Zoe",
    //                         direction: "incoming",
    //                         position: "single",
    //                     }}
    //                 />
    //             );
    //         }
    //         setLoadedMessages(messages.reverse().concat(loadedMessages));
    //         setCounter(i);
    //         setLoadingMore(false);
    //     }, 1500);
    // };

    /**
     * function to get todat and yesterday as string instead of date
     */
    const REFERENCE = moment(); // fixed just for testing, use moment();
    const TODAY = REFERENCE.clone().startOf("day");
    const YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");
    function isToday(momentDate: any) {
        return momentDate.isSame(TODAY, "d");
    }
    function isYesterday(momentDate: any) {
        return momentDate.isSame(YESTERDAY, "d");
    }

    return (
        <MessageList
            autoScrollToBottomOnMount={true}
            //  loadingMore={loadingMore} onYReachStart={onYReachStart}
        >
            {sortingOrder.map((m) => {
                // check for sender name attributes
                const name = m.from.employee?.givenname || "";
                const middle = m.from.employee?.middlename || "";
                const family = m.from.employee?.familyname || "";
                const sender = `${name} ${middle} ${family}`;
                const sendr = `${name} ${family}`;
                return (
                    <React.Fragment key={m.id}>
                        {filteredArr.find((item) => item.id === m.id) && (
                            /**
                             * Custom messageseparator with datestamp in centered box
                             */
                            <MessageSeparator>
                                <div style={{ padding: "6px 8px ", backgroundColor: "#efeded", borderRadius: "4px" }}>
                                    {isToday(moment(m.send_at)) ? (
                                        <Typography variant="caption" sx={{ color: "#b1b1b1" }}>
                                            {t("Today")}
                                        </Typography>
                                    ) : isYesterday(moment(m.send_at)) ? (
                                        <Typography variant="caption" sx={{ color: "#b1b1b1" }}>
                                            {t("Yesterday")}
                                        </Typography>
                                    ) : (
                                        <Typography variant="caption" sx={{ color: "#b1b1b1" }}>
                                            {moment(m.send_at).format("L")}
                                        </Typography>
                                    )}
                                </div>
                            </MessageSeparator>
                        )}

                        <Message
                            style={{ maxWidth: "40vw" }}
                            key={m.id}
                            model={{
                                direction: `${m.from.id === localStorage.getItem("user_id") ? "outgoing" : "incoming"}`,
                                type: "custom",
                                position: "single",
                            }}
                        >
                            <Message.CustomContent>
                                <Disable disabled={Boolean(m.sender_deleted)}>
                                    <div
                                        style={{
                                            boxSizing: "border-box",
                                            color: "rgba(0, 0, 0, 0.87)",
                                            backgroundColor: `${
                                                m.from.id === localStorage.getItem("user_id") ? `${colorOfDialog(modelType)}1f` : "#00000010"
                                            }`,
                                            marginTop: 0,
                                            padding: " 0.6em 0.9em",
                                            borderRadius: `${
                                                m.from.id === localStorage.getItem("user_id") ? "0.7em 0.7em 0 0.7em" : "0em 0.7em 0.7em 0.7em"
                                            }`,
                                            whiteSpace: "pre-wrap",
                                            overflowWrap: "anywhere",
                                            wordBreak: "break-word",
                                            fontFamily: "Maven Pro, system-ui, sans-serif",
                                            fontWeight: "normal",
                                            fontSize: "0.91em",
                                            fontVariant: "normal",
                                        }}
                                    >
                                        {/* Sender name */}
                                        <Typography
                                            sx={{ fontWeight: 500, color: "#173357", textDecoration: m.sender_deleted ? "line-through" : "auto" }}
                                        >
                                            {middle ? sender : sendr}
                                        </Typography>
                                        {/* Message */}
                                        <Typography>{m.message}</Typography>
                                        {/* Timestamp */}
                                        <Typography variant="caption" style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {moment(m.send_at).format("LT")}
                                        </Typography>
                                    </div>
                                </Disable>
                            </Message.CustomContent>
                        </Message>
                    </React.Fragment>
                );
            })}
        </MessageList>
    );
};
export default ConversationChats;
