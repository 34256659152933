import React, { createContext, useContext, useState } from "react";
import { filterArticles_filterArticles_data } from "../../__generated__/filterArticles";
// Run the query when category is set

interface ArticleFilterContextProps {
    query: string;
    setQuery: (query: string) => void;
    category: string | null;
    setCategory: (category: string | null) => void;
    module: string | null;
    setModule: (module: string | null) => void;
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    articles: filterArticles_filterArticles_data[];
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    article: filterArticles_filterArticles_data | null;
    setArticle: React.Dispatch<React.SetStateAction<filterArticles_filterArticles_data | null>>;
    setArticles: React.Dispatch<React.SetStateAction<filterArticles_filterArticles_data[]>>;
}

const ArticleFilterContext = createContext<ArticleFilterContextProps | undefined>(undefined);

export const ArticleFilterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [articles, setArticles] = useState<filterArticles_filterArticles_data[]>([]);
    const [article, setArticle] = useState<filterArticles_filterArticles_data | null>(null);
    const [query, setQuery] = useState("");
    const [module, setModule] = useState<string | null>(null);
    const [page, setPage] = useState<number>(1);
    const [category, setCategory] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <ArticleFilterContext.Provider
            value={{
                query,
                setQuery,
                setArticle,
                article,
                category,
                setCategory,
                module,
                setModule,
                page,
                setPage,
                articles,
                setArticles,
                loading,
                setLoading,
            }}
        >
            {children}
        </ArticleFilterContext.Provider>
    );
};

export const useArticleFilter = () => {
    const context = useContext(ArticleFilterContext);
    if (!context) {
        throw new Error("useArticleFilter must be used within an ArticleFilterProvider");
    }
    return context;
};
