import { gql } from "@apollo/client";
import * as fragments from "./fragments";
//? get full tender
export const QUERY_TENDER = gql`
    query GetTender($id: ID!) {
        tender(id: $id) {
            ...FullTender
        }
    }
    ${fragments.fullTender}
`;
//? get related tender suggestions
export const QUERY_RELATED_TENDER_SUGGESTIONS = gql`
    query GetRelatedTenderSuggestions($tenderId: ID!) {
        tender(id: $tenderId) {
            id
            related_tenders_suggestion {
                ...RelatedTenderSubset
            }
        }
    }
    ${fragments.relatedTender}
`;
//? get full nuts
export const GET_NUTS = gql`
    query GetNuts($query: String, $code: String, $level: String) {
        nuts(query: $query, code: $code, level: $level) {
            ...FullNuts
        }
    }
    ${fragments.fullNuts}
`;
//? get organization or settling(s)
export const GET_ORGANIZATION_OR_SETTLING = gql`
    query GetOrganizationOrSettlings($query: String!) {
        organizationsAndSettlings(query: $query) {
            __typename
            ... on Organization {
                ...FullOrganization
            }
            ... on Settling {
                ...FullSettlingWithOrganization
            }
        }
    }
    ${fragments.fullOrganization}
    ${fragments.fullSettlingWithOrganization}
`;
//? get full sectordata
export const GET_SECTORS = gql`
    query GetSectors {
        sectors {
            ...FullSubSector
        }
    }
    ${fragments.fullSubSector}
`;

//? get full sectordata
export const GET_TENDER_NAME_ID_AMOUNTOFDOCS = gql`
    query GetTenderIdNameDocs($id: ID!) {
        tender(id: $id) {
            id
            status
            namegiventocontract
            documentDateCount {
                amount
                date
            }
        }
    }
`;

export const GET_TENDER_INCOMPATIBLE_DATES = gql`
    query GetTenderIncompatibleDates($id: ID!) {
        tender(id: $id) {
            id
            incompatibleDates
        }
    }
`;

//? Query for default summary
export const QUERY_DEFAULT_SUMMARY = gql`
    query GetDefaultSummary($id: ID!) {
        tender(id: $id) {
            id
            status
            namegiventocontract
            omschrijving
            omvang_opdracht
            published_host
            original_link
            deadline_inschrijving
            updated_at
            first_dispatchdate
            gunningscriteria
            type_procedure
            published_juridisch_kader
            published_legal_basis
            translated_type
            published_namegiventocontract
            opties_einde {
                lots
                value
            }
            searchresults {
                search {
                    id
                    name
                    color
                }
            }
            rawPublications {
                id
                namegiventocontract
                dispatchdate
                host
                publication_type {
                    ... on OriginalPublicationType {
                        id
                        name
                        omschrijving
                        __typename
                    }
                    ... on EformsPublicationType {
                        id
                        name
                        omschrijving
                        __typename
                    }
                }
            }
            documentDateCount {
                amount
                date
            }
            country {
                name
            }
            # Sectors
            sectors {
                id
                name
                parent {
                    id
                    name
                }
                children {
                    id
                    name
                }
            }
            omschrijving_source {
                ...FullSource
            }

            omvang_opdracht_source {
                ...FullSource
            }

            lots {
                ...FullTenderDetailLots
            }
        }
    }
    ${fragments.fullTenderDetailLots}
    ${fragments.fullSource}
`;

//?  Query contacting authority/contracting authorities details
export const GET_CONTRACTING_AUTHORITY_DETAILS_WIDGET = gql`
    query GetCaDataForWidgetOfCurrentTender($id: ID!) {
        tender(id: $id) {
            id
            published_website
            published_contactpersoon
            published_email
            published_telefoon
            published_telefoon_type
            published_adres
            published_vestigingsplaats
            published_opdrachtgever
            published_host
            country {
                alpha2
            }

            published_postcode
            clients {
                id
                kvknumber
                name
                address
                postalcode
                city
                name
                favicon
                favicon_resized
                country {
                    alpha2
                    name
                    numeric
                }
                logo
                urls {
                    id
                    url
                    type
                }
                settlings(first: 10, page: 1) {
                    data {
                        type
                        visit_address {
                            id
                            city
                            street
                            country
                            housenumber
                            postal_code
                            housenumber_suffix
                        }
                    }
                }
                phonenumbers {
                    id
                    phonenumber
                    type
                }
                emails {
                    id
                    email
                    type
                }

                pivot {
                    category
                    lot {
                        id
                        number
                    }
                }
            }
        }
    }
`;

//?  Query award information for current tender
export const QUERY_AWARD_INFORMATION_FOR_CURRENT_TENDER = gql`
    query GetAwardInfoForCurrentTender($id: ID!) {
        tender(id: $id) {
            id
            datum_gunning
            looptijd_start {
                lots
                value
            }
            next_looptijd_einde {
                lots
                value
            }
            status
            published_lowest_bid
            published_highest_bid
            published_participants
            country {
                numeric
            }
            lots {
                ...FullTenderAwardLots
            }
            bids {
                ...FullBidsTenderDetail
            }
        }
    }
    ${fragments.fullTenderAwardLots}
    ${fragments.fullBidsTenderDetail}
`;

//?  Query contract information for current tender
export const QUERY_CONTRACT_INFORMATION_FOR_CURRENT_TENDER = gql`
    query GetContractInfoForCurrentTender($id: ID!) {
        tender(id: $id) {
            id
            published_looptijd_start
            published_looptijd_einde
            published_looptijd_beschrijving
            published_looptijd_interval
            published_looptijd_periode
            datum_gunning
            looptijd_start {
                lots
                value
            }
            looptijd_einde {
                lots
                value
            }
            next_looptijd_einde {
                lots
                value
            }
            looptijd_einde_incl_opties {
                lots
                value
            }
            opties_einde {
                lots
                value
            }
            status_contract {
                lots
                value
            }
            displayPublications {
                id
                renewal_descr
            }
            lots {
                ...FullTenderDetailLots
            }
            contract {
                id
                description
                undeterminedPeriod
                period {
                    ...FullPeriod
                }
                options {
                    amount
                    length
                    period
                    indefinite
                    id
                }
                source {
                    ...FullSource
                }
            }
        }
    }
    ${fragments.fullTenderDetailLots}
    ${fragments.fullSource}
    ${fragments.fullPeriod}
`;

//?  Query to scopevalues of current tender
export const QUERY_SCOPEVALUES_OF_CURRENT_TENDER = gql`
    query GetScopeValuesOfCurrentTender($id: ID!) {
        tender(id: $id) {
            id
            lots {
                ...FullTenderDetailLots
            }
            scope {
                id
                description
                source {
                    ...FullSource
                }
                min {
                    id
                    value
                    currency
                    vat
                    vat_percentage
                }
                max {
                    id
                    value
                    currency
                    vat
                    vat_percentage
                }
                total {
                    id
                    value
                    currency
                    vat
                    vat_percentage
                }
                lines {
                    id
                    description
                    unit {
                        id
                        label
                    }
                    minUnitValue
                    maxUnitValue
                    valueIsEstimated
                    valueIsHistoric
                    valueIsOptional
                    parent_id
                    lot {
                        id
                        title
                    }
                    period {
                        id
                        startDate
                        endDate
                        type
                        value
                    }
                    value {
                        id
                        value
                        currency
                        vat_percentage
                        vat
                    }
                    minValue {
                        id
                        value
                        currency
                        vat_percentage
                        vat
                    }
                    maxValue {
                        id
                        value
                        currency
                        vat_percentage
                        vat
                    }
                }
            }
        }
    }
    ${fragments.fullTenderDetailLots}
    ${fragments.fullSource}
`;

export const QUERY_RELATED_TENDERS_FOR_CURRENT_TENDER = gql`
    query GetRelatedTendersForCurrentTender($id: ID!) {
        tender(id: $id) {
            related_tenders {
                id
                datum_gunning
                namegiventocontract
                first_dispatchdate
                looptijd_einde_incl_opties {
                    lots
                    value
                }

                opties_einde {
                    value
                    lots
                }

                looptijd_start {
                    lots
                    value
                }

                looptijd_einde {
                    lots
                    value
                }

                next_looptijd_einde {
                    lots
                    value
                }

                status
                bids {
                    id
                    bid
                    combination
                    percentage
                    marketParty {
                        id
                        name
                        favicon
                        favicon_resized
                        country {
                            alpha2
                        }
                        names {
                            id
                            type
                            value
                        }
                    }
                    totalValue {
                        id
                        value
                        currency
                    }
                    winner
                    lot {
                        id
                        title
                        number
                    }
                }
                lots {
                    id
                    title
                    number
                    next_looptijd_einde
                    looptijd_start
                    looptijd_einde
                    participants_count
                    award_date
                }
            }
        }
    }
`;

//? get last message and boolean
export const GET_LAST_TENDER_MESSAGE = gql`
    query GetLastTenderMessage($id: ID!) {
        tender(id: $id) {
            id
            lastPrivateNote
            hasConversations
            unreadChatsCount
        }
    }
`;
//? get last message and boolean
export const GET_TENDER_SECTORS = gql`
    query GetTenderSectors($id: ID!) {
        tender(id: $id) {
            id
            # Sectors
            sectors {
                id
                name
                parent {
                    id
                    name
                }
                children {
                    id
                    name
                }
            }
        }
    }
`;

//? get last message and boolean
export const GET_LAST_CA_MESSAGE = gql`
    query GetCaPrivateNote($id: ID!) {
        contracting_authority(id: $id) {
            id
            lastPrivateNote
        }
    }
`;

//? get last message and boolean
export const GET_LAST_MP_MESSAGE = gql`
    query GetMpPrivateNote($id: ID!) {
        tenderer(id: $id) {
            lastPrivateNote
        }
    }
`;

//? get last message and boolean
export const GET_LAST_OPP_MESSAGE = gql`
    query GetOppPrivateNote($id: ID!) {
        opportunityFile(id: $id) {
            lastPrivateNote
        }
    }
`;

//? Get tender events
export const GET_TENDER_EVENTS = gql`
    query GetTenderEvents($id: ID!) {
        tender(id: $id) {
            id
            deadline_inschrijving
            country {
                alpha2
            }
            events {
                id
                name
                tags {
                    name
                }
                isStored
                timeSignificant
                isReplacedBy {
                    id
                    name
                    created_at
                }
                # 			isReplacedBy
                # 			periodEnd
                # 			periodStart
                planned
                source {
                    ...FullSource
                }
                created_at
                updated_at
            }
        }
    }
    ${fragments.fullSource}
`;

//? get last message and boolean
export const GET_CAS_FOR_OPPORTUNITY_DOCS = gql`
    query GetCasForOpportunityDocs($id: ID!) {
        tender(id: $id) {
            id
            clients {
                id
                name
            }
        }
    }
`;

// ? Get all liked tenders of a user
export const QUERY_TENDERLIKED = gql`
    query GetTenderLikes($id: ID!) {
        tender(id: $id) {
            id
            teamLikes {
                id
                employee {
                    id
                    name
                    familyname
                }
            }

            teamDislikes {
                id
                employee {
                    id
                    name
                    familyname
                }
            }
        }
    }
`;

// ? get length of worklist of current user
export const QUERY_OPPWORKLISTLENGTH = gql`
    query GetOpportunityFilesWithResultsCount(
        $first: Int!
        $page: Int
        $orderField: QueryOpportunityFilesWithResultsOrderByColumn!
        $order: SortOrder!
    ) {
        opportunityFilesWithResults(first: $first, page: $page, orderBy: [{ column: $orderField, order: $order }]) {
            data {
                id
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

//? get all tenders in worklist of current user
export const QUERY_FULL_WORKLIST_PAGINATION = gql`
    query FilterWorklistPagination($page: Int, $count: Int, $orderField: String!, $order: SortOrder!, $search: TenderSearchInput) {
        filterTenders(page: $page, first: $count, search: $search, orderBy: [{ column: $orderField, order: $order }]) {
            results {
                data {
                    id
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

//? Check if mp is winner of tender/lot
export const GET_WINNER_STATE_LOTS_BIDS = gql`
    query GetStateOfWinner($id: ID!) {
        tender(id: $id) {
            id
            status
            lots {
                id
            }
            bids {
                id
                winner
                preliminaryAwarded
                marketParty {
                    id
                }
                lot {
                    id
                    number
                }
            }
        }
    }
`;

// ? get all articles sorted on module column
export const QUERY_CAS_FOR_SINGLE_TENDER = gql`
    query GetCasForSingleTender($id: ID!) {
        tender(id: $id) {
            id
            clients {
                id
                pivot {
                    category
                }
                kvknumber
                name
                address
                postalcode
                city
                size
                country {
                    name
                    alpha2
                    numeric
                }
                favicon
                favicon_resized
                logo
            }
        }
    }
`;

// ? Get isocountry code
export const QUERY_GETISOCOUNTRYCODE = gql`
    query getIsoCountryCode($id: ID!) {
        getCountry(id: $id) {
            alpha2
            alpha3
            name
            numeric
        }
    }
`;

//? get results of page
export const QUERY_USER_OPPS_OF_PAGE = gql`
    query GetopportunityPagePersonalOpportunities($page_id: Int!) {
        opportunityPagePersonalOpportunities(page_id: $page_id) {
            id
            comment
            parsedValues: yearValues {
                year
                value
            }
            page {
                id
                page
            }
        }
    }
`;

//? get results of page
export const QUERY_RESULTS_OF_PAGE = gql`
    query GetOpportunityPageResults($page_id: Int!) {
        opportunityPageResults(page_id: $page_id) {
            id
            page {
                id
                page
            }
            search {
                id
                name
                query
                filters
            }
            snippet
        }
    }
`;

//? get emailaddresses of single organization
export const QUERY_ORGANIZATION_EMAILS = gql`
    query GetOrganizationEmails($organizationId: ID!) {
        organization(id: $organizationId) {
            id
            emails {
                id
                email
                type
            }
        }
    }
`;

//? get phone numbers of single organization
export const QUERY_ORGANIZATION_PHONES = gql`
    query GetOrganizationPhones($organizationId: ID!) {
        organization(id: $organizationId) {
            id
            phonenumbers {
                id
                phonenumber
                type
            }
        }
    }
`;

//? get postaladdresses of single organization
export const QUERY_ORGANIZATION = gql`
    query GetOrganizationPostals($organizationId: ID!) {
        organization(id: $organizationId) {
            id
            postal_addresses {
                id
                city
                country
                housenumber
                housenumber_suffix
                postal_code
                street
            }
        }
    }
`;

export const GET_ORGANIZATION_LOGO = gql`
    query GetOrganizationLogo($organizationId: ID!) {
        organization(id: $organizationId) {
            id
            logo
        }
    }
`;

// Save user preferences
export const EXPORT_CHOSEN_COLUMNS = gql`
    mutation ExportChosenColumns($search: TenderSearchInput, $orderField: String!, $order: SortOrder!, $columns: [String]!, $headers: [String]!) {
        exportFilterTenders(search: $search, orderBy: [{ column: $orderField, order: $order }], columns: $columns, headers: $headers)
    }
`;

//? get all activities of current user
export const QUERY_TENDER_STATE_FOR_USER = gql`
    query GetTenderStateForUser($id: ID!) {
        tender(id: $id) {
            userStatus
            hasConversations
            unreadChatsCount
        }
    }
`;

//? get relevant documents
export const QUERY_GETDOCUMENTSLIST = gql`
    query GetDocumentsList($id: ID!) {
        tender(id: $id) {
            id
            lots {
                id
                number
            }
            relevantDocuments {
                id
                directory
                filename
                temporary_link
                extension
                created_at
                pivot {
                    id
                    lot_id
                    dispatchdate
                }
            }
        }
    }
`;

//? get tenderdocuments, per layer
export const QUERY_GETPAGINATIONDOCLIST = gql`
    query GetTenderDocsWithPagination($tender_id: Int!, $folder: String!, $date: Date, $count: Int) {
        tenderDocuments(tender_id: $tender_id, folder: $folder, date: $date, count: $count) {
            folders {
                label
                date
                count
            }

            files {
                id
                label
                link: temporary_link
                class
                date
                lot {
                    id
                    number
                }
            }
        }
    }
`;

export const GET_UNITS_OF_MEASURE = gql`
    query GetUnitsOfMeasure {
        unitsOfMeasure {
            name
            units {
                id
                name
            }
        }
    }
`;

//? show name in tab
export const QUERY_TENDERS_OF_CA_MINIMUM = gql`
    query FilterTendersOverviewOnCaPageMinimum($page: Int, $count: Int, $orderBy: [OrderByClause!]!, $search: TenderSearchInput) {
        filterTenders(page: $page, first: $count, search: $search, orderBy: $orderBy) {
            filters {
                title
                topFilter
                active
                searchRuleFilter
                values {
                    identifier
                    label
                    amountOfResults
                }
            }
            results {
                data {
                    id
                    userStatus
                    searchresults {
                        search {
                            id
                            name
                            color
                        }
                    }
                    hasConversations
                    namegiventocontract
                    deadline_inschrijving
                    status

                    country {
                        name
                        alpha2
                        numeric
                    }
                    published_opdrachtgever
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

export const GET_ENDING_CONTRACTS_QUARTER = gql`
    query EndingQuarterContracts($quarter: String!) {
        getUserContractsInQuarter(quarter: $quarter) {
            liked
            total
            search {
                id
                name
                color
            }
        }
    }
`;

//? get five latest activities to show in dashboard
export const QUERY_FIVE_LATEST_TENDER_ACTIVITIES = gql`
    query GetFiveLatestTenderActivities($page: Int, $count: Int, $orderField: String!, $order: SortOrder!, $search: TenderSearchInput) {
        filterTenders(page: $page, first: $count, search: $search, orderBy: [{ column: $orderField, order: $order }]) {
            results {
                data {
                    # Tender data
                    id
                    updated_at
                    type_update
                    namegiventocontract

                    clientA {
                        id
                        name
                        favicon
                        favicon_resized
                    }

                    # CA data published
                    published_opdrachtgever
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

export const GET_DASHBOARD_WORKLIST = gql`
    query GetFullDashboardWorklist($page: Int, $count: Int, $orderField: String!, $order: SortOrder!, $search: TenderSearchInput) {
        filterTenders(page: $page, first: $count, search: $search, orderBy: [{ column: $orderField, order: $order }]) {
            results {
                data {
                    id
                    userStatus
                    namegiventocontract
                    status
                    deadline_inschrijving
                    looptijd_start {
                        lots
                        value
                    }
                    next_looptijd_einde {
                        lots
                        value
                    }
                    hasConversations
                    searchresults {
                        search {
                            id
                            name
                            color
                        }
                    }
                    likes {
                        id
                    }
                    published_opdrachtgever
                }
                paginatorInfo {
                    total
                }
            }
        }
    }
`;

//? GQL query to get all opportunities of a certain type for a certain CA (file_type_id is optional)
export const QUERY_OPPS_OF_CA_DOC_TYPE = gql`
    query GetCAOpportunitiesOfType($ca_id: Int!, $file_type_id: Int, $first: Int!, $page: Int) {
        contractingAuthorityOpportunities(contracting_authority_id: $ca_id, file_type_id: $file_type_id, first: $first, page: $page) {
            data {
                id
                comment
                created_at
                page {
                    id
                    page
                    file {
                        id
                        year_from
                        name
                    }
                }
                parsedValues: yearValues {
                    year
                    value
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const QUERY_DETAILPAGE_OF_OPPORTUNITY_GENERAL = gql`
    query GetOppDetailPageGeneralInfo($id: ID!) {
        opportunityFile(id: $id) {
            id
            name
            created_at
            publication_date
            original_name
            temporary_link
            year_from

            pages {
                id
                page
            }

            # searchRule results
            personalResults {
                id
                search {
                    id
                    name
                    query
                    filters
                }
            }
            # AD data
            contractingAuthority {
                id
                name
                favicon
                favicon_resized
                country {
                    alpha2
                    name
                    numeric
                }
                types
                logo
            }
            type {
                id
            }
        }
    }
`;

//? get opportunity detailpage
export const QUERY_DETAILPAGE_OF_OPPORTUNITY = gql`
    query GetOppDetailPage($file_id: Int!, $first: Int!, $page: Int, $status: Boolean) {
        opportunityFilePersonalPages(file_id: $file_id, first: $first, page: $page, status: $status) {
            data {
                id
                file {
                    id
                    name
                }
                page
                status
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

//? show name in tab
export const QUERY_TENDERS_OF_MP_WITH_FILTER_MINIMUM = gql`
    query FilterTendersOverviewOnMarketPartyPageMinimum($page: Int, $count: Int, $search: TenderSearchInput, $orderBy: [OrderByClause!]!) {
        filterTenders(page: $page, first: $count, search: $search, orderBy: $orderBy) {
            filters {
                title
                topFilter
                active
                searchRuleFilter
                values {
                    identifier
                    label
                    amountOfResults
                }
            }
            results {
                data {
                    id
                    status
                    namegiventocontract
                    deadline_inschrijving
                    userStatus
                    published_opdrachtgever
                    hasConversations
                    searchresults {
                        search {
                            id
                            name
                            color
                        }
                    }
                    clientA {
                        id
                        name
                        favicon
                        favicon_resized
                    }
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

//? get 5 tenders of tenderer to show in overview page
export const QUERY_FILTERED_TENDERS_OF_ORG = gql`
    query FilterTendersOftenderer($page: Int, $count: Int!, $org_id: Int) {
        filterBids(page: $page, first: $count, organization_id: $org_id) {
            data {
                id
                winner
                updated_at
                tender {
                    id
                    namegiventocontract
                    next_looptijd_einde {
                        lots
                        value
                    }
                    looptijd_start {
                        lots
                        value
                    }
                    clients {
                        id
                        pivot {
                            category
                        }
                        name
                    }
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

//? get tenders that match with set variables in tender searchwizard
export const QUERY_TENDERS = gql`
    query FilterSearchRuleTenders($page: Int, $count: Int, $orderField: String!, $order: SortOrder!, $search: TenderSearchInput) {
        filterTenders(page: $page, first: $count, search: $search, orderBy: [{ column: $orderField, order: $order }]) {
            filters {
                title
                topFilter
                active
                searchRuleFilter
                values {
                    identifier
                    label
                    amountOfResults
                }
            }
            results {
                data {
                    id
                    namegiventocontract
                    clients {
                        id
                        name
                        favicon
                        favicon_resized
                        pivot {
                            category
                        }
                    }
                    published_opdrachtgever
                    status
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

//? get all liked tenders of current user
export const QUERY_DRAGGABLE_TENDER_CARD = gql`
    query QueryTenderDataForCard($tender_id: ID!) {
        tender(id: $tender_id) {
            id
            status
            namegiventocontract
            deadline_inschrijving
            searchresults {
                search {
                    id
                    name
                    color
                }
            }
            clients {
                id
                name
                favicon
                favicon_resized
                pivot {
                    category
                }
            }
        }
    }
`;

export const GET_CHOSEN_TIMESLOT_DETAILS = gql`
    query Get_chosen_timeslot_details($hash: String!) {
        meetingTimeslot(hash: $hash) {
            hash
            start
            end
            duration
            type
        }
    }
`;

export const GET_AVAILABLE_DEMO_TIMESLOTS = gql`
    query Get_available_demo_timeslots($from: DateTime) {
        meetingTimeslots(type: SUPPORT, from: $from) {
            hash
            start
            end
            duration
            type
        }
    }
`;

//? get all timeslots
export const QUERY_TIMESLOTS_DATES = gql`
    query meetingTimeslotDays {
        meetingTimeslotDays(type: SUPPORT)
    }
`;

//? get all tenders in platform, sorted column: updated_at
export const QUERY_TENDERS_MINIMUM = gql`
    query FilterTendersOverviewMinimum($page: Int, $count: Int, $orderBy: [OrderByClause!]!, $search: TenderSearchInput) {
        filterTenders(page: $page, first: $count, search: $search, orderBy: $orderBy) {
            filters {
                title
                topFilter
                active
                searchRuleFilter
                values {
                    identifier
                    label
                    amountOfResults
                }
            }
            results {
                data {
                    id
                    namegiventocontract
                    status
                    searchresults {
                        search {
                            id
                            name
                            color
                        }
                    }
                    deadline_inschrijving
                    country {
                        name
                        alpha2
                        numeric
                    }
                    clients {
                        id
                        name
                        favicon
                        favicon_resized
                        pivot {
                            category
                        }
                    }
                    hasConversations
                    published_opdrachtgever
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

//? get all liked tenders of current user
export const QUERY_TENDERS_INTERESTING_MINIMUM = gql`
    query FilterInterestingMinimumTendersOverview($page: Int, $count: Int, $orderBy: [OrderByClause!]!, $search: TenderSearchInput) {
        filterTenders(page: $page, first: $count, search: $search, orderBy: $orderBy) {
            filters {
                title
                topFilter
                active
                searchRuleFilter
                values {
                    identifier
                    label
                    amountOfResults
                }
            }
            results {
                data {
                    id
                    userStatus
                    type_update
                    searchresults {
                        search {
                            id
                            name
                            color
                        }
                    }
                    hasConversations
                    namegiventocontract
                    deadline_inschrijving
                    status
                    clients {
                        id
                        name
                        favicon
                        favicon_resized
                        pivot {
                            category
                        }
                    }
                    country {
                        name
                        alpha2
                        numeric
                    }
                    published_opdrachtgever
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

//? get all liked tenders of current user
export const QUERY_TENDERS_INTERESTING_MINIMUM_COLUMN = gql`
    query FilterInterestingMinimumCOLUMNTendersOverview(
        $page: Int
        $count: Int
        $orderField: String!
        $order: SortOrder!
        $search: TenderSearchInput
    ) {
        filterTenders(page: $page, first: $count, search: $search, orderBy: [{ column: $orderField, order: $order }]) {
            filters {
                title
                topFilter
                active
                searchRuleFilter
                values {
                    identifier
                    label
                    amountOfResults
                }
            }
            results {
                data {
                    id
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

//? Check if mp is winner of tender/lot
export const GET_CONTRACT_OF_TENDER_OR_LOTS = gql`
    query GetContractOfTenderOrLots($id: ID!) {
        tender(id: $id) {
            id
            contract {
                id
                options {
                    id
                    period
                    indefinite
                    amount
                    length
                }
            }
            lots {
                id
                contract {
                    id
                    options {
                        id
                        period
                        indefinite
                        amount
                        length
                    }
                }
            }
            bids {
                id
                winner
                marketParty {
                    id
                }
                lot {
                    id
                    number
                    contract {
                        id
                        options {
                            id
                            period
                            indefinite
                            amount
                            length
                        }
                    }
                }
            }
        }
    }
`;

//? Check if mp is winner of tender/lot
export const GET_DATES_OF_TENDER_OR_LOTS = gql`
    query GetDatesOfTenderOrLots($id: ID!) {
        tender(id: $id) {
            id
            status
            looptijd_start {
                lots
                value
            }
            published_looptijd_start
            published_looptijd_einde
            next_looptijd_einde {
                lots
                value
            }
            looptijd_einde {
                lots
                value
            }
            lots {
                id
                number
                looptijd_einde
                looptijd_start
                next_looptijd_einde
            }
            bids {
                id
                winner
                marketParty {
                    id
                }
                lot {
                    id
                    number
                    looptijd_einde
                    looptijd_start
                }
            }
        }
    }
`;

//? Check if mp is winner of tender/lot
export const GET_CONTRACT_OF_TENDER_OR_LOTS_NEW_FORMAT = gql`
    query GetContractOfTenderOrLotsNewFormat($id: ID!) {
        tender(id: $id) {
            id
            options {
                lots
                value
            }
        }
    }
`;

//? Check if mp is winner of tender/lot
export const GET_DATES_OF_TENDER_OR_LOTS_NEW_STRUCTURE = gql`
    query GetDatesOfTenderOrLotsNewStructure($id: ID!) {
        tender(id: $id) {
            id
            looptijd_start {
                lots
                value
            }
            contract_duration {
                lots
                value
            }
            next_looptijd_einde {
                lots
                value
            }
            looptijd_einde {
                lots
                value
            }
            published_looptijd_start
            published_looptijd_einde
        }
    }
`;

//? get CLICKED TENDER
export const QUERY_SINLGE_TENDER_SPLITVIEW = gql`
    query GetTenderDetailSplitview($id: ID!) {
        tender(id: $id) {
            id
            userStatus
            omschrijving
            omvang_opdracht
            namegiventocontract
            published_vestigingsplaats
            first_dispatchdate
            looptijd_einde_incl_opties {
                lots
                value
            }
            published_looptijd_start
            published_looptijd_einde
            published_opdrachtgever
            hasConversations
            bids {
                id
            }
            lots {
                id
            }
            userLabels {
                snippets {
                    snippets
                }
            }
            searchresults {
                search {
                    id
                    name
                    color
                }
            }
            scope {
                id
                min {
                    id
                    value
                    currency
                }
                max {
                    id
                    value
                    currency
                }
                total {
                    id
                    value
                    currency
                }
            }

            sectors {
                id
                name
                parent {
                    id
                    name
                }
            }
            contract {
                id
                description
                period {
                    id
                    value
                    type
                }
                options {
                    id
                    period
                    amount
                    length
                    indefinite
                }
            }
            country {
                name
                alpha2
                numeric
            }
            clientA {
                id
                name
                favicon
                favicon_resized
            }
            deadline_inschrijving
            looptijd_start {
                lots
                value
            }
            looptijd_einde {
                lots
                value
            }
            next_looptijd_einde {
                lots
                value
            }
            status
        }
    }
`;

//? get tenders that match with set variables in tender searchwizard
export const QUERY_TENDER_DETAIL_BUILDER = gql`
    query FilterSearchRuleTenderDetailInformation($id: ID!) {
        tender(id: $id) {
            id
            translated_country
            country {
                name
                alpha2
                numeric
            }
            first_dispatchdate
            scope {
                id
                min {
                    id
                    value
                    currency
                }
                max {
                    id
                    value
                    currency
                }
                total {
                    id
                    value
                    currency
                }
            }
            sectors {
                id
                name
                parent {
                    id
                    name
                }
            }
            omvang_opdracht
            omschrijving
            namegiventocontract
            clients {
                id
                name
                favicon
                favicon_resized
                pivot {
                    category
                }
            }
            contract {
                id
                options {
                    amount
                    length
                    period
                    indefinite
                    id
                }
            }
            published_opdrachtgever
            deadline_inschrijving
            status
            looptijd_start {
                lots
                value
            }
            looptijd_einde {
                lots
                value
            }
            looptijd_einde_incl_opties {
                lots
                value
            }
            next_looptijd_einde {
                lots
                value
            }
            published_looptijd_start
            published_looptijd_einde
        }
    }
`;

//? get all tenders in worklist of current user
export const QUERY_FULL_WORKLIST_MINIMUM = gql`
    query FilterWorklistMinimumOverview($page: Int, $count: Int, $orderBy: [OrderByClause!]!, $search: TenderSearchInput) {
        filterTenders(page: $page, first: $count, search: $search, orderBy: $orderBy) {
            filters {
                title
                topFilter
                active
                searchRuleFilter
                values {
                    identifier
                    label
                    amountOfResults
                }
            }
            results {
                data {
                    id
                    userStatus
                    searchresults {
                        search {
                            id
                            name
                            color
                        }
                    }
                    hasConversations
                    namegiventocontract
                    deadline_inschrijving
                    status
                    clients {
                        id
                        name
                        favicon
                        favicon_resized
                        pivot {
                            category
                        }
                    }
                    country {
                        name
                        alpha2
                        numeric
                    }
                    published_opdrachtgever
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

//? get all tenders in worklist of current user
export const QUERY_WORKLIST_SPLIT = gql`
    query FilterWorklistSplitView($page: Int, $count: Int, $orderBy: [OrderByClause!]!, $search: TenderSearchInput) {
        filterTenders(page: $page, first: $count, search: $search, orderBy: $orderBy) {
            filters {
                title
                topFilter
                active
                searchRuleFilter
                values {
                    identifier
                    label
                    amountOfResults
                }
            }
            results {
                data {
                    id
                    userStatus
                    status
                    namegiventocontract
                    published_opdrachtgever
                    clientA {
                        id
                        name
                        favicon
                        favicon_resized
                    }
                    searchresults {
                        search {
                            id
                            name
                            color
                        }
                    }
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

//? Query for default summary
export const QUERY_LOTS_OF_TNDR = gql`
    query GetLotsOfTender($id: ID!) {
        tender(id: $id) {
            id
            datum_gunning
            lots {
                ...FullTenderDetailLots
            }
        }
    }
    ${fragments.fullTenderDetailLots}
`;

//? Check if mp is winner of tender/lot
export const GET_CLIENTA_OF_TENDER = gql`
    query GetClientAOfTender($id: ID!) {
        tender(id: $id) {
            id
            clientA {
                id
                name
                favicon
                favicon_resized
            }
            published_opdrachtgever
        }
    }
`;

export const GET_FILTERSET_FOR_FILTERMODAL = gql`
    query getFilterset {
        getAllTenderFilterValues {
            filters {
                title
                topFilter
                active
                searchRuleFilter
                values {
                    identifier
                    label
                    amountOfResults
                    __typename
                }
                __typename
            }
        }
    }
`;
