import { gql } from "@apollo/client";

export const fullReply = gql`
    fragment FullReply on CommentReply {
        id
        comment
        user_deleted
        parentReplyId
        user {
            id
            employee {
                id
                name
                givenname
                familyname
            }
        }
        updated_at
        created_at
        deleted_at
    }
`;

export const fullCpv = gql`
    fragment FullCpv on Cpv {
        afdeling
        code
        omschrijving
        groep
        klasse
        subcategorie
    }
`;

export const fullExternalDateAsSource = gql`
    fragment FullExternalDateAsSource on ExternalDate {
        end
        executed
        id
        name
        period
        planned
        start
        type
    }
`;

export const fullPublication = gql`
    fragment FullPublication on Publication {
        id
        dispatchdate
        host
        type
        publication_type {
            ... on OriginalPublicationType {
                id
                name
                omschrijving
                __typename
            }
            ... on EformsPublicationType {
                id
                name
                omschrijving
                __typename
            }
        }

        omschrijving
        omvang_opdracht
        cpv_main
        cpv_extra {
            ...FullCpv
        }
        url_document
        nadere_toelichting
    }
    ${fullCpv}
`;

export const fullDocument = gql`
    fragment FullDocument on Document {
        id
        directory
        filename
        extension
        temporary_link
    }
`;

export const fullSource = gql`
    fragment FullSource on Source {
        ... on Document {
            ...FullDocument
        }
        ... on Publication {
            ...FullPublication
        }
        ... on ExternalDate {
            ...FullExternalDateAsSource
        }
    }
    ${fullDocument}
    ${fullPublication}
    ${fullExternalDateAsSource}
`;

export const descriptionOnTender = gql`
    fragment DescriptionOnTender on Tender {
        description {
            id
            value
            source {
                ...FullSource
            }
        }
        qualitativeScope {
            id
            value
            source {
                ...FullSource
            }
        }
    }
    ${fullSource}
`;

export const descriptionOnLot = gql`
    fragment DescriptionOnLot on Lot {
        description {
            id
            value
            source {
                ...FullSource
            }
        }
        qualitativeScope {
            id
            value
            source {
                ...FullSource
            }
        }
    }
    ${fullSource}
`;

export const fullPeriod = gql`
    fragment FullPeriod on Period {
        id
        startDate
        endDate
        type
        value
    }
`;

export const fullContract = gql`
    fragment FullContract on Contract {
        period {
            ...FullPeriod
        }
        options {
            amount
            length
            period
            indefinite
            id
        }
        source {
            ...FullSource
        }
        description
        undeterminedPeriod
    }
    ${fullPeriod}
    ${fullSource}
`;

export const fullLot = gql`
    fragment FullLot on Lot {
        id
        title
        number
        published_description
        award_status
        looptijd_start
        looptijd_einde
        looptijd_periode
        looptijd_interval
        looptijd_overeenkomst
        contract {
            ...FullContract
        }
        ...DescriptionOnLot
    }
    ${fullContract}
    ${descriptionOnLot}
`;

export const fullNuts = gql`
    fragment FullNuts on Nuts {
        id
        label
        code
        nuts_level
    }
`;

export const fullValue = gql`
    fragment FullValue on ValueObject {
        value
        currency
        vat_percentage
        vat
    }
`;

export const fullScope = gql`
    fragment FullScope on Scope {
        id
        source {
            ...FullSource
        }
        total {
            ...FullValue
        }
        min {
            ...FullValue
        }
        max {
            ...FullValue
        }
        description
        lines_list {
            id
            valueIsHistoric
            valueIsOptional
            valueIsEstimated
            description
            lot {
                ...FullLot
            }
            source {
                ...FullSource
            }
            unit {
                id
                label
            }
            unitValue
            minUnitValue
            maxUnitValue
            value {
                ...FullValue
            }
            minValue {
                ...FullValue
            }
            maxValue {
                ...FullValue
            }
            period {
                ...FullPeriod
            }
            parent_id
        }
    }
    ${fullPeriod}
    ${fullLot}
    ${fullValue}
    ${fullSource}
`;

export const fullOrganization = gql`
    fragment FullOrganization on Organization {
        id
        name
        kvknumber
        has_urls
        favicon
        favicon_resized
        names {
            id
            value
        }
    }
`;

export const fullSettling = gql`
    fragment FullSettling on Settling {
        id
        name
        visit_address {
            id
            city
            street
            country
            housenumber
            postal_code
            housenumber_suffix
        }
        settlingnumber
        type
        active
    }
    ${fullOrganization}
`;

export const fullSettlingWithOrganization = gql`
    fragment FullSettlingWithOrganization on Settling {
        ...FullSettling
        organization {
            ...FullOrganization
        }
    }
    ${fullOrganization}
    ${fullSettling}
`;

export const fullBid = gql`
    fragment FullBid on Bid {
        id
        combination
        marketParty {
            # ...FullOrganization
            favicon_resized
            favicon
            id
            name
            kvknumber
            has_urls
        }
        settling {
            ...FullSettlingWithOrganization
        }
        totalValue {
            ...FullValue
        }
        minValue {
            ...FullValue
        }
        maxValue {
            ...FullValue
        }
        winner
        preliminaryAwarded
        lot {
            ...FullLot
        }
    }
    ${fullLot}
    ${fullValue}
    ${fullOrganization}
    ${fullSettlingWithOrganization}
`;

export const fullContractingAuthority = gql`
    fragment FullContractingAuthority on ContractingAuthority {
        id
        name
        kvknumber
        province
        favicon
        favicon_resized
        names {
            id
            value
        }
        # adres_visit
        # postcode_visit
        #nuts {
        #   ...FullNuts
        #  parent {
        #     ...FullNuts
        #}
        #}
        #  plaats_visit

        pivot {
            id
            category
            lot {
                ...FullLot
            }
        }
    }
    ${fullLot}
`;

export const fullSubSector = gql`
    fragment FullSubSector on Sector {
        id
        name
        parent {
            id
            name
        }
    }
`;

export const relatedTender = gql`
    fragment RelatedTenderSubset on Tender {
        id
        published_namegiventocontract
        #  status
        displayPublications {
            dispatchdate
        }
    }
`;

export const fullTender = gql`
    fragment FullTender on Tender {
        id
        followed
        notFollowable
        published_namegiventocontract
        published_host
        published_geraamde_waarde
        published_participants
        # WITHOUT DUPLICATES
        sorted_tags {
            id
            lots
            tags
        }
        # TAGGING
        tags {
            id
            tags
            pivot {
                id
                lot_id
            }
            sectors {
                id
            }
        }

        # Enrichment step status
        enrichmentstatus {
            created_at
            id
            step
            updated_at
            #    valid
        }
        missingEnrichmentSteps
        shouldUpdatePlanning

        # Opdrachtgever info
        published_opdrachtgever
        published_vestigingsplaats
        published_additional_contracting_authority
        published_adres
        published_contactpersoon
        translated_country
        country {
            name
            alpha2
            numeric
        }
        published_email
        published_telefoon
        published_postcode

        # Published data about contract dates
        published_looptijd_start
        published_looptijd_einde
        published_looptijd_overeenkomst
        published_looptijd_periode
        published_looptijd_interval
        published_looptijd_beschrijving

        deadline_inschrijving

        tenderned_link
        original_link
        status

        market_consultation

        cpv {
            ...FullCpv
        }

        documentDateCount {
            amount
            date
        }

        relevantDocuments {
            id
            temporary_link
            filename
            created_at
        }
        #   documentsList {
        #      id
        #     label
        #    link: temporary_link
        #   parent_id
        #  date
        #}
        lots {
            ...FullLot
        }
        sectors {
            ...FullSubSector
        }
        clients {
            ...FullContractingAuthority
        }
        scope {
            ...FullScope
        }
        contract {
            ...FullContract
        }
        regions {
            ...FullNuts
        }
        bids {
            ...FullBid
        }
        displayPublications {
            ...FullPublication
        }
        awardPublications {
            ...FullPublication
        }
        status
        opmerking
        remark_award
        inschrijvers_bekend
        #        externalDates {
        #           name
        #          planned
        #         executed
        #        type
        #       start
        #      end
        #     period
        #}
        created_at
        updated_at
        related_tenders {
            ...RelatedTenderSubset
        }
        related_tenders_suggestion {
            ...RelatedTenderSubset
        }
        ...DescriptionOnTender
        events {
            id
            source {
                ...FullSource
            }
            tags {
                name
            }
            name
            planned
            timeSignificant
            isReplacedBy {
                id
                name
            }
            periodStart {
                id
                planned
            }
            periodEnd {
                id
                planned
            }
            created_at
            updated_at
        }
    }

    ${fullLot}
    ${fullPeriod}
    ${fullContract}
    ${fullNuts}
    ${fullScope}
    ${fullBid}
    ${fullContractingAuthority}
    ${fullPublication}
    ${fullSubSector}
    ${fullCpv}
    ${descriptionOnTender}
    ${relatedTender}
`;

export const fullDocumentAsSource = gql`
    fragment FullDocumentAsSource on Document {
        id
        directory
        extension
        filename
        created_at
        temporary_link
    }
`;

export const fullPublicationAsSource = gql`
    fragment FullPublicationAsSource on Publication {
        id
        dispatchdate
        host
        type

        publication_type {
            ... on OriginalPublicationType {
                id
                name
                omschrijving
                __typename
            }
            ... on EformsPublicationType {
                id
                name
                omschrijving
                __typename
            }
        }
        url_document
    }
`;

export const fullTenderAwardLots = gql`
    fragment FullTenderAwardLots on Lot {
        id
        title
        number
        award_date
        looptijd_start
        next_looptijd_einde
        participants_count
    }
`;

export const fullTenderDetailLots = gql`
    fragment FullTenderDetailLots on Lot {
        id
        title
        number
        next_looptijd_einde
        looptijd_start
        looptijd_einde
        participants_count
        award_date
        lowest_bid
        highest_bid
        looptijd_periode
        looptijd_interval
        looptijd_overeenkomst
        # contract
        contract {
            id
            description
            undeterminedPeriod
            period {
                ...FullPeriod
            }
            options {
                amount
                length
                period
                indefinite
                id
            }
            source {
                ...FullSource
            }
        }

        # data to show in summary
        published_description
        description {
            id
            value
            source {
                ...FullSource
            }
        }
        qualitativeScope {
            id
            value
            source {
                ...FullSource
            }
        }
    }
    ${fullSource}
    ${fullPeriod}
`;

export const fullBidsTenderDetail = gql`
    fragment FullBidsTenderDetail on Bid {
        id
        bid
        combination
        percentage
        totalValue {
            id
            value
            currency
        }
        winner
        preliminaryAwarded
        marketParty {
            id
            favicon
            favicon_resized
            name
            names {
                id
                type
                value
            }
            country {
                alpha2
            }
        }
        lot {
            id
        }
    }
`;
