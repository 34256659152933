import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import "swiper/css"; // Import Swiper styles
import "swiper/css/navigation";
import "swiper/css/pagination";
import ColumnGridArticle from "./ColumnGridArticle";
import { QUERY_GETALLARTICLES } from "../../graphql/queryDefFaq";
import { getAllArticles } from "../../__generated__/getAllArticles";
import { useQuery } from "@apollo/client";

interface Props {
    currentID: string;
}

const ColumnCarousel: React.FC<Props> = ({ currentID }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const { data, error, loading } = useQuery<getAllArticles>(QUERY_GETALLARTICLES);

    if (loading) {
        return <p>loading</p>;
    }

    /**
     * Error occured or no data availables
     */
    if (error || !data || !data.articles) return <p>error loading articles</p>;

    const dataToShow = data.articles
        .filter((article) => article.category === "column" && article.module === "column" && article.active === true)
        .filter((art) => art.id !== currentID);

    const arr = [...dataToShow];

    const newestArticleIndex = data.articles
        .map((article, index) => ({ ...article, index }))
        .filter((article) => article.category === "column" && article.module === "column" && article.active === true && article.id !== currentID)
        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0]?.index;

    return (
        <Box py={noMobile ? 4 : 2} px={noMobile ? 4 : 2}>
            <Swiper
                modules={[Navigation, Pagination]}
                initialSlide={newestArticleIndex}
                // slidesPerGroup={1}
                centeredSlides={false}
                loop
                spaceBetween={10} // Space between slides
                pagination={{ clickable: true, type: "bullets", dynamicBullets: true }}
                navigation={true}
                breakpoints={{
                    640: {
                        slidesPerView: 1, // 1 slide per view for small screens
                    },
                    1024: {
                        slidesPerView: 2, // 2 slides per view for medium screens
                    },
                    1200: {
                        slidesPerView: 5, // 5 slides per view for larger screens
                    },
                    2500: {
                        slidesPerView: 8, // 8 slides per view for extra large screens
                    },
                }}
                style={{ padding: "32px 0px" }}
            >
                {arr.map((column: any, index: number) => (
                    <SwiperSlide key={column.id || index}>
                        <ColumnGridArticle
                            cardMediaHeight={175}
                            index={index}
                            title={column.title}
                            id={column.id}
                            date={column.created_at}
                            author_name={column.author.name}
                            author_picture={column.author.picture}
                            author_org={column.author.organization.logo}
                            author_function={column.author.function}
                            tags={column.tags}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );
};

export default ColumnCarousel;
