import React, { useContext, useState } from "react";
import { Avatar, Grid, Paper, Typography } from "@mui/material";
import { ProjectToOpenContext } from "../../../../components/layout/MainLayout";
import { MentionsInput, Mention } from "react-mentions";
import NoteParagraph from "./NoteParagraph";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";
import { SINGLE_PROJECT, SINGLE_PROJECT_NOTES } from "../../../../graphql/queryDefProjects";
import { CREATE_COMMENT_IN_PROJECT } from "../../../../graphql/mutationDefProjects";
import { single_project_notes, single_project_notesVariables } from "../../../../__generated__/single_project_notes";
import { createComment, createCommentVariables } from "../../../../__generated__/createComment";
import MentionButton from "./MentionButton";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../../models/MatomoEvent";
import { Disable } from "react-disable";
import DOMPurify from "dompurify";
import { sanitizeInput } from "../../../../utils/sanitizeInput";

interface Props {
    a?: number;
}

export interface Note {
    sender: {
        id: string;
        initials: string;
    };
    comment: string;
    date: string;
}

//inline mention
export const defaultMentionStyle = {
    backgroundColor: "#cee4e5",
    borderRadius: "4px",
    padding: "2px",
    marginLeft: "-3px",
};

// menu
export const defaultStyle = {
    control: {
        width: "100%",
        backgroundColor: "#fff",
        fontSize: 14,
        fontWeight: "normal",
    },

    "&singleLine": {
        display: "inline-block",
        width: "100%",

        "&:hover": {
            backgroundColor: "pink",
        },

        highlighter: {
            padding: 10,
            paddingTop: 8,
            borderRadius: "4px",
        },
        input: {
            outline: "1px solid #b8b8b8", // Use outline instead of border
            border: "none",
            padding: 9,
            paddingTop: 11,
            borderRadius: "4px",
        },
    },

    "&multiLine": {
        control: {
            minHeight: 35,
            borderRadius: "4px",
        },
        highlighter: {
            padding: 9,
            border: "1px solid transparent",
            borderRadius: "4px",
        },
        input: {
            padding: 9,
            paddingTop: 11,
            border: "1px solid silver",
            borderRadius: "4px",
        },
    },

    // List to show
    suggestions: {
        borderRadius: "6px" /* Adjust the border-radius as needed */,
        // border: "1px solid #cecece",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",

        overflow: "hidden",

        list: {
            backgroundColor: "white",
            fontSize: 14,
        },
        item: {
            padding: "5px 15px",
            "&focused": {
                backgroundColor: "#cee4e5",
            },
        },
    },
};

const Notes: React.FC<Props> = (props) => {
    const { trackEvent } = useMatomo();
    const trackClick = (event: MatomoEvent) => {
        trackEvent(event);
    };
    const { project } = useContext(ProjectToOpenContext);
    const [value, setValue] = useState("");

    const { data, loading, error, refetch } = useQuery<single_project_notes, single_project_notesVariables>(SINGLE_PROJECT_NOTES, {
        variables: {
            id: project.projectID,
        },
        fetchPolicy: "network-only",
    });

    // Create new comment
    const [saveNewNote] = useMutation<createComment, createCommentVariables>(CREATE_COMMENT_IN_PROJECT);

    const users = project.users.map((user) => ({
        id: user.id,
        display: user.name,
    }));

    const saveNewComment = () => {
        onSaveNewComment();
        trackClick({ category: "Project", action: "Save-comment" });
        setValue("");
    };

    /**
     * Create new note
     * @param taskId id of task
     * @param priority priority of task
     */
    const onSaveNewComment = async () => {
        if (value.length > 2) {
            try {
                saveNewNote({
                    variables: {
                        project_id: project.projectID,
                        comment: value,
                    },
                    onCompleted: () => {
                        refetch();
                    },
                    refetchQueries: [SINGLE_PROJECT],
                });
            } catch (e) {}
        }
    };

    // Mention button
    const handleAddAtSymbol = () => {
        setValue(value + " @");
        trackClick({ category: "Project", action: "Use-mention-button" });
        const textField = document.getElementById("myTextField");
        if (textField) {
            textField.focus();
        }
    };

    const renderpage = (content: React.ReactNode) => {
        return (
            <Grid item style={{ marginTop: 40 }}>
                <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between", marginBottom: "8px" }}>
                    {/* Component title */}
                    <Typography variant="h6" sx={{ fontSize: "1.1rem" }}>
                        Notities
                    </Typography>
                </div>
                <div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Avatar
                            sx={{
                                width: "28px",
                                height: "28px",
                                marginRight: "12px",
                            }}
                        >
                            <Typography sx={{ fontSize: "0.8rem" }}>{localStorage.getItem("initials")}</Typography>
                        </Avatar>

                        {/* <TextField fullWidth size="small" placeholder="Schrijf een notitie..." /> */}

                        <MentionsInput
                            id="myTextField"
                            singleLine
                            style={defaultStyle}
                            placeholder="Notitie schrijven..."
                            value={value}
                            onChange={(e) => {
                                const value = sanitizeInput(e.target.value, true);
                                const val = DOMPurify.sanitize(value);
                                setValue(val);
                            }}
                            onKeyPress={(event) => {
                                if (event.key === "Enter" && value.length > 2) {
                                    saveNewComment();
                                }
                            }}
                        >
                            <Mention
                                style={defaultMentionStyle}
                                trigger={users.length > 1 ? "@" : "werktnunietwantjebentdeenigegebruikerinditproject"}
                                data={users}
                                appendSpaceOnAdd
                            />
                        </MentionsInput>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "40px", height: "8px" }} />
                        <Disable disabled={users.length === 1}>
                            <MentionButton handleAddAtSymbol={handleAddAtSymbol}>@</MentionButton>
                        </Disable>
                    </div>
                </div>
                <Paper elevation={0} style={{ marginBottom: "16px", marginTop: "16px" }}>
                    {content}
                </Paper>
            </Grid>
        );
    };

    if (loading) {
        return renderpage(<div />);
    }

    if (error || !data || !data.project || !data.project.comments) {
        return renderpage(<div>Er ging iets mis...</div>);
    }

    // render a note component for each item
    return renderpage(
        <React.Fragment>
            {[...data.project.comments]
                .sort((a, b) => (moment(b.created_at ? b.created_at : "").isBefore(moment(a.created_at ? a.created_at : "")) ? -1 : 1))
                .map((c, index) => (
                    <Disable disabled={Boolean(c.user_deleted)} key={c.id}>
                        <NoteParagraph key={index} comment={c} />
                    </Disable>
                ))}
        </React.Fragment>
    );
};

export default Notes;
