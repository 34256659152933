import { gql } from "@apollo/client";

// ? Get all sidebar articles of current module
export const QUERY_SIDEBAR_ARTICLES_OF_CURRENT_MODULE = gql`
    query GetSideBarArticlesOfModule($module: String!) {
        getSidebarArticle(module: $module) {
            id
            active
            category
            content
            language
            module
            slug
            title
            top_item
        }
    }
`;

// ? get all articles sorted on module column
export const QUERY_GETALLARTICLES = gql`
    query getAllArticles {
        articles(orderBy: { column: "module", order: DESC }) {
            id
            title
            active
            category
            language
            module
            slug
            top_item
            tags {
                name
            }
            author {
                id
                name
                picture
                function
                organization {
                    id
                    name
                    logo
                }
            }
            created_at
        }
    }
`;

// ? get all articles sorted on module column
export const QUERY_FILTER_ARTICLES = gql`
    query filterArticles($module: String, $category: String, $search: String, $first: Int!, $page: Int) {
        filterArticles(orderBy: { column: "id", order: DESC }, first: $first, page: $page, category: $category, module: $module, search: $search) {
            data {
                id
                title
                active
                category
                language
                module
                slug
                top_item
                content
                created_at
                tags {
                    name
                }
                author {
                    id
                    name
                    email
                    givenname
                    familyname
                    linkedin
                    picture
                    function
                    phonenumber
                    organization {
                        id
                        name
                        logo
                    }
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

// ? get single article (clicked item by user)
export const QUERY_GETSINGLEARTICLE = gql`
    query SingleArticle($id: ID!) {
        article(id: $id) {
            id
            title
            category
            content
            module
            slug
            top_item
            created_at
            active
            language
            tags {
                name
            }
            author {
                id
                name
                email
                givenname
                familyname
                linkedin
                picture
                function
                phonenumber
                organization {
                    id
                    name
                    logo
                }
            }
        }
    }
`;
