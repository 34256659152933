import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import MainLayout from "../../components/layout/MainLayout";
import { Route, Routes, useLocation } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import setTheme from "../../utils/setTheme";
import { greyTheme } from "../../assets/styles";
import CategoryOverview, { arrayWithCategories } from "./CategoryOverview";
import SingleCatPage from "./SingleCatPage";
import DetailPage from "./DetailPage";

interface Props {
    a?: number;
}

const KnowledgeHubModule: React.FC<Props> = (props) => {
    const history = useLocation();
    setTheme("darkGray");

    const { trackPageView } = useMatomo();

    // Track page view
    trackPageView({
        documentTitle: "Knowledgehub", // optional
        href: `https://tool.tender.guide${history.pathname}`, // optional
    });

    return (
        <ThemeProvider theme={greyTheme}>
            <MainLayout page="knowledgehub">
                <Routes>
                    {/* route with all categories in cards */}
                    <Route path="" element={<CategoryOverview />} />

                    {/* map categories */}
                    {arrayWithCategories.map((cat) => {
                        return (
                            <Route
                                key={cat.id}
                                path=":categoryName"
                                element={<SingleCatPage title={cat.title} subtext={cat.subText} showSearch={true} textBelowSearchBar={undefined} />}
                            />
                        );
                    })}

                    <Route path=":categoryName/:id" element={<DetailPage />} />
                </Routes>
            </MainLayout>
        </ThemeProvider>
    );
};

export default KnowledgeHubModule;
