import { useQuery } from "@apollo/client";
import React, { useContext, useState } from "react";
import { GetCasOfTenderForProject, GetCasOfTenderForProjectVariables } from "../../../__generated__/GetCasOfTenderForProject";
import { QUERY_CAS_OF_TENDER_FOR_PROJECT } from "../../../graphql/queryDefProject";
import { Checkbox, Collapse, Divider, InputAdornment, List, ListItemButton, ListItemIcon, ListItemText, TextField, Typography } from "@mui/material";
import { Business, Search } from "@mui/icons-material";
import FaviconBox from "../../../components/FaviconBox";
import { ProjectToOpenContext } from "../../../components/layout/MainLayout";
import DOMPurify from "dompurify";
import { sanitizeInput } from "../../../utils/sanitizeInput";

interface Props {
    id: string;
}

const CaSection: React.FC<Props> = ({ id }) => {
    const { project, changeProject } = useContext(ProjectToOpenContext);
    const [staticLoad, setStaticLoad] = useState<boolean>(true);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const {
        data: fetchdata,
        loading,
        error,
    } = useQuery<GetCasOfTenderForProject, GetCasOfTenderForProjectVariables>(QUERY_CAS_OF_TENDER_FOR_PROJECT, {
        variables: {
            id: id,
        },
        onCompleted: (data) => {
            if (data && data.tender && data.tender.clients) {
                changeProject({ ca_ids: data.tender.clients.map((i) => i.id) });
            }
            // set staticload to false to prevent GUI from flipping
            setStaticLoad(false);
        },
    });

    if (loading || staticLoad) {
        return (
            <List sx={{ width: "100%", bgcolor: "background.paper" }} component="nav" aria-labelledby="nested-list-subheader">
                <ListItemButton disabled dense>
                    <ListItemIcon sx={{ minWidth: 0, marginRight: "12px" }}>
                        <Business sx={{ color: "#F57117" }} />
                    </ListItemIcon>
                    <ListItemText primary="Laden..." />
                    <Checkbox checked={true} />
                </ListItemButton>
            </List>
        );
    }

    if (!fetchdata || !fetchdata.tender || !fetchdata.tender.clients || error) {
        return (
            <List sx={{ width: "100%", bgcolor: "background.paper" }} component="nav" aria-labelledby="nested-list-subheader">
                <ListItemButton disabled dense>
                    <ListItemIcon sx={{ minWidth: 0, marginRight: "12px" }}>
                        <Business sx={{ color: "#F57117" }} />
                    </ListItemIcon>
                    <ListItemText primary="Er ging iets mis" />
                    <Checkbox checked={true} />
                </ListItemButton>
            </List>
        );
    }

    const caClients = fetchdata.tender.clients;

    // If no enriched ca's are available => hide CA section
    if (caClients.length === 0) {
        return <Divider />;
    }

    const handleSelect = (value: boolean, client: any) => {
        // if client doesnt exist => add to array
        if (!value) {
            changeProject({ ca_ids: [...project.ca_ids, client] });
        }
        // if client doesnt exist => remove from array
        else {
            const newss = project.ca_ids.filter((item) => item !== client);

            changeProject({ ca_ids: newss });
        }
    };

    /**
     * Select all ca of current tender
     */
    const selectAll = () => {
        if (fetchdata.tender?.clients.length !== project.ca_ids.length) {
            // if true => select all
            changeProject({ ca_ids: fetchdata.tender?.clients.map((i) => i.id) });
        } else {
            // if false => unselect all

            changeProject({ ca_ids: [] });
        }
    };

    const chosenLength = project.ca_ids.length;
    const checkedLength = caClients.length !== chosenLength;

    /**
     * handle user input in searchbox to search a user on his givenname
     * @param event Value from textfield to search a user in the list.
     */
    const handleSearch = (event: any) => {
        event.stopPropagation();
        const {
            target: { value },
        } = event;
        const inputvalue = sanitizeInput(value, false);
        const val = DOMPurify.sanitize(inputvalue);
        setSearchTerm(val);
    };

    /**
     * Array that gives users back that match with input in searchfield.
     * - match based on input and givenname
     */
    const results = !searchTerm ? caClients : caClients.filter((item) => item.name?.toLowerCase().includes(searchTerm.toLocaleLowerCase()));

    return (
        <React.Fragment>
            <Divider />
            <Typography variant="body1" sx={{ fontWeight: 500, marginTop: "16px" }}>
                Aanbestedende diensten toevoegen
            </Typography>
            <List sx={{ width: "100%", bgcolor: "background.paper" }} component="nav" aria-labelledby="nested-list-subheader">
                <ListItemButton onClick={() => selectAll()} dense>
                    <ListItemIcon sx={{ minWidth: 0, marginRight: "12px" }}>
                        <Business sx={{ color: "#F57117" }} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Alle gekoppelde aanbestedende diensten"
                        secondary={`${caClients[0].name} ${caClients.length - 1 > 1 ? `en ${caClients.length - 1} andere` : ""}`}
                    />
                    <Checkbox
                        checked={caClients.length === chosenLength}
                        indeterminate={caClients.length > 0 && caClients.length <= chosenLength - 1}
                    />
                </ListItemButton>
                <Collapse in={checkedLength} timeout="auto" unmountOnExit>
                    {results.length > 5 && (
                        <div style={{ display: "flex" }}>
                            <TextField
                                onClick={(e) => e.stopPropagation()}
                                sx={{ margin: "10px", flexGrow: 1 }}
                                placeholder="Zoek een gebruiker..."
                                size="small"
                                variant="outlined"
                                onChange={(e) => {
                                    e.stopPropagation();
                                    handleSearch(e);
                                }}
                                fullWidth
                                value={searchTerm}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    )}

                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                        {/* Labels */}
                        {results.map((ca) => {
                            return (
                                <ListItemButton key={ca.id} onClick={() => handleSelect(Boolean(project.ca_ids.includes(ca.id)), ca.id)} dense>
                                    <ListItemIcon sx={{ minWidth: 0, marginRight: "12px" }}>
                                        <FaviconBox favicon={ca.favicon_resized ?? ca.favicon} name={ca.name} />
                                    </ListItemIcon>
                                    <ListItemText id={ca.id} primary={ca.name} />
                                    <Checkbox
                                        name={ca.name as string}
                                        edge="start"
                                        checked={Boolean(project.ca_ids.find((i) => i === ca.id))}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Collapse>
            </List>
            <Divider />
        </React.Fragment>
    );
};

export default CaSection;
