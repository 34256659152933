import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import ColumnCarousel from "./ColumnCarousel";
import moment from "moment";
import ColumnAuthor from "./ColumnAuthor";
import ColumnContent from "./ColumnContent";
import { Today } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { QUERY_GETALLARTICLES, QUERY_GETSINGLEARTICLE } from "../../graphql/queryDefFaq";
import { SingleArticle, SingleArticleVariables } from "../../__generated__/SingleArticle";
import { useQuery } from "@apollo/client";
import ColumnFooter from "./ColumnFooter";
import ColumnDots from "./ColumnDots";
import { getAllArticles } from "../../__generated__/getAllArticles";
import ColumnLoadAndError from "./ColumnLoadAndError";
import { QueryInitials } from "../../__generated__/QueryInitials";
import { QUERY_INITIALS } from "../../graphql/queryDefCurrentUser";
import ButtonBack from "../knowledgeHub/ButtonBack";

interface Props {
    props?: number;
}

// Styled Paper component
const StyledPaper = styled(Grid)(({ theme }) => ({
    height: "auto",
    position: "relative",
}));

const ColumnDetailContainer: React.FC<Props> = (props) => {
    const { data: user } = useQuery<QueryInitials>(QUERY_INITIALS);
    const name = user?.currentUser.employee.name?.replace(/\s/g, "%20");

    const theme = useTheme();
    const aboveMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const aboveTablet = useMediaQuery(theme.breakpoints.up("md"));
    const navigate = useNavigate();

    const { categoryName, id } = useParams();

    const columnID = id as string;
    const { data: columns, loading: columnsLoading } = useQuery<getAllArticles>(QUERY_GETALLARTICLES);
    const dataToShow = columns?.articles.filter((article) => article.category === "column" && article.active === true).reverse();

    // Determine the columnID to use for fetching the single article
    const effectiveColumnID = columnID || (dataToShow?.[0]?.id as string);

    const { data, error, loading } = useQuery<SingleArticle, SingleArticleVariables>(QUERY_GETSINGLEARTICLE, {
        skip: categoryName !== "column",
        // skip: columnID === undefined,
        fetchPolicy: "network-only",
        variables: { id: effectiveColumnID },
        onCompleted: () => {
            window.scrollTo({ top: 0, behavior: "instant" });
            // Check if there's an error or if the category is not "column"
            if (error || data?.article?.category !== "column") {
                navigate("/knowledge-hub");
            }
        },
    });

    if (loading || columnsLoading) {
        return <ColumnLoadAndError content="loading" />;
    }

    if (error || data?.article?.category !== "column") {
        return <ColumnLoadAndError content="Er ging iets mis" />;
    }

    // Assuming data?.article?.content contains the string
    const replacingContent =
        data?.article?.id === "138" && data?.article?.content
            ? data.article.content.replace("Neem dan snel contact met ons op voor meer informatie of een kennismakingsgesprek.", "<CONTACT/>")
            : data.article.content;

    return (
        <StyledPaper container>
            <ButtonBack navigateTo={`/knowledge-hub/column`} category={null} />
            {/* HEADER GRID */}
            <Grid item container xs={12}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        borderRadius: "26% 26% 49% 51% / 0% 0% 50% 50%",
                        backgroundColor: "#70a4b726",
                        padding: aboveTablet ? "128px 0px" : aboveMobile ? "64px 0px" : "16px 0px",
                    }}
                >
                    <div>
                        <Typography
                            sx={{
                                fontSize: aboveTablet ? "40px" : aboveMobile ? "1.5rem !important" : "1.3rem !important",
                                fontWeight: 600,
                                textAlign: "center",
                                padding: aboveMobile ? 0 : "0px 16px",
                            }}
                        >
                            {data?.article?.title}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                        <Today
                            sx={{
                                height: aboveMobile ? "24px" : "16px",
                                width: aboveMobile ? "24px" : "16px",
                                marginRight: "8px",
                                color: "#0000008a",
                            }}
                        />
                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                            {moment(data?.article?.created_at).format("LL")}
                        </Typography>
                    </div>
                </Grid>

                <Grid item container columns={14} pt={aboveTablet ? 12 : aboveMobile ? 6 : 4}>
                    <Grid item xs={14} md={3}>
                        {/* COLUMN TO FILL WITH SHAPES */}
                    </Grid>
                    {/* CONTENT BOX */}
                    <Grid
                        item
                        xs={14}
                        md={7}
                        paddingBottom={0}
                        px={aboveTablet ? 0 : "16px"}
                        sx={{ fontSize: aboveMobile ? " 15px" : "medium", overflowX: "hidden" }}
                    >
                        <ColumnContent text={replacingContent || ""} column_id={data.article.id} username={name || ""} />
                    </Grid>
                    <Grid item xs={14} md={4} px={aboveMobile ? 0 : 2}>
                        <div
                            style={{
                                position: "sticky",
                                top: "32px",
                                width: "100%",
                                overflowX: "hidden",
                                padding: aboveTablet ? "0px 96px" : aboveMobile ? "0px 128px" : "0px",
                            }}
                        >
                            <ColumnAuthor
                                column_id={data.article.id}
                                skeleton={false}
                                orgLogo={data?.article?.author?.organization.logo || ""}
                                authorImg={data?.article?.author?.picture || ""}
                                authorName={data?.article?.author?.name || ""}
                                authorTitle={data?.article?.author?.function || ""}
                                linkedin={data?.article?.author?.linkedin || ""}
                                phone={data?.article?.author?.phonenumber || ""}
                                mail={
                                    data.article.id === "138"
                                        ? `impact@bernaysinstitute.com?subject=Informatieaanvraag%20vanuit%20TenderGuide&body=Beste%20Bart,%0D%0A%0D%0ANaar%20aanleiding%20van%20de%20column%20op%20TenderGuide%20zou%20ik%20graag%20meer%20informatie%20ontvangen%20of%20een%20gesprek%20aangaan%20over%20wat%20Bernays%20Institute%20voor%20ons%20kan%20betekenen.%0D%0A%0D%0AMet%20vriendelijke%20groet,%0D%0A%0D%0A${name}`
                                        : data?.article?.author?.email || ""
                                }
                            />
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            {/* Left spacing grid */}
            {/* <Grid item xs={12} md={2} /> */}

            {/* GRID WITH ALL COLUMNS */}
            <Grid item xs={12} md={12} padding={aboveMobile ? 12 : 0} pt={aboveMobile ? 8 : 0} marginBottom={"64px"}>
                <ColumnCarousel currentID={data?.article?.id as string} />
            </Grid>

            {/* DOTS */}
            {aboveTablet && <ColumnDots position={"absolute"} />}

            {/* FOOTER */}
            <ColumnFooter />
        </StyledPaper>
    );
};

export default ColumnDetailContainer;
