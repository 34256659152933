import React, { useEffect, useState } from "react";
import { Box, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { Check, Clear } from "@mui/icons-material";
import { IconButton, TextField, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { SINGLE_PROJECT } from "../../../graphql/queryDefProjects";
import { UPDATE_PROJECT_NAME } from "../../../graphql/mutationDefProjects";
import { updateProjectName, updateProjectNameVariables } from "../../../__generated__/updateProjectName";
import DOMPurify from "dompurify";
import { sanitizeInput } from "../../../utils/sanitizeInput";

interface Props {
    projectTitle: string;
    setProjectTitle(name: string): void;
    editMode: boolean;
    setEditMode(editMode: boolean): void;
    projectId: string;
    bossOfProject: boolean;
}

const ProjectTitle: React.FC<Props> = ({ projectId, projectTitle, setProjectTitle, editMode, setEditMode, bossOfProject }) => {
    const [name, setName] = useState(projectTitle);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (projectTitle === "Voer een project titel in") {
            setEditMode(true);
        }
    }, [setEditMode, projectTitle]);

    /**
     * Set name to new input
     * set editmode => false
     */
    const saveSetting = () => {
        setProjectTitle(name);
        setEditMode(false);
    };

    /**
     * Set name to new input
     * set editmode => false
     */
    const updateSetting = () => {
        updateProjectTitle();
        setProjectTitle(name);
        setEditMode(false);
    };

    /**
     * reset name to last saved name
     * set editmode => false
     */
    const cancelSetting = () => {
        setName(projectTitle);
        setEditMode(false);
    };

    /**
     * Update project title
     */
    const [updateProjectTitle] = useMutation<updateProjectName, updateProjectNameVariables>(UPDATE_PROJECT_NAME, {
        variables: { id: projectId, title: name },
        refetchQueries: [SINGLE_PROJECT, "single_project"],
        awaitRefetchQueries: true,
    });

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: isMobile ? `calc(100% - 100px)` : `calc(100% - 190px)` }}>
            {/*
             * Check if editmode is active and check if user is boss of team
             * If both are true => show textfield to change a teamname
             */}
            {editMode && bossOfProject ? (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <TextField
                        autoFocus
                        name={"function"}
                        variant={"outlined"}
                        fullWidth
                        size={"small"}
                        value={name}
                        onChange={(e) => {
                            const inputvalue = sanitizeInput(e.target.value, false);
                            const val = DOMPurify.sanitize(inputvalue);
                            setName(val);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                if (projectTitle === name || projectTitle !== "Voer een project titel in") {
                                    cancelSetting();
                                } else {
                                    updateSetting();
                                }
                            }
                        }}
                        inputProps={{ style: { fontSize: isMobile ? "0.8rem" : "18px", fontWeight: 500 } }} // font size of input text
                    />
                    {/* Show button to save new teamname
                     * Button will be disabled as long as teamname = "Voer een teamnaam in" & teamname is less than 3 characters
                     */}
                    {projectTitle === "Voer een project titel in" && (
                        <Tooltip title="Opslaan" placement="top">
                            <IconButton size="small" disabled={name === "Voer een teamnaam in" || name.length < 3} onClick={() => saveSetting()}>
                                <Check />
                            </IconButton>
                        </Tooltip>
                    )}

                    {/* Show button to save new teamname and a button to cancel namechange if teamname was already changed
                     * Button will be disabled as long as teamname = "Voer een teamnaam in" & teamname is less than 3 characters
                     */}
                    {projectTitle !== "Voer een project titel in" && (
                        <React.Fragment>
                            <Tooltip title="Opslaan" placement="top">
                                <IconButton
                                    disabled={name.length < 3 || projectTitle === "Voer een project titel in" || projectTitle === name}
                                    size="small"
                                    onClick={() => updateSetting()}
                                >
                                    <Check />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Annuleren" placement="top">
                                <IconButton size="small" onClick={() => cancelSetting()}>
                                    <Clear />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    )}
                </div>
            ) : (
                // Clickable box to activate editmode
                <Box
                    onClick={() => setEditMode(true)}
                    sx={{
                        padding: "10.5px 14px 10.5px 14px",
                        height: "42.88px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        "&:hover": {
                            backgroundColor: bossOfProject ? "#f4f4f4" : "transparent",
                            cursor: bossOfProject ? "pointer" : "default",
                            borderRadius: bossOfProject ? "4px" : undefined,
                            width: "100%",
                        },
                    }}
                >
                    <Typography noWrap sx={{ fontSize: "18px", fontWeight: 500 }}>
                        {projectTitle}
                    </Typography>
                </Box>
            )}
        </div>
    );
};
export default ProjectTitle;
