import { gql } from "@apollo/client";
import * as fragments from "./fragments";

export const SET_ORG_TAG = gql`
    mutation addOrganizationTag($tag_id: ID!, $organization_id: ID!) {
        addOrganizationTag(tag_id: $tag_id, organization_id: $organization_id)
    }
`;

export const SET_RELEVANT_DOCUMENTS = gql`
    mutation SetRelevantDocuments($tenderId: ID!, $relevantDocuments: [Int]!) {
        setRelevantDocuments(id: $tenderId, relevantDocuments: $relevantDocuments) {
            relevantDocuments {
                id
                filename
                created_at
                temporary_link
            }
        }
    }
`;

export const SET_CONTRACTING_AUTHORITIES = gql`
    mutation SetContractingAuthorities($tenderId: ID!, $contractingAuthorities: [ContractingAuthoritiesInput]!) {
        setContractingAuthorities(id: $tenderId, contractingAuthorities: $contractingAuthorities) {
            clients {
                ...FullContractingAuthority
            }
        }
    }
    ${fragments.fullContractingAuthority}
`;

export const SET_SECTOR = gql`
    mutation SetSector($tenderId: ID!, $sectors: [Int]!, $updateSectordate: Boolean!) {
        setSectors(id: $tenderId, sectors: $sectors, update_sectordate: $updateSectordate) {
            sectors {
                ...FullSubSector
            }
        }
    }
    ${fragments.fullSubSector}
`;

export const SET_SCOPE = gql`
    mutation SetScope($tenderId: ID!, $scope: ScopeInput!) {
        setScope(id: $tenderId, scope: $scope) {
            scope {
                ...FullScope
            }
        }
    }
    ${fragments.fullScope}
`;

export const SET_REGIONS = gql`
    mutation SetRegions($tenderId: ID!, $regions: [String]!, $update: Boolean!) {
        setRegions(id: $tenderId, regions: $regions, update: $update) {
            regions {
                ...FullNuts
            }
        }
    }
    ${fragments.fullNuts}
`;

export const SET_BIDS = gql`
    mutation SetBids(
        $tenderId: ID!
        $bids: [BidInput]!
        $noteBid: String
        $noteAward: String
        $triggerDistribution: Boolean!
        $areBidsKnown: Boolean!
    ) {
        setParticipants(
            id: $tenderId
            bids: $bids
            noteBid: $noteBid
            noteAward: $noteAward
            triggerDistribution: $triggerDistribution
            areBidsKnown: $areBidsKnown
        ) {
            bids {
                ...FullBid
            }
            lots {
                ...FullLot
            }
            opmerking
            remark_award
            inschrijvers_bekend
        }
    }
    ${fragments.fullBid}
    ${fragments.fullLot}
`;

export const SET_CONTRACT = gql`
    mutation SetContract($tenderId: ID!, $tenderContract: ContractInput, $lotContracts: [LotContractInput!]) {
        setContract(id: $tenderId, tenderContract: $tenderContract, lotContracts: $lotContracts) {
            contract {
                ...FullContract
            }
            lots {
                ...FullLot
            }
        }
    }
    ${fragments.fullContract}
    ${fragments.fullLot}
`;

export const SET_DESCRIPTION = gql`
    mutation SetDescription($tenderId: ID!, $description: TextInput, $qualitativeScope: TextInput) {
        setDescription(id: $tenderId, description: $description, qualitativeScope: $qualitativeScope) {
            id
        }
    }
`;

export const SET_TENDER_PROPERTY = gql`
    mutation SetTenderProperty($tenderId: ID!, $field: String, $value: String) {
        setTenderProperty(id: $tenderId, field: $field, value: $value) {
            id
        }
    }
`;

export const SET_RELATED_TENDERS = gql`
    mutation SetRelatedTenders($tenderId: ID!, $related: [Int]!) {
        setRelatedTenders(id: $tenderId, related: $related) {
            related_tenders {
                ...RelatedTenderSubset
            }
        }
    }
    ${fragments.relatedTender}
`;

export const SET_DEADLINE = gql`
    mutation SetDeadline($tenderId: ID!, $deadline: DateTime!, $update: Boolean) {
        setDeadline(id: $tenderId, deadline: $deadline, update: $update) {
            deadline_inschrijving
        }
    }
`;

export const CREATE_OR_UPDATE_ORGANIZATION = gql`
    mutation CreateOrUpdateOrganization($organization: OrganizationInput!, $settlings: [SettlingInput]!) {
        createOrUpdateOrganization(organization: $organization, settlings: $settlings) {
            ...FullOrganization
            settlings {
                ...FullSettling
            }
        }
    }
    ${fragments.fullOrganization}
    ${fragments.fullSettling}
`;

export const DISLIKETENDER = gql`
    mutation dislikeTender($tenderID: ID!, $reason: String, $remark: String) {
        dislikeTender(tender_id: $tenderID, reason: $reason, remark: $remark)
    }
`;

// like contracting authority
export const LIKECONTRACTING_AUTHORITY = gql`
    mutation likeContractingAuthority($ca_id: ID!) {
        likeContractingAuthority(organization_id: $ca_id)
    }
`;

// dislike organization
export const DISLIKEORGANIZATION = gql`
    mutation dislikeOrganization($org_id: ID!) {
        dislikeMarketParty(organization_id: $org_id)
    }
`;

// ? Dislike contracting authorities
export const DISLIKECAS = gql`
    mutation dislikeCAS($org_id: ID!) {
        dislikeContractingAuthority(organization_id: $org_id)
    }
`;

// ? mutation to like all selected tenders
// ? mutation is called for each selected item, mutation itself likes each tender one by one
export const DISLIKE_SELECTED_TENDERS = gql`
    mutation dislikeTenderBatch($tenderID: ID!, $reason: String, $remark: String) {
        dislikeTender(tender_id: $tenderID, reason: $reason, remark: $remark)
    }
`;

// ? Dislike contracting authorities
export const DISLIKETENDERER = gql`
    mutation dislikeTenderer($org_id: ID!) {
        dislikeMarketParty(organization_id: $org_id)
    }
`;

// like organization
export const LIKEORGANIZATION = gql`
    mutation likeOrganization($org_id: ID!) {
        likeMarketParty(organization_id: $org_id)
    }
`;

// ? mutation to like all selected tenders
// ? mutation is called for each selected item, mutation itself likes each tender one by one
export const LIKETENDERBATCH = gql`
    mutation likeTenderBatch($tenderID: ID!, $reason: String, $remark: String) {
        likeTender(tender_id: $tenderID, reason: $reason, remark: $remark)
    }
`;

export const LIKETENDER = gql`
    mutation likeTender($tenderID: ID!, $reason: String, $remark: String) {
        likeTender(tender_id: $tenderID, reason: $reason, remark: $remark)
    }
`;

//? save tender events
export const SAVE_TENDER_EVENT = gql`
    mutation addTenderEventToCalendar($event_id: Int!) {
        addTenderEventToCalendar(event_id: $event_id)
    }
`;

export const TENDER_SOFTDELETE = gql`
    mutation TenderSoftDelete($tenderID: ID!) {
        deleteTender(tender_id: $tenderID)
    }
`;

export const UNARCHIVETENDER = gql`
    mutation UnArchiveTender($tenderID: ID!, $reason: String, $remark: String) {
        likeTender(tender_id: $tenderID, reason: $reason, remark: $remark)
    }
`;

export const SEND_QUESTION_TO_SUPPORT = gql`
    mutation SendQuestionToSupport($question: String!, $extra_data: [String]) {
        contactRequest(question: $question, extra_data: $extra_data)
    }
`;

// export const SENDTENDERMESSAGE = gql`
//     mutation SendChatMessage($message: String!, $conversation: ID, $to_user: ID) {
//         sendMessage(message: $message, conversation: $conversation, to_user: $to_user) {
//             id
//             message
//             from {
//                 id
//                 name
//                 email
//                 employee {
//                     id
//                     givenname
//                     middlename
//                     familyname
//                 }
//             }
//             send_at
//         }
//     }
// `;

export const TENDER_ARCHIVE = gql`
    mutation TenderArchive($tenderID: ID!) {
        archiveTender(tender_id: $tenderID)
    }
`;

export const MARK_ART_AS_READ = gql`
    mutation SetKnowledgeBaseRead($art_id: Int!) {
        setKnowledgeBaseRead(knowledge_base_id: $art_id)
    }
`;

// deletePersonalOpportunity
export const CREATE_OPPORTUNITY = gql`
    mutation CreateOpportunity($id: ID, $page_id: ID!, $comment: String!, $values: [Int!]!, $years: [Int!]!) {
        addOpportunity(id: $id, page_id: $page_id, comment: $comment, values: $values, years: $years)
    }
`;

export const DELETE_PERSONAL_OPPORTUNITY = gql`
    mutation deletePersonalOpportunity($id: ID!) {
        deletePersonalOpportunity(id: $id) {
            id
        }
    }
`;

export const SET_PAGE_READ_STATE = gql`
    mutation setPageReadState($id: ID!, $status: Boolean) {
        markPage(id: $id, status: $status)
    }
`;

//? update emailaddresses of current organization
export const UPDATE_ORGANIZATION_EMAILS = gql`
    mutation UpdateOrganizationEmails($organizationId: ID!, $emails: [EmailInput!]!) {
        updateOrganization(input: { id: $organizationId, emails: $emails }) {
            id
            emails {
                id
                email
                type
            }
        }
    }
`;

//? update phone numbers of current organization
export const UPDATE_ORGANIZATION_PHONES = gql`
    mutation UpdateOrganizationPhones($organizationId: ID!, $phonenumbers: [PhonenumberInput!]!) {
        updateOrganization(input: { id: $organizationId, phonenumbers: $phonenumbers }) {
            id
            phonenumbers {
                id
                phonenumber
                type
            }
        }
    }
`;

//? update postaladdresses of current organization
export const UPDATE_ORGANIZATION = gql`
    mutation UpdateOrganizationPostals($organizationId: ID!, $addresses: [AddressInput!]!) {
        updateOrganization(input: { id: $organizationId, postal_addresses: $addresses }) {
            id
            postal_addresses {
                id
                city
                country
                housenumber
                housenumber_suffix
                postal_code
                street
            }
        }
    }
`;

export const UPDATE_ORGANIZATION_LOGO = gql`
    mutation UpdateOrganizationLogo($organizationId: ID!, $logoUrl: String!, $crop: CropConfig, $type: OrgImgType!) {
        updateOrganizationLogo(organizationId: $organizationId, logoUrl: $logoUrl, crop: $crop, type: $type) {
            id
            logo
        }
    }
`;

//? update profile settings of current user
export const REQUEST_NEW_PASSWORD = gql`
    mutation requestNewPassword($input: ForgotPasswordInput!) {
        forgotPassword(input: $input) {
            message
            status
        }
    }
`;

//? update profile settings of current user
export const SAVE_NEW_PASSWORD = gql`
    mutation saveNewSetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
            message
            status
        }
    }
`;

export const SAVESEARCHRULE = gql`
    mutation SaveSearch($search: SearchInput!, $name: String!, $moment: SearchDistributionMoment!, $color: String!) {
        saveTenderSearch(search: $search, name: $name, moment: $moment, color: $color) {
            id
            name
            state
        }
    }
`;

export const UPDATESEARCHRULE = gql`
    mutation UpdateTenderSearchRule($id: ID!, $search: SearchInput!, $name: String!, $moment: SearchDistributionMoment!, $color: String!) {
        updateTenderSearch(id: $id, search: $search, name: $name, moment: $moment, color: $color)
    }
`;

export const SET_SEARCH_DISTRIBUTION = gql`
    mutation setSearchDistribution($search_id: ID!, $user_id: ID!, $distribution: SearchDistributionInput!) {
        setSearchDistribution(search_id: $search_id, user_id: $user_id, distribution: $distribution)
    }
`;

// ? GraphQL mutation to add a new searchterm in searchterm array
export const MUTATION_ADD_SEARCHTERM = gql`
    mutation addWord($keyword: String!) {
        saveKeyword(keyword: $keyword) {
            keyword
        }
    }
`;
// ? GraphQL mutation to delete clicked searchterm in searchterm array
export const MUTATION_DELETE_SEARCHTERM = gql`
    mutation deleteWord($id: Int!) {
        deleteKeyword(id: $id) {
            keyword
        }
    }
`;

//? update profile settings of current user
export const UPDATEPERSONALSETTINGS = gql`
    mutation updateProfileSettings($function: String!, $mobile: String!, $phone: String!, $language: String!) {
        updateProfile(settings: { function: $function, language: $language, mobile: $mobile, phone: $phone })
    }
`;

export const UPDATE_USER_PREFERENCES = gql`
    mutation updateUserSettings(
        $default_views: [DefaultViewPreferenceInput]
        $tenders: TenderModulePreferences
        $market_parties: MarketPartiesModulePreferences
        $contracting_authorities: ContractingAuthoritiesModulePreferences
    ) {
        updatePreferences(
            default_views: $default_views
            tenders: $tenders
            market_parties: $market_parties
            contracting_authorities: $contracting_authorities
        )
    }
`;

export const DELETESEARCHRULE = gql`
    mutation DeleteSearch($id: ID!) {
        deleteTenderSearch(id: $id) {
            id
            name
        }
    }
`;

export const DELETEOPPORTUNITYSEARCHRULE = gql`
    mutation DeleteOpportunitySearch($id: ID!) {
        deleteOpportunitySearch(id: $id) {
            id
            name
        }
    }
`;

export const UPDATEPASSWORD = gql`
    mutation updateUserPassword($current_password: String!, $password: String!, $password_confirmation: String!) {
        updatePassword(input: { current_password: $current_password, password: $password, password_confirmation: $password_confirmation }) {
            status
        }
    }
`;

export const SAVE_CHOSEN_TIMESLOT = gql`
    mutation Save_chosen_timeslot($timeslot_hash: ID!) {
        reserveSupportTimeslot(timeslot_hash: $timeslot_hash) {
            start
            end
            duration
            type
            hash
        }
    }
`;

export const CANCEL_CHOSEN_TIMESLOT = gql`
    mutation Cancel_chosen_timeslot($timeslot_hash: ID!) {
        cancelSupportMeeting(timeslot_hash: $timeslot_hash) {
            start
            end
            duration
            type
            hash
        }
    }
`;

// ? GraphQL mutation to add a new planning string with tagname combined
export const ADD_PlANNING_STRING = gql`
    mutation AddPlanningString($name: String!, $tagname: String!) {
        addPlanningString(name: $name, tagname: $tagname)
    }
`;

// ? GraphQL mutation to add tag to tender event
export const ADD_TAG_TO_TENDER_EVENT = gql`
    mutation AddTagTenderEvents($event_id: ID!, $tag: String!) {
        addTagTenderEvents(event_id: $event_id, tag: $tag)
    }
`;

// ? GraphQL mutation to sweep worklist
export const SWEEP_WORKLIST = gql`
    mutation CleanupTenderWorklist {
        cleanupTenderWorklist
    }
`;

export const UPDATE_SEARCH_PROPS = gql`
    mutation updateTenderSearchProperties($id: ID!, $name: String, $color: String) {
        updateTenderSearchProperties(id: $id, name: $name, color: $color)
    }
`;

export const UPDATE_DASHBOARD = gql`
    mutation updateDashboardLayout($input: UpdateDashboardLayoutInput!, $rowsToRemove: [String!], $columnsToRemove: [String!]) {
        updateDashboardLayout(input: $input, rowsToRemove: $rowsToRemove, columnsToRemove: $columnsToRemove) {
            rows {
                columns {
                    id
                    name
                    widgetId
                }
                id
                order
            }
        }
    }
`;

export const DELETE_SAVED_BID = gql`
    mutation deleteBid($id: ID!) {
        deleteBid(id: $id) {
            id
        }
    }
`;

// export const RESET_DASHBOARD = gql`
//     mutation resetDashboard {
//         resetDashboardLayout {
//             rows {
//                 columns {
//                     id
//                     name
//                     widgetId
//                 }
//                 id
//                 order
//             }
//         }
//     }
// `;

// export const CREATE_SETTLING = gql`
//     mutation CreateSettling(
//         $organization_id: Int!
//         $name: String!
//         $settlingnumber: String!
//         $type: String!
//         $subnumber: String!
//         $visit_street: String!
//         $visit_housenumber: String!
//         $visit_housenumbersuffix: String!
//         $visit_postalcode: String!
//         $visit_city: String!
//         $visit_country: String!
//         $postal_address: String!
//         $postal_postalcode: String!
//         $postal_city: String!
//         $phonenumber: String!
//         $faxnumber: String!
//         $email: String!
//         $actief: Int!
//         $status: String!
//     ) {
//         createSettling(
//             organization_id: $organization_id
//             name: $name
//             settlingnumber: $settlingnumber
//             type: $type
//             subnumber: $subnumber
//             visit_street: $visit_street
//             visit_housenumber: $visit_housenumber
//             visit_housenumbersuffix: $visit_housenumbersuffix
//             visit_postalcode: $visit_postalcode
//             visit_city: $visit_city
//             visit_country: $visit_country
//             postal_address: $postal_address
//             postal_postalcode: $postal_postalcode
//             postal_city: $postal_city
//             phonenumber: $phonenumber
//             faxnumber: $faxnumber
//             email: $email
//             actief: $actief
//             status: $status
//         ) {
//             ...FullSettling
//         }
//     }
//     ${fragments.fullSettling}
// `;
