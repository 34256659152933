import React, { useEffect, useState } from "react";
import { Grid, Button, Typography, Badge, Box } from "@mui/material";
import TimeSlots from "./TimeSlots";
import UserInfoForm from "./UserInfoForm";
import _ from "lodash";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { Get_available_demo_timeslots } from "../../__generated__/Get_available_demo_timeslots";
import FullPageSpinner from "../../components/loading/FullPageSpinner";
import { DatePicker, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { meetingTimeslotDays } from "../../__generated__/meetingTimeslotDays";
import { GET_AVAILABLE_DEMO_TIMESLOTS, QUERY_TIMESLOTS_DATES } from "../../graphql/queryDefinitions";

interface Props {
    chosenTimeSlot: string;
    setChosenTimeSlot: React.Dispatch<React.SetStateAction<string>>;
    handleSubmit: any;
    checked: boolean;
    setChecked: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
}

const TimeSlotsOverview: React.FC<Props> = ({ chosenTimeSlot, setChosenTimeSlot, handleSubmit, checked, setChecked, loading }) => {
    const { register, handleSubmit: submit, errors } = useForm();
    const support = true;
    const timeslotarray = useQuery<meetingTimeslotDays>(QUERY_TIMESLOTS_DATES);
    const [highlightedDays, setHighlightedDays] = useState([] as any);
    const [selectedDate, setSelectedDate] = useState(moment().add(2, "hours").format("YYYY-MM-DD HH:mm:ss"));
    // Fetch al available timeslots of type "support"
    const { loading: load, data: availableSlots } = useQuery<Get_available_demo_timeslots>(GET_AVAILABLE_DEMO_TIMESLOTS, {
        variables: {
            from: selectedDate,
        },
        pollInterval: chosenTimeSlot !== "" ? 0 : 500,
        fetchPolicy: "network-only",
    });

    const handleDateChange = (date: any) => {
        const chosenDate = moment(date).format("YYYY-MM-DD");
        const today = moment().format("YYYY-MM-DD");
        if (chosenDate === today) {
            setSelectedDate(moment().add(2, "hours").format("YYYY-MM-DD HH:mm:ss"));
        } else {
            setSelectedDate(moment(chosenDate).format("YYYY-MM-DD HH:mm:ss"));
        }
        setChosenTimeSlot("");
    };

    /**
     * @returns badge component is visible when a timeslot is available on a specific date
     */
    function ServerDay(props: PickersDayProps<any> & { highlightedDays?: string[] }) {
        const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
        const isSelected = highlightedDays.includes(moment(props.day).format("YYYY-MM-DD"));
        return (
            <Badge
                key={props.day.toString()}
                overlap="circular"
                badgeContent={
                    isSelected ? (
                        <Box
                            sx={{
                                width: "8px",
                                height: "8px",
                                borderRadius: "4px",
                                marginRight: "4px",
                                marginTop: "4px",
                                backgroundColor: "#E03660",
                            }}
                        />
                    ) : undefined
                }
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        );
    }

    const sunnyDays = timeslotarray.data?.meetingTimeslotDays;

    /**
     * Set timeslotarray to highlighted state
     * highlighted state will be used to generate pink dots to show user on which date timeslots will be available
     */
    useEffect(() => {
        if (sunnyDays) {
            setHighlightedDays(sunnyDays);
        }
    }, [sunnyDays]);

    /**
     * Map over date array to set format to moment()
     */
    const moments: any = highlightedDays?.map((d: any) => moment(d));

    /**
     * Get latest date in array
     * is used to set the max range of clickable dates
     */
    const maxDate = moment.max(moments);

    const renderPage = (content: React.ReactNode) => (
        <form onSubmit={submit(handleSubmit)} style={{ width: "100%", height: "100%" }}>
            <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <div id="content-box" style={{ flex: 1, minHeight: "425px" }}>
                    {content}
                </div>
                <div style={{ marginTop: "20px", height: "40px", display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography>Of kies een datum: </Typography>
                        <DatePicker
                            // shouldDisableDate={disableWeekends}
                            disablePast
                            // minimum clickable date is today
                            minDate={moment()}
                            //  max. clickable date is last date in date array
                            maxDate={maxDate}
                            // override day component
                            slots={{
                                day: ServerDay,
                            }}
                            showDaysOutsideCurrentMonth
                            // chosen date in moment format
                            value={moment(selectedDate)}
                            // function to fire on date change
                            onChange={handleDateChange}
                            /**
                             * Changes in GUI
                             * - Hide toolbar
                             * - Hide buttons in action bar
                             * - Push highlighted days array to dat component (array used in ServerDay)
                             */
                            slotProps={{
                                textField: {
                                    variant: "standard",
                                    InputProps: {
                                        size: "medium",
                                        readOnly: true,
                                        disableUnderline: true,
                                        sx: { display: "flex", flexDirection: "row-reverse" },
                                    },
                                },

                                inputAdornment: {
                                    sx: {
                                        marginLeft: "0px",
                                        marginRight: "12px",
                                    },
                                },

                                toolbar: {
                                    // Customize value display
                                    toolbarFormat: "YYYY",
                                    // Change what is displayed given an empty value
                                    toolbarPlaceholder: "??",
                                    // Hide the toolbar
                                    hidden: true,
                                },
                                day: {
                                    highlightedDays,
                                } as any,
                                actionBar: {
                                    actions: [],
                                },
                            }}
                        />
                    </div>

                    {/* // if checked is true and chosenTimeSlot is not empty, show next button */}
                    {!checked && support !== true ? (
                        <Button disabled={_.isEmpty(chosenTimeSlot)} variant={"contained"} color="primary" onClick={() => setChecked(true)}>
                            Volgende
                        </Button>
                    ) : (
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                {support !== true && (
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={() => {
                                            setChecked(false);
                                            // setChosenTimeSlot("");
                                        }}
                                    >
                                        terug
                                    </Button>
                                )}
                                <Button disabled={_.isEmpty(chosenTimeSlot) || loading} variant="contained" color="primary" type="submit">
                                    Bevestig tijdstip
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </form>
    );

    if (load) {
        return renderPage(
            <div style={{ width: "100%", height: "100%" }}>
                <Typography variant="h3" style={{ fontWeight: 500, marginBottom: "20px" }}>
                    Tijden ophalen
                </Typography>
                <FullPageSpinner />
            </div>
        );
    }

    if (
        availableSlots?.meetingTimeslots === null ||
        availableSlots === undefined ||
        availableSlots.meetingTimeslots === undefined ||
        availableSlots.meetingTimeslots.length === 0
    ) {
        return renderPage(
            <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Typography variant="h3" style={{ fontWeight: 500, marginBottom: "20px" }}>
                    Geen tijden beschikbaar
                </Typography>
            </div>
        );
    }

    return renderPage(
        // Grid to show when timeslots are available
        <Grid item xs={12}>
            {!checked ? (
                <TimeSlots chosenTimeSlot={chosenTimeSlot} setChosenTimeSlot={setChosenTimeSlot} availableSlots={availableSlots.meetingTimeslots} />
            ) : (
                <UserInfoForm register={register} errors={errors} loading={loading} />
            )}
        </Grid>
    );
};

export default TimeSlotsOverview;
