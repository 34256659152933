import React from "react";
import { useTheme } from "@mui/material/styles";
import { Typography, useMediaQuery } from "@mui/material";
import SearchBar from "./SearchBar";

interface Props {
    title: string;
    subtext: React.ReactNode;
    showSearch: boolean;
    textBelowSearchBar: React.ReactNode;
}

const Layout: React.FC<Props> = ({ title, subtext, showSearch, textBelowSearchBar, children }) => {
    const theme = useTheme();
    const aboveMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const aboveTablet = useMediaQuery(theme.breakpoints.up("md"));
    const aboveLG = useMediaQuery(theme.breakpoints.up("lg"));

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                height: aboveMobile ? "100vh" : `calc(100vh - 64px)`,
            }}
        >
            <div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        borderRadius: "26% 26% 49% 51% / 0% 0% 50% 50%",
                        backgroundColor: "#70a4b726",
                        padding: aboveLG ? "128px 0px" : aboveMobile ? "64px 0px" : "16px 0px",
                    }}
                >
                    <div>
                        <Typography
                            sx={{
                                fontSize: aboveTablet ? "40px" : aboveMobile ? "1.5rem !important" : "1.3rem !important",
                                fontWeight: 600,
                                textAlign: "center",
                                padding: aboveMobile ? 0 : "0px 16px",
                            }}
                        >
                            {title}
                        </Typography>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "10px",
                            textAlign: "center",
                            marginBottom: aboveMobile ? "0px" : "48px",
                            padding: aboveMobile ? "0px 32px" : "8px",
                        }}
                    >
                        {subtext}
                    </div>
                </div>
            </div>
            <div>
                {/* Searchbar */}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "-32px",
                        width: "100%",

                        // ADD TO KEEP SEARCHBAR VISIBLE
                        // position: "sticky",
                        // top: 32,
                        // transition: "top 0.3s ease", // Optional: Smooth transition
                    }}
                >
                    {showSearch && <SearchBar />}
                </div>
                {/* Text below  */}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {textBelowSearchBar}
                </div>
            </div>
            <div style={{ flex: 1, overflowY: "auto", marginBottom: "32px" }}>
                {children} {/* Arrow at the bottom */}
            </div>
        </div>
    );
};

export default Layout;
