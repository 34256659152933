import React, { useState } from "react";
import { Grid, Paper } from "@mui/material";

//Components
// import AdvancedSearch from "./AdvancedSearch";
import { useTranslation } from "react-i18next";

//Icons used on this page
import FilterList from "./filtermodal/FilterList";
import { useQuery } from "@apollo/client";
import { GET_FILTERSET_FOR_FILTERMODAL } from "../../../graphql/queryDefinitions";
import {
    getFilterset,
    getFilterset_getAllTenderFilterValues_filters,
    getFilterset_getAllTenderFilterValues_filters_values,
} from "../../../__generated__/getFilterset";
import { sortingArray } from "../../../components/FilterDrawer/TopFilterBar";
import CaFilter from "./filtermodal/CaFilter";
import CountryFilter from "./filtermodal/CountryFilter";
import Sectorfilter from "./filtermodal/Sectorfilter";
import CaTypeFilter from "./filtermodal/CaTypeFilter";
import TypeFilter from "./filtermodal/TypeFilter";
import { useTenderSearchContext } from "../../../components/guides/tenderWizard/WizardContext";
import CpvFilter from "./filtermodal/CpvFilter";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";

interface Props {
    type: "tender" | "opportunity";
}

// Define the types based on your GraphQL schema
type FilterValue = getFilterset_getAllTenderFilterValues_filters_values;
type Filter = getFilterset_getAllTenderFilterValues_filters; // Filter with title and values

export interface FilterValueWithChosen extends FilterValue {
    chosen: boolean; // Add chosen property to FilterValue
}

export interface FilterWithValues extends Filter {
    values: FilterValueWithChosen[]; // Update values to be FilterValueWithChosen[]
    chosenCount: number;
}

const FilterModal: React.FC<Props> = ({ type }) => {
    const { filters, setInitialFilterSetTenders } = useTenderSearchContext(); // Use the context hook to access context value and function

    const { t } = useTranslation();
    const [allOptions, setAllOptions] = useState<FilterWithValues[] | null>(null); // Use FilterWithValues[] for the state type
    const [selectedTitle, setSelectedTitle] = useState<string>("");

    // Get the filters
    const getRelevantFilters = (filters: FilterWithValues[]) => {
        const requiredFilters = new Set(["country", "sectors", "contractingAuthorities", "contractingAuthorityTypes", "type", "cpvs"]);

        // Then, add the `chosen` property to the values of each filter
        const selection = filters.filter((filter) => requiredFilters.has(filter.title));

        // Filter only the filters whose titles match the ones in `requiredFilters`
        return selection;
    };

    // Query to get the filter data
    const { error: errorQuery, loading: loadQuery } = useQuery<getFilterset>(GET_FILTERSET_FOR_FILTERMODAL, {
        onCompleted: (data) => {
            if (data && data.getAllTenderFilterValues && data.getAllTenderFilterValues.filters) {
                // Then, add the `chosen` property to the values of each filter
                const allFiltersWithChosen = data.getAllTenderFilterValues.filters.map((filter) => ({
                    ...filter,
                    chosenCount: 0,
                    values: filter.values.map((value) => ({
                        ...value,
                        chosen: false, // Initialize `chosen` to false for all values
                    })),
                }));

                setAllOptions(allFiltersWithChosen?.sort((a, b) => sortingArray.indexOf(a.title) - sortingArray.indexOf(b.title)));
                setInitialFilterSetTenders(allFiltersWithChosen);

                setSelectedTitle("country");
            }
        },
    });

    // Render loading and error states
    if (loadQuery) {
        return (
            <Grid container spacing={3} sx={{ height: "85vh", width: "90vw" }}>
                {/*
                 *   Grid item with a list of search terms. In this list its possible to choose terms for search rule.
                 *   user can search in list and add word if needed
                 */}
                <Grid item xs={3} sx={{ height: "100%" }}>
                    <FilterList
                        filterList={getRelevantFilters(allOptions ?? [])}
                        onClickOption={setSelectedTitle}
                        chosenFilter={selectedTitle}
                        filters={filters}
                    />
                </Grid>

                <Grid item xs={9} sx={{ height: "100%" }}>
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <h3>Kies een filter</h3>
                        </div>
                    </div>
                    {/*
                     * RIGHT BOX WITH FILTERDATA
                     */}
                    <div style={{ display: "flex", flexDirection: "column", height: type === "tender" ? `calc(100% - 56px)` : "calc(100% - 22px)" }}>
                        <Paper
                            variant="outlined"
                            elevation={0}
                            sx={{ height: "100%", overflow: "auto", padding: 2, display: "flex", alignItems: "center", justifyContent: "center" }}
                        >
                            <FullPageSpinner />
                        </Paper>
                    </div>
                </Grid>
            </Grid>
        );
    }
    if (errorQuery) {
        return (
            <Grid container spacing={3} sx={{ height: "85vh", width: "90vw" }}>
                {/*
                 *   Grid item with a list of search terms. In this list its possible to choose terms for search rule.
                 *   user can search in list and add word if needed
                 */}
                <Grid item xs={3} sx={{ height: "100%" }}>
                    <FilterList
                        filterList={getRelevantFilters(allOptions ?? [])}
                        onClickOption={setSelectedTitle}
                        chosenFilter={selectedTitle}
                        filters={filters}
                    />
                </Grid>

                <Grid item xs={9} sx={{ height: "100%" }}>
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <h3>Kies een filter</h3>
                        </div>
                    </div>
                    {/*
                     * RIGHT BOX WITH FILTERDATA
                     */}
                    <div style={{ display: "flex", flexDirection: "column", height: type === "tender" ? `calc(100% - 56px)` : "calc(100% - 22px)" }}>
                        <Paper
                            variant="outlined"
                            elevation={0}
                            sx={{ height: "100%", overflow: "auto", padding: 2, display: "flex", alignItems: "center", justifyContent: "center" }}
                        >
                            Er ging iets mis
                        </Paper>
                    </div>
                </Grid>
            </Grid>
        );
    }

    const filteredFilters = selectedTitle ? allOptions?.filter((filter) => filter.title === selectedTitle) : allOptions;

    const renderFilters = (selectedTitle: string, filteredFilters: any[]) => {
        if (!filteredFilters || filteredFilters.length !== 1) return null;

        if (selectedTitle === "country") {
            const regionFilterValues = allOptions?.filter((filter) => filter.title === "regions") ?? [];

            return <CountryFilter filterValues={filteredFilters[0].values} filter={"country"} regionFilterValues={regionFilterValues[0] || {}} />;
        } else if (selectedTitle === "contractingAuthorities") {
            return <CaFilter filterValues={filteredFilters[0].values} filter={"contractingAuthorities"} type="tender" />;
        } else if (selectedTitle === "sectors") {
            const sectorFilterValues = allOptions?.filter((filter) => filter.title === "sectors") ?? [];
            const subsectorFilterValues = allOptions?.filter((filter) => filter.title === "subSectors") ?? [];

            // eslint-disable-next-line react/jsx-no-undef
            return <Sectorfilter sectorValues={sectorFilterValues} subsectorValues={subsectorFilterValues} />;
        } else if (selectedTitle === "cpvs") {
            return <CpvFilter filterValues={filteredFilters[0].values} filter={"cpvs"} />;
        } else if (selectedTitle === "contractingAuthorityTypes") {
            return <CaTypeFilter filterValues={filteredFilters[0].values} filter={"contractingAuthorityTypes"} />;
        } else if (selectedTitle === "type") {
            return <TypeFilter filterValues={filteredFilters[0].values} filter={"type"} />;
        } else {
            return <></>;
        }
    };

    return (
        <Grid container spacing={3} sx={{ height: "85vh", width: "90vw" }}>
            {/*
             *   Grid item with a list of search terms. In this list its possible to choose terms for search rule.
             *   user can search in list and add word if needed
             */}
            <Grid item xs={3} sx={{ height: "100%" }}>
                <FilterList
                    filterList={getRelevantFilters(allOptions ?? [])}
                    onClickOption={setSelectedTitle}
                    chosenFilter={selectedTitle}
                    filters={filters}
                />
            </Grid>

            <Grid item xs={9} sx={{ height: "100%" }}>
                <div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {selectedTitle ? (
                            <h3>
                                Filtermogelijkheden voor{" "}
                                {selectedTitle === "country"
                                    ? "gebied"
                                    : selectedTitle === "cpvs"
                                    ? "CPV-code"
                                    : `${t(`filter.${selectedTitle}`).toLocaleLowerCase()}`}
                            </h3>
                        ) : (
                            <h3>Kies een filter</h3>
                        )}
                    </div>
                </div>
                {/*
                 * RIGHT BOX WITH FILTERDATA
                 */}
                <div style={{ display: "flex", flexDirection: "column", height: type === "tender" ? `calc(100% - 56px)` : "calc(100% - 22px)" }}>
                    <Paper variant="outlined" elevation={0} sx={{ height: "100%", overflow: "auto" }}>
                        {renderFilters(selectedTitle as string, filteredFilters ?? [])}
                    </Paper>
                </div>
            </Grid>
        </Grid>
    );
};

export default FilterModal;
