import React, { useState } from "react";
import { IconButton, styled, TextField, Tooltip } from "@mui/material";
import { createTemplate, createTemplateVariables } from "../../../../__generated__/createTemplate";
import { CREATE_TEMPLATE } from "../../../../graphql/mutationDefTasks";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Check, Clear } from "@mui/icons-material";
import DOMPurify from "dompurify";
import { sanitizeInput } from "../../../../utils/sanitizeInput";

interface Props {
    refetch: () => void;
    onCancel: () => void;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
    fontSize: theme.typography.h5.fontSize, // Match H5 font size
    fontWeight: theme.typography.h5.fontWeight, // Match H5 font weight
    lineHeight: theme.typography.h5.lineHeight, // Match H5 line height
    "& .MuiInputBase-input": {
        fontSize: theme.typography.h5.fontSize,
        fontWeight: 500,
        lineHeight: theme.typography.h5.lineHeight,
    },
}));

const CreateTemplate: React.FC<Props> = ({ refetch, onCancel }) => {
    const [value, setValue] = useState<string>("");
    // Explain mutation
    const [createTemplate, { loading, error }] = useMutation<createTemplate, createTemplateVariables>(CREATE_TEMPLATE);

    // Explain mutation handler
    const create = async () => {
        if (error) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await createTemplate({
                variables: { name: value },
                onCompleted: () => {
                    refetch();
                    setValue("");
                    onCancel();
                },
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    const cancel = () => {
        setValue("");
        onCancel();
    };

    return (
        <>
            <StyledTextField
                autoFocus
                fullWidth
                InputProps={{ disableUnderline: true }}
                placeholder="Geef sjabloon een naam..."
                variant="standard"
                value={value}
                onChange={(e) => {
                    const inputvalue = sanitizeInput(e.target.value, false);
                    const val = DOMPurify.sanitize(inputvalue);
                    setValue(val);
                }}
            />
            <Tooltip title="Sjabloon maken" placement="top" disableInteractive>
                <IconButton disabled={value.length <= 3 || loading} size="small" onClick={create}>
                    <Check fontSize="small" sx={{ color: value.length <= 3 ? "lightgrey" : "#508c79" }} />
                </IconButton>
            </Tooltip>

            <Tooltip title="Annuleren" placement="top" disableInteractive>
                <IconButton size="small" onClick={cancel}>
                    <Clear fontSize="small" sx={{ color: "#c0392b" }} />
                </IconButton>
            </Tooltip>
        </>
    );
};

export default CreateTemplate;
