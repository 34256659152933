import React, { useEffect, useState } from "react";
import { Box, Tooltip } from "@mui/material";

import { Check, Clear } from "@mui/icons-material";
import { IconButton, TextField, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { CREATE_NEW_TEAM, QUERY_SUBSCRIPTION_AND_TEAMS, UPDATE_TEAM } from "../../../graphql/queryDefUserAndTeams";
import { createTeam, createTeamVariables } from "../../../__generated__/createTeam";
import { updateTeam, updateTeamVariables } from "../../../__generated__/updateTeam";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";
import DOMPurify from "dompurify";
import { sanitizeInput } from "../../../utils/sanitizeInput";

interface Props {
    teamName: string;
    setTeamName(name: string): void;
    editMode: boolean;
    setEditMode(editMode: boolean): void;
    teamId: string;
    bossOfTeam: boolean;
}

const TeamName: React.FC<Props> = ({ teamId, teamName, setTeamName, editMode, setEditMode, bossOfTeam }) => {
    const [name, setName] = useState(teamName);
    const { trackEvent } = useMatomo();

    // Track event
    const trackSharing = (event: MatomoEvent) => {
        trackEvent(event);
    };

    useEffect(() => {
        if (teamName === "Voer een teamnaam in") {
            setEditMode(true);
        }
    }, [setEditMode, teamName]);

    /**
     * Create team with name state as teamname
     */
    const [createNewTeam] = useMutation<createTeam, createTeamVariables>(CREATE_NEW_TEAM, {
        variables: { teamName: name },
        refetchQueries: [QUERY_SUBSCRIPTION_AND_TEAMS, "GetScubscriptionAndTeams"],
        awaitRefetchQueries: true,
    });

    /**
     * Update team with name state as teamname
     */
    const [updateTeam] = useMutation<updateTeam, updateTeamVariables>(UPDATE_TEAM, {
        variables: { teamId: teamId || "", teamName: name },
        refetchQueries: [QUERY_SUBSCRIPTION_AND_TEAMS, "GetScubscriptionAndTeams"],
        awaitRefetchQueries: true,
    });

    /**
     * Set name to new input
     * set editmode => false
     */
    const saveSetting = () => {
        trackSharing({ category: "Personal", action: "Click-create-new-team", href: window.location.href, name: name });
        createNewTeam();
        setTeamName(name);
        setEditMode(false);
    };

    /**
     * Set name to new input
     * set editmode => false
     */
    const updateSetting = () => {
        trackSharing({ category: "Personal", action: "Click-update-team-name", href: window.location.href, name: name });
        updateTeam();
        setTeamName(name);
        setEditMode(false);
    };

    /**
     * reset name to last saved name
     * set editmode => false
     */
    const cancelSetting = () => {
        setName(teamName);
        setEditMode(false);
    };

    return (
        <div style={{ marginTop: "4px", display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
            {/*
             * Check if editmode is active and check if user is boss of team
             * If both are true => show textfield to change a teamname
             */}
            {editMode && bossOfTeam ? (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <TextField
                        autoFocus
                        name={"function"}
                        label={name.length < 3 ? <span style={{ color: "indianred" }}>Minimaal 3 karakters</span> : ""}
                        variant={"outlined"}
                        fullWidth
                        size={"small"}
                        value={name}
                        onChange={(e) => {
                            const inputvalue = sanitizeInput(e.target.value, false);
                            const val = DOMPurify.sanitize(inputvalue);
                            setName(val);
                        }}
                    />
                    {/* Show button to save new teamname
                     * Button will be disabled as long as teamname = "Voer een teamnaam in" & teamname is less than 3 characters
                     */}
                    {teamName === "Voer een teamnaam in" && (
                        <Tooltip title="Opslaan" placement="top">
                            <IconButton size="small" disabled={name === "Voer een teamnaam in" || name.length < 3} onClick={() => saveSetting()}>
                                <Check />
                            </IconButton>
                        </Tooltip>
                    )}

                    {/* Show button to save new teamname and a button to cancel namechange if teamname was already changed
                     * Button will be disabled as long as teamname = "Voer een teamnaam in" & teamname is less than 3 characters
                     */}
                    {teamName !== "Voer een teamnaam in" && name.length > 2 && (
                        <React.Fragment>
                            <Tooltip title="Opslaan" placement="top">
                                <IconButton size="small" onClick={() => updateSetting()}>
                                    <Check />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Annuleren" placement="top">
                                <IconButton size="small" onClick={() => cancelSetting()}>
                                    <Clear />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    )}
                </div>
            ) : (
                // Clickable box to activate editmode
                <Box
                    onClick={() => setEditMode(true)}
                    sx={{
                        padding: "10.5px 14px 10.5px 14px",
                        height: "37.63px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        "&:hover": {
                            backgroundColor: bossOfTeam ? "#f4f4f4" : "transparent",
                            cursor: bossOfTeam ? "pointer" : "default",
                            borderRadius: bossOfTeam ? "4px" : undefined,
                            width: "100%",
                        },
                    }}
                >
                    <Typography>{teamName}</Typography>
                </Box>
            )}
        </div>
    );
};
export default TeamName;
