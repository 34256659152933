import React, { useEffect, useState } from "react";
import { CircularProgress, Divider, IconButton, InputBase, Paper, useMediaQuery, useTheme } from "@mui/material";
import { Clear, Search } from "@mui/icons-material";
import { useArticleFilter } from "../../components/contextProviders/ArticleFilterContext";
import { useParams } from "react-router-dom";
import { arrayWithCategories } from "./CategoryOverview";

interface Props {
    a?: number;
}

const SearchBar: React.FC<Props> = (props) => {
    const { query, setQuery, loading, setPage, setArticles } = useArticleFilter();
    const [searchText, setSearchText] = useState(query);
    const theme = useTheme();
    const aboveMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const { categoryName } = useParams();

    const categorie = arrayWithCategories.find((cat) => cat.url === categoryName);

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            setPage(1);
            setQuery(searchText); // Update query after 700ms
        }, 700);

        return () => clearTimeout(delayDebounce); // Cleanup timeout if user types again
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    if (loading) {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Paper component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: aboveMobile ? 500 : "80vw" }}>
                    <IconButton disabled sx={{ p: "10px" }} aria-label="menu">
                        <Search />
                    </IconButton>
                    <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Artikelen ophalen..." value={searchText} />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
                        <CircularProgress size={24} />
                    </IconButton>
                </Paper>
            </div>
        );
    }

    // if (error) {
    //     return (
    //         <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
    //             <Paper component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 500 }}>
    //                 <IconButton disabled sx={{ p: "10px" }} aria-label="menu">
    //                     <Search />
    //                 </IconButton>
    //                 <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Er ging iets mis..." />
    //                 <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
    //                 <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
    //                     <CircularProgress size={24} />
    //                 </IconButton>
    //             </Paper>
    //         </div>
    //     );
    // }

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Paper
                sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: aboveMobile ? 500 : "80vw",
                }}
            >
                <IconButton disabled sx={{ p: "10px" }} aria-label="menu">
                    <Search />
                </IconButton>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={`Zoeken in ${categorie ? categorie?.tekst : "kennis hub"}`}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                    onClick={() => {
                        setSearchText("");
                        setPage(1);
                        setArticles([]);
                    }}
                >
                    <Clear sx={{ color: searchText ? "error.main" : "inherit" }} />
                </IconButton>
            </Paper>
        </div>
    );
};

export default SearchBar;
