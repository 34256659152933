import React from "react";
import {
    MenuItem,
    Grid,
    Paper,
    createTheme,
    Theme,
    ThemeProvider,
    Select,
    Pagination,
    useTheme,
    useMediaQuery,
    Tooltip,
    IconButton,
} from "@mui/material";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { useTranslation } from "react-i18next";
import { ApolloError } from "@apollo/client";
import { Disable } from "react-disable";
import moment from "moment";
// import { useLocation } from "react-router-dom";
import { useColumnSetter } from "../../../../components/contextProviders/ColumnContext";

import {
    FilterTendersOverviewOnCaPageMinimum_filterTenders_results_data,
    FilterTendersOverviewOnCaPageMinimum_filterTenders_results_paginatorInfo,
} from "../../../../__generated__/FilterTendersOverviewOnCaPageMinimum";
import LinearLoader from "../../../../components/loading/LinearLoader";
import MobileTableNavigation from "../../../../components/MobileTableNavigation";
import { CaTenderColumns } from "./CaTenderColumns";
import { InfoOutlined } from "@mui/icons-material";
import { TenderFilterTarget } from "../../../../__generated__/globalTypes";

interface Props {
    /**
     * Data to be displayed in table
     */
    rows: FilterTendersOverviewOnCaPageMinimum_filterTenders_results_data[];
    /**
     * Pagination info
     */
    paginatorInfo: FilterTendersOverviewOnCaPageMinimum_filterTenders_results_paginatorInfo;
    /**
     * onChange page or rows per page
     */
    onChangePagination(page: number, rowsPerPage: number): void;
    onTableChange: (changedColumn: string, direction: "asc" | "desc") => void;
    sortOrder: { name: string; direction: "asc" | "desc" };
    /**
     * Loading state
     */
    loading: boolean;
    /**
     * Apollo error
     */
    error?: ApolloError | undefined;
    ca_id: string;
    ca_name: string | null;
    ca_favicon: string | null;
    target: TenderFilterTarget;
}

const CustomMuiTheme = (theme: Theme) =>
    createTheme({
        palette: {
            primary: {
                main: "#173357",
            },
        },
        typography: {
            fontSize: 14,
            fontFamily: "Maven Pro, system-ui, sans-serif",
            body1: {
                fontSize: "0.875rem",
                "@media (max-width: 800px)": {
                    fontSize: "0.8rem",
                },
            },
        },
        components: {
            // MUIDataTable: {
            //     tableRoot: {
            //         width: "100% !important",
            //     },
            // },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        display: "none !important",
                    },
                },
            },
            // Custom hovercolor: opacity 7% -> add 12 to hexcode
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        "&:hover": {
                            backgroundColor: `${"#173357"}12 !important`,
                        },
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        textAlign: "start",
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: "8px",
                    },
                },
            },
            // MUIDataTableHeadCell: {
            //     data: {
            //         textAlign: "start",
            //     },
            // },
        },
    });

const CaTenderTable: React.FC<Props> = ({
    rows,
    paginatorInfo,
    onChangePagination,
    onTableChange,
    loading,
    ca_id: CAID,
    ca_name,
    ca_favicon,
    sortOrder,
    target,
}) => {
    const { t } = useTranslation();
    const amountPages = Math.ceil(paginatorInfo.total / paginatorInfo.perPage);
    const { setSelectedRows, selectedRows } = useColumnSetter();
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

    /**
     * Data reformat definition, each container is a columnsection in table.
     */
    const convertedData = rows.map((item) => {
        const container: any = {};
        const id = "id";
        const tender = "tender";
        const contractingAuthorities = "contractingAuthorities";
        const favicon = "favicon";
        const ca_id = "ca_id";
        const deadline_inschrijving = "deadline_inschrijving";
        const type_procedure = "type_procedure";
        const type = "type";
        const status = "status";
        const next_looptijd_einde = "next_looptijd_einde";
        const looptijd_start = "looptijd_start";
        const looptijd_einde = "looptijd_einde";
        const published_vestigingsplaats = "published_vestigingsplaats";
        const country = "country";
        const published_adres = "published_adres";
        const published_postcode = "published_postcode";
        const datum_gunning = "datum_gunning";
        const published_host = "published_host";
        const status_contract = "status_contract";
        const looptijd_einde_incl_opties = "looptijd_einde_incl_opties";
        const userStatus = "userStatus";
        const searches = "searches";

        container[id] = item.id;
        container[tender] = item.namegiventocontract;
        container[contractingAuthorities] = ca_name;
        container[ca_id] = CAID;
        container[favicon] = ca_favicon;
        container[userStatus] = item.userStatus;
        container[searches] = item.searchresults.map((i: any) => i);
        container[deadline_inschrijving] = item.deadline_inschrijving ? moment(item.deadline_inschrijving).format("L LT") : "";
        container[status] = item.status;
        container[country] = item.country?.alpha2 ? item.country.alpha2 : null;

        container[type_procedure] = item.id;
        container[type] = item.id;
        container[next_looptijd_einde] = item.id;
        container[looptijd_start] = item.id;
        container[looptijd_einde] = item.id;
        container[published_vestigingsplaats] = item.id;
        container[published_adres] = item.id;
        container[published_postcode] = item.id;
        container[datum_gunning] = item.id;
        container[published_host] = item.id;
        container[status_contract] = item.id;
        container[looptijd_einde_incl_opties] = item.id;

        return container;
    });

    /**
     * Fuction to get the selected rows and add id's of item inside row to context provider. now its possible to use the data inside to export,archive etc.
     */
    const onRowSelectionChange = (currentRowsSelected: Array<any>, allRowsSelected: Array<any>, rowsSelected: Array<any> | undefined) => {
        if (rowsSelected !== undefined) {
            const ids = rowsSelected.map((index) => convertedData[index].id);
            setSelectedRows(ids);
        }
    };

    // TODO: sortfunction doesnt work
    // sortcompare function maybe ?
    const options: MUIDataTableOptions = {
        elevation: 0,
        filter: false,
        download: false,
        print: false,
        pagination: false,
        search: false,
        filterType: "checkbox",
        draggableColumns: {
            enabled: false,
            transitionTime: 200,
        },
        serverSide: true,
        selectableRowsOnClick: false,
        selectToolbarPlacement: "none",
        onRowSelectionChange: onRowSelectionChange,
        rowsSelected: selectedRows.map((id: string) => convertedData.findIndex((item) => item.id === id)),
        // onColumnSortChange: onOrderChange,
        fixedSelectColumn: false,
        responsive: "standard",
        // selectableRows: loc.pathname === "/tenders" ? "none" : "multiple",
        selectableRows: "none",

        /**
         * Textual helpers with translation
         * Header tooltip text
         */
        textLabels: {
            body: {
                noMatch: t("TableHelpers.NoMatch"),
                toolTip: t("TableHelpers.Sort"),
                columnHeaderTooltip: (column) => `${t(`Column_Header.${column.label}`)}`,
            },
        },

        sortOrder: sortOrder,
        onColumnSortChange: onTableChange,

        // TODO: Defined some data that is commented (doesnt work for now, bug in tenderGQL)
        customFooter() {
            if (onlyMobile) {
                return (
                    <MobileTableNavigation
                        perPage={paginatorInfo.perPage}
                        total={paginatorInfo.total}
                        firstItemIndex={paginatorInfo.firstItem as number}
                        lastItemIndex={paginatorInfo.lastItem as number}
                        currentPage={paginatorInfo.currentPage}
                        onChangePagination={onChangePagination}
                    />
                );
            }
            return (
                <Grid container spacing={2} style={{ padding: 20 }} alignItems="center" justifyContent="space-between">
                    {/* Left section
                     *  number of total results found
                     */}
                    <Grid item sm={12} md={3} style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                        {paginatorInfo.total === 0 ? 0 : paginatorInfo?.firstItem?.toLocaleString()}-{paginatorInfo?.lastItem?.toLocaleString()}{" "}
                        {t("of")} {paginatorInfo?.total.toLocaleString()}{" "}
                        {target === "ALL_DOCS" && (
                            <Tooltip
                                title={
                                    "Deze resultaten werden gevonden in de samenvatting en in alle documenten samen. Vanwege een prestatie beperking wordt alleen gezocht in documenten van de laatste 12 maanden. Als je de zoekregel opslaat, wordt wel onbeperkt gezocht in alle documenten."
                                }
                            >
                                <IconButton sx={{ width: "0.7em", height: "0.7em", margin: "0 8px" }}>
                                    <InfoOutlined sx={{ width: "0.7em", height: "0.7em" }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>

                    {/* Middle section,
                     *  Show amount of pages with back/next arrow
                     */}
                    <Grid item sm={12} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Pagination
                            page={paginatorInfo.currentPage}
                            count={amountPages}
                            onChange={(_, page) => onChangePagination(page, paginatorInfo.perPage)}
                        />
                    </Grid>

                    {/* Right section
                     *  Selectable field with option to show more/less rows per page
                     */}
                    <Grid item sm={12} md={3} style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                        <Select
                            variant="standard"
                            value={paginatorInfo.perPage}
                            onChange={(e) => onChangePagination(paginatorInfo.currentPage, e.target.value as number)}
                            style={{ marginRight: 4 }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>

                        {t("resultsPerPage")}
                    </Grid>
                </Grid>
            );
        },
    };

    return (
        <React.Fragment>
            <LinearLoader loading={loading} color="blue" />
            <Disable disabledOpacity={0.6} disabled={loading}>
                <Paper square elevation={0}>
                    {/* Added paper element around table to use the square variant instead of the default, 
                - in MuidatatableOptons: elevation -> 0 */}
                    <ThemeProvider theme={CustomMuiTheme}>
                        <MUIDataTable title="Alle aanbestedingen" data={convertedData} columns={CaTenderColumns} options={options} />
                    </ThemeProvider>
                </Paper>
            </Disable>
        </React.Fragment>
    );
};

export default CaTenderTable;
