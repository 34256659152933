import React, { useState } from "react";
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Typography, Divider, useTheme, useMediaQuery } from "@mui/material";
import WidgetBox from "../WidgetBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContractorsOnly from "./ContractorsOnly";
import { Link } from "react-router-dom";
import ExtendedDetails from "./ExtendedDetails";
import { useTranslation } from "react-i18next";
import moment from "moment";
import StatusChip from "../StatusChip";

// gql imports
import { useQuery } from "@apollo/client";
import { GetStarredRelatedOrgs } from "../../__generated__/GetStarredRelatedOrgs";
import {
    GetRelatedTendersForCurrentTender_tender_related_tenders,
    GetRelatedTendersForCurrentTender_tender_related_tenders_bids,
} from "../../__generated__/GetRelatedTendersForCurrentTender";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { withStyles } from "tss-react/mui";
import { QUERY_GETSTARREDORGS } from "../../graphql/queryDefCurrentUser";
import { mergeOptiesEinde } from "../../utils/contracttimeline";
import FaviconBox from "../FaviconBox";
import { LightTooltip } from "../../routes/projects/columnView/projectcard/ConnectedEntitities";
interface Props {
    related_tenders: GetRelatedTendersForCurrentTender_tender_related_tenders[];
}

const Accordion = withStyles(MuiAccordion, {
    root: {
        border: "none",
        boxShadow: "none",
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {
        margin: "0 !important",
    },
});

const RelatedTenderWidget: React.FC<Props> = ({ related_tenders }) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState<string | false>(false);
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { trackEvent } = useMatomo();

    // Track event
    const trackClickRltdTender = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    /**
     * query for starred tenderers
     */
    const { error, data } = useQuery<GetStarredRelatedOrgs>(QUERY_GETSTARREDORGS);

    /**
     * check if error occured
     */
    if (error || data?.currentUser?.market_parties === undefined || data?.currentUser?.market_parties === null) {
        return null;
    }

    const starredORGS = data.currentUser.market_parties;

    const areAllLotsNull = (bids: GetRelatedTendersForCurrentTender_tender_related_tenders_bids[] | null) => {
        // Check if the bids array is not empty
        if (bids === null || bids.length === 0) {
            return true;
        }

        // Iterate through each bid in the array
        for (let i = 0; i < bids.length; i++) {
            // Check if the lot field is not null
            if (bids[i].lot !== null) {
                return false; // Return false if any lot is not null
            }
        }

        // Return true if all lot fields are null
        return true;
    };

    // Helper function to deduplicate and filter the bids
    const getUniqueWinningBids = (bids: any[]) => {
        return [
            ...new Map(
                bids
                    .filter((bid) => bid.winner === true && bid.marketParty) // Ensure marketParty exists
                    .map((b) => [b.marketParty.id, b]) // Deduplicate by `b.marketParty.id`
            ).values(),
        ];
    };

    return (
        <WidgetBox header={t("tenderPage.RelatedTenders")} highlight="blue" padding={0}>
            <div style={{ padding: 0 }}>
                {related_tenders.map((item) => {
                    return (
                        <React.Fragment key={item.id}>
                            <Accordion
                                TransitionProps={{
                                    timeout: 100,
                                }}
                                variant="outlined"
                                expanded={
                                    item.bids !== undefined && item.bids !== null && item.bids?.length > 0 && expanded === item.id ? true : false
                                }
                                onChange={item.bids !== undefined && item.bids !== null && item.bids.length > 0 ? handleChange(item.id) : undefined}
                            >
                                {/* code to open accordion 1 by 1 instead of multiple */}
                                <AccordionSummary
                                    expandIcon={
                                        item.bids !== undefined && item.bids !== null && item.bids.length > 0 ? (
                                            <ExpandMoreIcon />
                                        ) : (
                                            <div style={{ height: 24, width: 24, cursor: "default !important" }} />
                                        )
                                    }
                                    aria-label="Expand"
                                    sx={{
                                        "& .Mui-disabled": {
                                            cursor: "default !important",
                                        },
                                        "& .MuiAccordionSummary-root": {
                                            cursor:
                                                item.bids !== undefined && item.bids !== null && item.bids?.length > 0
                                                    ? "pointer"
                                                    : "default !important",
                                        },
                                        "& .MuiAccordionSummary-content": {
                                            cursor:
                                                item.bids !== undefined && item.bids !== null && item.bids?.length > 0
                                                    ? "pointer"
                                                    : "default !important",
                                        },
                                        "& .MuiAccordionSummary-expandIconWrapper": {
                                            cursor:
                                                item.bids !== undefined && item.bids !== null && item.bids?.length > 0
                                                    ? "pointer"
                                                    : "default !important",
                                        },
                                        "&:hover": {
                                            background:
                                                item.bids !== undefined && item.bids !== null && item.bids?.length > 0 ? "#f4f4f4" : undefined,
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            color: "#000000",
                                            fontSize: "0.785rem",
                                            flexBasis: "7%",
                                        }}
                                    >
                                        {item.first_dispatchdate !== null ? moment(item.first_dispatchdate).format("YYYY") : ""}
                                    </Typography>
                                    <div
                                        style={{
                                            flexBasis: "90%",
                                            flexShrink: 2,
                                            paddingRight: onlyMobile ? "8px" : "20px",
                                            display: "flex",
                                            justifyContent: "center",
                                            paddingLeft: onlyMobile ? "8px" : undefined,
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Link
                                            style={{ display: "flex" }}
                                            to={`/tenders/${item.id}`}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                trackClickRltdTender({
                                                    category: "Tender",
                                                    action: "Go-from-related-tenders-to-tender",
                                                    value: parseInt(item.id),
                                                });
                                            }}
                                            // onFocus={(event) => event.stopPropagation()}
                                        >
                                            <Typography
                                                sx={{
                                                    display: "inline-block",
                                                    "&:hover": {
                                                        textDecoration: "underline",
                                                    },
                                                }}
                                            >
                                                {item.namegiventocontract}
                                            </Typography>
                                        </Link>
                                        <LightTooltip
                                            arrow={false}
                                            placement="bottom-start"
                                            title={
                                                <div>
                                                    {getUniqueWinningBids(item.bids ?? []).map((i) => {
                                                        return (
                                                            <div
                                                                key={i?.id}
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    marginBottom:
                                                                        item.bids?.filter((bid) => bid.winner === true)?.length === 1 ? "0px" : "6px",
                                                                }}
                                                            >
                                                                <FaviconBox
                                                                    favicon={i.marketParty.favicon_resized || i.marketParty.favicon}
                                                                    id={i?.id}
                                                                    name={i.marketParty.name}
                                                                />
                                                                <span style={{ fontSize: "14px", lineHeight: 1.1 }}>
                                                                    {i.marketParty.name} <br />
                                                                </span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            }
                                        >
                                            <div style={{ display: "flex" }}>
                                                {(() => {
                                                    const uniqueBids = getUniqueWinningBids(item.bids ?? []);

                                                    return (
                                                        <>
                                                            {uniqueBids.slice(0, 5).map((b) => (
                                                                <div key={b.id}>
                                                                    <FaviconBox
                                                                        favicon={b.marketParty.favicon_resized || b.marketParty.favicon}
                                                                        name={b.marketParty.name}
                                                                    />
                                                                </div>
                                                            ))}

                                                            {uniqueBids.length === 1 ? (
                                                                <Typography variant="caption">{uniqueBids[0].marketParty.name}</Typography>
                                                            ) : uniqueBids.length > 5 ? (
                                                                <Typography variant="caption">+{uniqueBids.length - 5} andere</Typography>
                                                            ) : null}
                                                        </>
                                                    );
                                                })()}
                                            </div>
                                        </LightTooltip>
                                    </div>
                                    {/*
                                     * If expanded is not same as id show chip
                                     * If its the same -> chip is visible in accordiondetail
                                     */}
                                    {expanded !== item.id && (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <StatusChip type={item.status} colored={false} hover={false} />
                                        </div>
                                    )}
                                </AccordionSummary>
                                {/* AccordionDetails when there are only tenderers */}
                                {item.status !== "PROCUREMENT_DISCONTINUED" && (
                                    <AccordionDetails
                                        sx={{
                                            "& .MuiAccordionDetails-root": {
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                flexDirection: "column",
                                            },
                                        }}
                                    >
                                        {/* component that shows up when there are only contractors */}
                                        {item.bids !== undefined && item.bids !== null && item.bids?.length < 10 && areAllLotsNull(item.bids) ? (
                                            <ContractorsOnly
                                                tender_id={item.id}
                                                startDate={item.looptijd_start ? item.looptijd_start[0]?.value : ""}
                                                endDate={item.looptijd_einde ? item.looptijd_einde[0]?.value : ""}
                                                bids={item.bids}
                                                firstEndDate={item.next_looptijd_einde ? item.next_looptijd_einde[0]?.value : ""}
                                                starredOrgs={starredORGS}
                                                looptijd_einde_incl_opties={item.looptijd_einde_incl_opties}
                                                opties={mergeOptiesEinde(item.opties_einde)}
                                                datum_gunning={item.datum_gunning}
                                            />
                                        ) : item.bids !== undefined && item.bids !== null && item.bids?.length < 10 && item.lots.length > 0 ? (
                                            //component that shows up when there are lots
                                            <ExtendedDetails
                                                bids={item.bids}
                                                lots={item.lots}
                                                starredOrgs={starredORGS}
                                                opties={mergeOptiesEinde(item.opties_einde)}
                                                tender={item}
                                            />
                                        ) : (
                                            // text with button when there are to many items to display
                                            <React.Fragment>
                                                <div style={{ marginBottom: 16, display: "flex", justifyContent: "center" }}>
                                                    <Typography variant="body1">
                                                        Er zijn {item.lots.length > 0 ? `${item.lots.length} percelen met` : ""} in totaal{" "}
                                                        {item.bids?.length} opdrachtnemers.{" "}
                                                        <Link to={`/tenders/${item.id}`}>Ga naar aanbesteding</Link>
                                                    </Typography>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </AccordionDetails>
                                )}
                            </Accordion>
                            <Divider />
                        </React.Fragment>
                    );
                })}
            </div>
        </WidgetBox>
    );
};

export default RelatedTenderWidget;
