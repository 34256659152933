import React, { useState } from "react";

import { Box, CircularProgress, IconButton, TextField, Tooltip } from "@mui/material";
import MatomoEvent from "../../../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Check, Clear } from "@mui/icons-material";
import { Disable } from "react-disable";
import TooltipOnNewLines from "../../../../components/TooltipOnNewLines";
import { Variant } from "@mui/material/styles/createTypography";
import DOMPurify from "dompurify";
import { sanitizeInput } from "../../../../utils/sanitizeInput";

interface Props {
    tasktext: string;
    id: string;
    updateText?: (id: string, text: string) => void;
    loading: boolean;
    variant?: Variant;
    fontWeight?: string | number;
    fontSize?: string | number;
}

const EditTaskBtn: React.FC<Props> = ({ id, tasktext, updateText, loading, variant = "body1", fontWeight = "400", fontSize = "14px" }) => {
    const [editMode, setEditMode] = useState<boolean>(false);
    const [name, setName] = useState(tasktext);

    const { trackEvent } = useMatomo();
    // Change description of task

    // Track event
    const track = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * Set name to new input
     * set editmode => false
     */
    const updateSetting = () => {
        updateText && updateText(id, name);
        setEditMode(false);
        track({ category: "Project", action: "Change-task-In-library", name: "description" });
    };

    /**
     * reset name to last saved name
     * set editmode => false
     */
    const cancelSetting = () => {
        setName(tasktext);
        setEditMode(false);
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
            {/*
             * Check if editmode is active and check if user is boss of team
             * If both are true => show textfield to change a teamname
             */}
            {editMode ? (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "90%" }}>
                    <TextField
                        autoFocus
                        name={"function"}
                        variant={"standard"}
                        size={"small"}
                        fullWidth
                        value={name}
                        onChange={(e) => {
                            const inputvalue = sanitizeInput(e.target.value, false);
                            const val = DOMPurify.sanitize(inputvalue);
                            setName(val);
                        }}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{
                            style: { padding: "1px 0px" },
                        }}
                    />

                    {/* Show button to save new teamname and a button to cancel namechange if teamname was already changed
                     * Button will be disabled as long as teamname = "Voer een teamnaam in" & teamname is less than 3 characters
                     */}
                    {name.length > 2 && (
                        <React.Fragment>
                            <Tooltip title="Opslaan" placement="top" disableInteractive>
                                <IconButton disabled={loading} size="small" onClick={() => updateSetting()}>
                                    {loading ? <CircularProgress size={18} /> : <Check fontSize="small" sx={{ color: "#508c79" }} />}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Annuleren" placement="top" disableInteractive>
                                <IconButton size="small" onClick={() => cancelSetting()}>
                                    <Clear fontSize="small" sx={{ color: "#c0392b" }} />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    )}
                </div>
            ) : (
                // Clickable box to activate editmode
                <div style={{ width: "90%" }}>
                    <Disable disabled={false}>
                        <Box
                            onClick={() => {
                                setEditMode(true);
                            }}
                            sx={{
                                // padding: "10.5px 14px 10.5px 14px",
                                height: "26.13px",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                "&:hover": {
                                    backgroundColor: "#f4f4f4",
                                    cursor: "pointer",
                                    borderRadius: "4px",
                                    width: "100%",
                                },
                            }}
                        >
                            <TooltipOnNewLines
                                text={name}
                                variant={variant}
                                lines={1}
                                id={`task${id}`}
                                fontweight={fontWeight}
                                fontSize={fontSize}
                                placement={"top"}
                            />
                        </Box>
                    </Disable>
                </div>
            )}
        </div>
    );
};

export default EditTaskBtn;
