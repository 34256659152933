import React from "react";

import { CardActionArea, Checkbox, Paper, styled, Typography } from "@mui/material";
import FaviconBox from "../../../../components/FaviconBox";
import { Star } from "@mui/icons-material";
import { FilterType } from "../../../../components/guides/tenderWizard/WizardContext";
import { useQuery } from "@apollo/client";
import { GetCaForProject, GetCaForProjectVariables } from "../../../../__generated__/GetCaForProject";
import { QUERY_CA_FOR_PROJECT } from "../../../../graphql/queryDefProject";

interface Props {
    value: FilterType;
    starredCAS: number[];
    chosenADIdentifiers: number[];
    onToggleAD: (type: FilterType) => void;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    paddingRight: "8px",
    // textAlign: "center",
    display: "flex", // changed to flex for horizontal layout
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.text.secondary,
    maxWidth: "100%", // ensure the container doesn't expand beyond available space
}));

const ChosenCaOption: React.FC<Props> = ({ value, chosenADIdentifiers, onToggleAD, starredCAS }) => {
    /**
     * Default tender summary query
     */
    const { data } = useQuery<GetCaForProject, GetCaForProjectVariables>(QUERY_CA_FOR_PROJECT, {
        variables: { id: value.identifier.toString() },
        fetchPolicy: "network-only",
    });

    return (
        <CardActionArea
            onClick={() =>
                onToggleAD({
                    key: data?.contracting_authority?.id as string,
                    label: data?.contracting_authority?.name as string,
                    identifier: parseInt(data?.contracting_authority?.id as string),
                })
            }
        >
            <Item variant="outlined">
                <div>
                    <FaviconBox
                        favicon=""
                        name={value.label !== "" ? value.label : (data?.contracting_authority?.name as string)}
                        color={"#F57117"}
                        id={value.identifier.toString() ?? (data?.contracting_authority?.id as string)}
                    />
                </div>
                <Typography
                    noWrap={false}
                    sx={{ flexGrow: 1, wordWrap: "break-word", maxWidth: "calc(100% - 24px)", display: "flex", alignItems: "center" }}
                >
                    {value.label !== "" ? value.label : (data?.contracting_authority?.name as string)}
                    {starredCAS.includes(parseInt(data?.contracting_authority?.id as string)) && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Star sx={{ color: "#F57117", marginLeft: "8px", width: "20px", height: "20px" }} />
                        </div>
                    )}
                </Typography>
                <Checkbox checked={chosenADIdentifiers.includes(parseInt(data?.contracting_authority?.id as string))} />
            </Item>
        </CardActionArea>
    );
};

export default ChosenCaOption;
