import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Divider, Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import StatusChip from "../../../components/StatusChip";
import SearchRuleData from "../../../components/SearchRuleData";
import ThumbUp from "@mui/icons-material/ThumbUp";
import ThumbDown from "@mui/icons-material/ThumbDown";
import Message from "@mui/icons-material/Message";
import { Euro, Schedule } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FilterSearchRuleTenders_filterTenders_results_data } from "../../../__generated__/FilterSearchRuleTenders";
import moment from "moment";
import DescriptionIcon from "@mui/icons-material/Description";
import FaviconBox from "../../../components/FaviconBox";
import SectorIconLoader from "../../../components/boxes/SectorIconLoader";
import _ from "lodash";
import SplitDetailMetaStartEndDate from "./SplitDetailMetaStartEndDate";
import { useLazyQuery } from "@apollo/client";
import {
    FilterSearchRuleTenderDetailInformation,
    FilterSearchRuleTenderDetailInformationVariables,
    FilterSearchRuleTenderDetailInformation_tender,
} from "../../../__generated__/FilterSearchRuleTenderDetailInformation";
import { QUERY_TENDER_DETAIL_BUILDER } from "../../../graphql/queryDefinitions";
import { getRelativeTimeInDays } from "../../../utils/relativeTimeInDays";
import { currencyFormat } from "../../../utils/currencyFormat";
interface Props {
    data: FilterSearchRuleTenders_filterTenders_results_data[] | null | undefined;
    selectedTender: string | undefined;
    totalTenders: number | undefined;
    loading: boolean;
}

// THIS COMPONENT IS USED IN TENDERDETAIL SPLITVIEW MODE AND RESULTPAGE IN SEARCHRULE WIZARD.
const TenderViewer: React.FC<Props> = ({ data, selectedTender, totalTenders, loading }) => {
    const { t } = useTranslation();
    const history = useLocation();

    // check if url is worklist or searchwizard.
    // based on url show/hide some modules.
    const worklist = history.pathname === "/tenders/worklist";
    const resultModal = history.pathname === "/profile/search-rules";
    const yellow = "#F57117";
    const [selectedItem, setSelectedItem] = useState<FilterSearchRuleTenderDetailInformation_tender>();

    /**
     * Highlighted summary query
     */
    const [getdetails, { loading: loadDetails }] = useLazyQuery<
        FilterSearchRuleTenderDetailInformation,
        FilterSearchRuleTenderDetailInformationVariables
    >(QUERY_TENDER_DETAIL_BUILDER, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.tender) {
                setSelectedItem(data.tender);
            }
        },
    });

    /**
     * Don't set id if no rows are available
     */
    useEffect(() => {
        if (data !== null && data !== undefined && data.length > 0 && (selectedItem === undefined || selectedItem.id !== selectedTender)) {
            getdetails({ variables: { id: selectedTender as string } });
        }
    }, [data, getdetails, selectedTender, selectedItem]);

    if (loadDetails || loading) {
        return (
            <Paper elevation={0} square style={{ height: "100%", overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <DescriptionIcon style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />
                    <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                        Resultaten ophalen
                    </Typography>
                </div>
            </Paper>
        );
    }

    if (totalTenders === 0 || totalTenders === undefined) {
        return (
            <Paper elevation={0} square style={{ height: "100%", overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <DescriptionIcon style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />
                    <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                        Geen resultaten gevonden voor deze zoekopdracht
                    </Typography>
                    {/* {
                        <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                            (op basis van aanbestedingen van de afgelopen 6 maanden)
                        </Typography>
                    } */}
                </div>
            </Paper>
        );
    }

    if (selectedItem === undefined) {
        return (
            <Paper elevation={0} square style={{ height: "100%", overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <DescriptionIcon style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />
                    <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                        Geen aanbesteding geselecteerd
                    </Typography>
                </div>
            </Paper>
        );
    }

    const primaryCA = selectedItem.clients.find((ca) => ca.pivot?.category === "A");
    const uniqueParentIds = _.uniqBy(selectedItem.sectors, function (e) {
        return e?.parent?.id;
    });

    return (
        <Paper elevation={0} square style={{ height: "100%", overflow: "auto" }}>
            <Grid container>
                {/* Top Grid with state and searchrules that match with specific tender */}
                <Grid
                    xs={12}
                    md={12}
                    item
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "100%",
                        padding: "16px",
                        paddingBottom: "0px",
                        paddingLeft: "48px",
                        marginBottom: "16px",
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {selectedItem.status ? (
                            <div style={{ marginRight: 8 }}>
                                {loadDetails ? (
                                    <Skeleton variant="circular" width={40} height={40} />
                                ) : (
                                    <StatusChip type={selectedItem.status} colored={true} />
                                )}
                            </div>
                        ) : (
                            <div style={{ marginRight: 0 }} />
                        )}
                        {/* splitview, show searchrule label(s) */}
                        {worklist && selectedTender !== undefined && (
                            <div>
                                <SearchRuleData placement="top" tender_id={selectedTender} searches={[]} />
                            </div>
                        )}
                    </div>
                    {/* spliview, show buttongroup to like, dislike or chat  */}
                    {worklist && (
                        <div>
                            {/* Like */}
                            <Tooltip placement="top" title="Interessant">
                                <IconButton
                                    aria-label="list"
                                    size="small"
                                    style={{ margin: 1 }}
                                    onClick={() => {
                                        alert("interesting");
                                    }}
                                >
                                    <ThumbUp style={{ fontSize: "1.3rem" }} />
                                </IconButton>
                            </Tooltip>

                            {/* Dislike */}
                            <Tooltip placement="top" title="Niet interessant">
                                <IconButton
                                    aria-label="column"
                                    size="small"
                                    style={{ margin: 1 }}
                                    onClick={() => {
                                        alert("disliked");
                                    }}
                                >
                                    <ThumbDown style={{ fontSize: "1.3rem" }} />
                                </IconButton>
                            </Tooltip>

                            {/* Chat option */}
                            <Tooltip placement="top" title="Opmerking plaatsen">
                                <IconButton
                                    aria-label="event"
                                    size="small"
                                    style={{ margin: 1 }}
                                    onClick={() => {
                                        alert("Chat opened");
                                    }}
                                >
                                    <Message />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                </Grid>

                {/* Title of clicked tender */}
                <Grid
                    xs={12}
                    md={12}
                    item
                    style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        paddingLeft: "48px",
                        paddingRight: "48px",
                        marginBottom: "16px",
                    }}
                >
                    {/* wizard, show title */}
                    {resultModal && <Typography variant="h5">{selectedItem.namegiventocontract}</Typography>}
                    {resultModal && (
                        <Typography variant="body1" style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 5 }}>
                            <FaviconBox
                                marginTop={0}
                                color={yellow}
                                favicon={primaryCA?.favicon_resized ?? primaryCA?.favicon ?? null}
                                name={primaryCA?.name ? primaryCA.name : selectedItem.published_opdrachtgever}
                            />{" "}
                            {primaryCA?.name ? primaryCA.name : selectedItem.published_opdrachtgever}
                        </Typography>
                    )}

                    {/* splitview, show title with link to detailpage */}
                    {worklist && (
                        <Link to={`/tenders/${selectedItem.id}`}>
                            <Typography style={{ display: "inline" }} variant="h5">
                                {selectedItem.namegiventocontract}
                            </Typography>
                        </Link>
                    )}
                </Grid>
                {/* 
                                // TODO: style => to classes for responsiveness small screen and extra large 
                                */}
                <Grid style={{ height: worklist ? "60vh" : "57vh" }}>
                    {/* Grid container with all items, overflow set for scroll */}
                    <Grid
                        container
                        style={{
                            height: worklist ? "90%" : "100%",
                        }}
                    >
                        <Grid
                            xs={12}
                            md={12}
                            item
                            style={{
                                height: worklist ? "80%" : "73%",
                                overflow: "auto",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                paddingLeft: "48px",
                                paddingRight: "48px",
                                marginBottom: "16px",
                            }}
                        >
                            {/* Summary */}
                            {selectedItem.omschrijving && (
                                <div style={{ marginBottom: "16px" }}>
                                    <Typography variant="h4">{t("wizard.Description")}</Typography>
                                    <Typography
                                        style={{ display: "inline", whiteSpace: "pre-wrap" }}
                                        variant={"body2"}
                                        dangerouslySetInnerHTML={{
                                            __html: selectedItem.omschrijving,
                                        }}
                                    />
                                </div>
                            )}

                            {/* Scope */}
                            {selectedItem.omvang_opdracht && (
                                <div style={{ marginBottom: "16px" }}>
                                    <Typography variant="h4">{t("wizard.Scope")}</Typography>
                                    <Typography
                                        style={{ display: "inline", whiteSpace: "pre-wrap" }}
                                        variant={"body2"}
                                        dangerouslySetInnerHTML={{
                                            __html: selectedItem.omvang_opdracht,
                                        }}
                                    />
                                </div>
                            )}
                        </Grid>

                        <Grid xs={12}>
                            <div style={{ paddingBottom: 16 }}>
                                <Divider variant="middle" />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "flex-start",
                                    justifyContent: "center",
                                    width: "100%",
                                    padding: "0 48px 16px 48px",
                                }}
                            >
                                <div style={{ flex: " 1 1 30%" /*grow | shrink | basis */, minHeight: "70px" }}>
                                    {/* Deadline */}
                                    <div style={{ marginBottom: "16px" }}>
                                        <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                            <Schedule fontSize="small" sx={{ marginRight: "4px", color: "#9e9e9e" }} /> Deadline
                                        </Typography>
                                        {selectedItem.deadline_inschrijving ? (
                                            <Typography>{moment(selectedItem.deadline_inschrijving).format("L LT")},</Typography>
                                        ) : (
                                            <Typography>-</Typography>
                                        )}
                                        {selectedItem.deadline_inschrijving && (
                                            <Typography>
                                                {getRelativeTimeInDays(selectedItem.deadline_inschrijving, t).show && (
                                                    <>({getRelativeTimeInDays(selectedItem.deadline_inschrijving, t).date})</>
                                                )}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                                <div style={{ flex: " 1 1 30%" /*grow | shrink | basis */, minHeight: "70px" }}>
                                    <div style={{ marginBottom: "16px" }}>
                                        <SplitDetailMetaStartEndDate tender_id={selectedItem.id} />
                                    </div>
                                </div>
                                <div style={{ flex: " 1 1 30%" /*grow | shrink | basis */, minHeight: "70px" }}>
                                    {/* Optional years */}
                                    <div style={{ marginBottom: "16px" }}>
                                        <Typography variant="h4" sx={{ height: "20px", display: "flex", alignItems: "center" }}>
                                            {t("tenderPage.Options")}
                                        </Typography>
                                        {selectedItem.contract === null && "-"}
                                        {selectedItem.contract !== undefined &&
                                            selectedItem.contract?.options !== undefined &&
                                            selectedItem.contract?.options.length > 0 &&
                                            selectedItem.contract?.options.map((option, i) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        {option.indefinite === true ? (
                                                            <>
                                                                {t("tenderPage.indefiniteNumberOfOptionsFrom")} {option.length}{" "}
                                                                {option.length === 1
                                                                    ? t(`singleContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)
                                                                    : t(`multiContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {option.amount} x {option.length}{" "}
                                                                {option.length === 1
                                                                    ? t(`singleContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)
                                                                    : t(`multiContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)}
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                    </div>
                                </div>

                                {/*
                                 * Second row of items
                                 */}
                                <div style={{ flex: " 1 1 30%" /*grow | shrink | basis */, minHeight: "70px" }}>
                                    {" "}
                                    {/* First publication */}
                                    <div style={{ marginBottom: "16px" }}>
                                        <Typography variant="h4" sx={{ height: "20px", display: "flex", alignItems: "center" }}>
                                            {t("tenderPage.FirstPublication")}
                                        </Typography>
                                        <Typography>
                                            {selectedItem.first_dispatchdate ? moment(selectedItem.first_dispatchdate).format("L") : "-"}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: " 1 1 30%" /*grow | shrink | basis */, minHeight: "70px" }}>
                                    {/* Scope */}
                                    {selectedItem.scope !== null ? (
                                        <div style={{ marginBottom: "16px" }}>
                                            {selectedItem?.scope?.total !== null ? (
                                                <>
                                                    <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                                        <Euro
                                                            fontSize="small"
                                                            sx={{
                                                                marginRight: "4px",
                                                                color: "#9e9e9e",
                                                            }}
                                                        />{" "}
                                                        {t("tenderPage.TotalExpectedValue")}
                                                    </Typography>
                                                    <Typography>
                                                        {currencyFormat(selectedItem?.scope?.total.currency)}
                                                        {selectedItem?.scope?.total?.value?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                                                    </Typography>
                                                </>
                                            ) : selectedItem.scope.max !== null ? (
                                                <>
                                                    <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                                        <Euro
                                                            fontSize="small"
                                                            sx={{
                                                                marginRight: "4px",
                                                                color: "#9e9e9e",
                                                            }}
                                                        />{" "}
                                                        {t("tenderPage.MaximumExpectedValue")}
                                                    </Typography>
                                                    <Typography>
                                                        {currencyFormat(selectedItem?.scope?.max.currency)}
                                                        {selectedItem?.scope?.max?.value?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                                                    </Typography>
                                                </>
                                            ) : selectedItem.scope.min !== null ? (
                                                <>
                                                    <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                                        <Euro
                                                            fontSize="small"
                                                            sx={{
                                                                marginRight: "4px",
                                                                color: "#9e9e9e",
                                                            }}
                                                        />{" "}
                                                        {t("tenderPage.MinimumExpectedValue")}
                                                    </Typography>
                                                    <Typography>
                                                        {currencyFormat(selectedItem?.scope?.min.currency)}
                                                        {selectedItem?.scope?.min?.value?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                                                    </Typography>
                                                </>
                                            ) : (
                                                <div style={{ marginBottom: "16px" }}>
                                                    <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                                        <Euro
                                                            fontSize="small"
                                                            sx={{
                                                                marginRight: "4px",
                                                                color: "#9e9e9e",
                                                            }}
                                                        />{" "}
                                                        {t("wizard.Scope")}
                                                    </Typography>
                                                    <Typography>-</Typography>{" "}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div style={{ marginBottom: "16px" }}>
                                            <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                                <Euro fontSize="small" sx={{ marginRight: "4px", color: "#9e9e9e" }} /> {t("wizard.Scope")}
                                            </Typography>
                                            <Typography>-</Typography>{" "}
                                        </div>
                                    )}
                                </div>
                                <div style={{ flex: " 1 1 30%" /*grow | shrink | basis */, minHeight: "70px" }}>
                                    {/* subsectors or sectors based on country (528 = nl) of tender */}
                                    {selectedItem.country !== null && selectedItem.country.numeric === "528" ? (
                                        <div style={{ marginBottom: "16px" }}>
                                            <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                                {selectedItem.sectors.length > 1 ? t("subsectors") : t("subsector")}
                                            </Typography>
                                            {/* map subsectors */}
                                            {selectedItem.sectors.map((s) => (
                                                <div key={s.id} style={{ display: "flex", alignItems: "center" }}>
                                                    <SectorIconLoader sector_id={s.id} />
                                                    <Typography style={{ marginLeft: 8 }}>{s.name}</Typography>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div style={{ marginBottom: "16px" }}>
                                            <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                                {uniqueParentIds.length > 1 ? t("HeadSectors") : t("HeadSector")}
                                            </Typography>
                                            {/* map sectors */}
                                            {uniqueParentIds.map((s) => {
                                                return (
                                                    <div key={s.id} style={{ display: "flex", alignItems: "center" }}>
                                                        <Typography>{s.parent?.name}</Typography>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default TenderViewer;
