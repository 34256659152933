import React from "react";
import { Typography } from "@mui/material";
import { filterArticles_filterArticles_data } from "../../__generated__/filterArticles";
import { useNavigate } from "react-router-dom";
import { categories, getCategoryIcon } from "../../components/chat/CatSelector";
import ColumnContent from "../columns/ColumnContent";
import { QueryInitials } from "../../__generated__/QueryInitials";
import { QUERY_INITIALS } from "../../graphql/queryDefCurrentUser";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface Props {
    article: filterArticles_filterArticles_data;
    setArticle: (value: React.SetStateAction<filterArticles_filterArticles_data | null>) => void;
    replacingContent: string;
    overview?: boolean;
}

const ArticleListItem: React.FC<Props> = ({ article, setArticle, replacingContent, overview }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { data: user } = useQuery<QueryInitials>(QUERY_INITIALS);
    const name = user?.currentUser.employee.name?.replace(/\s/g, "%20");

    const getCategoryIconForModule = (module: string) => {
        const category = categories.find((i) => {
            const icon = getCategoryIcon(i.icon);
            return icon && i.link === module;
        });

        return category ? getCategoryIcon(category.icon) : null;
    };

    return (
        <div
            onClick={() => {
                if (overview) {
                    if (article.category === "tendenz") {
                        navigate(`/tendenz/${article.id}`);
                    } else {
                        setArticle(article);
                        navigate(`${article.category}/${article.id}`);
                    }
                } else {
                    if (article.category === "tendenz") {
                        navigate(`/tendenz/${article.id}`);
                    } else {
                        setArticle(article);
                        navigate(article.id);
                    }
                }
            }}
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                borderRadius: "24px",
                background: "#70a4b726",
                width: "100%",
                position: "relative",
                overflow: "hidden",
            }}
        >
            {/* Image Container with Overlay */}
            <div
                style={{
                    borderRadius: "24px",
                    backgroundColor: "#70a4b726",
                    height: "150px",
                    cursor: "pointer",
                    overflow: "hidden",
                    padding: "16px 24px 16px 24px",
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                }}
                onMouseEnter={(e) => {
                    const overlayElement = e.currentTarget.querySelector("div") as HTMLElement;
                    if (overlayElement) overlayElement.style.background = "rgba(0, 0, 0, 0.4)";
                }}
                onMouseLeave={(e) => {
                    const overlayElement = e.currentTarget.querySelector("div") as HTMLElement;
                    if (overlayElement) overlayElement.style.background = "rgba(0, 0, 0, 0)";
                }}
            >
                {/* Black overlay */}
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(0, 0, 0, 0)",
                        transition: "background 0.3s ease-in-out",
                        borderRadius: "24px",
                    }}
                />

                {/* Icon */}
                {article.module && article.module !== "-" && (
                    <div style={{ marginRight: "8px", display: "flex", alignItems: "center", position: "absolute" }}>
                        {(() => {
                            const icon = getCategoryIconForModule(article.module);
                            return icon
                                ? React.cloneElement(icon, {
                                      sx: {
                                          fontSize: "125px",
                                          marginRight: "4px",
                                          color: "#70a4b71a",
                                          marginLeft: "-60px",
                                          marginTop: "20px",
                                      },
                                  })
                                : null;
                        })()}
                    </div>
                )}

                {/* Category */}
                <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                        <Typography variant="h3" fontWeight={700} sx={{ width: "80%" }}>
                            {article.title}
                        </Typography>
                        {article.module !== "-" ? (
                            <div
                                style={{
                                    backgroundColor: "#70a4b7",
                                    margin: "-18px -32px -24px -24px",
                                    padding: "12px 24px 12px 12px",
                                    borderBottomLeftRadius: "16px",
                                    color: "#fff",
                                    fontWeight: "500",
                                }}
                            >
                                {t(`faq.${article.module}`)}
                            </div>
                        ) : article.category === "deepdive" ? (
                            <div
                                style={{
                                    backgroundColor: "#70a4b7",
                                    margin: "-18px -32px -24px -24px",
                                    padding: "12px 24px 12px 12px",
                                    borderBottomLeftRadius: "16px",
                                    color: "#fff",
                                    fontWeight: "500",
                                }}
                            >
                                deep dive
                            </div>
                        ) : article.category === "interview" ? (
                            <div
                                style={{
                                    backgroundColor: "#70a4b7",
                                    margin: "-18px -32px -24px -24px",
                                    padding: "12px 24px 12px 12px",
                                    borderBottomLeftRadius: "16px",
                                    color: "#fff",
                                    fontWeight: "500",
                                }}
                            >
                                interview
                            </div>
                        ) : null}
                    </div>

                    {/* Content */}
                    <div style={{ height: "60px", overflow: "hidden", marginTop: "-12px" }}>
                        <ColumnContent text={replacingContent || ""} column_id={article.id} username={name || ""} />
                    </div>

                    {/* Created date */}
                    <div>
                        <Typography fontStyle="italic" variant="caption">
                            {moment(article.created_at).format("L")}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArticleListItem;
