import { Box, TextField, Typography } from "@mui/material";
import React, { useContext } from "react";
import ProjectFaseSelector from "../projectModal/ProjectFaseSelector";
import { ProjectToOpenContext } from "../../../components/layout/MainLayout";
import DOMPurify from "dompurify";
import { sanitizeInput } from "../../../utils/sanitizeInput";

interface Props {
    a?: number;
}

const ProjectTitle: React.FC<Props> = (props) => {
    const { project, changeProject } = useContext(ProjectToOpenContext);

    return (
        <React.Fragment>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Project titel
            </Typography>
            <Box style={{ width: "100%", display: "flex", marginTop: "6px" }}>
                {/* Title */}
                <TextField
                    sx={{ flexGrow: 1 }}
                    placeholder="Project titel"
                    size="small"
                    variant="outlined"
                    // onKeyDown={}
                    onChange={(e) => {
                        const value = sanitizeInput(e.target.value, false);
                        const val = DOMPurify.sanitize(value);
                        changeProject({ projectTitle: val });
                    }}
                    value={project.projectTitle}
                />
                <ProjectFaseSelector width="100px" create />
            </Box>
        </React.Fragment>
    );
};

export default ProjectTitle;
