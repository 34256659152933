import React, { useState, useEffect, useRef, useCallback } from "react";

// Material UI components
import { ListItem, ListItemText, Typography, TextField, ListItemIcon, Checkbox, Button, List, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
// Custom components
import FaviconBox from "../FaviconBox";
import CountryFlagBox from "../boxes/CountryFlagBox";
import StatusDot from "../StatusDot";
import { sortCountry } from "../TenderSearchComponent/TenderSearch";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import SectorIconLoader from "../boxes/SectorIconLoader";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";
import DOMPurify from "dompurify";
import { sanitizeInput } from "../../utils/sanitizeInput";
interface Props {
    allowReset: boolean;
    values: any[];
    onChange(values: any[]): void;
    options: DropdownOption[];
    allowSearch?: boolean;
    setOpenFilter(open: string | boolean): void;
    title: string;
    helpertext?: string;
    allowGqlSearch?: boolean;
    setGqlSearch?(query: string): void;
    gqlSearch?: string;
    gqlLoading?: boolean;
    /**
     * handler to set custom options
     */
    setCustomChosenOptions?(value: React.SetStateAction<any[]>): void;
}
export type DropdownOption = { key: string; label: string; id: any };

const FilterItem: React.FC<Props> = ({
    allowReset,
    values,
    onChange,
    options,
    allowSearch,
    setOpenFilter,
    title,
    allowGqlSearch = false,
    setGqlSearch,
    gqlSearch,
    gqlLoading,
    helpertext,
    setCustomChosenOptions,
}) => {
    const [filter, setFilter] = useState<string>("");
    // Local state version of currently selected items
    const [newOptions, setNewOptions] = useState<any[]>(values);
    // Local state version of all options
    const [allOptions, setAllOptions] = useState<DropdownOption[]>(options);
    const { t } = useTranslation();
    const yellow = "#F57117";
    const { pathname } = useLocation();

    /**
     * When new values/options as prop are passed, update the internal state
     */
    useEffect(() => {
        setNewOptions(values);
        setAllOptions(options);
    }, [options, values]);

    /**
     * on click save button -> set selected items, reset filter to empty string and close selected filter
     */
    const handleClose = () => {
        onChange(newOptions);
        setFilter("");
        setOpenFilter(false);
    };

    /**
     * When a option is clicked, toggle the active state in the local state
     */
    const handleOptionClick = React.useCallback(
        (key: number) => {
            setNewOptions((prev) => {
                if (prev.includes(key)) {
                    return prev.filter((o) => o !== key);
                } else {
                    return [...prev, key];
                }
            });
            setCustomChosenOptions &&
                setCustomChosenOptions((prev: any) => {
                    if (prev.includes(key)) {
                        return prev.filter((o: number) => o !== key);
                    } else {
                        return [...prev, key];
                    }
                });
        },
        [setCustomChosenOptions]
    );

    /**
     * Only when search is allowed, we filter the options before rendering in the list
     */
    const filteredOptions =
        allowSearch !== true
            ? allOptions
            : allOptions.filter((option) => {
                  if (!filter) {
                      return true;
                  } else {
                      return option.label.toLowerCase().includes(filter.toLowerCase());
                  }
              });
    const inputGqlSearchDrawer = useRef(null);
    // debounce function to prevent request if characters is < 3 except 0

    const [userValue, setUserValue] = useState(gqlSearch || "");
    // debounce function to prevent request if characters is < 3 except 0

    const debounced = useRef(
        debounce(
            (value) => (value.length === 0 ? setGqlSearch && setGqlSearch(value) : value.length >= 3 && setGqlSearch && setGqlSearch(value)),
            900
        )
    );
    const updateUserValue = useCallback(({ target: { value } }) => {
        const inputvalue = sanitizeInput(value, false);
        const val = DOMPurify.sanitize(inputvalue);
        debounced.current(val);
        setUserValue(val);
    }, []);
    return (
        <React.Fragment>
            {/* Clicked filter opens and shows a searchbox if true and items inside selected filter.
                User can search inside this filter, selected items wil be visible in closed view when user clicks on save.
            */}
            {allowGqlSearch && setGqlSearch && gqlSearch !== undefined ? (
                <div style={{ padding: 8 }}>
                    <TextField
                        helperText={helpertext}
                        ref={inputGqlSearchDrawer}
                        placeholder="Zoeken..."
                        variant="outlined"
                        size="small"
                        value={userValue}
                        onChange={updateUserValue}
                        autoFocus
                        fullWidth
                        // pass data to Row component below
                        inputProps={{
                            sx: {
                                fontSize: "14px",
                                color: "#000000",
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <React.Fragment>
                                    {gqlLoading && gqlLoading === true && gqlSearch.length > 0 ? (
                                        <CircularProgress color="inherit" size={14} />
                                    ) : null}
                                </React.Fragment>
                            ),
                        }}
                    />
                </div>
            ) : allowSearch ? (
                <div style={{ padding: 16 }}>
                    <TextField
                        placeholder="Zoeken..."
                        variant="outlined"
                        size="small"
                        value={filter}
                        onChange={(e) => {
                            const val = DOMPurify.sanitize(e.target.value);
                            setFilter(val);
                        }}
                        autoFocus
                        fullWidth
                        inputProps={{
                            sx: {
                                fontSize: "14px",
                                color: "#000000",
                            },
                        }}
                    />
                </div>
            ) : null}
            <div style={{ overflow: "auto", flex: 1 }}>
                <div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <List>
                            {/* map over filtered items.
                                Options will be filtered when user starts typing in searchfield
                            */}
                            {filteredOptions
                                //! Sort array of options on country => NL will be the first element in the array
                                ?.sort((a, b) => sortCountry.indexOf(a.key) - sortCountry.indexOf(b.key))
                                .map((item) => {
                                    return (
                                        <React.Fragment key={item.key}>
                                            {/*
                                             * // TODO: style to classes to create responsive design
                                             */}
                                            <ListItem
                                                // ! role = undefined -> onClick function now works  alse on checkboxclick
                                                role={undefined}
                                                style={{ paddingLeft: 50 }}
                                                button
                                                onClick={() => handleOptionClick(item.id as number)}
                                            >
                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                    <Checkbox
                                                        style={{ marginRight: "16px" }}
                                                        sx={{
                                                            "& .Mui-checked": {
                                                                color: "#000000",
                                                            },
                                                        }}
                                                        checked={newOptions.findIndex((el) => el === item.id) !== -1}
                                                        edge="start"
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                {/* If favicon is not undefined -> show faviconbox or name if no favicon is available */}
                                                {title === "contractingAuthorities" && (
                                                    <div>
                                                        <FaviconBox favicon="" id={item.id.toString()} name={item.key} color={yellow} marginTop={0} />
                                                    </div>
                                                )}
                                                {title === "contracting_authorities" && (
                                                    <div>
                                                        <FaviconBox favicon="" id={item.id.toString()} name={item.key} color={yellow} marginTop={0} />
                                                    </div>
                                                )}
                                                {title === "status" && (
                                                    <div style={{ marginRight: 6, marginLeft: -4 }}>
                                                        <StatusDot type={item.id.toString()} placement={"right"} />
                                                    </div>
                                                )}
                                                {/* if countryFlag is not undefined -> show countryFlag */}
                                                {title === "country" && (
                                                    <div style={{ marginLeft: -4, marginRight: 6, marginTop: -2 }}>
                                                        <CountryFlagBox code={null} alpha2={item.id.toString()} />
                                                    </div>
                                                )}
                                                {/* if countryFlag is not undefined -> show countryFlag */}
                                                {title === "ca_country" && (
                                                    <div style={{ marginLeft: -4, marginRight: 6, marginTop: -2 }}>
                                                        <CountryFlagBox code={item.key} alpha2={item.id.toString()} />
                                                    </div>
                                                )}
                                                {/* show label for searchrules (tenders) */}
                                                {title === "searches" && (pathname === "/tenders/worklist" || pathname === "/tenders/reviews") && (
                                                    <div style={{ marginLeft: -4, marginRight: 6, marginTop: 6 }}>
                                                        <LocalOfferIcon style={{ color: item.key }} />
                                                    </div>
                                                )}
                                                {/* show label for searchrules (opportunity) */}
                                                {title === "searches" &&
                                                    (pathname === "/opportunities/worklist" || pathname === "/opportunities/reviews") && (
                                                        <div style={{ marginLeft: -4, marginRight: 6, marginTop: 6 }}>
                                                            <FindInPageIcon style={{ color: item.key }} />
                                                        </div>
                                                    )}
                                                {/* show label for searchrules */}
                                                {title === "subSectors" && (
                                                    <div style={{ marginLeft: -4, marginRight: 6, marginTop: 6 }}>
                                                        <SectorIconLoader sector_id={item.id.toString()} />
                                                    </div>
                                                )}
                                                <ListItemText primary={<Typography sx={{ color: "#000000" }}>{item.label}</Typography>} />
                                            </ListItem>
                                        </React.Fragment>
                                    );
                                })}
                        </List>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", margin: "24px" }}>
                {/* TODO: reset all filters  */}
                {allowReset && newOptions.length > 0 && (
                    <Button
                        sx={{ color: "#000000" }}
                        onClick={() => {
                            setNewOptions([]);
                            setCustomChosenOptions && setCustomChosenOptions([]);
                        }}
                        variant="text"
                    >
                        {t("filter.reset")}
                    </Button>
                )}
                <Button style={{ color: "white" }} variant="contained" color="primary" onClick={handleClose}>
                    {t("filter.save")}
                </Button>
            </div>
        </React.Fragment>
    );
};

export default FilterItem;
