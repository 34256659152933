import React, { useEffect, useState } from "react";
import { Paper, List, ListItemText, ListItemSecondaryAction, Typography, IconButton, Tooltip, ListItemButton, CircularProgress } from "@mui/material";

import { useTranslation } from "react-i18next";
//All icons used in this component
import CheckIcon from "@mui/icons-material/Check";
import ListIcon from "@mui/icons-material/List";
import ClearIcon from "@mui/icons-material/Clear";
import { GetWordArray } from "../../../__generated__/GetWordArray";
import { QUERY_ALL_SEARCHTERMS } from "../../../graphql/queryDefCurrentUser";
import { useMutation, useQuery } from "@apollo/client";
import { MUTATION_ADD_SEARCHTERM, MUTATION_DELETE_SEARCHTERM } from "../../../graphql/mutationDefinitions";
import { addWord, addWordVariables } from "../../../__generated__/addWord";
import { deleteWord, deleteWordVariables } from "../../../__generated__/deleteWord";
import { useQueryBuilder } from "../Dialog/QueryBuilder/QueryBuilderContext";
import Searchbar from "./Searchbar";
import {
    ADD_WORD_TO_ORGANIZATION_LIBRARY,
    DELETE_WORD_FROM_ORGANIZATION_LIBRARY,
    ORGANIZATION_KEYWORDS,
} from "../../../graphql/queryDefUserAndTeams";
import { organizationKeywords } from "../../../__generated__/organizationKeywords";
import WordSwitch from "./WordSwitch";
import { createOrganizationKeyword, createOrganizationKeywordVariables } from "../../../__generated__/createOrganizationKeyword";
import { deleteOrganizationKeyword, deleteOrganizationKeywordVariables } from "../../../__generated__/deleteOrganizationKeyword";
// import { toast } from "react-toastify";
import { CheckIfUserIsAdmin } from "../../../__generated__/CheckIfUserIsAdmin";
import { QUERY_USER_IS_SUB_ADMIN } from "../../../graphql/queryDefCurrentUser";
import { LibraryAdd } from "@mui/icons-material";
import { useProfile } from "../../../components/contextProviders/ProfileOpenerContext";
import DOMPurify from "dompurify";
//  Show organisation add when user is a subscription admin
interface Props {
    onSelect(value: string): void;
    subSector?: boolean;
    hideTitle?: boolean;
    variant: "elevation" | "outlined";
}

interface Keyword {
    id: string;
    keyword: string;
}

const SearchTermList: React.FC<Props> = ({ onSelect, subSector = false, hideTitle = false, variant }) => {
    const { t } = useTranslation();
    const { org_name } = useProfile();

    const [showMyWords, setShowMyWords] = useState<boolean>(true);
    const [hoverState, setHoverState] = useState<number | boolean>(false);
    const { data: queryWordArray, loading } = useQuery<GetWordArray>(QUERY_ALL_SEARCHTERMS);
    const { data: orgWordArray } = useQuery<organizationKeywords>(ORGANIZATION_KEYWORDS);

    const [wordBucket, setWordToBucket] = useState<Keyword[]>([]);

    const [errors, setErrors] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState<string>("");
    const { groups, nextIsNearTerm } = useQueryBuilder();

    // is user subscription admin
    const { data } = useQuery<CheckIfUserIsAdmin>(QUERY_USER_IS_SUB_ADMIN);

    const [saveWord] = useMutation<addWord, addWordVariables>(MUTATION_ADD_SEARCHTERM);
    const [deleteWord] = useMutation<deleteWord, deleteWordVariables>(MUTATION_DELETE_SEARCHTERM);

    const [saveWordOrgBucket] = useMutation<createOrganizationKeyword, createOrganizationKeywordVariables>(ADD_WORD_TO_ORGANIZATION_LIBRARY);
    const [deleteWordOrgBucket] = useMutation<deleteOrganizationKeyword, deleteOrganizationKeywordVariables>(DELETE_WORD_FROM_ORGANIZATION_LIBRARY);

    const [loadInSearchbar, setLoadInSearchbar] = useState<boolean>(false);
    const [loadInline, setLoadInline] = useState<boolean>(false);

    // Check if a word is selected in any condition
    const isWordSelected = (word: string) => {
        return groups.some((group) => group.conditions.some((condition) => condition.keyword === word || condition.nearTerm === word));
    };
    /**
     * Search in list if words are already in list or not
     * Regex to check if word has no special characters.
     * If regex doesnt match, errortext wil appear.
     * @param event userinput from textfield
     */
    const handleSearchChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setErrors("");
        const t = DOMPurify.sanitize(value);
        setSearchTerm(t);

        sessionStorage.removeItem("lastSearchTerm");
        sessionStorage.setItem("lastSearchTerm", JSON.stringify(t));

        /**
         * characters that are allowed to use in the TextField
         * 2 spaces, text (+specials) and numbers
         */
        // const reg = new RegExp(/^$|^[a-zA-Z\d]+$/).test(value);
        // const reg = new RegExp(/^[A-Za-zÀ-ȕ\d-]+(\s[A-Za-zÀ-ȕ\d-]*){0,2}$/).test(value);
        const reg = new RegExp(/^(?!.*[*?&-].*\s)([A-Za-zÀ-ȕ\d]+(\s[A-Za-zÀ-ȕ\d]*){0,2}|[A-Za-zÀ-ȕ\d*?&-]+)$/).test(value);

        if (value.length === 0) {
            setErrors("");
        }
        if (value === " ") {
            setErrors("Spatie kan niet als eerste character");
        }
        if (value.split(" ").length > 3) {
            setErrors("Maximaal 3 woorden");
        }
        if (!reg && value.length > 1 && value.split(" ").length <= 3) {
            setErrors("Speciale tekens zijn niet toegestaan");
        }

        /**
         * get text inside div with id 'searchtermToReplace'
         */
        const term = document.getElementById("searchtermToReplace");

        // Get live data from searchtermToReplace when guide is active
        // Workaround to get live data in guide. (state not working when guide is shown)
        if (term) {
            term.innerHTML = value;
        }
    };

    /**
     * FUNCTION TO ADD NEW WORD
     * - check if this word doesnt exist in list
     * - minimum of 3 characters
     * - when added to list, not possible to add again
     * - clear textfield after adding word to list
     */
    const addWordToBucket = async (word: string) => {
        const reg = new RegExp(/^(?!.*[*?&-].*\s)([A-Za-zÀ-ȕ\d]+(\s[A-Za-zÀ-ȕ\d]*){0,2}|[A-Za-zÀ-ȕ\d*?&-]+)$/).test(word);

        if (reg && searchTerm.length >= 3 && !wordBucket.some((item) => item.keyword.toLocaleLowerCase() === word.toLocaleLowerCase())) {
            setLoadInSearchbar(true);
            // add word to local state
            setWordToBucket([...wordBucket, { id: `${word}`, keyword: word }]);
            setSearchTerm("");
            try {
                await saveWord({
                    variables: {
                        keyword: word,
                    },
                    onCompleted: () => {
                        setTimeout(() => {
                            setLoadInSearchbar(false);
                        }, 500);
                    },
                    refetchQueries: [
                        QUERY_ALL_SEARCHTERMS, // DocumentNode object parsed with gql
                        "GetWordArray", // Query name
                        ORGANIZATION_KEYWORDS,
                        "organizationKeywords",
                    ],
                });
            } catch (e) {}
        } else {
            setLoadInline(true);
            try {
                await saveWord({
                    variables: {
                        keyword: word,
                    },
                    onCompleted: () => {
                        setTimeout(() => {
                            setLoadInline(false);
                        }, 500);
                    },
                    refetchQueries: [
                        QUERY_ALL_SEARCHTERMS, // DocumentNode object parsed with gql
                        "GetWordArray", // Query name
                        ORGANIZATION_KEYWORDS,
                        "organizationKeywords",
                    ],
                });
            } catch (e) {}
        }
    };

    /**
     * FUNCTION TO ADD NEW WORD
     * - check if this word doesnt exist in list
     * - minimum of 3 characters
     * - when added to list, not possible to add again
     * - clear textfield after adding word to list
     */
    const addWordToORGBucket = async (word: string) => {
        const reg = new RegExp(/^(?!.*[*?&-].*\s)([A-Za-zÀ-ȕ\d]+(\s[A-Za-zÀ-ȕ\d]*){0,2}|[A-Za-zÀ-ȕ\d*?&-]+)$/).test(word);

        // when user is does add from searchbar ->wordbucket is orglist
        if (reg && searchTerm.length >= 3 && !wordBucket.some((item) => item.keyword.toLocaleLowerCase() === word.toLocaleLowerCase())) {
            setLoadInSearchbar(true);
            // add word to local state
            setWordToBucket([...wordBucket, { id: `${word}`, keyword: word }]);
            setSearchTerm("");
            try {
                await saveWordOrgBucket({
                    variables: {
                        keyword: word,
                    },
                    onCompleted: (data) => {
                        setTimeout(() => {
                            setLoadInSearchbar(false);
                        }, 500);
                    },
                    refetchQueries: [
                        QUERY_ALL_SEARCHTERMS, // DocumentNode object parsed with gql
                        "GetWordArray", // Query name
                        ORGANIZATION_KEYWORDS,
                        "organizationKeywords",
                    ],
                    awaitRefetchQueries: true,
                });
            } catch (e) {}
        } else {
            setLoadInline(true);
            // when user is does add from searchbar ->wordbucket is mylist
            try {
                await saveWordOrgBucket({
                    variables: {
                        keyword: word,
                    },
                    onCompleted: (data) => {
                        setTimeout(() => {
                            setLoadInline(false);
                        }, 500);
                        // toast.info(`${word} toegevoegd aan ${org_name} woordenlijst`);
                    },
                    refetchQueries: [
                        QUERY_ALL_SEARCHTERMS, // DocumentNode object parsed with gql
                        "GetWordArray", // Query name
                        ORGANIZATION_KEYWORDS,
                        "organizationKeywords",
                    ],
                });
            } catch (e) {}
        }
    };

    /**
     * Delete a word from the list
     * @param value selected word-id to delete.
     */
    const handleDeleteWord = async (id: string, word: string) => {
        setWordToBucket(wordBucket.filter((o) => o.keyword !== word));
        try {
            await deleteWord({
                variables: {
                    id: parseInt(id),
                },
                onCompleted: () => {
                    // toast.info(`${word} is verwijderd`);
                },
                refetchQueries: [
                    QUERY_ALL_SEARCHTERMS, // DocumentNode object parsed with gql
                    "GetWordArray", // Query name
                    ORGANIZATION_KEYWORDS,
                    "organizationKeywords",
                ],
                awaitRefetchQueries: true,
            });
        } catch (e) {}
    };

    /**
     * Delete a word from the list
     * @param value selected word-id to delete.
     */
    const handleDeleteWordFromOrgBucket = async (id: string, word: string) => {
        setWordToBucket(wordBucket.filter((o) => o.keyword !== word));
        try {
            await deleteWordOrgBucket({
                variables: {
                    id: id,
                },
                onCompleted: (data) => {
                    // toast.info(`${word} is verwijderd`);
                },
                refetchQueries: [
                    QUERY_ALL_SEARCHTERMS, // DocumentNode object parsed with gql
                    "GetWordArray", // Query name
                    ORGANIZATION_KEYWORDS,
                    "organizationKeywords",
                ],
            });
        } catch (e) {}
    };

    /**
     * When searchterm is false, show all words
     * When searchTerm is true, show words with matching characters.
     * - item and searchTerm values to lowercase to check if the characters are the same. Now it doesnt matter if user or input is capitalchars or not
     */
    const results = !searchTerm ? wordBucket : wordBucket.filter((item) => item.keyword.toLowerCase().includes(searchTerm.toLowerCase()));
    const copy = [...results];

    // useEffect(() => {
    //     setWordToBucket(queryWordArray?.currentUser.keywords || []);
    // }, [queryWordArray?.currentUser.keywords]);

    // Use useEffect to update wordToBucket whenever the queryWordArray or orgWordArray changes
    useEffect(() => {
        if (showMyWords) {
            const keywords: Keyword[] =
                queryWordArray?.currentUser.keywords?.map((item: any) => ({
                    id: item.id, // Make sure each item has the 'id' field
                    keyword: item.keyword, // Make sure each item has the 'keyword' field
                })) || [];

            // Use the user's keywords if showMyWords is true
            setWordToBucket(keywords);
        } else {
            const keywords: Keyword[] =
                orgWordArray?.organizationKeywords?.map((item: any) => ({
                    id: item.id, // Make sure each item has the 'id' field
                    keyword: item.keyword, // Make sure each item has the 'keyword' field
                })) || [];
            // Otherwise, use the organization's keywords
            setWordToBucket(keywords);
        }
    }, [queryWordArray?.currentUser.keywords, orgWordArray?.organizationKeywords, showMyWords]);

    const userIsSubAdmin = Boolean(data?.currentUser.subscription_admin);
    const orgWords = orgWordArray?.organizationKeywords ?? [];

    return (
        <div style={{ width: "100%", height: "100%" }}>
            {/*
             * header for searchterms un bucket
             */}
            {!hideTitle ? <h3>{t("wordModal.your-searchterms")}</h3> : <div style={{ height: "16px" }} />}

            {/*
             * Bucket with searchterms, now chosenWords is prefilled so its possible to use all the different searchmethodes
             */}
            <Paper
                variant={variant}
                elevation={0}
                sx={(theme) => ({
                    width: "100%",
                    height: !hideTitle ? `calc(100% - 56px) !important` : "100%",
                    display: "flex",
                    flexDirection: "column",
                })}
            >
                {/*
                 * Div for searchbar
                 */}
                <Searchbar
                    loadingAdd={loadInSearchbar}
                    currentView={showMyWords ? "my" : "org"}
                    disabled={!searchTerm || searchTerm.length < 3 || isWordSelected(searchTerm) || Boolean(errors)}
                    query={searchTerm}
                    reset={() => setSearchTerm("")}
                    handleSearchChange={handleSearchChange}
                    addWordToBucket={addWordToBucket}
                    myWords={queryWordArray?.currentUser.keywords ?? []}
                    orgWords={orgWords}
                    addWordToORGBucket={addWordToORGBucket}
                    isSubAdmin={userIsSubAdmin}
                />
                <WordSwitch
                    myWordsLength={queryWordArray?.currentUser.keywords.length ?? 0}
                    orgWordsLength={orgWordArray?.organizationKeywords?.length ?? 0}
                    setShowMyWords={setShowMyWords}
                    showMyWords={showMyWords}
                    loading={loading}
                />
                {/* Fetch keywords */}
                {loading ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexGrow: 1 }}>
                        <div
                            style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "-122px" }}
                        >
                            <ListIcon style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />
                            <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                                Zoekwoorden ophalen...
                            </Typography>
                        </div>
                    </div>
                ) : // show when user has no keywords added
                wordBucket.length === 0 ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexGrow: 1 }}>
                        <div
                            style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "-122px" }}
                        >
                            <ListIcon style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />
                            <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                                Je hebt nog geen zoekwoorden toegevoegd
                            </Typography>
                        </div>
                    </div>
                ) : (
                    //  Show all keywords of current user in reverse order. newest on top
                    <List dense sx={{ width: "100%", overflow: "auto" }} data-tut="reactour__showAddWord">
                        {copy.reverse().map((value, i) => {
                            // Set last searchterm to sessionstorage to make it usable in word tour

                            return (
                                <ListItemButton
                                    data-tut={i === 0 && "reactour__showAddWordRemovable"}
                                    style={{
                                        backgroundColor: hoverState === i ? "rgba(0, 0, 0, 0.04)" : "transparent",
                                    }}
                                    //next word is a term to use in NEAR cant contain *|?|" "
                                    disabled={subSector || (nextIsNearTerm() && value.keyword.includes(" "))}
                                    key={i}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onSelect(value.keyword);
                                    }}
                                    onMouseEnter={() => setHoverState(i)}
                                    onMouseLeave={() => setHoverState(false)}
                                >
                                    <ListItemText
                                        id={value.keyword}
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    style={{
                                                        color: value.keyword.includes("*") || value.keyword.includes("?") ? "#c0392b" : "canvastext",
                                                    }}
                                                >
                                                    {value.keyword}
                                                    {/* Show remove searchterm if id is a numeric string and not the keyword itself
                                                     * Delete mutation needs id to remove from DB
                                                     */}
                                                    {showMyWords && hoverState === i && value.id !== value.keyword ? (
                                                        <>
                                                            <Tooltip title="Zoekwoord verwijderen" placement="right" disableInteractive>
                                                                <IconButton
                                                                    sx={{
                                                                        padding: "2px",
                                                                        marginLeft: "4px",
                                                                    }}
                                                                    size="small"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleDeleteWord(value.id, value.keyword);
                                                                    }}
                                                                >
                                                                    <ClearIcon style={{ fontSize: "1rem", color: "#c0392b" }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {userIsSubAdmin && (
                                                                <>
                                                                    {loadInline ? (
                                                                        <IconButton
                                                                            size="small"
                                                                            id="add_searchterm_btn"
                                                                            data-tut="add_searchterm_btn"
                                                                            color="primary"
                                                                            aria-label="directions"
                                                                            disabled
                                                                        >
                                                                            <CircularProgress color="inherit" size={10} />
                                                                        </IconButton>
                                                                    ) : (
                                                                        <Tooltip
                                                                            placement="right"
                                                                            title={`Toevoegen aan ${org_name} zoekwoorden`}
                                                                            disableInteractive
                                                                        >
                                                                            <IconButton
                                                                                size="small"
                                                                                id="add_searchterm_btn"
                                                                                data-tut="add_searchterm_btn"
                                                                                disabled={orgWords.some(
                                                                                    (item) =>
                                                                                        item.keyword?.toLowerCase() === value.keyword.toLowerCase()
                                                                                )}
                                                                                color="primary"
                                                                                aria-label="directions"
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    addWordToORGBucket(value.keyword);
                                                                                }}
                                                                            >
                                                                                <LibraryAdd
                                                                                    sx={{
                                                                                        fontSize: "0.8rem",
                                                                                        color: orgWords.some(
                                                                                            (item) =>
                                                                                                item.keyword?.toLowerCase() ===
                                                                                                value.keyword.toLowerCase()
                                                                                        )
                                                                                            ? "#b3b3b3"
                                                                                            : "primary.main",
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : // Show button when user is a subscription admin and the view is the organization list
                                                    userIsSubAdmin && !showMyWords && hoverState === i && value.id !== value.keyword ? (
                                                        <Tooltip title="Zoekwoord verwijderen" placement="right" disableInteractive>
                                                            <IconButton
                                                                sx={{
                                                                    padding: "2px",
                                                                    marginLeft: "4px",
                                                                }}
                                                                size="small"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleDeleteWordFromOrgBucket(value.id, value.keyword);
                                                                }}
                                                            >
                                                                <ClearIcon style={{ fontSize: "1rem", color: "#c0392b" }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        style={{ textDecoration: subSector ? "line-through" : "none" }}
                                    />
                                    <ListItemSecondaryAction
                                        style={{
                                            right: 30,
                                            cursor: "pointer",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        onMouseEnter={() => setHoverState(i)}
                                        onMouseLeave={() => setHoverState(false)}
                                        onClick={(e) => {
                                            if (subSector === false) {
                                                e.stopPropagation();
                                                onSelect(value.keyword);
                                            }
                                        }}
                                        id="addBtnID"
                                    >
                                        {/* loadingstate when adding item */}
                                        {hoverState === i ? (
                                            // hovered item is not in query already
                                            <Typography variant="button" id="addBtnID">
                                                voeg toe
                                            </Typography>
                                        ) : isWordSelected(value.keyword) ? (
                                            //  item is in query already
                                            <CheckIcon style={{ opacity: "0.7" }} />
                                        ) : // TODO: same check as check icon.
                                        // show searchlabel if word is already used in a searchrule
                                        // (i === 2 && type === "tender") || (i === 3 && type === "tender") ? (
                                        //     <LocalOfferOutlinedIcon style={{ opacity: "0.7" }} />
                                        // ) :
                                        null}
                                        {/*
                                         * option to remove
                                         */}
                                    </ListItemSecondaryAction>
                                </ListItemButton>
                            );
                        })}
                    </List>
                )}
            </Paper>
        </div>
    );
};

export default SearchTermList;
