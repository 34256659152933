import React, { useEffect, useMemo, useState } from "react";
import { Button, Drawer, List, Typography, useMediaQuery, useTheme } from "@mui/material";

import TuneIcon from "@mui/icons-material/Tune";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Disable } from "react-disable";
import update from "immutability-helper";
import { useTranslation } from "react-i18next";
import { DateRange } from "../TopFilterBar/PeriodFilter";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GetUserSearchRules } from "../../__generated__/GetUserSearchRules";
// import { GetUserSavedOppYears } from "../../__generated__/GetUserSavedOppYears";
import { arrayOfItems, sortingArray, statusArray } from "./TopFilterBar";
import { useLocation } from "react-router-dom";

// filter components
import FilterTitleLine from "./FilterTitleLine";
import FilterItem from "./FilterItem";
import FilterItemStringVersion from "./FilterItemStringVersion";
import PeriodFilterItem from "./PeriodFilterItem";

// timestamps
import FutureDates from "./FutureDates";
import PreviousDates from "./PreviousDates";
import FutureMonths from "./FutureMonths";
import IntRangeFilter, { IntRange } from "./IntRangeFilterItem";
import { QUERY_CA_FILTER_DATA, QUERY_OPP_YEARS_OF_USER } from "../../graphql/opportunityQueries/queryDefinitions";
import { GetUserSavedOppYears } from "../../__generated__/GetUserSavedOppYears";
import {
    GetContractingAuthorityFilterData,
    GetContractingAuthorityFilterDataVariables,
    GetContractingAuthorityFilterData_filterContractingAuthorities_results,
} from "../../__generated__/GetContractingAuthorityFilterData";
import { ContractingAuthoritiesFilterSource, SortOrder } from "../../__generated__/globalTypes";
import { useCustomOptions } from "../contextProviders/TenderCustomOptionProvider";
import FilterItemCaVersion from "./filterItemCaVersion";
import { customOptionsRegions } from "../../routes/profile/Dialog/opportunityWizard/ResultModalOpp";
import { useCustomOppOptions } from "../contextProviders/OppCustomOptionProvider";
import { QUERY_SEARCHRULES } from "../../graphql/queryDefCurrentUser";

interface Props {
    deadline?: DateRange;
    setDeadline?(deadline: DateRange): void;
    firstPublication?: DateRange;
    setFirstPublication?(firstPublication: DateRange): void;
    endDate?: DateRange;
    setEndDate?(endDate: DateRange): void;
    endDateInc?: DateRange;
    setEndDateInc?(endDateInc: DateRange): void;
    awardDate?: DateRange;
    setAwardDate?(awardDate: DateRange): void;
    startDateContract?: DateRange;
    setStartDateContract?(startDateContract: DateRange): void;
    nextEndDateContract?: DateRange;
    setNextEndDateContract?(nextEndDateContract: DateRange): void;
    updatedAt?: DateRange;
    setUpdatedAt?(updatedAt: DateRange): void;
    ratedDate?: DateRange;
    setRatedDate?(ratedDate: DateRange): void;
    publishedOppDate?: DateRange;
    setPublishedOppDate?(publishedOppDate: DateRange): void;
    lastUpdate?: DateRange;
    setLastUpdate?(lastUpdate: DateRange): void;
    personalYear?: DateRange;
    setPersonalYear?(personalYear: DateRange): void;
    yearFrom?: DateRange;
    setYearFrom?(yearFrom: DateRange): void;
    documentLastReviewed?: DateRange;
    setDocumentLastReviewed?(document_last_reviewed: DateRange): void;
    IntRangeState?: IntRange;
    setIntRange?(IntRangeState: IntRange): void;
    setCaSearchQuery?(caSearch: string): void;
    caSearchQuery?: string;
    /**
     * boolean to open/close drawer
     */
    open: boolean;
    /**
     * handler to close modal
     */
    onClose(): void;
    /**
     * Data to fill filters
     */
    filterData: any[];
    /**
     * searchinput => state with active filters
     */
    searchInput: any;
    /**
     * @param searchInput Input in searchbox
     */
    onChange(searchInput: any): void;
    /**
     * handler to start search
     */
    startSearch(): void;
    /**
     * string to generate themecolor
     */
    location: "tenders" | "opportunities" | "cas" | "tenderers";
    /**
     * loading state
     */
    loading: boolean;
}

const FilterDrawer: React.FC<Props> = ({
    open,
    onClose,
    filterData,
    searchInput,
    onChange,
    startSearch,
    location,
    deadline,
    setDeadline,
    firstPublication,
    setFirstPublication,
    endDate,
    setEndDate,
    endDateInc,
    setEndDateInc,
    awardDate,
    setAwardDate,
    startDateContract,
    setStartDateContract,
    nextEndDateContract,
    setNextEndDateContract,
    updatedAt,
    setUpdatedAt,
    ratedDate,
    setRatedDate,
    loading,
    personalYear,
    setPersonalYear,
    publishedOppDate,
    setPublishedOppDate,
    yearFrom,
    setYearFrom,
    documentLastReviewed,
    setDocumentLastReviewed,
    IntRangeState,
    setIntRange,
    caSearchQuery,
    setCaSearchQuery,
    setLastUpdate,
    lastUpdate,
}) => {
    const [openFilter, setOpenFilter] = useState<boolean | string>(false);
    const { t } = useTranslation();
    // fetch searchrules of current user
    const { data, loading: loadSearches } = useQuery<GetUserSearchRules>(QUERY_SEARCHRULES);
    // fetch opportunity years of current user
    const { data: yearCopy } = useQuery<GetUserSavedOppYears>(QUERY_OPP_YEARS_OF_USER);
    // create shadow copy to sort array
    const oppYears =
        yearCopy !== undefined && yearCopy.currentUser.savedOpportunityYears !== null ? [...yearCopy.currentUser.savedOpportunityYears] : [];
    const filterCopy = [...filterData];
    const { pathname } = useLocation();
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const blue = "#173357";
    const green = "#225E4D";
    const pink = "#E03660";
    const yellow = "#F57117";
    const grey = "#707070";
    const [caFilterSet, setCaFilterSet] = useState<GetContractingAuthorityFilterData_filterContractingAuthorities_results | null>();
    //custom options for ca filter
    const { setCustomOptionsWorklist, setCustomOptionsReviews, setCustomOptionsAll, customOptionsWorklist, customOptionsAll, customOptionsReviews } =
        useCustomOptions();

    const {
        setCustomOppOptionsWorklist,
        setCustomOppOptionsReviews,
        setCustomOppOptionsAll,
        customOppOptionsWorklist,
        customOppOptionsAll,
        customOppOptionsReviews,
    } = useCustomOppOptions();

    const filterSet = useMemo(() => {
        const checkingPath = pathname.includes("opportunities") ? { opportunityFileCount: 0 } : { tenderCount: 0 };
        return checkingPath;
    }, [pathname]);

    // Variables for the GQL query
    const variables: GetContractingAuthorityFilterDataVariables = {
        page: 1,
        count: 1000,
        orderField: "last_update_date_timestamp",
        order: SortOrder.DESC,
        search: {
            source: ContractingAuthoritiesFilterSource.ALL_CONTRACTING_AUTHORITIES,
            query: "",
            filters: filterSet,
        },
    };

    const [searchCA, { loading: loadCA }] = useLazyQuery<GetContractingAuthorityFilterData, GetContractingAuthorityFilterDataVariables>(
        QUERY_CA_FILTER_DATA,
        {
            variables: variables,
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (data && data.filterContractingAuthorities) {
                    setCaFilterSet(data.filterContractingAuthorities.results);
                }
            },
        }
    );

    // Filters to hide in GUI for all modules/tabs
    const remove_filter_overall = filterCopy
        // Remove scopeMin filter
        .filter((filter: any) => filter.title !== "scopeMin")
        // Remove scopeMax filter
        .filter((filter: any) => filter.title !== "scopeMax")
        // Remove tenderers filter (in all tenders page)
        .filter((filter: any) => filter.title !== "contractors")
        // Remove source filter
        .filter((filter: any) => filter.title !== "platform")
        // Remove agenda
        .filter((filter: any) => filter.title !== "agenda")
        // Remove type_procedure
        .filter((filter: any) => filter.title !== "type_procedure")
        .filter((filter: any) => filter.title !== "last_update_type");

    const array_to_show =
        pathname === "/tenders"
            ? remove_filter_overall
                  .filter((filter: any) => filter.title !== "searches")
                  .filter((filter: any) => filter.title !== "startdate_contract")
                  .filter((filter: any) => filter.title !== "next_enddate_contract")
                  .filter((filter: any) => filter.title !== "enddate_contract_quarters")
                  .filter((filter: any) => filter.title !== "rated_date")
                  .filter((filter: any) => filter.title !== "reviews")
                  .filter((filter: any) => filter.title !== "enddate_contract_incl_options")
                  .filter((filter: any) => filter.title !== "enddate_contract")
            : pathname === "/tenders/worklist"
            ? remove_filter_overall.filter((filter: any) => filter.title !== "rated_date").filter((filter: any) => filter.title !== "reviews")
            : pathname === "/organizations"
            ? remove_filter_overall
                  .filter((filter: any) => filter.title !== "ca_country")
                  .filter((filter: any) => filter.title !== "contractingAuthorityTypes")
                  .filter((filter: any) => filter.title !== "has_bids")
                  .filter((filter: any) => filter.title !== "has_bids_string")
                  .filter((filter: any) => filter.title !== "kvknumber")
                  .filter((filter: any) => filter.title !== "lastUpdate_timestamp")
                  .filter((filter: any) => filter.title !== "name")
                  .filter((filter: any) => filter.title !== "bids_count")
                  .filter((filter: any) => filter.title !== "status")
                  .filter((filter: any) => filter.title !== "updated_at")
                  .filter((filter: any) => filter.title !== "last_update_date_timestamp")
                  .filter((filter: any) => filter.title !== "updated_at_timestamp")
                  .filter((filter: any) => filter.title !== "search")
            : pathname === "/organizations/starred"
            ? remove_filter_overall
                  .filter((filter: any) => filter.title !== "ca_country")
                  .filter((filter: any) => filter.title !== "contractingAuthorityTypes")
                  .filter((filter: any) => filter.title !== "has_bids")
                  .filter((filter: any) => filter.title !== "has_bids_string")
                  .filter((filter: any) => filter.title !== "kvknumber")
                  .filter((filter: any) => filter.title !== "lastUpdate_timestamp")
                  .filter((filter: any) => filter.title !== "name")
                  .filter((filter: any) => filter.title !== "bids_count")
                  .filter((filter: any) => filter.title !== "status")
                  .filter((filter: any) => filter.title !== "updated_at")
                  .filter((filter: any) => filter.title !== "last_update_date_timestamp")
                  .filter((filter: any) => filter.title !== "updated_at_timestamp")
                  .filter((filter: any) => filter.title !== "search")
            : pathname === "/contracting-authorities"
            ? remove_filter_overall
                  .filter((filter: any) => filter.title !== "bids_count")
                  .filter((filter: any) => filter.title !== "has_bids")
                  .filter((filter: any) => filter.title !== "has_bids_string")
                  .filter((filter: any) => filter.title !== "kvknumber")
                  .filter((filter: any) => filter.title !== "lastUpdate_timestamp")
                  .filter((filter: any) => filter.title !== "name")
                  .filter((filter: any) => filter.title !== "status")
                  .filter((filter: any) => filter.title !== "updated_at")
                  .filter((filter: any) => filter.title !== "country")
                  .filter((filter: any) => filter.title !== "last_update_date_timestamp")
                  .filter((filter: any) => filter.title !== "updated_at_timestamp")
                  .filter((filter: any) => filter.title !== "search")
            : pathname === "/contracting-authorities/starred"
            ? remove_filter_overall
                  .filter((filter: any) => filter.title !== "bids_count")
                  .filter((filter: any) => filter.title !== "has_bids")
                  .filter((filter: any) => filter.title !== "has_bids_string")
                  .filter((filter: any) => filter.title !== "kvknumber")
                  .filter((filter: any) => filter.title !== "lastUpdate_timestamp")
                  .filter((filter: any) => filter.title !== "name")
                  .filter((filter: any) => filter.title !== "status")
                  .filter((filter: any) => filter.title !== "updated_at")
                  .filter((filter: any) => filter.title !== "country")
                  .filter((filter: any) => filter.title !== "last_update_date_timestamp")
                  .filter((filter: any) => filter.title !== "updated_at_timestamp")
                  .filter((filter: any) => filter.title !== "search")
            : pathname === "/opportunities"
            ? remove_filter_overall
                  .filter((filter: any) => filter.title !== "searches")
                  .filter((filter) => filter.title !== "year_to")
                  .filter((filter) => filter.title !== "result_count")
                  .filter((filter) => filter.title !== "personal_opportunity_count")
                  .filter((filter) => filter.title !== "document_unreviewed_page_count")
                  .filter((filter) => filter.title !== "personal_year")
                  .filter((filter) => filter.title !== "personal_value")
                  .filter((filter) => filter.title !== "document_last_reviewed")
                  .filter((filter) => filter.title !== "text")
                  .filter((filter) => filter.title !== "contracting_authority_state")
                  .filter((filter: any) => filter.title !== "contracting_authority_region")
            : pathname === "/opportunities/worklist"
            ? remove_filter_overall
                  .filter((filter) => filter.title !== "year_to")
                  .filter((filter) => filter.title !== "result_count")
                  .filter((filter) => filter.title !== "personal_opportunity_count")
                  .filter((filter) => filter.title !== "document_unreviewed_page_count")
                  .filter((filter) => filter.title !== "text")
                  .filter((filter) => filter.title !== "contracting_authority_state")
                  .filter((filter) => filter.title !== "personal_year")
                  .filter((filter) => filter.title !== "personal_value")
                  .filter((filter: any) => filter.title !== "contracting_authority_region")
            : pathname === "/opportunities/reviews"
            ? remove_filter_overall
                  .filter((filter) => filter.title !== "year_to")
                  .filter((filter) => filter.title !== "result_count")
                  .filter((filter) => filter.title !== "personal_opportunity_count")
                  .filter((filter) => filter.title !== "document_unreviewed_page_count")
                  .filter((filter) => filter.title !== "text")
                  .filter((filter) => filter.title !== "contracting_authority_state")
                  .filter((filter: any) => filter.title !== "contracting_authority_region")
            : remove_filter_overall;

    useEffect(() => {
        if (caSearchQuery === undefined || (caSearchQuery !== undefined && caSearchQuery.length === 0)) {
            setCaFilterSet(undefined);
        }
        if (caSearchQuery !== undefined && caSearchQuery.length > 2) {
            searchCA({
                variables: {
                    page: 1,
                    count: 1000,
                    orderField: "last_update_date_timestamp",
                    order: SortOrder.DESC,
                    search: {
                        source: ContractingAuthoritiesFilterSource.ALL_CONTRACTING_AUTHORITIES,
                        query: `"${caSearchQuery}"`,
                        filters: {},
                    },
                },
            });
        }
    }, [searchCA, caSearchQuery]);

    return (
        <Drawer
            sx={{
                "& .MuiDrawer-paper": {
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    width: onlyMobile ? "80vw" : "33vw",
                },
            }}
            anchor="right"
            open={open}
        >
            <div style={{ display: "flex", height: "100%", flexDirection: "column", justifyContent: "space-between" }}>
                {openFilter === false ? (
                    /**
                     * If openFilter === false, show "all filters"
                     */
                    <Typography
                        variant="h1"
                        sx={{
                            color:
                                location === "tenders"
                                    ? blue
                                    : location === "opportunities"
                                    ? green
                                    : location === "cas"
                                    ? yellow
                                    : location === "tenderers"
                                    ? pink
                                    : grey,
                            margin: "16px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <TuneIcon style={{ marginRight: 8 }} /> {t("filter.allFilters")}
                    </Typography>
                ) : (
                    /**
                     * if openFilter === string, show selected filter and back button
                     */
                    <Typography
                        variant="h1"
                        sx={{
                            color:
                                location === "tenders"
                                    ? blue
                                    : location === "opportunities"
                                    ? green
                                    : location === "cas"
                                    ? yellow
                                    : location === "tenderers"
                                    ? pink
                                    : grey,
                            margin: "16px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <ArrowBackIosIcon
                            onClick={() => {
                                // if openFilter === deadline -> reset deadline if user goes back to all filters without saving
                                if (openFilter === "deadline_inschrijving") {
                                    setDeadline && setDeadline({ from: null, to: null });
                                }
                                // if openFilter === first_publication -> reset setFirstPublication if user goes back to all filters without saving
                                if (openFilter === "first_publication") {
                                    setFirstPublication && setFirstPublication({ from: null, to: null });
                                }
                                // if openFilter === enddate_contract -> reset setEndDate if user goes back to all filters without saving
                                if (openFilter === "enddate_contract") {
                                    setEndDate && setEndDate({ from: null, to: null });
                                }
                                // if openFilter === enddate_contract_incl_options -> reset setEndDateInc if user goes back to all filters without saving
                                if (openFilter === "enddate_contract_incl_options") {
                                    setEndDateInc && setEndDateInc({ from: null, to: null });
                                }
                                if (openFilter === "award_date") {
                                    setAwardDate && setAwardDate({ from: null, to: null });
                                }
                                if (openFilter === "startdate_contract") {
                                    setStartDateContract && setStartDateContract({ from: null, to: null });
                                }
                                if (openFilter === "next_enddate_contract") {
                                    setNextEndDateContract && setNextEndDateContract({ from: null, to: null });
                                }
                                if (openFilter === "updated_at") {
                                    setUpdatedAt && setUpdatedAt({ from: null, to: null });
                                }
                                if (openFilter === "rated_date") {
                                    setRatedDate && setRatedDate({ from: null, to: null });
                                }
                                // Published_date filter for opportunities
                                if (openFilter === "publication_date") {
                                    setPublishedOppDate && setPublishedOppDate({ from: null, to: null });
                                }
                                // Published_date filter for opportunities
                                if (openFilter === "last_update_date") {
                                    setLastUpdate && setLastUpdate({ from: null, to: null });
                                }
                                //set openFilter to false to show all filters
                                setOpenFilter(false);
                            }}
                            style={{ marginRight: 8, cursor: "pointer" }}
                        />
                        {t(`filter.${openFilter}`)}
                    </Typography>
                )}
                {openFilter === false ? (
                    /**
                     * if openFilter === false -> show list of all filters
                     * show filtername, selected items
                     * disable filter if options.length = 0
                     */
                    <div style={{ overflow: "auto", flex: 1 }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <List>
                                {array_to_show
                                    // sort filterset on "sortingArray"
                                    ?.sort((a, b) => sortingArray.indexOf(a.title) - sortingArray.indexOf(b.title))
                                    .map((filter, i) => {
                                        // change test to filter.title
                                        const test = filter.title;
                                        const filterItems = searchInput?.[test as keyof any] as string[];

                                        if (test === "searches") {
                                            return (
                                                <FilterTitleLine
                                                    key={i}
                                                    loading={loadSearches}
                                                    disable={loadSearches}
                                                    active={filter.active}
                                                    label={`${filter.title}`}
                                                    setOpenFilter={() => {
                                                        setOpenFilter(filter.title);
                                                    }}
                                                    selected={filterItems || []}
                                                    noItems={filter.values.length}
                                                />
                                            );
                                        }
                                        // Check last update set active or not
                                        if (test === "last_update_date") {
                                            return (
                                                <FilterTitleLine
                                                    key={i}
                                                    loading={loadSearches}
                                                    disable={loadSearches}
                                                    active={
                                                        lastUpdate !== undefined && lastUpdate.from !== null && lastUpdate.to !== null ? true : false
                                                    }
                                                    label={`lastUpdate`}
                                                    setOpenFilter={() => {
                                                        setOpenFilter(filter.title);
                                                    }}
                                                    selected={
                                                        lastUpdate !== undefined && lastUpdate.from !== null && lastUpdate.to !== null
                                                            ? ["1"]
                                                            : 0 || []
                                                    }
                                                    noItems={filter.values.length}
                                                />
                                            );
                                        }
                                        return (
                                            <Disable
                                                key={i}
                                                disabled={
                                                    false
                                                    // filter.values.length === 0 &&
                                                    // filter.active === false &&
                                                    // filter.title !== "deadline" &&
                                                    // filter.title !== "first_publication" &&
                                                    // filter.title !== "enddate_contract" &&
                                                    // filter.title !== "enddate_contract_incl_options" &&
                                                    // filter.title !== "award_date" &&
                                                    // filter.title !== "startdate_contract" &&
                                                    // filter.title !== "next_enddate_contract" &&
                                                    // filter.title !== "updated_at" &&
                                                    // filter.title !== "searches" &&
                                                    // filter.title !== "rated_date"
                                                }
                                            >
                                                <FilterTitleLine
                                                    loading={loading}
                                                    disable={loading}
                                                    active={filter.active}
                                                    label={`${filter.title}`}
                                                    setOpenFilter={() => {
                                                        setOpenFilter(filter.title);
                                                    }}
                                                    selected={filterItems || []}
                                                    noItems={filter.values.length}
                                                />
                                            </Disable>
                                        );
                                    })}
                            </List>
                        </div>
                    </div>
                ) : openFilter === "contractingAuthorities" ? (
                    <FilterItemCaVersion
                        allowReset
                        allowSearch={false}
                        allowGqlSearch={true}
                        setGqlSearch={setCaSearchQuery}
                        gqlSearch={caSearchQuery}
                        gqlLoading={loadCA}
                        setOpenFilter={setOpenFilter}
                        helpertext={caFilterSet?.data.length === 0 ? "Geen resultaat gevonden" : ""}
                        title={`contractingAuthorities`}
                        onChange={(target) => {
                            // If target has over 0 items. add filter to searchInput
                            if (target.length > 0) {
                                onChange(update(searchInput, { contractingAuthorities: { $set: target } }));
                            }
                            // If target.length = 0 -> remove empty filter from searchInput
                            if (target.length === 0) {
                                // onChange(update(searchInput, { $unset: ["reviews" as keyof any] }));
                                onChange(update(searchInput, { $unset: ["contractingAuthorities" as keyof any] }));
                            }
                            // Execute startsearch each time
                            // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                            startSearch();
                        }}
                        setCustomChosenOptions={
                            pathname === "/tenders"
                                ? setCustomOptionsAll
                                : pathname === "/tenders/worklist"
                                ? setCustomOptionsWorklist
                                : setCustomOptionsReviews
                        }
                        customChosenOptions={
                            pathname === "/tenders"
                                ? customOptionsAll
                                : pathname === "/tenders/worklist"
                                ? customOptionsWorklist
                                : customOptionsReviews
                        }
                        //* map all items (without 0 value)
                        options={
                            caSearchQuery !== undefined &&
                            caSearchQuery.length > 0 &&
                            caFilterSet?.paginatorInfo.total !== undefined &&
                            caFilterSet?.paginatorInfo.total > 0
                                ? caFilterSet?.data.map((ca) => ({
                                      key: ca.name,
                                      label: ca.name,
                                      id: parseInt(ca.id),
                                  }))
                                : filterCopy
                                      ?.filter((filter) => filter.title === "contractingAuthorities")[0]
                                      .values.filter((f: any) => f.amountOfResults > 0)
                                      .sort(function (a: any, b: any) {
                                          return parseInt(b.key) - parseInt(a.key);
                                      })
                                      .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                          key: fltr.label,
                                          label: `${fltr.label} (${fltr.amountOfResults})`,
                                          id: parseInt(fltr.identifier),
                                      }))
                        }
                    />
                ) : // opportunity CA

                openFilter === "contracting_authorities" ? (
                    <FilterItemCaVersion
                        allowReset
                        allowSearch={false}
                        allowGqlSearch={true}
                        helpertext={caFilterSet?.data.length === 0 ? "Geen resultaat gevonden" : ""}
                        setGqlSearch={setCaSearchQuery}
                        gqlSearch={caSearchQuery}
                        gqlLoading={loadCA}
                        setOpenFilter={setOpenFilter}
                        title={`contracting_authorities`}
                        // values={(searchInput?.["contracting_authorities" as keyof any] as string[]) || []}
                        onChange={(target) => {
                            // If target has over 0 items. add filter to searchInput
                            if (target.length > 0) {
                                onChange(update(searchInput, { contracting_authorities: { $set: target } }));
                            }
                            // If target.length = 0 -> remove empty filter from searchInput
                            if (target.length === 0) {
                                // onChange(update(searchInput, { $unset: ["reviews" as keyof any] }));
                                onChange(update(searchInput, { $unset: ["contracting_authorities" as keyof any] }));
                            }
                            // Execute startsearch each time
                            // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                            startSearch();
                        }}
                        setCustomChosenOptions={
                            pathname === "/opportunities"
                                ? setCustomOppOptionsAll
                                : pathname === "/opportunities/worklist"
                                ? setCustomOppOptionsWorklist
                                : setCustomOppOptionsReviews
                        }
                        customChosenOptions={
                            pathname === "/opportunities"
                                ? customOppOptionsAll
                                : pathname === "/opportunities/worklist"
                                ? customOppOptionsWorklist
                                : customOppOptionsReviews
                        }
                        //* map all items (without 0 value)
                        options={
                            caSearchQuery !== undefined &&
                            caSearchQuery.length > 0 &&
                            caFilterSet?.paginatorInfo.total !== undefined &&
                            caFilterSet?.paginatorInfo.total > 0
                                ? caFilterSet?.data.map((ca) => ({
                                      key: ca.name,
                                      label: ca.name,
                                      id: parseInt(ca.id),
                                  }))
                                : filterCopy
                                      ?.filter((filter) => filter.title === "contracting_authorities")[0]
                                      .values.filter((f: any) => f.amountOfResults > 0)
                                      .sort(function (a: any, b: any) {
                                          return parseInt(b.key) - parseInt(a.key);
                                      })
                                      .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                          key: fltr.label,
                                          label: `${fltr.label} (${fltr.amountOfResults})`,
                                          id: parseInt(fltr.identifier),
                                      }))
                        }
                    />
                ) : openFilter === "reviews" ? (
                    <FilterItem
                        allowReset
                        setOpenFilter={setOpenFilter}
                        title={`reviews`}
                        values={(searchInput?.["reviews" as keyof any] as string[]) || []}
                        onChange={(target) => {
                            // If target has over 0 items. add filter to searchInput
                            if (target.length > 0) {
                                onChange(update(searchInput, { reviews: { $set: target } }));
                            }
                            // If target.length = 0 -> remove empty filter from searchInput
                            if (target.length === 0) {
                                // onChange(update(searchInput, { $unset: ["reviews" as keyof any] }));
                                onChange(update(searchInput, { reviews: { $set: [1] } }));
                            }
                            // Execute startsearch each time
                            // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                            startSearch();
                        }}
                        //* map all items (0 value included)
                        // options={filter.values.map((cas) => ({
                        //     key: cas.label,
                        //     label: `${cas.label} (${cas.amountOfResults})`,
                        // }))}
                        //* map all items (without 0 value)
                        options={arrayOfItems
                            .sort((a, b) => [1, 2, 0, -1].indexOf(a.id) - [1, 2, 0, -1].indexOf(b.id))
                            .map((fltr: { label: string; amountOfResults: string; id: number }) => ({
                                key: fltr.id.toString(),
                                label: fltr.label,
                                id: fltr.id,
                            }))}
                    />
                ) : openFilter === "enddate_contract_quarters" ? (
                    <FilterItemStringVersion
                        onChange={(target) => {
                            // If target has over 0 items. add filter to searchInput
                            if (target.length > 0) {
                                onChange(update(searchInput, { enddate_contract_quarters: { $set: target } }));
                            }
                            // If target.length = 0 -> remove empty filter from searchInput
                            if (target.length === 0) {
                                onChange(update(searchInput, { $unset: ["enddate_contract_quarters" as keyof any] }));
                            }
                            // Execute startsearch each time
                            // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                            startSearch();
                        }}
                        allowReset
                        allowSearch
                        setOpenFilter={setOpenFilter}
                        title={"enddate_contract_quarters"}
                        values={(searchInput?.["enddate_contract_quarters" as keyof any] as string[]) || []}
                        //* options (without 0 values)
                        options={filterCopy
                            ?.filter((filter) => filter.title === "enddate_contract_quarters")[0]
                            .values.filter((f: any) => f.amountOfResults > 0)
                            .sort((a: any, b: any) => a.label.localeCompare(b.label))
                            .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                key: fltr.label,
                                label: `${fltr.label} (${fltr.amountOfResults})`,
                                id: fltr.identifier,
                            }))}
                    />
                ) : // stringfilter for cpv codes
                openFilter === "cpvs" ? (
                    <FilterItemStringVersion
                        onChange={(target) => {
                            // If target has over 0 items. add filter to searchInput
                            if (target.length > 0) {
                                onChange(update(searchInput, { cpvs: { $set: target } }));
                            }
                            // If target.length = 0 -> remove empty filter from searchInput
                            if (target.length === 0) {
                                onChange(update(searchInput, { $unset: ["cpvs" as keyof any] }));
                            }
                            // Execute startsearch each time
                            // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                            startSearch();
                        }}
                        allowReset
                        allowSearch
                        setOpenFilter={setOpenFilter}
                        title={"cpvs"}
                        values={(searchInput?.["cpvs" as keyof any] as string[]) || []}
                        //* options (without 0 values)
                        options={filterCopy
                            ?.filter((filter) => filter.title === "cpvs")[0]
                            .values.filter((f: any) => f.amountOfResults > 0)
                            .filter((f: any) => f.label !== "")
                            .sort((a: any, b: any) => a.label.localeCompare(b.label))
                            .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                key: fltr.label,
                                label: `${fltr.label} (${fltr.amountOfResults})`,
                                id: fltr.identifier,
                            }))}
                    />
                ) : openFilter === "regions" ? (
                    <FilterItemStringVersion
                        onChange={(target) => {
                            // If target has over 0 items. add filter to searchInput
                            if (target.length > 0) {
                                onChange(update(searchInput, { regions: { $set: target } }));
                            }
                            // If target.length = 0 -> remove empty filter from searchInput
                            if (target.length === 0) {
                                onChange(update(searchInput, { $unset: ["regions" as keyof any] }));
                            }
                            // Execute startsearch each time
                            // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                            startSearch();
                        }}
                        allowReset
                        allowSearch
                        setOpenFilter={setOpenFilter}
                        title={"regions"}
                        values={(searchInput?.["regions" as keyof any] as string[]) || []}
                        //* options (without 0 values)
                        options={filterCopy
                            ?.filter((filter) => filter.title === "regions")[0]
                            .values.filter((f: any) => f.amountOfResults > 0)
                            .sort((a: any, b: any) => a.label.localeCompare(b.label))
                            .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                key: fltr.label,
                                label: `${fltr.label} (${fltr.amountOfResults})`,
                                id: fltr.identifier,
                            }))}
                    />
                ) : openFilter === "contracting_authority_region" ? (
                    <FilterItemStringVersion
                        onChange={(target) => {
                            // If target has over 0 items. add filter to searchInput
                            if (target.length > 0) {
                                onChange(update(searchInput, { contracting_authority_region: { $set: target } }));
                            }
                            // If target.length = 0 -> remove empty filter from searchInput
                            if (target.length === 0) {
                                onChange(update(searchInput, { $unset: ["contracting_authority_region" as keyof any] }));
                            }
                            // Execute startsearch each time
                            // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                            startSearch();
                        }}
                        allowReset
                        allowSearch
                        setOpenFilter={setOpenFilter}
                        title={"contracting_authority_region"}
                        values={(searchInput?.["contracting_authority_region" as keyof any] as string[]) || []}
                        //* options (without 0 values)
                        options={customOptionsRegions}
                    />
                ) : // CA_ city (opportunity filter)
                openFilter === "contracting_authority_city" ? (
                    <FilterItemStringVersion
                        onChange={(target) => {
                            // If target has over 0 items. add filter to searchInput
                            if (target.length > 0) {
                                onChange(update(searchInput, { contracting_authority_city: { $set: target } }));
                            }
                            // If target.length = 0 -> remove empty filter from searchInput
                            if (target.length === 0) {
                                onChange(update(searchInput, { $unset: ["contracting_authority_city" as keyof any] }));
                            }
                            // Execute startsearch each time
                            // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                            startSearch();
                        }}
                        allowReset
                        allowSearch
                        setOpenFilter={setOpenFilter}
                        title={"contracting_authority_city"}
                        values={(searchInput?.["contracting_authority_city" as keyof any] as string[]) || []}
                        //* options (without 0 values)
                        options={filterCopy
                            ?.filter((filter) => filter.title === "contracting_authority_city")[0]
                            .values.filter((f: any) => f.amountOfResults > 0)
                            .sort((a: any, b: any) => a.label.localeCompare(b.label))
                            .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                key: fltr.label,
                                label: `${fltr.label} (${fltr.amountOfResults})`,
                                id: fltr.identifier,
                            }))}
                    />
                ) : // contracting_authority_province (opportunityfilter)
                openFilter === "contracting_authority_province" ? (
                    <FilterItemStringVersion
                        onChange={(target) => {
                            // If target has over 0 items. add filter to searchInput
                            if (target.length > 0) {
                                onChange(update(searchInput, { contracting_authority_province: { $set: target } }));
                            }
                            // If target.length = 0 -> remove empty filter from searchInput
                            if (target.length === 0) {
                                onChange(update(searchInput, { $unset: ["contracting_authority_province" as keyof any] }));
                            }
                            // Execute startsearch each time
                            // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                            startSearch();
                        }}
                        allowReset
                        allowSearch
                        setOpenFilter={setOpenFilter}
                        title={"contracting_authority_province"}
                        values={(searchInput?.["contracting_authority_province" as keyof any] as string[]) || []}
                        //* options (without 0 values)
                        options={filterCopy
                            ?.filter((filter) => filter.title === "contracting_authority_province")[0]
                            .values.filter((f: any) => f.amountOfResults > 0)
                            .sort((a: any, b: any) => a.label.localeCompare(b.label))
                            .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                key: fltr.label,
                                label: `${fltr.label} (${fltr.amountOfResults})`,
                                id: fltr.identifier,
                            }))}
                    />
                ) : // last update filter
                openFilter === "last_update_date" && lastUpdate && setLastUpdate ? (
                    <PeriodFilterItem
                        setOpenFilter={setOpenFilter}
                        label={"lastUpdate"}
                        dateRangeState={lastUpdate}
                        setDateRange={setLastUpdate}
                        onChange={onChange}
                        searchInput={searchInput}
                    >
                        <PreviousDates setDateRange={setLastUpdate} />
                    </PeriodFilterItem>
                ) : // Deadline filter
                openFilter === "deadline_inschrijving" && deadline && setDeadline ? (
                    <PeriodFilterItem
                        setOpenFilter={setOpenFilter}
                        label={"deadline_inschrijving"}
                        dateRangeState={deadline}
                        setDateRange={setDeadline}
                        onChange={onChange}
                        searchInput={searchInput}
                    >
                        <FutureDates setDateRange={setDeadline} />
                    </PeriodFilterItem>
                ) : // first publication filter
                openFilter === "first_publication" && firstPublication && setFirstPublication ? (
                    <PeriodFilterItem
                        setOpenFilter={setOpenFilter}
                        label={"first_publication"}
                        dateRangeState={firstPublication}
                        setDateRange={setFirstPublication}
                        onChange={onChange}
                        searchInput={searchInput}
                    >
                        <PreviousDates setDateRange={setFirstPublication} />
                    </PeriodFilterItem>
                ) : // enddate contract filter
                openFilter === "enddate_contract" && endDate && setEndDate ? (
                    <PeriodFilterItem
                        setOpenFilter={setOpenFilter}
                        label={"enddate_contract"}
                        dateRangeState={endDate}
                        setDateRange={setEndDate}
                        onChange={onChange}
                        searchInput={searchInput}
                    >
                        <FutureMonths setDateRange={setEndDate} />
                    </PeriodFilterItem>
                ) : // enddate contract incl. options filter
                openFilter === "enddate_contract_incl_options" && endDateInc && setEndDateInc ? (
                    <PeriodFilterItem
                        setOpenFilter={setOpenFilter}
                        label={"enddate_contract_incl_options"}
                        dateRangeState={endDateInc}
                        setDateRange={setEndDateInc}
                        onChange={onChange}
                        searchInput={searchInput}
                    >
                        <FutureMonths setDateRange={setEndDateInc} />
                    </PeriodFilterItem>
                ) : openFilter === "award_date" && awardDate && setAwardDate ? (
                    <PeriodFilterItem
                        setOpenFilter={setOpenFilter}
                        label={"award_date"}
                        dateRangeState={awardDate}
                        setDateRange={setAwardDate}
                        onChange={onChange}
                        searchInput={searchInput}
                    >
                        <PreviousDates setDateRange={setAwardDate} />
                    </PeriodFilterItem>
                ) : openFilter === "startdate_contract" && startDateContract && setStartDateContract ? (
                    <PeriodFilterItem
                        setOpenFilter={setOpenFilter}
                        label={"startdate_contract"}
                        dateRangeState={startDateContract}
                        setDateRange={setStartDateContract}
                        onChange={onChange}
                        searchInput={searchInput}
                    >
                        <FutureMonths setDateRange={setStartDateContract} />
                    </PeriodFilterItem>
                ) : openFilter === "next_enddate_contract" && nextEndDateContract && setNextEndDateContract ? (
                    <PeriodFilterItem
                        setOpenFilter={setOpenFilter}
                        label={"next_enddate_contract"}
                        dateRangeState={nextEndDateContract}
                        setDateRange={setNextEndDateContract}
                        onChange={onChange}
                        searchInput={searchInput}
                    >
                        <FutureMonths setDateRange={setNextEndDateContract} />
                    </PeriodFilterItem>
                ) : openFilter === "updated_at" && updatedAt && setUpdatedAt ? (
                    <PeriodFilterItem
                        setOpenFilter={setOpenFilter}
                        label={"updated_at"}
                        dateRangeState={updatedAt}
                        setDateRange={setUpdatedAt}
                        onChange={onChange}
                        searchInput={searchInput}
                    >
                        <PreviousDates setDateRange={setUpdatedAt} />
                    </PeriodFilterItem>
                ) : openFilter === "rated_date" && ratedDate && setRatedDate ? (
                    <PeriodFilterItem
                        setOpenFilter={setOpenFilter}
                        label={"rated_date"}
                        dateRangeState={ratedDate}
                        setDateRange={setRatedDate}
                        onChange={onChange}
                        searchInput={searchInput}
                    >
                        <PreviousDates setDateRange={setRatedDate} />
                    </PeriodFilterItem>
                ) : openFilter === "publication_date" && publishedOppDate && setPublishedOppDate ? (
                    <PeriodFilterItem
                        setOpenFilter={setOpenFilter}
                        label={"publication_date"}
                        dateRangeState={publishedOppDate}
                        setDateRange={setPublishedOppDate}
                        onChange={onChange}
                        searchInput={searchInput}
                    >
                        <PreviousDates setDateRange={setPublishedOppDate} />
                    </PeriodFilterItem>
                ) : openFilter === "document_last_reviewed" && documentLastReviewed && setDocumentLastReviewed ? (
                    <PeriodFilterItem
                        setOpenFilter={setOpenFilter}
                        label={"document_last_reviewed"}
                        dateRangeState={documentLastReviewed}
                        setDateRange={setDocumentLastReviewed}
                        onChange={onChange}
                        searchInput={searchInput}
                    >
                        <PreviousDates setDateRange={setDocumentLastReviewed} />
                    </PeriodFilterItem>
                ) : openFilter === "personal_value" && IntRangeState && setIntRange ? (
                    <IntRangeFilter
                        setOpenFilter={setOpenFilter}
                        label={"personal_value"}
                        IntRangeState={IntRangeState}
                        setIntRange={setIntRange}
                        onChange={onChange}
                        searchInput={searchInput}
                    />
                ) : (
                    /**
                     * Open filter (single)
                     */
                    filterCopy
                        ?.filter((filter) => filter.title === openFilter)
                        .map((filter, i) => {
                            const test = filter.title;
                            const filterItems = searchInput?.[test as keyof any] as number[];

                            return (
                                <FilterItem
                                    key={i}
                                    onChange={(target) => {
                                        // If target has over 0 items. add filter to searchInput
                                        if (target.length > 0) {
                                            onChange(update(searchInput, { [test]: { $set: target } }));
                                        }
                                        // If target.length = 0 -> remove empty filter from searchInput
                                        if (target.length === 0) {
                                            onChange(update(searchInput, { $unset: [test as keyof any] }));
                                        }
                                        // Execute startsearch each time
                                        // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                        startSearch();
                                    }}
                                    allowReset
                                    allowSearch
                                    setOpenFilter={setOpenFilter}
                                    title={filter.title}
                                    values={filterItems || []}
                                    //* options (without 0 values)
                                    options={
                                        filter.title === "personal_year"
                                            ? oppYears
                                                  // new to old
                                                  ?.sort(function (a: any, b: any) {
                                                      return b - a;
                                                  })
                                                  .map((year, i) => ({
                                                      key: i,
                                                      label: `${year}`,
                                                      id: year,
                                                  }))
                                            : filter.title === "status"
                                            ? filter.values
                                                  .filter((f: any) => f.amountOfResults > 0)
                                                  ?.sort((a: any, b: any) => statusArray.indexOf(a.identifier) - statusArray.indexOf(b.identifier))
                                                  .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                                      key: fltr.label,
                                                      label: `${fltr.label} (${fltr.amountOfResults})`,
                                                      id: parseInt(fltr.identifier),
                                                  }))
                                            : filter.title === "searches" &&
                                              (pathname === "/tenders/worklist" || pathname === "/tenders/reviews") &&
                                              data?.currentUser.searches !== undefined
                                            ? data?.currentUser.searches.map((search) => ({
                                                  key: search?.color,
                                                  label: search.name,
                                                  id: parseInt(search.id),
                                              }))
                                            : filter.title === "searches" &&
                                              (pathname === "/opportunities/worklist" || pathname === "/opportunities/reviews") &&
                                              data?.currentUser.opportunitySearches !== undefined
                                            ? data?.currentUser.opportunitySearches?.map((search) => ({
                                                  key: "#225e4d",
                                                  label: search.name,
                                                  id: parseInt(search.id),
                                              }))
                                            : filter.title === "year_from"
                                            ? filter.values
                                                  .filter((f: any) => f.amountOfResults > 0)
                                                  ?.sort(function (a: any, b: any) {
                                                      return parseInt(b.label) - parseInt(a.label);
                                                  })
                                                  .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                                      key: fltr.label,
                                                      label: `${fltr.label} (${fltr.amountOfResults})`,
                                                      id: parseInt(fltr.identifier),
                                                  }))
                                            : filter.values
                                                  .filter((f: any) => f.amountOfResults > 0)
                                                  .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                                      key: fltr.label,
                                                      label: `${fltr.label} (${fltr.amountOfResults})`,
                                                      id: parseInt(fltr.identifier),
                                                  }))
                                    }
                                    //* options (with 0 values)
                                    // options={filter.values.map((cas: { label: string; amountOfResults: string }) => ({
                                    //     key: cas.label,
                                    //     label: `${cas.label} (${cas.amountOfResults})`,
                                    // }))}
                                />
                            );
                        })
                )}

                {openFilter === false && (
                    <div style={{ display: "flex", justifyContent: "center", margin: onlyMobile ? "16px" : "24px" }}>
                        {/*
                         * Reset button to clear selected items in filter
                         */}

                        {/*
                         * if active filter > 0 show reset button
                         * 1. first reset button for tender/reviews only
                         * 2. reset button for all pages except tender/reviews
                         */}

                        {filterCopy !== null &&
                            filterCopy !== undefined &&
                            filterCopy.filter((f) => f.active === true).length > 0 &&
                            pathname === "/tenders/reviews" &&
                            sessionStorage.getItem("rtf") !== `{"reviews":[1]}` && (
                                <Button
                                    disabled={loading}
                                    sx={{ color: "#000000" }}
                                    variant="text"
                                    onClick={() => {
                                        onClose();
                                        // Remove active filters and local state
                                        onChange(update(searchInput, { $set: {} }));
                                        setDeadline && setDeadline({ from: null, to: null });
                                        setFirstPublication && setFirstPublication({ from: null, to: null });
                                        setEndDate && setEndDate({ from: null, to: null });
                                        setEndDateInc && setEndDateInc({ from: null, to: null });
                                        if (pathname === "/tenders/reviews") {
                                            onChange(update(searchInput, { $set: { reviews: [1] } }));
                                        }
                                    }}
                                >
                                    {t("filter.reset")}
                                </Button>
                            )}
                        {/*
                         * Resetbutton for all pages except tender/reviews
                         */}
                        {filterCopy !== null &&
                            filterCopy !== undefined &&
                            filterCopy.filter((f) => f.active === true).length > 0 &&
                            pathname !== "/tenders/reviews" && (
                                <Button
                                    disabled={loading}
                                    sx={{ color: "#000000" }}
                                    variant="text"
                                    onClick={() => {
                                        onClose();
                                        // Remove active filters and local state
                                        onChange(update(searchInput, { $set: {} }));
                                        setDeadline && setDeadline({ from: null, to: null });
                                        setFirstPublication && setFirstPublication({ from: null, to: null });
                                        setEndDate && setEndDate({ from: null, to: null });
                                        setEndDateInc && setEndDateInc({ from: null, to: null });
                                        if (pathname === "/tenders/reviews") {
                                            onChange(update(searchInput, { $set: { reviews: [1] } }));
                                        }
                                    }}
                                >
                                    {t("filter.reset")}
                                </Button>
                            )}
                        {/*
                         * onClose startsearch, and go back to all filters div
                         * color 'white' -> textcolor
                         */}
                        <Button style={{ color: "white" }} variant="contained" color="primary" onClick={onClose} disabled={loading}>
                            {t("filter.Close_modal")}
                        </Button>
                    </div>
                )}
            </div>
        </Drawer>
    );
};

export default FilterDrawer;
