import React, { useEffect, useRef, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { Disable } from "react-disable";
import { filterArticles_filterArticles_paginatorInfo } from "../../__generated__/filterArticles";
import { useArticleFilter } from "../../components/contextProviders/ArticleFilterContext";
import ArticleListItem from "./ArticleListItem";

interface Props {
    loadMore: () => void;
    loading: boolean;
    paginatorInfo: filterArticles_filterArticles_paginatorInfo;
    overview?: boolean;
}

const ListOfArticles: React.FC<Props> = ({ loading, loadMore, paginatorInfo, overview }) => {
    const { articles, setArticle } = useArticleFilter();
    const parentRef = useRef<HTMLDivElement | null>(null);

    const [parentHeight, setParentHeight] = useState("700px"); // Default height

    useEffect(() => {
        if (!loading) {
            if (parentRef.current) {
                setParentHeight(`${parentRef.current.clientHeight}px`);
            }

            // Optional: Update on resize
            const handleResize = () => {
                if (parentRef.current) {
                    setParentHeight(`${parentRef.current.clientHeight}px`);
                }
            };

            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }
        return;
    }, [loading]);

    const replaceImagesInContent = (content: string) => {
        // Remove <img> tags and <figure> tags containing <img>
        return content.replace(/<img[^>]*>/g, "").replace(/<figure[^>]*>(.*?)<\/figure>/g, "");
    };

    // Initial loader
    if (loading && articles.length === 0) {
        return (
            <Disable disabled>
                <Grid container>
                    <style>
                        {`
                        div::-webkit-scrollbar {
                            display: none;
                        }
                    `}
                    </style>

                    {[1, 2, 3, 4].map((article, index) => {
                        return (
                            <Grid key={article} item xs={12} sx={{ marginBottom: "24px" }}>
                                <div style={{ height: "100%", borderRadius: "24px", overflow: "hidden" }}>
                                    <Skeleton variant="rectangular" width={"100%"} height={150} animation="wave" />
                                </div>
                            </Grid>
                        );
                    })}
                </Grid>
            </Disable>
        );
    }

    return (
        <Disable disabled={loading}>
            <Grid container id="scroller" ref={parentRef}>
                <style>
                    {`
                    div::-webkit-scrollbar {
                        display: none;
                    }
                `}
                </style>
                <div style={{ width: "100%" }}>
                    <InfiniteScroll
                        // key={articles.length}
                        height={parentHeight}
                        /**
                         * Scroll if 85% is scrolled
                         */
                        scrollThreshold={0.85}
                        /**
                         * id of menulist to target the menu as window instead of whole window
                         */
                        scrollableTarget="scroller"
                        /**
                         * This is important field to render the next data.
                         */
                        dataLength={articles.length} //This is important field to render the next data
                        /**
                         * function to get next data
                         */
                        next={loadMore}
                        /**
                         * boolean to check if more data is available
                         */
                        hasMore={Boolean(paginatorInfo?.hasMorePages)}
                        /**
                         * loader component
                         */
                        loader={<></>}
                    >
                        {articles.map((article) => {
                            // Assuming data?.article?.content contains the string
                            const replacingContent =
                                article.id === "138" && article.content
                                    ? replaceImagesInContent(
                                          article.content.replace(
                                              "Neem dan snel contact met ons op voor meer informatie of een kennismakingsgesprek.",
                                              "<CONTACT/>"
                                          )
                                      )
                                    : replaceImagesInContent(article.content);

                            return (
                                <Grid key={article.id} item xs={12} sx={{ marginBottom: "24px", width: "100%" }}>
                                    <ArticleListItem
                                        article={article}
                                        setArticle={setArticle}
                                        replacingContent={replacingContent}
                                        overview={overview}
                                    />
                                </Grid>
                            );
                        })}
                    </InfiniteScroll>
                </div>
            </Grid>
        </Disable>
    );
};

export default ListOfArticles;
