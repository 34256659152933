import React, { useEffect, useState } from "react";
import RequestModule from "./RequestModule";
import { Grid, Paper, Divider, useMediaQuery, useTheme } from "@mui/material";
import LeftExplanationPanel from "./LeftExplanationPanel";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Get_chosen_timeslot_details, Get_chosen_timeslot_details_meetingTimeslot } from "../../__generated__/Get_chosen_timeslot_details";
import queryString from "query-string";
import TimeSlotsOverview from "./TimeSlotsOverview";
import { Save_chosen_timeslot, Save_chosen_timeslotVariables } from "../../__generated__/Save_chosen_timeslot";
import TimeSlotDetails from "./TimeSlotDetails";
import LinearLoader from "../../components/loading/LinearLoader";
import { Cancel_chosen_timeslot, Cancel_chosen_timeslotVariables } from "../../__generated__/Cancel_chosen_timeslot";
import { SAVE_CHOSEN_TIMESLOT, CANCEL_CHOSEN_TIMESLOT } from "../../graphql/mutationDefinitions";
import { GET_CHOSEN_TIMESLOT_DETAILS } from "../../graphql/queryDefinitions";

interface Props {
    a?: number;
}

const RequestDemoContainer: React.FC<Props> = (props) => {
    const [checked, setChecked] = useState(false);
    const theme = useTheme();
    const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [chosenTimeSlot, setChosenTimeSlot] = useState<string>("");
    const [meetingDetails, setMeetingDetails] = useState<Get_chosen_timeslot_details_meetingTimeslot | null>(null);
    const query = queryString.parse(window.location.search);
    const token = query.token as string;

    /**
     * Book timeslot
     */
    const [book, { error: errorMutation, loading: loadingMutation }] = useMutation<Save_chosen_timeslot, Save_chosen_timeslotVariables>(
        SAVE_CHOSEN_TIMESLOT
    );

    /**
     * cancel timeslot
     */
    const [cancel, { error: errorCancel, loading: loadingCancel }] = useMutation<Cancel_chosen_timeslot, Cancel_chosen_timeslotVariables>(
        CANCEL_CHOSEN_TIMESLOT
    );

    const book_timeslot = async () => {
        if (errorMutation) {
        }
        try {
            await book({
                variables: {
                    timeslot_hash: chosenTimeSlot,
                },
                onCompleted: (data) => {
                    GetMeetingDetails({
                        variables: {
                            hash: chosenTimeSlot,
                        },
                    });
                },
            });
        } catch (e) {}
    };

    const cancel_timeslot = async (hash: string) => {
        if (errorCancel) {
        }
        try {
            await cancel({
                variables: {
                    timeslot_hash: hash,
                },
                onCompleted: (data) => {
                    setMeetingDetails(null);
                    setChosenTimeSlot("");
                },
            });
        } catch (e) {}
    };

    /**
     * Fetch details of chosen timeslot
     */
    const [GetMeetingDetails] = useLazyQuery<Get_chosen_timeslot_details>(GET_CHOSEN_TIMESLOT_DETAILS, {
        onCompleted: (data) => {
            setMeetingDetails(data.meetingTimeslot);
        },
    });

    /**
     * Check if token or cancel is set
     * do something if one of them is set
     */
    useEffect(() => {
        if (token) {
            GetMeetingDetails({
                variables: {
                    hash: token,
                },
            });
        }
    }, [token, GetMeetingDetails]);

    const renderPage = (content: React.ReactNode) => (
        <RequestModule>
            <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Grid
                    sx={{
                        width: { lg: "75vw", xl: "75vw", xxl: "75vw" },
                    }}
                >
                    <LinearLoader loading={loadingMutation || loadingCancel} color="blue" />
                    <Paper square style={{ padding: 24 }}>
                        <Grid container>
                            {/* Left item with logo and some basic information for user */}
                            {!onlySmallScreen && (
                                <Grid item xs={false} sm={false} md={4} style={{ padding: 8 }}>
                                    <LeftExplanationPanel />
                                </Grid>
                            )}
                            {!onlySmallScreen && <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />}
                            <Grid
                                sx={{
                                    padding: "8px 8px 8px 32px",
                                    display: "flex",
                                    flexDirection: "row",
                                    pointerEvents: loadingMutation || loadingCancel ? "none" : "auto",
                                }}
                                item
                                container
                                xs={12}
                                sm={12}
                                md={8}
                            >
                                {content}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </div>
        </RequestModule>
    );

    // If user has already chosen a timeslot, show the details of that timeslot
    if (meetingDetails !== null) return renderPage(<TimeSlotDetails meetingDetails={meetingDetails} cancel_timeslot={cancel_timeslot} />);

    if (token === undefined || meetingDetails === null)
        return renderPage(
            <TimeSlotsOverview
                chosenTimeSlot={chosenTimeSlot}
                setChosenTimeSlot={setChosenTimeSlot}
                handleSubmit={book_timeslot}
                checked={checked}
                setChecked={setChecked}
                loading={loadingMutation}
            />
        );

    return null;
};

export default RequestDemoContainer;

export const sleep = async (ms: number) => new Promise((res) => setTimeout(res, ms));
