import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import setTheme from "../../utils/setTheme";
import { materialTheme } from "../../assets/styles";
import MainLayout from "../../components/layout/MainLayout";

import {
    //  useNavigate, useLocation,
    Routes,
    Route,
    useLocation,
} from "react-router-dom";
import NotFound404 from "../NotFound404";
import TendenzModule from "./TendenzModule";
import { useQuery } from "@apollo/client";
import { TendenzEvent } from "../../__generated__/TendenzEvent";
import { TENDENZ_EVENT } from "../../graphql/tendenzDefinitions";
import FullPageSpinner from "../../components/loading/FullPageSpinner";
import { Box, useMediaQuery, useTheme } from "@mui/system";
import HeroDot1 from "./Hero_GUI/HeroDot1";
import OverlappingDotLeft from "./Hero_GUI/OverlappingDotLeft";
import OverlappingDotRight from "./Hero_GUI/OverlappingDotRight";
import WobbleLine from "./Hero_GUI/WobbleLine";

interface Props {
    a?: number;
}

const TendenzContainer: React.FC<Props> = (props) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const location = useLocation();

    /**
     * QUERY TENDENZ_EVENT
     */
    const { data, loading } = useQuery<TendenzEvent>(TENDENZ_EVENT, {
        skip: location.pathname !== "/tendenz",
        fetchPolicy: "network-only",
    });

    /**
     * set theme color
     */
    useEffect(() => {
        setTheme("darkGray");
    });

    if (loading) {
        return (
            <ThemeProvider theme={materialTheme}>
                <MainLayout page="TendenZ">
                    {" "}
                    <Box
                        sx={{
                            overflow: "hidden",
                            position: "relative",
                            width: "100%",
                            height: "100vh",
                            background: `linear-gradient(45deg, #70a4b7, #82b6a0)`,
                            color: "text.primary",
                            marginTop: noMobile ? "-64px" : "0px",
                        }}
                    >
                        <FullPageSpinner />

                        {/* Dot left */}
                        {noMobile && <HeroDot1 />}
                        {/* Dot  overlap left*/}
                        {noMobile && <OverlappingDotLeft />}
                        {/* Dot */}
                        {noMobile && <OverlappingDotRight />}
                        {/* Wobble line */}
                        {noMobile && <WobbleLine />}
                    </Box>
                </MainLayout>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={materialTheme}>
            <MainLayout page="TendenZ">
                <Routes>
                    <Route path="" element={<TendenzModule edition={data as TendenzEvent} />} />
                    <Route path=":version" element={<TendenzModule key={window.location.pathname} />} />
                    <Route path="*" element={<NotFound404 url={"/tendenz"} />} />
                </Routes>
            </MainLayout>
        </ThemeProvider>
    );
};

export default TendenzContainer;
