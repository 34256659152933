import React, { useEffect } from "react";
import { Checkbox, FormControlLabel, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import CountryFlagBox from "../../../../components/boxes/CountryFlagBox";
import { FilterWithValues } from "../FilterModal";
import { useTenderSearchContext } from "../../../../components/guides/tenderWizard/WizardContext";
import { TenderFilterInput } from "../../../../__generated__/globalTypes";
import update from "immutability-helper";

interface Props {
    filterValues: any;
    filter: string;
    regionFilterValues: FilterWithValues;
}

const CountryFilter: React.FC<Props> = ({ filter, filterValues, regionFilterValues }) => {
    const recon = regionFilterValues.values.map((option: any) => ({
        key: option.identifier,
        label: option.label,
        identifier: option.identifier,
    }));

    // Sort the country filter values so that the Netherlands is always on top
    const sortedCountryFilterValues = [...filterValues].sort((a, b) => (a.identifier === "528" ? -1 : b.identifier === "528" ? 1 : 0));

    const { regions, setRegions, country, setCountry, filters, setFilters } = useTenderSearchContext(); // Use the context hook to access context value and function

    //REGION
    useEffect(() => {
        if (filters.regions) {
            const updatedRegions = filters.regions
                .map((id: string) => recon.find((item: any) => item.identifier === id)) // Find objects in recon
                .filter(Boolean) // Remove undefined values
                .map((region: any) => region.identifier); // Ensure identifier is a string

            // Only update state if the values actually changed
            if (JSON.stringify(regions) !== JSON.stringify(updatedRegions)) {
                setRegions(updatedRegions);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.regions, recon]); // Only runs when filters.regions or recon changes

    // COUNTRY
    useEffect(() => {
        if (filters.country && filters.country.length === 0) {
            // Unset 'country' if it's an empty array
            setFilters(update(filters, { $unset: ["country" as keyof TenderFilterInput] }));
        } else if (filters.country) {
            const updatedCountry = filters.country
                .map((id: number) => sortedCountryFilterValues.find((item) => parseInt(item.identifier) === id))
                .filter(Boolean) // Remove undefined values
                .map((country: any) => parseInt(country.identifier)); // Ensure identifier is a number

            // Only update state if the values actually changed
            if (JSON.stringify(country) !== JSON.stringify(updatedCountry)) {
                setCountry(updatedCountry);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.country, sortedCountryFilterValues]); // Runs only when filters.country or sortedCountryFilterValues change

    // Country toggle function
    const onToggleCountry = (countryIdentifier: string) => {
        const parsedCountryId = parseInt(countryIdentifier);

        setCountry((prevCountry) => {
            const isSelected = prevCountry.includes(parsedCountryId);
            let updatedCountry;

            if (isSelected) {
                // Remove the country
                updatedCountry = prevCountry.filter((c) => c !== parsedCountryId);
            } else {
                // Add the country
                updatedCountry = [...prevCountry, parsedCountryId];
            }

            // Update filters based on the updatedCountry array
            setFilters(
                update(
                    filters,
                    updatedCountry.length > 0 ? { country: { $set: updatedCountry } } : { $unset: ["country" as keyof TenderFilterInput] }
                )
            );

            return updatedCountry;
        });
    };
    // Function to toggle region selection
    const onToggleRegion = (regionIdentifier: string) => {
        setRegions((prevRegions) => {
            const isSelected = prevRegions.includes(regionIdentifier);
            let updatedRegions;

            if (isSelected) {
                // If region is selected, remove it
                updatedRegions = prevRegions.filter((r) => r !== regionIdentifier);
            } else {
                // If region is not selected, add it
                updatedRegions = [...prevRegions, regionIdentifier];
            }

            // Update filters based on the updatedRegions array
            setFilters(
                update(
                    filters,
                    updatedRegions.length > 0 ? { regions: { $set: updatedRegions } } : { $unset: ["regions" as keyof TenderFilterInput] }
                )
            );

            return updatedRegions;
        });
    };
    return (
        <div style={{ padding: "16px" }}>
            {sortedCountryFilterValues.map((cntr: any) => {
                // NEDERLAND
                if (cntr.identifier === "528") {
                    return (
                        <List dense key={cntr.identifier} sx={{ padding: 0 }}>
                            <ListSubheader sx={{ display: "flex" }}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ marginLeft: -4, marginRight: 6, marginTop: -2 }}>
                                        <CountryFlagBox code={null} alpha2={cntr.identifier.toString()} />
                                    </div>
                                    {cntr.label}
                                </div>
                                <FormControlLabel
                                    sx={{ marginLeft: "16px" }}
                                    label=""
                                    control={
                                        <Checkbox
                                            checked={country.includes(parseInt(cntr.identifier))}
                                            onChange={() => {
                                                onToggleCountry(cntr.identifier);
                                            }}
                                        />
                                    }
                                />
                            </ListSubheader>
                            {recon
                                ?.filter((rgn) => !rgn.label?.includes("(BE)"))
                                .sort((a, b) => {
                                    const labelA = a.label || ""; // default to empty string if label is null or undefined
                                    const labelB = b.label || ""; // default to empty string if label is null or undefined

                                    if (labelA < labelB) return -1;
                                    if (labelA > labelB) return 1;
                                    return 0;
                                })
                                .map((region) => {
                                    return (
                                        <ListItemButton key={region.identifier} onClick={() => onToggleRegion(region.identifier as string)}>
                                            <ListItemText inset primary={region.label} />
                                            <ListItemIcon>
                                                <Checkbox edge="start" checked={regions.includes(region.identifier as string)} />
                                            </ListItemIcon>
                                        </ListItemButton>
                                    );
                                })}
                        </List>
                    );
                }
                // BELGIË
                else
                    return (
                        <List dense key={cntr.identifier} sx={{ padding: 0 }}>
                            <ListSubheader sx={{ display: "flex" }}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ marginLeft: -4, marginRight: 6, marginTop: -2 }}>
                                        <CountryFlagBox code={null} alpha2={cntr.identifier.toString()} />
                                    </div>
                                    {cntr.label}
                                </div>
                                <FormControlLabel
                                    sx={{ marginLeft: "16px" }}
                                    label=""
                                    control={
                                        <Checkbox
                                            checked={country.includes(parseInt(cntr.identifier))}
                                            onChange={() => {
                                                onToggleCountry(cntr.identifier);
                                            }}
                                        />
                                    }
                                />
                            </ListSubheader>
                            {regionFilterValues.values
                                ?.filter((rgn) => rgn.label?.includes("(BE)"))
                                .sort((a, b) => {
                                    const labelA = a.label || ""; // default to empty string if label is null or undefined
                                    const labelB = b.label || ""; // default to empty string if label is null or undefined

                                    if (labelA < labelB) return -1;
                                    if (labelA > labelB) return 1;
                                    return 0;
                                })
                                .map((region) => {
                                    return (
                                        <ListItemButton key={region.identifier} onClick={() => onToggleRegion(region.identifier as string)}>
                                            <ListItemText inset primary={region.label} />
                                            <ListItemIcon>
                                                <Checkbox edge="start" checked={regions.includes(region.identifier as string)} />
                                            </ListItemIcon>
                                        </ListItemButton>
                                    );
                                })}
                        </List>
                    );
            })}
        </div>
    );
};

export default CountryFilter;
