import { gql } from "@apollo/client";

/**
 * Query admin
 */
export const IS_USER_ADMIN = gql`
    query GetAdminState {
        currentUser {
            id
            is_admin
        }
    }
`;

export const CREATE_ORGANIZATION_NAME = gql`
    mutation createOrganizationName($organization_id: Int!, $settling_id: Int, $value: String!, $type: OrganizationNameType!) {
        createOrganizationName(organization_id: $organization_id, settling_id: $settling_id, value: $value, type: $type) {
            id
            value
            organization {
                id
            }
            settling {
                id
            }
        }
    }
`;
