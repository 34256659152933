import React, { createContext, useContext, useState } from "react";
import {
    DistributionIntervalInput,
    SearchDistributionInput,
    SearchDistributionMoment,
    TenderFilterInput,
    TenderFilterTarget,
} from "../../../__generated__/globalTypes";
import { GetUserEmail, GetUserEmail_currentUser_employee_organization_subscriptions_sectors } from "../../../__generated__/GetUserEmail";
import { QUERY_EMAIL } from "../../../graphql/queryDefCurrentUser";
import { useQuery } from "@apollo/client";
import { FilterWithValues } from "../../../routes/profile/Dialog/FilterModal";

export interface FilterType {
    key: string;
    label: string;
    identifier: number;
}
// Define the type for your context value
interface TenderSearchType {
    // Add your additional state variables here
    chosenWords: string[];
    query: string;
    domain: TenderFilterTarget;
    filters: TenderFilterInput;
    name: string;
    moment: SearchDistributionMoment | null;
    dist: SearchDistributionInput;
    sectorList: (GetUserEmail_currentUser_employee_organization_subscriptions_sectors | null)[];
    sector: number[];
    checkedSub: number[];
    country: number[];
    staticLoad: boolean;
    copyIDS: number[];
    shareIDS: number[];
    color: string;
    idsToDelete: number[];
    regions: string[];
    chosenADs: FilterType[];
    caTypes: FilterType[];
    types: FilterType[];
    cpvs: FilterType[];
    initialFilterSetTenders: FilterWithValues[] | null;
    setInitialFilterSetTenders: React.Dispatch<React.SetStateAction<FilterWithValues[] | null>>;
    setCpvs: React.Dispatch<React.SetStateAction<FilterType[]>>;
    setTypes: React.Dispatch<React.SetStateAction<FilterType[]>>;
    setCaTypes: React.Dispatch<React.SetStateAction<FilterType[]>>;
    setChosenADs: React.Dispatch<React.SetStateAction<FilterType[]>>;
    setRegions: React.Dispatch<React.SetStateAction<string[]>>;
    setIdsToDelete: React.Dispatch<React.SetStateAction<number[]>>;
    setChosenWords: React.Dispatch<React.SetStateAction<string[]>>;
    setQuery: React.Dispatch<React.SetStateAction<string>>;
    setDomain: React.Dispatch<React.SetStateAction<TenderFilterTarget>>;
    setFilters: React.Dispatch<React.SetStateAction<TenderFilterInput>>;
    setName: React.Dispatch<React.SetStateAction<string>>;
    setMoment: React.Dispatch<React.SetStateAction<SearchDistributionMoment | null>>;
    setDist: React.Dispatch<React.SetStateAction<SearchDistributionInput>>;
    setCountry: React.Dispatch<React.SetStateAction<number[]>>;
    setSector: React.Dispatch<React.SetStateAction<number[]>>;
    setCheckedSub: React.Dispatch<React.SetStateAction<number[]>>;
    copySearchTo: React.Dispatch<React.SetStateAction<number[]>>;
    shareSearchWith: React.Dispatch<React.SetStateAction<number[]>>;
    setColor: React.Dispatch<React.SetStateAction<string>>;
    setStaticLoad: React.Dispatch<React.SetStateAction<boolean>>;
    reset: () => void;
}

// Create the context with an initial value
const TenderSearchObject = createContext<TenderSearchType>({
    chosenWords: [],
    query: "",
    domain: TenderFilterTarget.SUMMARY,
    filters: {},
    name: "",
    moment: null,
    dist: {
        worklist: true,
        in_app: true,
        email_interval: DistributionIntervalInput.DAILY,
    },
    regions: [],
    sector: [],
    checkedSub: [],
    country: [],
    staticLoad: false,
    copyIDS: [],
    shareIDS: [],
    sectorList: [],
    color: "#808080",
    idsToDelete: [],
    chosenADs: [],
    caTypes: [],
    types: [],
    cpvs: [],
    initialFilterSetTenders: [],
    setInitialFilterSetTenders: () => console.warn(""),
    setCpvs: () => console.warn(""),
    setTypes: () => console.warn(""),
    setCaTypes: () => console.warn(""),
    setChosenADs: () => console.warn(""),
    setRegions: () => console.warn(""),
    setIdsToDelete: () => console.warn(""),
    setChosenWords: () => console.warn(""),
    setQuery: () => console.warn(""),
    setDomain: () => console.warn(""),
    setFilters: () => console.warn(""),
    setName: () => console.warn(""),
    setMoment: () => console.warn(""),
    setDist: () => console.warn(""),
    setCountry: () => console.warn(""),
    setSector: () => console.warn(""),
    setCheckedSub: () => console.warn(""),
    copySearchTo: () => console.warn(""),
    shareSearchWith: () => console.warn(""),
    setColor: () => console.warn(""),
    setStaticLoad: () => console.warn(""),
    reset: () => console.warn(""),
});

// Create a provider component for your context
export const TenderSearchProvider: React.FC = ({ children }) => {
    // Initialize your additional state variables here
    const [chosenWords, setChosenWords] = useState<string[]>([]);
    const [query, setQuery] = useState<string>("");
    const [domain, setDomain] = useState<TenderFilterTarget>(TenderFilterTarget.SUMMARY);
    const [filters, setFilters] = useState<TenderFilterInput>({});
    const [name, setName] = useState<string>("");
    const [moment, setMoment] = useState<SearchDistributionMoment | null>(null);
    const [dist, setDist] = useState<SearchDistributionInput>({
        worklist: true,
        in_app: true,
        email_interval: DistributionIntervalInput.DAILY,
    });
    const [sector, setSector] = useState<number[]>([]);
    const [checkedSub, setCheckedSub] = useState<number[]>([]);
    const [country, setCountry] = useState<number[]>([]);
    const [staticLoad, setStaticLoad] = useState<boolean>(false);
    const [copyIDS, copySearchTo] = useState<number[]>([]);
    const [shareIDS, shareSearchWith] = useState<number[]>([]);
    const [color, setColor] = useState("#808080");
    const [sectorList, setSectorList] = useState<(GetUserEmail_currentUser_employee_organization_subscriptions_sectors | null)[]>([]);
    const [idsToDelete, setIdsToDelete] = useState<number[]>([]);
    const [regions, setRegions] = useState<string[]>([]);
    const [chosenADs, setChosenADs] = useState<FilterType[]>([]);
    const [caTypes, setCaTypes] = useState<FilterType[]>([]);
    const [types, setTypes] = useState<FilterType[]>([]);
    const [cpvs, setCpvs] = useState<FilterType[]>([]);
    const [initialFilterSetTenders, setInitialFilterSetTenders] = useState<any>([]);

    useQuery<GetUserEmail>(QUERY_EMAIL, {
        onCompleted(data) {
            if (
                data &&
                data.currentUser &&
                data.currentUser.employee &&
                data.currentUser.employee.organization &&
                data.currentUser.employee.organization.subscriptions &&
                data.currentUser.employee.organization.subscriptions[0].sectors
            ) {
                setSectorList(data.currentUser.employee.organization.subscriptions[0].sectors);
            }
        },
    });

    const reset = () => {
        setChosenWords([]);
        setQuery("");
        setDomain(TenderFilterTarget.SUMMARY);
        setFilters({});
        setName("");
        setMoment(null);
        setDist({
            worklist: true,
            in_app: true,
            email_interval: DistributionIntervalInput.DAILY,
        });
        setSector([]);
        setCheckedSub([]);
        setCountry([]);
        setStaticLoad(false);
        copySearchTo([]);
        shareSearchWith([]);
        setColor("#808080");
        setIdsToDelete([]);
        setRegions([]);
        setCpvs([]);
        setTypes([]);
        setCaTypes([]);
        setChosenADs([]);
    };

    return (
        <TenderSearchObject.Provider
            value={{
                chosenWords,
                query,
                domain,
                filters,
                name,
                moment,
                dist,
                sector,
                checkedSub,
                country,
                staticLoad,
                copyIDS,
                shareIDS,
                color,
                sectorList,
                idsToDelete,
                regions,
                chosenADs,
                caTypes,
                types,
                cpvs,
                initialFilterSetTenders,
                setInitialFilterSetTenders,
                setCpvs,
                setTypes,
                setCaTypes,
                setChosenADs,
                setRegions,
                setIdsToDelete,
                setSector,
                setChosenWords,
                setQuery,
                setDomain,
                setFilters,
                setName,
                setMoment,
                setDist,
                setCheckedSub,
                setStaticLoad,
                copySearchTo,
                shareSearchWith,
                setColor,
                setCountry,
                reset,
            }}
        >
            {children}
        </TenderSearchObject.Provider>
    );
};

// Create a custom hook to easily consume the context
export const useTenderSearchContext = () => {
    return useContext(TenderSearchObject);
};
