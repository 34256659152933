import React, { useCallback, useEffect, useRef, useState } from "react";
import { Typography, TextField, MenuList, MenuItem, Theme, CircularProgress } from "@mui/material";
import { FixedSizeList as List, ListChildComponentProps, areEqual } from "react-window";
import Highlighter from "react-highlight-words";

import StatusDot from "../StatusDot";
import CountryFlagBox from "../boxes/CountryFlagBox";
import FaviconBox from "../FaviconBox";
import { useTranslation } from "react-i18next";
import { sortCountry } from "../TenderSearchComponent/TenderSearch";
import { MemoIcon } from "../boxes/SectorIconLoader";
import { statusArray } from "../FilterDrawer/TopFilterBar";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useLocation } from "react-router-dom";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import FilterDropdownTemplate from "./FilterDropdownTemplate";
import { debounce } from "lodash";
import DOMPurify from "dompurify";
import { sanitizeInput } from "../../utils/sanitizeInput";

export type DropdownOption = { key: string; label: string; id: number };

interface Props {
    /**
     * selected options
     */
    values: number[];
    /**
     * @param values new fetched options
     */
    onChange(values: any[]): void;
    /**
     * options to show in list as listitem(s)
     */
    options: DropdownOption[];
    /**
     * Boolean to show searchbox
     */
    allowSearch?: boolean;
    /**
     * width of dropdownmenu (optional)
     */
    width?: number;
    /**
     * Boolean to set component disabled
     */
    disabled: boolean;
    /**
     * Pass through to template component
     */
    label: string;
    /**
     * colored border (optional)
     */
    borderColor?: keyof Theme["moduleColors"];
    /**
     * Show searchbox for seperate gql query
     */
    allowGqlSearch?: boolean;
    /**
     * Run seperate gql query as searchengine
     * @param query searchinput
     */
    setGqlSearch?(query: string): void;
    /**
     * Searchinput seperate query
     */
    gqlSearch?: string;
    /**
     * loading state seperate query
     */
    gqlLoading?: boolean;
    /**
     * textual helper to show user if seperate query has data or not
     */
    helpertext?: string;
    /**
     * handler to set custom options
     */
    setCustomChosenOptions?(value: React.SetStateAction<number[]>): void;
}

const FilterDropdown: React.FC<Props> = ({
    disabled,
    values,
    onChange,
    options,
    label,
    allowSearch,
    borderColor,
    width = 180,
    allowGqlSearch = false,
    setGqlSearch,
    gqlSearch,
    gqlLoading,
    helpertext,
    setCustomChosenOptions,
}) => {
    const { t } = useTranslation();
    // state with search string
    const [filter, setFilter] = useState<string>("");
    // Local state version of currently selected items
    const [newOptions, setNewOptions] = useState<number[]>(values);
    // Local state version of all options
    const [allOptions, setAllOptions] = useState<DropdownOption[]>(options);
    // const [chosenOptions, setChosenOptions] = useState<number[]>([]);

    // When new values/options as prop are passed, update the internal state
    useEffect(() => {
        setNewOptions(values);
        setAllOptions(options);
    }, [options, values]);

    /**
     * Handler when the popover gets opened
     */
    const handleOpen = () => {
        if (label === t("filter.contracting_authorities")) {
            return;
        }
        setAllOptions((prevAllOptions) => {
            return [...prevAllOptions].sort((a, b) => {
                const aSelected = values.includes(a.id);
                const bSelected = values.includes(b.id);
                if (aSelected && !bSelected) return -1;
                if (!aSelected && bSelected) return 1;
                return a.label.localeCompare(b.label);
            });
        });
    };

    /**
     * On close/save of the FilterDropdown:
     * 1. Update the parent state
     * 2. Reset the search filter text
     */
    const handleSave = () => {
        onChange(newOptions);
        setFilter("");
    };

    const closeDropDown = () => {
        setFilter("");
        setNewOptions(values);
    };

    /**
     * When a option is clicked, toggle the active state in the local state
     */
    const handleOptionClick = React.useCallback(
        (key: number) => {
            setCustomChosenOptions &&
                setCustomChosenOptions((prev: any) => {
                    if (prev.includes(key)) {
                        return prev.filter((o: number) => o !== key);
                    } else {
                        return [...prev, key];
                    }
                });
            setNewOptions((prev) => {
                if (prev.includes(key)) {
                    return prev.filter((o) => o !== key);
                } else {
                    return [...prev, key];
                }
            });
        },
        [setCustomChosenOptions]
    );

    /**
     * The amount of selected items
     */
    const amountSelectedItems = newOptions.length;

    /**
     * Only when search is allowed, we filter the options before rendering in the list
     */
    const filteredOptions =
        allowSearch !== true
            ? allOptions
            : label === t("filter.year_from")
            ? allOptions
                  ?.sort(function (a, b) {
                      return parseInt(b.label) - parseInt(a.label);
                  })
                  .filter((option) => {
                      if (!filter) {
                          return true;
                      } else {
                          return option.label.toLowerCase().includes(filter.toLowerCase());
                      }
                  })
            : label === t("filter.personal_year")
            ? allOptions
                  ?.sort(function (a, b) {
                      return parseInt(b.label) - parseInt(a.label);
                  })
                  .filter((option) => {
                      if (!filter) {
                          return true;
                      } else {
                          return option.label.toLowerCase().includes(filter.toLowerCase());
                      }
                  })
            : label === t("filter.country")
            ? allOptions
                  ?.sort((a, b) => sortCountry.indexOf(b.key.toString()) - sortCountry.indexOf(a.key.toString()))
                  .filter((option) => {
                      if (!filter) {
                          return true;
                      } else {
                          return option.label.toLowerCase().includes(filter.toLowerCase());
                      }
                  })
            : // Label === reviews
            label === t("filter.reviews")
            ? allOptions
                  .sort((a, b) => [1, 2, 0, -1].indexOf(a.id) - [1, 2, 0, -1].indexOf(b.id))
                  .filter((option) => {
                      if (!filter) {
                          return true;
                      } else {
                          return option.label.toLowerCase().includes(filter.toLowerCase());
                      }
                  })
            : // Label === status
            label === t("filter.status")
            ? allOptions
                  .sort((a, b) => statusArray.indexOf(a.id.toString()) - statusArray.indexOf(b.id.toString()))
                  .filter((option) => {
                      if (!filter) {
                          return true;
                      } else {
                          return option.label.toLowerCase().includes(filter.toLowerCase());
                      }
                  })
            : label === t("filter.searches")
            ? allOptions.filter((option) => {
                  if (!filter) {
                      return true;
                  } else {
                      return option.label.toLowerCase().includes(filter.toLowerCase());
                  }
              })
            : allOptions.filter((option) => {
                  if (!filter) {
                      return true;
                  } else {
                      return option.label.toLowerCase().includes(filter.toLowerCase());
                  }
              });

    /**
     * Virtualized list settings
     */
    const amountItems = filteredOptions.length;
    const maxHeight = 400;
    const itemHeight = 32;
    const listHeight = Math.min(maxHeight, itemHeight * amountItems);
    const listWidth = width;

    /**
     * Template settings
     */
    const labelForTemplate: React.ReactNode =
        amountSelectedItems > 0 ? (
            <strong>
                {label} ({amountSelectedItems})
            </strong>
        ) : (
            label
        );

    // const onGqlSearchChange = (event: { target: { value: string } }) => {
    //     if (setGqlSearch) {
    //         setGqlSearch(event.target.value);
    //     }
    // };
    const inputGqlSearch = useRef(null);

    const [userValue, setUserValue] = useState(gqlSearch || "");
    // debounce function to prevent request if characters is < 3 except 0

    const debounced = useRef(
        debounce(
            (value) => (value.length === 0 ? setGqlSearch && setGqlSearch(value) : value.length >= 3 && setGqlSearch && setGqlSearch(value)),
            900
        )
    );
    const updateUserValue = useCallback(({ target: { value } }) => {
        const inputvalue = sanitizeInput(value, false);
        const val = DOMPurify.sanitize(inputvalue);
        debounced.current(val);
        setUserValue(val);
    }, []);

    return (
        <FilterDropdownTemplate
            disabled={disabled}
            amountSelectedItems={amountSelectedItems}
            label={labelForTemplate}
            borderColor={borderColor}
            allowReset={newOptions.length > 0}
            onOpen={handleOpen}
            onSave={handleSave}
            onClose={closeDropDown}
            onReset={() => {
                setCustomChosenOptions && setCustomChosenOptions([]);
                onChange([]);
            }}
        >
            <div>
                {/* Searchbar to search in seperate query */}
                {allowGqlSearch && setGqlSearch && gqlSearch !== undefined ? (
                    <div style={{ padding: 8 }}>
                        <TextField
                            helperText={helpertext}
                            ref={inputGqlSearch}
                            placeholder="Zoeken..."
                            variant="outlined"
                            size="small"
                            value={userValue}
                            onChange={updateUserValue}
                            autoFocus
                            fullWidth
                            // pass data to Row component below
                            inputProps={{
                                sx: { fontSize: "14px" },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <React.Fragment>
                                        {gqlLoading && gqlLoading === true && gqlSearch.length > 0 ? (
                                            <CircularProgress color="inherit" size={14} />
                                        ) : null}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    </div>
                ) : allowSearch ? (
                    <div style={{ padding: 8 }}>
                        <TextField
                            placeholder="Zoeken..."
                            variant="outlined"
                            size="small"
                            value={filter}
                            onChange={(e) => {
                                const inputvalue = sanitizeInput(e.target.value, false);
                                const val = DOMPurify.sanitize(inputvalue);
                                setFilter(val);
                            }}
                            autoFocus
                            fullWidth
                            // pass data to Row component below
                            inputProps={{ sx: { fontSize: "14px" } }}
                        />
                    </div>
                ) : null}

                <MenuList style={{ padding: 4 }}>
                    <List
                        height={listHeight}
                        itemCount={amountItems}
                        itemSize={itemHeight}
                        width={listWidth}
                        itemData={{ filteredOptions, handleOptionClick, newOptions, filter, label }}
                    >
                        {RowMemo}
                    </List>
                </MenuList>
            </div>
        </FilterDropdownTemplate>
    );
};

export default React.memo(FilterDropdown);

const Row: React.FC<ListChildComponentProps> = ({ index, style, data }) => {
    const option = data.filteredOptions[index];
    const isSelected = data.newOptions.includes(option.id);
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const yellow = "#F57117";

    return (
        <MenuItem key={index} dense onClick={() => data.handleOptionClick(option.id)} selected={isSelected} style={style}>
            {/*
             * if label is translationkey status show StatusDot component
             * Check if user is on specific page
             */}
            {data.label === t("filter.searches") &&
                (pathname === "/tenders/reviews" ||
                    pathname === "/tenders/worklist" ||
                    pathname.includes("/organizations") ||
                    pathname.includes("/contracting-authorities")) && (
                    <div style={{ marginLeft: -4, marginRight: 6, marginTop: 6 }}>
                        <LocalOfferIcon style={{ color: option.key }} />
                    </div>
                )}
            {data.label === t("filter.searches") && (pathname === "/opportunities/reviews" || pathname === "/opportunities/worklist") && (
                <div style={{ marginLeft: -4, marginRight: 6, marginTop: 6 }}>
                    <FindInPageIcon style={{ color: option.key }} />
                </div>
            )}
            {/* If translationkey is status, show labels in color   */}
            {data.label === t("filter.status") && (
                <div style={{ marginRight: 6, marginLeft: -4 }}>
                    <StatusDot type={option.id.toString()} placement={"right"} />
                </div>
            )}
            {/* If translationkey is country, show countryflag */}
            {data.label === t("filter.country") && (
                <div style={{ marginLeft: -4, marginRight: 6, marginTop: -2 }}>
                    <CountryFlagBox code={null} alpha2={option.id.toString()} />
                </div>
            )}
            {/* If translationkey is ca, show favicon */}
            {data.label === t("filter.contractingAuthorities") && (
                <div>
                    <FaviconBox favicon="" name={option.key} color={yellow} id={option.id} />
                </div>
            )}
            {/* If translationkey is subsectors, show icon */}
            {data.label === t("filter.subSectors") && (
                <div style={{ marginTop: 4, marginRight: 4 }}>
                    <MemoIcon sector_id={option.id.toString()} />
                </div>
            )}
            <Typography variant="inherit" noWrap>
                {isSelected ? (
                    <strong>{option.label}</strong>
                ) : (
                    <Highlighter highlightTag={Highlight} searchWords={data.filter ? [data.filter] : []} textToHighlight={option.label} />
                )}
            </Typography>
        </MenuItem>
    );
};

const RowMemo = React.memo(Row, areEqual);

/**
 * Highlight with bold letters, used for search-highlighter
 */
const Highlight: React.FC<{ children: any; highlightIndex: any }> = ({ children, highlightIndex }) => (
    <strong className="highlighted-text">{children}</strong>
);
