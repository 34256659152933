import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { InputAdornment, TextField, Divider, Tooltip, IconButton, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import OpportunityAccordionPerDoc from "./OpportunityAccordionPerDoc";
import { useLazyQuery } from "@apollo/client";
import {
    GetAllDocsForOpportunitiesOnTender,
    GetAllDocsForOpportunitiesOnTender_filterOpportunityFiles_results,
    GetAllDocsForOpportunitiesOnTender_filterOpportunityFiles_snippets,
    GetAllDocsForOpportunitiesOnTenderVariables,
} from "../../__generated__/GetAllDocsForOpportunitiesOnTender";
import { QUERY_OPPORTUNITY_DOCUMENTS_ON_TENDER_DETAILPAGE } from "../../graphql/opportunityQueries/queryDefinitions";
import { handleOppHighlighting } from "../HighlightOpportunity";
import { OpportunityFilterInput, OpportunitySearchInput, OpportunitySearchSource, SortOrder } from "../../__generated__/globalTypes";
import { debounce } from "lodash";
import FullPageSpinner from "../loading/FullPageSpinner";
import { GetCasForOpportunityDocs_tender_clients } from "../../__generated__/GetCasForOpportunityDocs";
import { PlaylistAdd } from "@mui/icons-material";

import SearchWizardContainer from "../../routes/profile/Dialog/SearchWizardContainer";
import { useTour } from "../contextProviders/TourContext";
import DOMPurify from "dompurify";
import { sanitizeInput } from "../../utils/sanitizeInput";

interface Props {
    caIDS: GetCasForOpportunityDocs_tender_clients[];
    oppGuideOpen: boolean;
}

const filters: OpportunityFilterInput = {};
const defaultSearch: OpportunitySearchInput = { filters };
const defaultVariables: GetAllDocsForOpportunitiesOnTenderVariables = {
    page: 1,
    first: 50,
    orderField: "year_from",
    order: SortOrder.DESC,
    search: defaultSearch,
    source: OpportunitySearchSource.ALL_OPPORTUNITY_FILES,
};

const OpportunityOnTenderDetail: React.FC<Props> = ({ caIDS, oppGuideOpen }) => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const { t } = useTranslation();
    const [
        orderField,
        // setOrderField
    ] = useState<string>(defaultVariables.orderField);
    const [
        orderDir,
        // setOrderDir
    ] = useState<SortOrder>(defaultVariables.order);
    const [query, setQuery] = useState<string>("");
    const [searchInput, setSearchInput] = useState<OpportunityFilterInput>({});
    const [page] = useState(defaultVariables.page);
    const [rowsPerPage] = useState(defaultVariables.first);
    const [oppData, setOppData] = useState<GetAllDocsForOpportunitiesOnTender_filterOpportunityFiles_results | null>();
    const [oppSnippets, setOppSnippets] = useState<(GetAllDocsForOpportunitiesOnTender_filterOpportunityFiles_snippets | null)[] | null>();
    const [openPopup, setOpenPopup] = useState<string | false>(false);
    // const [staticLoad, setStaticLoad] = useState(false);
    const { setCurrentStep } = useTour();

    /**
     * Variables for the GQL query
     */
    const variables: GetAllDocsForOpportunitiesOnTenderVariables = {
        source: defaultVariables.source,
        page: page,
        first: rowsPerPage,
        orderField: orderField,
        order: orderDir,
        search: {
            query: query,
            filters: searchInput,
        },
    };

    useEffect(() => {
        const ids = caIDS.map((ca) => parseInt(ca.id));
        const uniqueChars = [...new Set(ids)];

        setSearchInput({ contracting_authorities: uniqueChars });
    }, [caIDS]);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        // expand accordion based on lot ID
        setExpanded(isExpanded ? panel : false);
    };

    // reset expanded state when query is empty
    useEffect(() => {
        if (query.length === 0) {
            setExpanded(false);
        }
    }, [query]);

    /**
     * GQL query to get all opportunities, on complete
     * -> set filters to state and data to another state
     */
    const [run, { loading, error }] = useLazyQuery<GetAllDocsForOpportunitiesOnTender, GetAllDocsForOpportunitiesOnTenderVariables>(
        QUERY_OPPORTUNITY_DOCUMENTS_ON_TENDER_DETAILPAGE,
        {
            variables: variables,
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (data && data.filterOpportunityFiles) {
                    setOppData(data.filterOpportunityFiles.results);
                    setOppSnippets(data.filterOpportunityFiles.snippets);
                    if (query.length !== 0) {
                        setExpanded(data.filterOpportunityFiles.results?.data[0].id.toString() || "");
                    }
                    handleOppHighlighting();
                    // setStaticLoad(true);
                    if (oppGuideOpen) {
                        setTimeout(() => {
                            setCurrentStep(1);
                        }, 300);
                    }
                }
            },
        }
    );

    /**
     * Debounce used to wait 1100 ms before setting new query
     * new query triggers refetch data
     */
    const debouncedSearch = debounce(async (newQuery) => {
        setQuery(newQuery);
    }, 1100);

    /**
     * @param newQuery typed query
     */
    const handleQueryChange = (newQuery: string) => {
        debouncedSearch(newQuery);
    };

    /**
     * On each state change run GQL query again with updated variables
     */
    useEffect(() => {
        run({
            variables: {
                source: defaultVariables.source,
                page: page,
                first: rowsPerPage,
                orderField: orderField,
                order: orderDir,
                search: {
                    query: query,
                    filters: searchInput,
                },
            },
        });
    }, [run, t, page, rowsPerPage, orderField, orderDir, query, searchInput]);

    /**
     * @param content opportunity documents
     */
    const renderPage = (content: React.ReactNode) => (
        <span data-tut="reactour__searchbar_opp_widgetbox">
            <div>
                {/*
                 * div with searchbar
                 * marked documents button
                 * downloadbutton
                 */}
                <div style={{ padding: 8, display: "flex" }}>
                    <TextField
                        data-tut="reactour__searchbar_opp"
                        onChange={(e) => {
                            if (e.target.value.length >= 3 || e.target.value.length === 0) {
                                const inputvalue = sanitizeInput(e.target.value, false);
                                const val = DOMPurify.sanitize(inputvalue);
                                handleQueryChange(val);
                            }
                        }}
                        size="small"
                        fullWidth
                        variant="outlined"
                        placeholder="Zoeken in documenten..."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" style={{ marginLeft: -8 }}>
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div style={{ display: "flex", alignItems: "center", paddingLeft: 8 }}>
                        <Tooltip title="Toevoegen als zoekregel" placement="bottom">
                            <IconButton
                                data-tut="reactour__searchbar_opp_iconbtn"
                                disabled={query.length <= 3 || oppData?.data === undefined || oppData.data.length === 0}
                                onClick={() => {
                                    setOpenPopup("opportunity");
                                }}
                                size="small"
                                style={{ margin: "0 6px" }}
                            >
                                <PlaylistAdd />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                {/* Div shows up when user clicks on button
                 *  Default is hide */}
                {content}
            </div>
        </span>
    );

    if (loading) {
        return renderPage(
            <div style={{ height: 40, padding: 8, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <FullPageSpinner />
            </div>
        );
    }

    if (error || !oppData || (oppData.data.length === 0 && query.length > 0)) {
        return renderPage(
            <div style={{ height: 40, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Typography color="textSecondary">Geen resultaten gevonden.</Typography>
            </div>
        );
    }
    return renderPage(
        <div style={{ maxHeight: 425, overflow: "auto" }}>
            {oppData.data.map((opp, i) => {
                const snippets = oppSnippets?.find((snippet) => snippet?.id === opp.id)?.snippets?.map((snip) => snip) || [];

                return (
                    <React.Fragment key={i}>
                        <OpportunityAccordionPerDoc
                            opp_id={opp.id}
                            key={opp.id}
                            caName={opp.contractingAuthority.name || ""}
                            favicon={opp.contractingAuthority.favicon}
                            defaultExpanded={i === 0}
                            name={opp.name || ""}
                            expanded={expanded}
                            clicked={opp.id}
                            handleChange={handleChange(opp.id)}
                            snippets={snippets}
                        />
                        <Divider />
                    </React.Fragment>
                );
            })}

            {openPopup !== false && (
                <SearchWizardContainer
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    editRule={{
                        // id used as key
                        id: "newOppFromWidgetOnTenderDetail",
                        name: "",
                        // state: null,
                        // distribution: null,
                        query: query,
                        // target: "",
                        filters: JSON.stringify(searchInput),
                        // color: "#9e9e9e",
                    }}
                />
            )}
        </div>
    );
};

export default OpportunityOnTenderDetail;
