import React, { useEffect, useState } from "react";
import { Typography, TextField, MenuList, MenuItem, Theme } from "@mui/material";
import { FixedSizeList as List, ListChildComponentProps, areEqual } from "react-window";
import Highlighter from "react-highlight-words";
import FilterDropdownTemplate from "./FilterDropdownTemplate";
import StatusDot from "../StatusDot";
import CountryFlagBox from "../boxes/CountryFlagBox";
import FaviconBox from "../FaviconBox";
import { useTranslation } from "react-i18next";
import { sortCountry } from "../TenderSearchComponent/TenderSearch";
import SectorIconLoader from "../boxes/SectorIconLoader";
import TaskPriorityChip from "../../routes/projects/projectModal/task/TaskPriorityChip";
import { AccountTree } from "@mui/icons-material";
import DOMPurify from "dompurify";
import { sanitizeInput } from "../../utils/sanitizeInput";

export type DropdownOption = { key: string; label: string; id: any };

interface Props {
    values: string[];
    onChange(values: any[]): void;
    options: DropdownOption[];
    allowSearch?: boolean;
    width?: number;
    disabled: boolean;
    // Pass through to template component
    label: string;
    borderColor?: keyof Theme["moduleColors"];
    /** add the id parameter in the prefered order */
    customSorting?: string[];
    setOnlyOne?: boolean;
}

const FilterDropdown: React.FC<Props> = ({
    disabled = false,
    values,
    onChange,
    options,
    label,
    allowSearch,
    borderColor,
    width = 180,
    customSorting,
    setOnlyOne,
}) => {
    // state with search string
    const [filter, setFilter] = useState<string>("");

    // Local state version of currently selected items
    const [newOptions, setNewOptions] = useState<string[]>(values);
    // Local state version of all options
    const [allOptions, setAllOptions] = useState<DropdownOption[]>(options);

    // When new values/options as prop are passed, update the internal state
    useEffect(() => {
        setNewOptions(values);
        setAllOptions(options);
    }, [options, values]);

    /**
     * Handler when the popover gets opened
     */
    const handleOpen = () => {
        setAllOptions((prevAllOptions) => {
            return [...prevAllOptions].sort((a, b) => {
                const aSelected = values.includes(a.id);
                const bSelected = values.includes(b.id);
                if (aSelected && !bSelected) return -1;
                if (!aSelected && bSelected) return 1;

                // Use customSorting if provided
                if (customSorting?.length) {
                    const aIndex = customSorting.indexOf(a.id);
                    const bIndex = customSorting.indexOf(b.id);
                    if (aIndex !== -1 && bIndex !== -1) return aIndex - bIndex;
                    if (aIndex !== -1) return -1;
                    if (bIndex !== -1) return 1;
                }

                return a.label.localeCompare(b.label);
            });
        });
    };

    /**
     * On close/save of the FilterDropdown:
     * 1. Update the parent state
     * 2. Reset the search filter text
     */
    const handleSave = () => {
        onChange(newOptions);
        setFilter("");
    };

    const closeDropDown = () => {
        setFilter("");
        setNewOptions(values);
    };

    /**
     * When an option is clicked, toggle the active state in the local state.
     * Optionally, pass `setOnlyOne` as true to ensure only the clicked value is in the options.
     */
    const handleOptionClick = React.useCallback((key: string) => {
        setNewOptions((prev) => {
            if (setOnlyOne) {
                // If setOnlyOne is true, clear all options and set only the clicked value
                return [key];
            } else {
                // Toggle the selected value (allow multiple selections)
                if (prev.includes(key)) {
                    return prev.filter((o) => o !== key);
                } else {
                    return [...prev, key];
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * The amount of selected items
     */
    const amountSelectedItems = newOptions.length;

    /**
     * Only when search is allowed, we filter the options before rendering in the list
     */
    const filteredOptions =
        allowSearch !== true
            ? allOptions
            : allOptions
                  //! sort country to NL first
                  ?.sort((a, b) => sortCountry.indexOf(a.key) - sortCountry.indexOf(b.key))
                  .filter((option) => {
                      if (!filter) {
                          return true;
                      } else {
                          return option.label.toLowerCase().includes(filter.toLowerCase());
                      }
                  });

    /**
     * Virtualized list settings
     */
    const amountItems = filteredOptions.length;
    const maxHeight = 400;
    const itemHeight = 32;
    const listHeight = Math.min(maxHeight, itemHeight * amountItems);
    const listWidth = width;

    /**
     * Template settings
     */
    const labelForTemplate: React.ReactNode =
        amountSelectedItems > 0 ? (
            <strong>
                {label} ({amountSelectedItems})
            </strong>
        ) : (
            label
        );

    return (
        <FilterDropdownTemplate
            disabled={disabled}
            amountSelectedItems={amountSelectedItems}
            label={labelForTemplate}
            borderColor={borderColor}
            allowReset={newOptions.length > 0}
            onOpen={handleOpen}
            onSave={handleSave}
            onClose={closeDropDown}
            onReset={() => onChange([])}
        >
            <div>
                {allowSearch && (
                    <div style={{ padding: 8 }}>
                        <TextField
                            placeholder="Zoeken..."
                            variant="outlined"
                            size="small"
                            value={filter}
                            onChange={(e) => {
                                const inputvalue = sanitizeInput(e.target.value, false);
                                const val = DOMPurify.sanitize(inputvalue);
                                setFilter(val);
                            }}
                            autoFocus
                            fullWidth
                            // pass data to Row component below
                            inputProps={{ sx: { fontSize: "14px" } }}
                        />
                    </div>
                )}

                <MenuList style={{ padding: 4 }}>
                    <List
                        height={listHeight}
                        itemCount={amountItems}
                        itemSize={itemHeight}
                        width={listWidth}
                        itemData={{ filteredOptions, handleOptionClick, newOptions, filter, label }}
                    >
                        {RowMemo}
                    </List>
                </MenuList>
            </div>
        </FilterDropdownTemplate>
    );
};

export default React.memo(FilterDropdown);

const Row: React.FC<ListChildComponentProps> = ({ index, style, data }) => {
    const option = data.filteredOptions[index];
    const isSelected = data.newOptions.includes(option.id);
    const { t } = useTranslation();

    const yellow = "#F57117";

    return (
        <MenuItem key={index} dense onClick={() => data.handleOptionClick(option.id)} selected={isSelected} style={style}>
            {data.label === "Project" && (
                <div style={{ marginLeft: -12, marginTop: 4, marginRight: 4 }}>
                    <AccountTree style={{ color: "lightgrey", width: "16px", height: "16px", marginRight: "4px" }} />
                </div>
            )}

            {data.label === "Prioriteit" && (
                <div style={{ marginLeft: -12, marginRight: 8 }}>
                    <TaskPriorityChip dot={true} priority={option.id} disabled={false} />
                </div>
            )}
            {/* if label is translationkey status show StatusDot component */}
            {data.label === t("filter.status") && (
                <div style={{ marginRight: 6, marginLeft: -4 }}>
                    <StatusDot type={option.id.toString()} placement={"right"} />
                </div>
            )}
            {/* If trnslationkey is country, show countryflag */}
            {data.label === t("filter.country") && (
                <div style={{ marginLeft: -4, marginRight: 6, marginTop: -2 }}>
                    <CountryFlagBox code={option.key} />
                </div>
            )}
            {/* If translationkey is ca, show favicon */}
            {data.label === t("filter.contractingAuthorities") && (
                <div>
                    <FaviconBox favicon="" name={option.key} color={yellow} />
                </div>
            )}
            {/* If translationkey is subsectors, show icon */}
            {data.label === t("filter.subSectors") && (
                <div style={{ marginTop: 4, marginRight: 4 }}>
                    <SectorIconLoader sector_id={option.id.toString()} />
                </div>
            )}
            <Typography variant="inherit" noWrap>
                {isSelected ? (
                    <strong>{option.label}</strong>
                ) : (
                    <Highlighter highlightTag={Highlight} searchWords={data.filter ? [data.filter] : []} textToHighlight={option.label} />
                )}
            </Typography>
        </MenuItem>
    );
};

const RowMemo = React.memo(Row, areEqual);

/**
 * Highlight with bold letters, used for search-highlighter
 */
const Highlight: React.FC<{ children: any; highlightIndex: any }> = ({ children, highlightIndex }) => (
    <strong className="highlighted-text">{children}</strong>
);
