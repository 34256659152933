import { Search } from "@mui/icons-material";
import {
    Avatar,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import truncate from "truncate";
import { UserInTeam } from "../../profile/UserAndTeams/UsersAndTeams";
import { TendenZ_registrations_TendenzRegistrations } from "../../../__generated__/TendenZ_registrations";
import UnregisterUser from "../UnregisterUser";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import DOMPurify from "dompurify";
import { sanitizeInput } from "../../../utils/sanitizeInput";

interface Props {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    userList: UserInTeam[];
    totalSeats: number;
    toggleUserSelection: (userId: string) => void;
    selectedUsers: string[];
    registeredUsers: (TendenZ_registrations_TendenzRegistrations | null)[] | null | undefined;
    loading: boolean;
    edition: string;
    eventID: string;
    type: "Webinar" | "Event";
}

const FormModal: React.FC<Props> = ({
    onSave,
    onClose,
    open,
    userList,
    totalSeats,
    toggleUserSelection,
    selectedUsers,
    registeredUsers,
    loading,
    edition,
    eventID,
    type,
}) => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState<string>("");

    const handleSearchUser = (event: any) => {
        const {
            target: { value },
        } = event;
        const inputvalue = sanitizeInput(value, false);
        const val = DOMPurify.sanitize(inputvalue);
        setSearchTerm(val);
    };

    /**
     * Array that gives users back that match with input in searchfield.
     * - match based on input and givenname
     */
    const results = !searchTerm ? userList : userList.filter((item) => item.employee.name?.toLowerCase().includes(searchTerm.toLocaleLowerCase()));

    /**
     * Amount of available seats with selection
     */
    const amountOfSeatsAvailable = totalSeats - (registeredUsers?.length ?? 0) - selectedUsers.length;

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="responsive-dialog-title" maxWidth="sm" fullWidth>
            <DialogTitle id="responsive-dialog-title">Gebruikers aanmelden</DialogTitle>

            <Grid item xs={12} sm={12} md={12}>
                <Paper square elevation={0} variant="outlined">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                            sx={{ margin: "10px", flexGrow: 1 }}
                            placeholder="Zoek een gebruiker..."
                            size="small"
                            variant="outlined"
                            onChange={handleSearchUser}
                            value={searchTerm}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>

                    {/*
                     * Textual helper where user can see the number of remaining licenses.
                     * and a CTA when they reached the limit.
                     * Owners will see textual helper
                     */}

                    <div style={{ padding: "6px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {type === "Event" && (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {amountOfSeatsAvailable > 0 ? (
                                    <span>
                                        Je kunt nog <span style={{ fontWeight: "bold" }}>{amountOfSeatsAvailable}</span>{" "}
                                        {amountOfSeatsAvailable === 1
                                            ? t("UserAndTeams.User").toLocaleLowerCase()
                                            : t("UserAndTeams.Users").toLocaleLowerCase()}{" "}
                                        aanmelden.
                                    </span>
                                ) : (
                                    <span>Geen vrije plekken beschikbaar.</span>
                                )}
                            </div>
                        )}
                    </div>

                    <Divider />

                    {/* List component with all users as listitem */}
                    <List
                        sx={{
                            padding: 0,
                            width: "100%",
                            backgroundColor: (theme) => theme.palette.background.paper,
                            maxHeight: { xs: "345px", md: "65vh" },
                            overflow: "auto",
                        }}
                    >
                        {results.map((usert) => {
                            const user = usert.employee;

                            /**
                             * Render UserListItem component for details each user in organization
                             * Interacts with searchbar on top
                             */
                            // const subbie = subOwner?.find((u) => u?.id === user.id) ? true : false;

                            return (
                                <>
                                    <ListItem role="Box">
                                        <ListItemAvatar>
                                            <Avatar>
                                                {(user.name ? user.name : "").charAt(0)}
                                                {(user.familyname ? user.familyname : "").charAt(0)}
                                            </Avatar>
                                        </ListItemAvatar>

                                        {/* Listitem with userdetails. */}
                                        <ListItemText
                                            /**
                                             * Combination of user givenname and familyname. line through when user is inactive
                                             */
                                            primary={
                                                <>
                                                    {/* <Typography style={{ fontSize: "0.875rem" }} className={user.state === "Inactief" ? classes.lineThrough : "null"}> */}
                                                    <Typography>{truncate(user.name ? user.name : "", 40)}</Typography>
                                                </>
                                            }
                                            /**
                                             * Second line in useritem, linethrough when state is inactive.
                                             */
                                            secondary={
                                                <>
                                                    {/* emaildetails */}
                                                    {/* <Typography style={{ fontSize: "0.875rem" }} className={user.state === "Inactief" ? classes.lineThrough : "null"}> */}
                                                    <Typography style={{ fontSize: "0.875rem" }}>{usert.email}</Typography>

                                                    {/* state of user, color based on state of useraccount */}
                                                    <Typography
                                                        style={{ fontSize: "0.875rem" }}
                                                        // className={user.state === "Actief" ? classes.active : user.state === "Inactief" ? classes.inactive : "null"}
                                                    >
                                                        {/* {user.state} */}
                                                        {usert.subscription_admin ? "Beheerdersaccount" : null}{" "}
                                                        {usert.verified !== true ? "Uitnodiging nog niet geaccepteerd" : ""}
                                                    </Typography>
                                                </>
                                            }
                                        />

                                        <>
                                            <ListItemSecondaryAction>
                                                {registeredUsers?.find((regi) => regi?.user.id === usert.id) ? (
                                                    <UnregisterUser id={parseInt(usert.id)} edition={edition} eventID={eventID} />
                                                ) : (
                                                    <Checkbox
                                                        disabled={
                                                            type === "Event" && amountOfSeatsAvailable <= 0 && !selectedUsers.includes(usert.id)
                                                        }
                                                        checked={selectedUsers.includes(usert.id)}
                                                        onChange={() => toggleUserSelection(usert.id)}
                                                        inputProps={{ "aria-label": "controlled" }}
                                                    />
                                                )}
                                            </ListItemSecondaryAction>
                                        </>
                                    </ListItem>
                                    <Divider />
                                </>
                            );
                        })}
                    </List>
                </Paper>
            </Grid>

            <DialogActions>
                <Button disabled={loading} onClick={onClose}>
                    Annuleren
                </Button>
                <LoadingButton loadingIndicator="Aanmelden…" onClick={onSave} disabled={loading} loading={loading} variant="contained" autoFocus>
                    Aanmelden
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default FormModal;
