import { gql } from "@apollo/client";
import * as fragments from "./fragments";
/**
 * Query all projects
 */
export const ALL_PROJECTS = gql`
    query GetAllProjects {
        projects {
            id
            title
            amountUsers
            amountTasks
            amountNotes
            latestComment {
                created_at
            }
            users: activeUsers {
                id
                employee {
                    id
                    name
                    givenname
                    familyname
                }
            }
            tasks: activeUserTasks {
                id
                task_completed
                task_deadline
                task_description
                task_priority
                users {
                    id
                    employee {
                        id
                        name
                        givenname
                        familyname
                    }
                }
            }
            state {
                id
                name
                order_number
            }
        }
    }
`;

export const ALL_PROJECTS_WIDGET = gql`
    query GetAllProjectsDashboard {
        projects {
            id
            title
            amountUsers
            amountTasks
            amountNotes
            latestComment {
                created_at
            }

            contractingAuthorities {
                id
                name
                favicon
                #   favicon_resized
            }

            users: activeUsers {
                id
                employee {
                    id
                    name
                    familyname
                }
            }

            tasks: activeUserTasks {
                id
                task_completed
                task_deadline
                task_description
                task_priority
                users {
                    id
                    employee {
                        id
                        name
                        familyname
                    }
                }
            }

            state {
                id
                name
                order_number
            }
        }
    }
`;

export const PROJECT_STATES = gql`
    query projectStates {
        projectStates {
            id
            name
            order_number
            amountOfProjects
        }
    }
`;

export const SINGLE_PROJECT = gql`
    query single_project($id: ID!) {
        project(id: $id) {
            id
            reviewCancellation
            dateOfAddingTender
            amountTasks
            amountNotes
            latestComment {
                created_at
            }
            title
            state {
                id
                name
                order_number
            }
            widgets
            users: activeUsers {
                id
                employee {
                    id
                    name
                    givenname
                    familyname
                }
            }
            contractingAuthorities {
                id
                name
                favicon
            }
            tenders {
                id
            }
        }
    }
`;

export const SINGLE_PROJECT_TASKS = gql`
    query single_project_tasks($id: ID!) {
        project(id: $id) {
            id
            tasks: activeUserTasks {
                id
                task_description
                task_completed
                task_completed_at
                deleted_at
                task_deadline
                task_priority
                users {
                    id
                    employee {
                        id
                        name
                        givenname
                        familyname
                    }
                }
            }
            deletedTasks {
                id
                task_completed_at
                task_description
                task_completed
                task_deadline
                task_priority
                deleted_at
                users {
                    id
                    employee {
                        id
                        name
                        givenname
                        familyname
                    }
                }
            }
        }
    }
`;

export const SINGLE_PROJECT_TENDERS = gql`
    query single_project_tenders($id: ID!) {
        project(id: $id) {
            id
            tenders {
                id
                status
                namegiventocontract
                deadline_inschrijving
            }
        }
    }
`;

export const SINGLE_PROJECT_CAS = gql`
    query single_project_cas($id: ID!) {
        project(id: $id) {
            id
            contractingAuthorities {
                id
                name
            }
        }
    }
`;

export const DATA_FOR_ENTITY_DOCS = gql`
    query get_entitity_docs($id: ID!) {
        tender(id: $id) {
            documentDateCount {
                amount
                date
            }
        }
    }
`;

export const DATA_FOR_ENTITY_SCHEDULE = gql`
    query get_entitity_schedule($id: ID!) {
        tender(id: $id) {
            original_link
            published_host
        }
    }
`;

export const SINGLE_PROJECT_NOTES = gql`
    query single_project_notes($id: ID!) {
        project(id: $id) {
            id
            comments {
                id
                comment
                user_deleted
                user {
                    id
                    employee {
                        id
                        name
                        givenname
                        familyname
                    }
                }
                created_at
                updated_at
                deleted_at
                replies {
                    ...FullReply
                    replies {
                        ...FullReply
                        replies {
                            ...FullReply
                            replies {
                                ...FullReply
                                replies {
                                    ...FullReply
                                    replies {
                                        ...FullReply
                                        replies {
                                            ...FullReply
                                            replies {
                                                ...FullReply
                                                replies {
                                                    ...FullReply
                                                    replies {
                                                        ...FullReply
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${fragments.fullReply}
`;

export const FULL_SINGLE_PROJECT = gql`
    query full_single_project($id: ID!) {
        project(id: $id) {
            id
            reviewCancellation
            dateOfAddingTender
            amountTasks
            amountNotes
            latestComment {
                created_at
            }
            title
            state {
                id
                name
                order_number
            }
            users: activeUsers {
                id
                employee {
                    id
                    name
                    givenname
                    familyname
                }
            }
            contractingAuthorities {
                id
                name
                favicon
            }
            tenders {
                id
            }
            widgets
            comments {
                id
                comment
                user {
                    id
                    employee {
                        id
                        name
                        givenname
                        familyname
                    }
                }
                created_at
                updated_at
                deleted_at
                replies {
                    ...FullReply
                    replies {
                        ...FullReply
                        replies {
                            ...FullReply
                            replies {
                                ...FullReply
                                replies {
                                    ...FullReply
                                    replies {
                                        ...FullReply
                                        replies {
                                            ...FullReply
                                            replies {
                                                ...FullReply
                                                replies {
                                                    ...FullReply
                                                    replies {
                                                        ...FullReply
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            tasks: activeUserTasks {
                id
                task_description
                task_completed
                task_deadline
                task_priority
                users {
                    id
                    employee {
                        id
                        name
                        givenname
                        familyname
                    }
                }
            }

            deletedTasks {
                id
                task_completed_at
                task_description
                task_completed
                task_deadline
                task_priority
                users {
                    id
                    employee {
                        id
                        name
                        givenname
                        familyname
                    }
                }
            }
        }
    }
    ${fragments.fullReply}
`;
