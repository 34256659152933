import { Card, CssBaseline, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import ProgramSection from "./ProgramSection";
import FormSection from "./FormSection";
import Hero from "./Hero";
// import History from "./History";
import LocationInfo from "./LocationInfo";
import Header from "./Header";
import MobileTendenzNavigation from "./MobileTendenzNavigation";
import CircleGraph from "./CircleGraph";
import useRegisterScrollTrigger from "./useRegisterScrollTrigger";
import { useLocation, useParams } from "react-router-dom";
import Webinar from "./Webinar";
import { PollProvider } from "./poll/PollContext";
import InlinePollContainer from "./poll/inline/InlinePollContainer";
import moment from "moment";
import { Poll } from "@mui/icons-material";
import { TendenzEvent } from "../../__generated__/TendenzEvent";
import TendenzNoActiveEvent from "./TendenzNoActiveEvent";
import WebinarViewer from "./WebinarViewer";
import EditionCarousel from "./EditionCarousel";

interface Props {
    edition?: TendenzEvent;
}

export interface ProgramData {
    title: string;
    time: string;
    bg: string;
    img: string;
    size: number;
    smaller?: boolean; // Optional, as it's not present in all items
    subTitle?: string; // Optional, as it's not present in all items
    function?: string; // Optional, as it's not present in all items
    programSneak?: string; // Optional, as it's not present in all items
    text?: string; // Optional, as it's not present in all items
}

export interface EventData {
    version: string;
    heroTitle: string;
    active: boolean;
    date: string;
    closeDate: string;
    timeToShow: string;
    showCountdown: boolean;
    locationName: string;
    locationImage1: string;
    locationImage2: string;
    locationLogo: string;
    locationDescription: string;
    locationAddress: string;
    locationPlace: string;
    programData: ProgramData[];
    type: "Webinar" | "Event";
    webinar?: WebinarData;
}

export interface WebinarData {
    version: string;
    heroTitle: string;
    active: boolean;
    date: string;
    timeToShow: string;
    closeDate: string;
    showCountdown: boolean;
    image1: string;
    image2: string;
    logo: string;
    description: string;
    address: string;
    environment: string;
    topics: string[];
    topicTitle: string;
    title: string;
    subject: string;
    type: "Webinar" | "Event";
}

const TendenzModule: React.FC<Props> = ({ edition }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const [showLogo, setShowLogo] = useState<boolean>(false);
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [showCircle] = useState<boolean>(false);
    const [eventData, setEventData] = useState<EventData | WebinarData | null>(null);
    const { version: routeVersion } = useParams<{ version: string }>(); // Get the version from the URL
    const location = useLocation();
    const [error, setError] = useState<string | null>(null);
    const activeVersion = location.pathname === "/tendenz" ? edition?.TendenzEvent?.id : routeVersion;

    const loadData = () => {
        // Perform data loading or any action here
        setTimeout(() => {
            setFetchCircle(true);
        }, 1000);
    };

    const [fetchCircle, setFetchCircle] = useState<boolean>(false);

    const registerRef = useRef(null);

    useRegisterScrollTrigger(registerRef, loadData);

    // set tabtitle
    document.title = "TendenZ";

    useEffect(() => {
        if (activeVersion !== undefined) {
            // window.scrollTo(0, 0);
            // Dynamically import the JSON file based on the version
            const loadEventData = async () => {
                try {
                    const data = await import(`./editions/v${activeVersion}.json`);
                    if (data.type === "Webinar") {
                        setEventData(data as WebinarData);
                    } else setEventData(data as EventData);
                } catch (err) {
                    setError("Event not found");
                }
            };

            loadEventData();
        }
    }, [activeVersion]);

    const handleMutationCompleted = () => {
        setRefreshTrigger((prev) => prev + 1);
    };

    if (activeVersion === undefined) {
        return <TendenzNoActiveEvent showLogo={showLogo} setShowLogo={setShowLogo} />;
    }

    if (error || !eventData) {
        return <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>{error}</div>;
    }

    return (
        <PollProvider>
            <CssBaseline />

            <Box
                sx={{
                    width: "100%",
                    minHeight: "100vh",
                    bgcolor: "background.default",
                    color: "text.primary",
                    marginTop: noMobile ? "-64px" : "0px",
                }}
            >
                {!noMobile && <MobileTendenzNavigation />}
                <Header showLogo={showLogo} setShowLogo={setShowLogo} />

                {/****************************************************************************
                 ** HERO *********************************************************************
                 *****************************************************************************/}
                <Hero showCountdown={eventData.showCountdown} date={eventData.date} time={eventData.timeToShow} teaser={eventData.heroTitle} />

                {/****************************************************************************
                 ** PROGRAM ******************************************************************
                 *****************************************************************************/}
                {eventData.type === "Event" && <ProgramSection programData={(eventData as EventData).programData} />}

                {!noMobile && eventData.type === "Event" && <div style={{ height: "50px" }} />}

                {/****************************************************************************
                 ** WEBINAR ******************************************************************
                 *****************************************************************************/}
                {eventData.type === "Webinar" && <Webinar webinarData={eventData as WebinarData} />}

                {/****************************************************************************
                 ** LOCATION *****************************************************************
                 *****************************************************************************/}
                {eventData.type === "Event" && <LocationInfo locationData={eventData as EventData} />}

                {/****************************************************************************
                 ** WEBINAR VIEWER ***********************************************************
                 *****************************************************************************/}
                {eventData.type === "Webinar" && <div style={{ height: "100px" }} />}

                {eventData.type === "Webinar" && <WebinarViewer />}

                {eventData.type === "Webinar" && <div style={{ height: "100px" }} />}

                {/****************************************************************************
                 ** FORM *********************************************************************
                 *****************************************************************************/}
                <FormSection
                    onMutationCompleted={handleMutationCompleted}
                    edition={eventData.version}
                    closeDate={eventData.closeDate}
                    type={eventData.type}
                    eventID={activeVersion as string}
                />

                {showCircle && (
                    <CircleGraph
                        fetch={fetchCircle}
                        registerRef={registerRef}
                        refreshTrigger={refreshTrigger}
                        eventID={activeVersion as string}
                        type={eventData.type}
                    />
                )}

                {/* <WavesBottom /> */}

                {eventData.active && !moment().isAfter(moment(eventData.date), "day") && noMobile && (
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                padding: "32px 32px 128px",
                            }}
                        >
                            <div id="poll" />
                            <Typography variant="h1" fontWeight={500} gutterBottom align="center" sx={{ margin: 5, fontSize: "3rem !important" }}>
                                Peiling
                            </Typography>
                            {moment(eventData.date).isSame(moment(), "day") ? (
                                <Card>
                                    <InlinePollContainer date={eventData.date} />
                                </Card>
                            ) : (
                                <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                    <Poll sx={{ height: "12rem", width: "12rem", color: "#82b6a07A" }} />
                                    <Typography align="center" variant="h1">
                                        Hier komen de stellingen tijdens {eventData.type.toLocaleLowerCase()}
                                    </Typography>
                                </div>
                            )}
                        </Box>
                    </>
                )}

                {/* <History /> */}
                <EditionCarousel />

                <div style={{ height: noMobile ? "150px" : "75px" }} />

                {/* <Footer /> */}
                {noMobile && (
                    <Box sx={{ position: "relative" }}>
                        <div
                            className="custom-shape-divider-top-1720529819"
                            style={{
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                width: "100%",
                                overflow: "hidden",
                                lineHeight: 0,
                                transform: `rotate(180deg)`,
                            }}
                        >
                            <svg
                                data-name="Layer 1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 1200 120"
                                preserveAspectRatio="none"
                                style={{ position: "relative", display: "block", width: `calc(149% + 1.3px)`, height: "95px" }}
                            >
                                <path
                                    d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                                    opacity=".5"
                                    className="shape-fill"
                                    style={{ fill: "#82b6a0" }}
                                />
                                <path
                                    d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                                    opacity=".5"
                                    className="shape-fill"
                                    style={{ fill: "#70a4b7" }}
                                />
                                <path
                                    d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                                    className="shape-fill"
                                    opacity=".5"
                                    style={{ fill: "#173357" }}
                                />
                            </svg>
                        </div>
                    </Box>
                )}
            </Box>
        </PollProvider>
    );
};

export default TendenzModule;
