import React from "react";
import { useArticleFilter } from "../../components/contextProviders/ArticleFilterContext";
import { Chip, Container, Grid } from "@mui/material";

interface Props {
    a?: number;
}

const subcategories = [
    { id: "column", type: "category", text: "Column" },
    { id: "blog", type: "category", text: "Blog" },
];

const ColumnFilters: React.FC<Props> = (props) => {
    const { setArticles, module, setModule, setPage } = useArticleFilter();

    // Function to handle toggle logic
    const toggleSubcategory = (currentSubcategory: string | null, subcategoryId: string): void => {
        setArticles([]);
        setPage(1);
        // If the current subcategory is already active, set to null; else set to the clicked subcategory
        const newSubcategory = currentSubcategory === subcategoryId ? null : subcategoryId;
        setModule(newSubcategory); // Call the onChange function passed as a prop
    };
    return (
        <Container maxWidth="lg" sx={{ padding: "0px 128px !important", margin: "16px 0px" }}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                {subcategories.map((cat, i) => (
                    <Grid item key={cat.id}>
                        <Chip
                            key={i}
                            label={cat.text}
                            onClick={() => toggleSubcategory(module, cat.id)}
                            color={module === cat.id ? "primary" : "default"}
                            onDelete={
                                module === cat.id
                                    ? () => {
                                          setModule(null);
                                          setArticles([]);
                                          setPage(1);
                                      }
                                    : undefined
                            } // Set to null when delete icon clicked
                        />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default ColumnFilters;
