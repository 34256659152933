import React, { useState, useEffect, useRef, useCallback } from "react";

// Material UI components
import { ListItem, ListItemText, Typography, TextField, ListItemIcon, Checkbox, Button, List, CircularProgress, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
// Custom components
import FaviconBox from "../FaviconBox";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import { Star } from "@mui/icons-material";
import { useProfile } from "../contextProviders/ProfileOpenerContext";
import DOMPurify from "dompurify";

interface Props {
    allowReset: boolean;
    onChange(values: any[]): void;
    options: DropdownOption[];
    allowSearch?: boolean;
    setOpenFilter(open: string | boolean): void;
    title: string;
    helpertext?: string;
    allowGqlSearch?: boolean;
    setGqlSearch?(query: string): void;
    gqlSearch?: string;
    gqlLoading?: boolean;
    /**
     * handler to set custom options
     */
    setCustomChosenOptions(value: React.SetStateAction<any[]>): void;
    customChosenOptions: any[];
}
export type DropdownOption = { key: string; label: string; id: any };

const FilterItemCaVersion: React.FC<Props> = ({
    allowReset,
    onChange,
    options,
    allowSearch,
    setOpenFilter,
    title,
    allowGqlSearch = false,
    setGqlSearch,
    gqlSearch,
    gqlLoading,
    helpertext,
    setCustomChosenOptions,
    customChosenOptions,
}) => {
    const { myCAS } = useProfile();
    const myCasitems = myCAS.map((option) => ({ key: option.name, label: option.name, id: parseInt(option.id) }));

    const [filter, setFilter] = useState<string>("");
    // Local state version of all options
    const [allOptions, setAllOptions] = useState<DropdownOption[]>(options);
    const { t } = useTranslation();
    const yellow = "#F57117";
    const { pathname } = useLocation();

    /**
     * check if user has selected some contracting authorities
     * Remove those ca's from the all list.
     */
    useEffect(() => {
        if (customChosenOptions.length > 0) {
            const filterByReference = (arr1: any, arr2: any) => {
                let res = [];
                res = arr1.filter((el: any) => {
                    return !arr2.find((element: any) => {
                        return element.id === el.id;
                    });
                });
                return res;
            };
            setAllOptions(filterByReference(options, customChosenOptions));
        } else {
            setAllOptions(options);
        }
    }, [options, customChosenOptions]);

    /**
     * on click save button -> set selected items, reset filter to empty string and close selected filter
     */
    const handleClose = () => {
        onChange(customChosenOptions.map((i) => i.id));
        setFilter("");
        setOpenFilter(false);
        setGqlSearch && setGqlSearch("");
        setUserValue("");

        if (pathname === "/tenders") {
            sessionStorage.setItem("coa", JSON.stringify(customChosenOptions));
        }
        if (pathname === "/tenders/reviews") {
            sessionStorage.setItem("cor", JSON.stringify(customChosenOptions));
        }
        if (pathname === "/tenders/worklist") {
            sessionStorage.setItem("cow", JSON.stringify(customChosenOptions));
        }
        if (pathname === "/opportunities") {
            sessionStorage.setItem("cooa", JSON.stringify(customChosenOptions));
        }
        if (pathname === "/opportunities/worklist") {
            sessionStorage.setItem("coow", JSON.stringify(customChosenOptions));
        }
        if (pathname === "/opportunities/reviews") {
            sessionStorage.setItem("coor", JSON.stringify(customChosenOptions));
        }
    };

    /**
     * When a option is clicked, toggle the active state in the local state
     */
    const handleOptionClick = React.useCallback(
        (key: DropdownOption) => {
            setCustomChosenOptions((prev: any) => {
                if (prev.includes(key)) {
                    return prev.filter((o: DropdownOption) => o.id !== key.id);
                } else {
                    return [...prev, key];
                }
            });
        },
        [setCustomChosenOptions]
    );

    const handleStarredBatch = () => {
        setCustomChosenOptions((prev) => {
            const newChosenOptions = [...prev];

            // Check how many items from myCAS are already selected
            const selectedMyCASItems = newChosenOptions.filter((option) => myCasitems.map((u) => u.id).includes(option.id));

            if (selectedMyCASItems.length === myCAS.length) {
                // Case 2: All myCAS items are selected, remove them all
                return newChosenOptions.filter((option) => !myCasitems.map((o) => o.id).includes(option.id));
            } else if (selectedMyCASItems.length === 0) {
                // Case 3: None of the myCAS items are selected, add them all
                myCasitems.forEach((item) => {
                    newChosenOptions.push(item);
                });
                return newChosenOptions;
            } else {
                // Case 1: Some myCAS items are missing, add the missing ones
                myCasitems.forEach((item) => {
                    if (!newChosenOptions.some((option) => option.id === item.id)) {
                        newChosenOptions.push(item);
                    }
                });
                return newChosenOptions;
            }
        });
    };
    /**
     * Only when search is allowed, we filter the options before rendering in the list
     */
    const filteredOptions =
        allowSearch !== true
            ? allOptions
            : allOptions.filter((option) => {
                  if (!filter) {
                      return true;
                  } else {
                      return option.label.toLowerCase().includes(filter.toLowerCase());
                  }
              });
    const inputGqlSearchDrawer = useRef(null);
    // debounce function to prevent request if characters is < 3 except 0

    const [userValue, setUserValue] = useState(gqlSearch || "");
    // debounce function to prevent request if characters is < 3 except 0

    const debounced = useRef(
        debounce(
            (value) => (value.length === 0 ? setGqlSearch && setGqlSearch(value) : value.length >= 3 && setGqlSearch && setGqlSearch(value)),
            900
        )
    );
    const updateUserValue = useCallback(({ target: { value } }) => {
        const val = DOMPurify.sanitize(value);
        debounced.current(val);
        setUserValue(val);
    }, []);

    return (
        <React.Fragment>
            {/* Clicked filter opens and shows a searchbox if true and items inside selected filter.
                User can search inside this filter, selected items wil be visible in closed view when user clicks on save.
            */}
            {allowGqlSearch && setGqlSearch && gqlSearch !== undefined ? (
                <div style={{ padding: 8 }}>
                    <TextField
                        helperText={helpertext}
                        ref={inputGqlSearchDrawer}
                        placeholder="Zoeken..."
                        variant="outlined"
                        size="small"
                        value={userValue}
                        onChange={updateUserValue}
                        autoFocus
                        fullWidth
                        // pass data to Row component below
                        inputProps={{
                            sx: { fontSize: "14px", color: "#000000" },
                        }}
                        InputProps={{
                            endAdornment: (
                                <React.Fragment>
                                    {gqlLoading && gqlLoading === true && gqlSearch.length > 0 ? (
                                        <CircularProgress color="inherit" size={14} />
                                    ) : null}
                                </React.Fragment>
                            ),
                        }}
                    />
                </div>
            ) : allowSearch ? (
                <div style={{ padding: 16 }}>
                    <TextField
                        placeholder="Zoeken..."
                        variant="outlined"
                        size="small"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        autoFocus
                        fullWidth
                        inputProps={{ sx: { fontSize: "14px", color: "#000000" } }}
                    />
                </div>
            ) : null}
            <div style={{ overflow: "auto", flex: 1 }}>
                <div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* ChosenOptions */}
                        <List>
                            {/* map over filtered items.
                                Options will be filtered when user starts typing in searchfield
                            */}
                            {customChosenOptions.map((item) => {
                                return (
                                    <React.Fragment key={item.key}>
                                        {/*
                                         * // TODO: style to classes to create responsive design
                                         */}
                                        <ListItem
                                            // ! role = undefined -> onClick function now works  alse on checkboxclick
                                            role={undefined}
                                            style={{ paddingLeft: 50 }}
                                            button
                                            onClick={() => handleOptionClick(item)}
                                        >
                                            <ListItemIcon style={{ minWidth: 30 }}>
                                                <Checkbox
                                                    style={{ marginRight: 16 }}
                                                    sx={{
                                                        "& .Mui-checked": {
                                                            color: "#000000",
                                                        },
                                                    }}
                                                    checked={customChosenOptions.findIndex((el) => el.id === item.id) !== -1}
                                                    edge="start"
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            {/* If favicon is not undefined -> show faviconbox or name if no favicon is available */}
                                            {title === "contractingAuthorities" && (
                                                <div>
                                                    <FaviconBox favicon="" id={item.id.toString()} name={item.key} color={yellow} marginTop={0} />
                                                </div>
                                            )}
                                            {title === "contracting_authorities" && (
                                                <div>
                                                    <FaviconBox favicon="" id={item.id.toString()} name={item.key} color={yellow} marginTop={0} />
                                                </div>
                                            )}
                                            <ListItemText primary={<Typography sx={{ color: "#000000" }}>{item.label}</Typography>} />
                                        </ListItem>
                                    </React.Fragment>
                                );
                            })}
                        </List>

                        {customChosenOptions.length > 0 && filteredOptions.length > 0 && <Divider />}
                        <List>
                            {customChosenOptions.filter((option) => myCasitems.map((i) => i.id).includes(option.id)).length !== myCasitems.length && (
                                <ListItem
                                    // ! role = undefined -> onClick function now works  alse on checkboxclick
                                    role={undefined}
                                    style={{ paddingLeft: 50 }}
                                    button
                                    onClick={handleStarredBatch}
                                >
                                    <ListItemIcon style={{ minWidth: 30 }}>
                                        <Checkbox
                                            style={{ marginRight: 16 }}
                                            sx={{
                                                "& .Mui-checked": {
                                                    color: "#000000",
                                                },
                                            }}
                                            checked={
                                                customChosenOptions.filter((option) => myCasitems.map((i) => i.id).includes(option.id)).length !==
                                                    0 &&
                                                customChosenOptions.filter((option) => myCasitems.map((i) => i.id).includes(option.id)).length ===
                                                    myCasitems.length
                                            }
                                            indeterminate={
                                                customChosenOptions.filter((option) => myCasitems.map((i) => i.id).includes(option.id)).length !==
                                                    myCasitems.length &&
                                                customChosenOptions.filter((option) => myCasitems.map((i) => i.id).includes(option.id)).length !== 0
                                            }
                                            edge="start"
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    {/* If favicon is not undefined -> show faviconbox or name if no favicon is available */}
                                    {title === "contractingAuthorities" && (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Star sx={{ color: "#F57117", marginRight: "8px", width: "20px", height: "20px" }} />
                                        </div>
                                    )}
                                    {title === "contracting_authorities" && (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Star sx={{ color: "#F57117", marginRight: "8px", width: "20px", height: "20px" }} />
                                        </div>
                                    )}
                                    <ListItemText
                                        primary={<Typography sx={{ color: "#000000" }}>Gemarkeerd met ster ({myCasitems.length})</Typography>}
                                    />
                                </ListItem>
                            )}
                            {/* map over filtered items.
                                Options will be filtered when user starts typing in searchfield
                            */}
                            {filteredOptions.map((item) => {
                                return (
                                    <React.Fragment key={item.key}>
                                        {/*
                                         * // TODO: style to classes to create responsive design
                                         */}
                                        <ListItem
                                            // ! role = undefined -> onClick function now works  alse on checkboxclick
                                            role={undefined}
                                            style={{ paddingLeft: 50 }}
                                            button
                                            onClick={() => handleOptionClick(item)}
                                        >
                                            <ListItemIcon style={{ minWidth: "30px" }}>
                                                <Checkbox
                                                    style={{ marginRight: "16px" }}
                                                    checked={customChosenOptions.findIndex((el) => el.id === item.id) !== -1}
                                                    edge="start"
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            {/* If favicon is not undefined -> show faviconbox or name if no favicon is available */}
                                            {title === "contractingAuthorities" && (
                                                <div>
                                                    <FaviconBox favicon="" id={item.id.toString()} name={item.key} color={yellow} marginTop={0} />
                                                </div>
                                            )}
                                            {title === "contracting_authorities" && (
                                                <div>
                                                    <FaviconBox favicon="" id={item.id.toString()} name={item.key} color={yellow} marginTop={0} />
                                                </div>
                                            )}
                                            <ListItemText primary={<Typography sx={{ color: "#000000" }}>{item.label}</Typography>} />
                                        </ListItem>
                                    </React.Fragment>
                                );
                            })}
                        </List>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", margin: "24px" }}>
                {/* TODO: reset all filters  */}
                {allowReset && customChosenOptions.length > 0 && (
                    <Button
                        sx={{ color: "#000000" }}
                        onClick={() => {
                            setCustomChosenOptions([]);
                            if (pathname === "/tenders") {
                                sessionStorage.removeItem("coa");
                            }
                            if (pathname === "/tenders/reviews") {
                                sessionStorage.removeItem("cor");
                            }
                            if (pathname === "/tenders/worklist") {
                                sessionStorage.removeItem("cow");
                            }
                        }}
                        variant="text"
                    >
                        {t("filter.reset")}
                    </Button>
                )}
                <Button style={{ color: "white" }} variant="contained" color="primary" onClick={handleClose}>
                    {t("filter.save")}
                </Button>
            </div>
        </React.Fragment>
    );
};

export default FilterItemCaVersion;
