import { createInstance } from "@datapunt/matomo-tracker-react";

const createMatomoInstance = () =>
    createInstance({
        urlBase: "//stats.tender.guide/",
        siteId: 4,
        disabled: false,
        // disabled: process.env.REACT_APP_TG_ENVIRONMENT !== "production",
        userId: localStorage.getItem("user_id") || "undefined", // optional, default value: `undefined`.
        heartBeat: {
            // optional, enabled by default
            active: true, // optional, default value: true
            seconds: 10, // optional, default value: `15
        },
        linkTracking: false, // Important!
    });

export default createMatomoInstance;
