import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GetTenderNotificationData, GetTenderNotificationDataVariables } from "../../__generated__/GetTenderNotificationData";
import { TENDER_DATA_NOTIFICATION } from "../../graphql/queryDefNotifications";
import { Avatar, ListItemAvatar, ListItemText, MenuItem, Skeleton, Tooltip, Typography } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import TooltipOnNewLines from "../TooltipOnNewLines";
import { SINGLE_TENDER_SEARCH } from "./RequestTenderSearchline";
import { tenderSearch, tenderSearchVariables } from "../../__generated__/tenderSearch";

interface Props {
    notification: any;
    onClick(e: string): void;
    keyIndex: number;
}

/**
 * Display a single notification row for the notification Dialog
 */
const TenderNotificationMenuItem: React.FC<Props> = ({ notification, onClick, keyIndex }) => {
    const [userSearches, setUserSearches] = useState<tenderSearch[]>([]); // Store fetched tender searches

    // notification.data[0] -> searchrule id
    // notification.data[1] -> tender id
    const { loading, data: tender } = useQuery<GetTenderNotificationData, GetTenderNotificationDataVariables>(TENDER_DATA_NOTIFICATION, {
        skip: notification === null || notification.data === null,
        variables: {
            tender_id: notification !== null && notification.data !== null ? (notification.data[1] as string) : "",
        },
    });
    const blue = "#173357";

    /**
     * Get tendersearch to show in notification
     */
    const [fetchSingleTenderSearch] = useLazyQuery<tenderSearch, tenderSearchVariables>(SINGLE_TENDER_SEARCH);

    useEffect(() => {
        if (notification?.search_ids) {
            // Fetch tender searches for all `search_ids` using `Promise.all`
            Promise.all(notification.search_ids.map((id: any) => fetchSingleTenderSearch({ variables: { id } }).then((response) => response.data)))
                .then((results) => {
                    // Filter out null results and update the state
                    const validResults = results.filter(Boolean) as tenderSearch[];
                    setUserSearches(validResults);
                })
                .catch((error) => {
                    console.error("Error fetching tender searches:", error);
                });
        }
    }, [notification?.search_ids, fetchSingleTenderSearch]);
    /**
     * Return null when no data is available
     */
    if (notification === null || notification.data === null) return null;

    /**
     * Spinner on loading state
     */
    if (loading) {
        return (
            <MenuItem
                key={keyIndex}
                onClick={() => onClick(tender?.tender?.id as string)}
                style={{ whiteSpace: "normal", maxHeight: 250, opacity: notification.read_at !== null ? 0.5 : undefined }}
            >
                <ListItemAvatar>
                    <Skeleton variant="circular" width={40} height={40} />
                </ListItemAvatar>

                <ListItemText primary={<Skeleton variant="text" width={"100%"} />} secondary={<Skeleton variant="text" width={"75%"} />} />
            </MenuItem>
        );
    }

    return (
        <MenuItem
            key={keyIndex}
            onClick={() => onClick(tender?.tender?.id as string)}
            style={{
                whiteSpace: "normal",
                maxHeight: 250,
                opacity: notification.read_at !== null ? 0.5 : undefined,
                position: "relative",
                padding: "8px 16px",
            }}
        >
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: blue }}>
                    <DescriptionIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <TooltipOnNewLines
                        placement={"bottom-start"}
                        text={`Nieuw: ${tender?.tender?.namegiventocontract}` || ""}
                        variant={"body1"}
                        lines={2}
                        id={`T${notification.id as string}`}
                        fontweight={notification.read_at === null ? 500 : "normal"}
                        fontSize={14}
                    />
                }
                secondary={
                    userSearches.length > 1 ? (
                        <Typography
                            style={{
                                fontSize: 12,
                                // When the nofitication is unread, the date-part of the notification is bold and accent colored
                                color: notification?.read_at ? blue : undefined,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {userSearches.map((i, index) => {
                                return (
                                    <>
                                        <Tooltip
                                            title={
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <LocalOfferIcon
                                                        key={i.tenderSearch?.id}
                                                        style={{
                                                            width: "0.75em",
                                                            height: "0.75em",
                                                            color: i.tenderSearch?.color,
                                                            marginRight: 4,
                                                        }}
                                                    />
                                                    <Typography variant="caption">{i.tenderSearch?.name}</Typography>
                                                </div>
                                            }
                                            key={i.tenderSearch?.id}
                                            placement="right"
                                            disableInteractive
                                        >
                                            <LocalOfferIcon
                                                key={i.tenderSearch?.id}
                                                style={{
                                                    marginLeft: index !== 0 ? -14 : 0,
                                                    width: "0.75em",
                                                    height: "0.75em",
                                                    color: i.tenderSearch?.color,
                                                    marginRight: 4,
                                                }}
                                            />
                                        </Tooltip>
                                    </>
                                );
                            })}
                            <span>Resultaat van meerdere zoekregels</span>
                        </Typography>
                    ) : userSearches.length === 1 ? (
                        // Show colored label and name of searchrule when available
                        <Typography
                            style={{
                                fontSize: 12,
                                // When the nofitication is unread, the date-part of the notification is bold and accent colored
                                color: notification?.read_at ? blue : undefined,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <LocalOfferIcon
                                style={{
                                    width: "0.75em",
                                    height: "0.75em",
                                    color: userSearches[0].tenderSearch?.color,
                                    marginRight: 4,
                                }}
                            />
                            {userSearches[0].tenderSearch?.name}
                        </Typography>
                    ) : (
                        <Typography
                            style={{
                                fontSize: 12,
                                // When the nofitication is unread, the date-part of the notification is bold and accent colored

                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <LocalOfferIcon
                                style={{
                                    width: "0.75em",
                                    height: "0.75em",
                                    color: "#e1e1e1",
                                    marginRight: 4,
                                }}
                            />
                            Resultaat van gewijzigde zoekregel
                        </Typography>
                    )
                }
            />
        </MenuItem>
    );
};

export default TenderNotificationMenuItem;
