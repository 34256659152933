import React, { useEffect, useState } from "react";
// Material UI components
import { Grid, Typography } from "@mui/material";
import { ApolloError, useLazyQuery } from "@apollo/client";
import update from "immutability-helper";
import { Disable } from "react-disable";
import {
    GetAllDocsForOppSearchWizard_filterOpportunityFiles_results_data,
    GetAllDocsForOppSearchWizard_filterOpportunityFiles_results_paginatorInfo,
    GetAllDocsForOppSearchWizard_filterOpportunityFiles_snippets,
} from "../../../../__generated__/GetAllDocsForOppSearchWizard";
import { ContractingAuthoritiesFilterSource, OpportunityFilterInput, SortOrder } from "../../../../__generated__/globalTypes";
import OppSplitList from "./OppSplitList";
import OppSplitViewer from "./OppSplitViewer";

import FilterDropdown from "../../../../components/TopFilterBar/FilterDropdown";
import { sortingArray } from "../../../../components/FilterDrawer/TopFilterBar";
import { useTranslation } from "react-i18next";
import {
    GetContractingAuthorityFilterData,
    GetContractingAuthorityFilterDataVariables,
    GetContractingAuthorityFilterData_filterContractingAuthorities_results,
} from "../../../../__generated__/GetContractingAuthorityFilterData";
import { QUERY_CA_FILTER_DATA } from "../../../../graphql/opportunityQueries/queryDefinitions";
import FilterDropdownCA from "../../../../components/TopFilterBar/FilterDropdownCA";
import { useCustomSearchOptions } from "../../../../components/contextProviders/SearchRuleCustomOptionProvider";
// import FilterDropdownStringVersion from "../../../../components/TopFilterBar/FilterDropdownStringVersion";
import FilterDropdownCAWithEdit from "../../../../components/TopFilterBar/newCaFilter.tsx/FilterDropdownCAWithEdit";

interface Props {
    oppData: GetAllDocsForOppSearchWizard_filterOpportunityFiles_results_data[] | null | undefined;
    filterData: any[] | null | undefined;
    snippetData: (GetAllDocsForOppSearchWizard_filterOpportunityFiles_snippets | null)[] | null | undefined;
    paginatorInfo: GetAllDocsForOppSearchWizard_filterOpportunityFiles_results_paginatorInfo | undefined;
    loading: boolean;
    error: ApolloError | undefined;
    onChange(searchInput: OpportunityFilterInput): void;
    filters: OpportunityFilterInput;
    startSearch(): void;
}

const ResultsModalOpp: React.FC<Props> = ({ oppData, loading, error, filterData, onChange, filters, startSearch, snippetData, paginatorInfo }) => {
    const [selectedItem, setSelectedItem] = useState<GetAllDocsForOppSearchWizard_filterOpportunityFiles_results_data>();
    const { t } = useTranslation();

    const [caSearchQuery, setCaSearchQuery] = useState<string>("");
    // State keeps set of contracting authorities based on input in searchbox (filter)
    const [caFilterSet, setCaFilterSet] = useState<GetContractingAuthorityFilterData_filterContractingAuthorities_results | null>();
    // fetch ca's based on userinput query in searchbox (filter)
    const { setCustomOptions, customOptions } = useCustomSearchOptions();

    const [searchCA, { loading: loadCaFilterset }] = useLazyQuery<GetContractingAuthorityFilterData, GetContractingAuthorityFilterDataVariables>(
        QUERY_CA_FILTER_DATA,
        {
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (data && data.filterContractingAuthorities) {
                    setCaFilterSet(data.filterContractingAuthorities.results);
                }
            },
        }
    );

    // open first item of array each render
    useEffect(() => {
        if (oppData) {
            setSelectedItem(oppData[0]);
        }
    }, [oppData]);

    useEffect(() => {
        if (caSearchQuery === undefined || (caSearchQuery !== undefined && caSearchQuery.length === 0)) {
            setCaFilterSet(undefined);
        }
        if (caSearchQuery !== undefined && caSearchQuery.length > 2) {
            searchCA({
                variables: {
                    page: 1,
                    count: 1000,
                    orderField: "last_update_date_timestamp",
                    order: SortOrder.DESC,
                    search: {
                        source: ContractingAuthoritiesFilterSource.ALL_CONTRACTING_AUTHORITIES,
                        query: `"${caSearchQuery}"`,
                        filters: {
                            opportunityFileCount: 0,
                        },
                    },
                },
            });
        }
    }, [searchCA, caSearchQuery]);

    return (
        <Grid
            container
            sx={(theme) => ({
                width: "90vw",
                [theme.breakpoints.up("xxl")]: {
                    width: "70vw",
                },
            })}
        >
            {/* Left column, suggestions*/}

            <Grid
                item
                xs={12}
                style={{ display: "flex", alignItems: "center", width: "100%", marginTop: 8, marginBottom: 8 }}
                data-tut="reactour__resultsFilter"
            >
                <Disable disabled={loading}>
                    {/* <div style={{ height: 40 }} /> */}
                    <div style={{ height: 40 }}>
                        {filterData
                            ?.sort((a, b) => sortingArray.indexOf(a.title) - sortingArray.indexOf(b.title))
                            .filter((filter) => filter.searchRuleFilter === true)
                            .map((filter, i) => {
                                // change test to filter.title
                                const test = filter.title;
                                const filterItems = filters?.[test as keyof OpportunityFilterInput] as number[];

                                if (filter.title === "contracting_authorities") {
                                    const optionsToShow =
                                        caSearchQuery !== undefined &&
                                        caSearchQuery.length > 0 &&
                                        caFilterSet?.paginatorInfo.total !== undefined &&
                                        caFilterSet?.paginatorInfo.total > 0
                                            ? caFilterSet?.data.map((ca) => ({
                                                  key: ca.name,
                                                  label: ca.name,
                                                  id: parseInt(ca.id),
                                              }))
                                            : filter.values
                                                  .filter((f: any) => f.amountOfResults > 0)
                                                  .sort(function (a: any, b: any) {
                                                      return parseInt(b.key) - parseInt(a.key);
                                                  })
                                                  .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                                      key: fltr.label,
                                                      label: `${fltr.label} (${fltr.amountOfResults})`,
                                                      id: parseInt(fltr.identifier),
                                                  }));
                                    if (filters.contracting_authorities) {
                                        return (
                                            <FilterDropdownCAWithEdit
                                                key={filter.title}
                                                helpertext={caFilterSet?.data.length === 0 ? "Geen resultaat gevonden" : ""}
                                                disabled={false}
                                                allowSearch={false}
                                                allowGqlSearch={true}
                                                setGqlSearch={setCaSearchQuery}
                                                gqlSearch={caSearchQuery}
                                                gqlLoading={loadCaFilterset}
                                                label={`${t(`filter.${filter.title}`)}`}
                                                /**
                                                 * Custom setter to make it possible to search in query and in filterdata to select ca's
                                                 */
                                                setCustomChosenOptions={setCustomOptions}
                                                customChosenOptions={customOptions}
                                                onChange={(target) => {
                                                    // If target has over 0 items. add filter to searchInput
                                                    if (target.length > 0) {
                                                        onChange(update(filters, { [test]: { $set: target } }));
                                                    }
                                                    // If target.length = 0 -> remove empty filter from searchInput
                                                    if (target.length === 0) {
                                                        onChange(update(filters, { $unset: [test as keyof OpportunityFilterInput] }));
                                                    }
                                                    // Execute startsearch each time
                                                    // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                                    startSearch();
                                                }}
                                                alreadyChosenIDs={filters.contracting_authorities || []}
                                                options={optionsToShow}
                                                width={330}
                                            />
                                        );
                                    }

                                    return (
                                        <FilterDropdownCA
                                            key={filter.title}
                                            helpertext={caFilterSet?.data.length === 0 ? "Geen resultaat gevonden" : ""}
                                            disabled={false}
                                            allowSearch={false}
                                            allowGqlSearch={true}
                                            setGqlSearch={setCaSearchQuery}
                                            gqlSearch={caSearchQuery}
                                            gqlLoading={loadCaFilterset}
                                            label={`${t(`filter.${filter.title}`)}`}
                                            /**
                                             * Custom setter to make it possible to search in query and in filterdata to select ca's
                                             */
                                            setCustomChosenOptions={setCustomOptions}
                                            customChosenOptions={customOptions}
                                            onChange={(target) => {
                                                // If target has over 0 items. add filter to searchInput
                                                if (target.length > 0) {
                                                    onChange(update(filters, { [test]: { $set: target } }));
                                                }
                                                // If target.length = 0 -> remove empty filter from searchInput
                                                if (target.length === 0) {
                                                    onChange(update(filters, { $unset: [test as keyof OpportunityFilterInput] }));
                                                }
                                                // Execute startsearch each time
                                                // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                                startSearch();
                                            }}
                                            options={optionsToShow}
                                            width={330}
                                        />
                                    );
                                }

                                return (
                                    <FilterDropdown
                                        key={i}
                                        disabled={false}
                                        allowSearch
                                        label={`${t(`filter.${filter.title}`)}`}
                                        values={filterItems || []}
                                        onChange={(target) => {
                                            // If target has over 0 items. add filter to searchInput
                                            if (target.length > 0) {
                                                onChange(update(filters, { [test]: { $set: target } }));
                                            }
                                            // If target.length = 0 -> remove empty filter from searchInput
                                            if (target.length === 0) {
                                                onChange(update(filters, { $unset: [test as keyof OpportunityFilterInput] }));
                                            }
                                            // Execute startsearch each time
                                            // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                            startSearch();
                                        }}
                                        options={filter.values
                                            .filter((f: any) => f.amountOfResults > 0)
                                            .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                                key: fltr.label,
                                                label: `${fltr.label} (${fltr.amountOfResults})`,
                                                id: parseInt(fltr.identifier),
                                            }))}
                                        width={275}
                                    />
                                );
                            })}

                        {/*
                         * Opportunity => contracting authority region filter (ca of document)
                         */}
                        {/* {filterData
                            ?.sort((a, b) => sortingArray.indexOf(a.title) - sortingArray.indexOf(b.title))
                            .filter((filter) => filter.title === "contracting_authority_region")
                            .map((filter, i) => {
                                const filterName = filter.title;

                                return (
                                    <FilterDropdownStringVersion
                                        key={filter.title}
                                        disabled={
                                            false
                                            // filter.values.length === 0
                                        }
                                        allowSearch
                                        label={`${t(`filter.${filter.title}`)}`}
                                        values={(filters?.[filterName as keyof OpportunityFilterInput] as any[]) || []}
                                        onChange={(target) => {
                                            // If target has over 0 items. add filter to searchInput
                                            if (target.length > 0) {
                                                onChange(update(filters, { [filterName]: { $set: target } }));
                                            }
                                            // If target.length = 0 -> remove empty filter from searchInput
                                            if (target.length === 0) {
                                                onChange(update(filters, { $unset: [filterName as keyof OpportunityFilterInput] }));
                                            }
                                            // Execute startsearch each time
                                            // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                            startSearch();
                                        }}
                                        options={customOptionsRegions}
                                        width={275}
                                    />
                                );
                            })} */}
                    </div>
                </Disable>
            </Grid>
            <Grid item container style={{ height: "73.6vh" }}>
                <Grid xs={12} md={5} item style={{ height: "100%", overflow: "auto" }} data-tut="reactour__resultsResults">
                    {/*
                     * List with tenderitems
                     * data = all tenders in worklist
                     * onitemclick = get id of clicked tender
                     * selectedindex = push id of selected tender to set clicked bg color
                     */}
                    <OppSplitList data={oppData} setSelectedItem={setSelectedItem} selectedItem={selectedItem} loading={loading} error={error} />
                </Grid>

                {/* Grid with detailview of selected item */}
                <Grid xs={12} md={7} item style={{ height: "100%", paddingLeft: "16px" }} data-tut="reactour__resultsDetails">
                    <OppSplitViewer
                        loading={loading}
                        selectedItem={selectedItem}
                        snippets={snippetData?.filter((s) => s?.id === selectedItem?.id).map((s) => s?.snippets)}
                        error={error}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} style={{ marginTop: 8 }}>
                <Grid item xs={5} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    {paginatorInfo && !loading ? (
                        <Typography style={{ marginRight: 4, fontWeight: 500 }}>
                            {paginatorInfo?.count} van {paginatorInfo?.total} resultaten
                        </Typography>
                    ) : (
                        <div style={{ height: 21 }} />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ResultsModalOpp;

export const customOptionsRegions = [
    {
        key: "1",
        label: "Groningen",
        id: "Groningen",
    },
    {
        key: "2",
        label: "Friesland",
        id: "Friesland",
    },
    {
        key: "3",
        label: "Drenthe",
        id: "Drenthe",
    },
    {
        key: "4",
        label: "Overijssel",
        id: "Overijssel",
    },
    {
        key: "5",
        label: "Flevoland",
        id: "Flevoland",
    },
    {
        key: "6",
        label: "Gelderland",
        id: "Gelderland",
    },
    {
        key: "7",
        label: "Utrecht",
        id: "Utrecht",
    },
    {
        key: "8",
        label: "Noord-Holland",
        id: "Noord-Holland",
    },
    {
        key: "9",
        label: "Zuid-Holland",
        id: "Zuid-Holland",
    },
    {
        key: "10",
        label: "Zeeland",
        id: "Zeeland",
    },
    {
        key: "11",
        label: "Noord-Brabant",
        id: "Noord-Brabant",
    },
    {
        key: "12",
        label: "Limburg",
        id: "Limburg",
    },
];
