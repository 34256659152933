import React from "react";
import { CircularProgress, Divider, IconButton, InputBase, Paper, Tooltip } from "@mui/material";
import { Clear, Search } from "@mui/icons-material";

interface Props {
    handleSearchChange: (e: any) => void;
    reset: () => void;
    loading: boolean;
    ref?: React.Ref<unknown> | undefined;
    query: string;
    queryToShow: string;
}

const Searchbar: React.FC<Props> = ({ query, queryToShow, handleSearchChange, reset, ref, loading }) => {
    return (
        <div data-tut="reactour__addWord" style={{ display: "flex", alignItems: "center" }}>
            <Paper
                data-tut="reactour__addWordField"
                square
                component="form"
                sx={{ padding: "2px 4px", display: "flex", alignItems: "center", width: "438px" }}
            >
                {/* Searchicon */}
                <Search style={{ margin: "10px 0px 10px 6px", color: "#707070" }} />

                {/* Inputfield query */}
                <InputBase
                    sx={{ marginLeft: (theme) => theme.spacing(1), flex: 1 }}
                    ref={ref}
                    placeholder="Zoeken..."
                    size="small"
                    value={queryToShow}
                    onChange={handleSearchChange}
                    autoFocus
                    fullWidth
                    // pass data to Row component below
                    inputProps={{
                        sx: { fontSize: "14px" },
                    }}
                />
                {loading && <CircularProgress color="inherit" size={14} sx={{ marginRight: "8px" }} />}

                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                {/*
                 * RESET BUTTON
                 */}
                <Tooltip placement="right" title="Zoekopdracht verwijderen" disableInteractive>
                    <IconButton
                        disabled={loading}
                        color="primary"
                        size="small"
                        aria-label="directions"
                        onClick={(e) => {
                            e.stopPropagation();
                            reset();
                        }}
                    >
                        <Clear sx={{ color: query === "" ? "#b3b3b3" : "error.main" }} />
                    </IconButton>
                </Tooltip>
            </Paper>
        </div>
    );
};

export default Searchbar;
