import React, { useState } from "react";
import LoginHeader from "../components/login/LoginHeader";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../components/login/LanguageSelector";
import { TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useMutation } from "@apollo/client";
import { requestNewPassword, requestNewPasswordVariables } from "../__generated__/requestNewPassword";
import { useNavigate } from "react-router-dom";
import { REQUEST_NEW_PASSWORD } from "../graphql/mutationDefinitions";
import DOMPurify from "dompurify";
import { sanitizeInput } from "../utils/sanitizeInput";

const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));
interface Props {
    a?: number;
}

const styles = {
    h1: {
        marginBottom: 6,
        fontSize: 18,
    },

    spacer: {
        height: 20,
    },
};

const ForgotPassword: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [success, setSuccess] = useState<string | null | undefined>("");

    /**
     * mutation to request a new password
     */
    const [passwordRequest] = useMutation<requestNewPassword, requestNewPasswordVariables>(REQUEST_NEW_PASSWORD);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (email) {
            try {
                setLoading(true);

                await passwordRequest({
                    variables: {
                        input: {
                            email: email,
                        },
                    },
                });
                setSuccess("E-mail is verzonden");
                await sleep(2500);
                navigate("/login");
            } catch (e) {
                setError(t("auth.forgot-password.error"));
            } finally {
                setLoading(false);
                setEmail("");
                setSuccess("");
            }
        } else {
            setError(t("auth.forgot-password.please-enter-email"));
        }
    };

    return (
        <div className="ForgotPassword auth-form-wrapper">
            <div className="auth-form-container">
                <LoginHeader />
                {success ? (
                    <Typography style={{ marginBottom: 6, fontSize: 18, textAlign: "center" }}>{`E-mail is verzonden naar ${email}`}</Typography>
                ) : (
                    <Typography style={{ marginBottom: 6, fontSize: 18 }}>{t("auth.forgot-password.title")}</Typography>
                )}
                {!success && (
                    <React.Fragment>
                        <p>{t("auth.forgot-password.instructions")}</p>

                        <form onSubmit={onSubmit}>
                            <TextField
                                type="email"
                                label={t("auth.email")}
                                value={email}
                                onChange={(e) => {
                                    const inputvalue = sanitizeInput(e.target.value, false);
                                    const val = DOMPurify.sanitize(inputvalue);
                                    setEmail(val);
                                }}
                                autoFocus
                                fullWidth
                                variant="outlined"
                                disabled={loading}
                            />
                            <div style={styles.spacer} />
                            <Button color="primary" variant="contained" size="large" type="submit" fullWidth disabled={loading || email === ""}>
                                {loading ? "Laden..." : t("auth.forgot-password.button-request")}
                            </Button>
                            {error && (
                                <div className="auth-form-error" style={{ marginBottom: 0, marginTop: 8 }}>
                                    {error}
                                </div>
                            )}
                        </form>
                    </React.Fragment>
                )}
                <LanguageSelector />
            </div>
        </div>
    );
};

export default ForgotPassword;
