import React from "react";
import { GetTenderDocsWithPagination_tenderDocuments } from "../../../__generated__/GetTenderDocsWithPagination";
// import _ from "lodash";
import TreeItem from "./TreeItem";
import DocBreadCrumbs from "./DocBreadCrumbs";
import { CircularProgress, ListItem } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";

interface Props {
    data: GetTenderDocsWithPagination_tenderDocuments;
    onDirectoryClick: (folder: string) => void;
    folder: string;
    setFolder: (folder: string) => void;
    date: any;
    tender_id: string;
    loading?: boolean;
    setCount: React.Dispatch<React.SetStateAction<number>>;
    count: number;
    onFileClick?: (folder: number) => void;
    relevantDocumentIds?: number[];
    onFileClickData?: (folder: { id: any; label: any; link: any }) => void;
    height?: string;
    downloadable: boolean;
}

export interface TenderDoc {
    __typename: "DocumentFile" | "DocumentFolder";
    id: number | null;
    label: string | null;
    link: string | null;
    class: string | null;
    date: any | null;
    lot: number | null;
    // download_link: string | null;
}

const ListOfDocs: React.FC<Props> = ({
    data,
    onDirectoryClick,
    folder,
    setFolder,
    date,
    tender_id,
    loading,
    setCount,
    count,
    onFileClick,
    relevantDocumentIds,
    onFileClickData,
    downloadable,
    height = "260px",
}) => {
    // const keys = [...[data.files], ...[data.folders]];
    // const dates = _.chain(keys.flatMap((item) => item?.map((i) => i)))
    //     .groupBy("date")
    //     .map((value, key) => ({ date: key, docs: value }))
    //     .value();

    const files = data.files as any;
    const folders = data.folders as any;

    /**
     * Combine files and folders in one array
     */
    const allItems = [...files, ...folders];

    // create a new array with new interface and definitons of props
    const docList: TenderDoc[] =
        allItems.map((a) => ({
            /**
             * file id (undefined if its a folder)
             */
            id: a?.id,
            /**
             * label of folder or file
             */
            label: a?.label,
            /**
             * 'DocumentFolder' or 'DocumentFile'
             */
            __typename: a?.__typename,
            /**
             * link to file (undefined if its a folder)
             */
            link: a?.link,
            /**
             * icon of file (undefined if its a folder)
             */
            class: a?.class,
            /**
             * date of file or folder
             */
            date: a?.date,
            lot: a?.lot?.number,
        })) || [];

    /**
     * get folder string and split on "/"
     * each item is a foldername
     * now possible to map over folders and make them clickable
     */
    const pathParts = folder.split("/").filter((part) => part?.trim() !== "");

    // TODO: Change dates and items to the new items created props

    return (
        <div style={{ maxHeight: height, overflow: "auto" }}>
            <React.Fragment>
                {/* DocumentGroup: accordion forEach date in arra with amount of documents in accordion,
                 * first accordion is expanded by default!
                 * TODO: add amount of documents in accordion for the whole tree of a date instead of first layer
                 */}{" "}
                <div>
                    {/* If pathParts is > 0 means that user clickad a folder
                     * on folder click => show breadcrumbs
                     * all items except last one are clickable to navigate to that folder
                     * */}
                    <DocBreadCrumbs pathParts={pathParts} setFolder={setFolder} folder={folder} tender_id={tender_id} downloadable={downloadable} />

                    {/*
                     * TreeItem contains folders and files
                     * if folders.length = 0 => show files
                     */}
                    {docList.length >= 10 ? (
                        <div id="scrollableDiv" style={{ maxHeight: height, overflow: "auto" }}>
                            <InfiniteScroll
                                // style={{ width: "100%" }}
                                height={height}
                                /**
                                 * Scroll if 98% is scrolled
                                 */
                                scrollThreshold={0.7}
                                /**
                                 * id of menulist to target the menu as window instead of whole window
                                 */
                                scrollableTarget="scrollableDiv"
                                /**
                                 * This is important field to render the next data.
                                 */
                                dataLength={docList.length} //This is important field to render the next data
                                /**
                                 * function to get next data
                                 */
                                next={() => setCount(count + 25)}
                                /**
                                 * boolean to check if more data is available
                                 */
                                hasMore={true}
                                /**
                                 * loader component
                                 */
                                loader={<></>}
                                /**
                                 * message when bottom is reached
                                 */
                                endMessage={<p style={{ textAlign: "center" }} />}
                            >
                                <TreeItem
                                    downloadable={downloadable}
                                    docList={docList.filter((i) => i.date === date)}
                                    onDirectoryClick={onDirectoryClick}
                                    tender_id={tender_id}
                                    onFileClick={onFileClick}
                                    relevantDocumentIds={relevantDocumentIds}
                                    onFileClickData={onFileClickData}
                                />
                            </InfiniteScroll>
                        </div>
                    ) : (
                        <>
                            {loading ? (
                                <ListItem>
                                    <CircularProgress color="primary" style={{ width: 24, height: 24 }} />
                                </ListItem>
                            ) : (
                                <TreeItem
                                    downloadable={downloadable}
                                    onFileClickData={onFileClickData}
                                    relevantDocumentIds={relevantDocumentIds}
                                    onFileClick={onFileClick}
                                    docList={docList.filter((i) => i.date === date)}
                                    onDirectoryClick={onDirectoryClick}
                                    tender_id={tender_id}
                                />
                            )}
                        </>
                    )}
                </div>
            </React.Fragment>
        </div>
    );
};

export default ListOfDocs;
