import React from "react";
import { Button, Chip, Container, Grid, ListSubheader, Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import { useArticleFilter } from "../../components/contextProviders/ArticleFilterContext";
import { Tune } from "@mui/icons-material";

interface Props {
    a?: number;
}

const subcategories = [
    { id: "general", type: "category", text: "Algemeen" },
    { id: "tenders", type: "category", text: "Aanbestedingen" },
    { id: "contracting_authorities", type: "category", text: "Aanbestedende diensten" },
    { id: "organizations", type: "category", text: "Marktpartijen" },
    { id: "opportunities", type: "category", text: "Kansen" },
    { id: "dashboard", type: "category", text: "Dashboard" },
    { id: "projects", type: "category", text: "Projecten" },
    { id: "personal", type: "category", text: "Persoonlijk" },
    { id: "users_teams", type: "category", text: "Gebruikers en teams" },
    { id: "searches", type: "category", text: "Zoekregels" },
    { id: "preferences", type: "category", text: "Voorkeuren" },
    { id: "logbook", type: "category", text: "Logboek" },
    { id: "notifications", type: "category", text: "Meldingen" },
    { id: "calender", type: "category", text: "Agenda" },
];

const FaqFilters: React.FC<Props> = () => {
    const { setArticles, module, setModule, setPage } = useArticleFilter();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const aboveLG = useMediaQuery(theme.breakpoints.up("md"));

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Function to handle toggle logic
    const toggleSubcategory = (currentSubcategory: string | null, subcategoryId: string): void => {
        setArticles([]);
        setPage(1);
        // If the current subcategory is already active, set to null; else set to the clicked subcategory
        const newSubcategory = currentSubcategory === subcategoryId ? null : subcategoryId;
        setModule(newSubcategory); // Call the onChange function passed as a prop
    };

    const ITEM_HEIGHT = 48;

    if (isMobile) {
        return (
            <Container maxWidth="md" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div>
                    <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        sx={{ margin: "16px 0px" }}
                        startIcon={<Tune />}
                    >
                        filter
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            style: {
                                paddingTop: 0, // Remove top padding from the list
                                paddingBottom: 0, // Remove bottom padding from the list
                                lineHeight: 40,
                            },
                        }}
                        slotProps={{
                            paper: {
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: "20ch",
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                },
                            },
                        }}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        <ListSubheader>Subcategorie</ListSubheader>
                        {subcategories.map((cat, i) => (
                            <Grid item key={cat.id}>
                                <MenuItem
                                    dense
                                    key={i}
                                    selected={module === cat.id}
                                    onClick={() => {
                                        if (module === cat.id) {
                                            setModule(null);
                                            setArticles([]);
                                            setPage(1);
                                        } else toggleSubcategory(module, cat.id);
                                    }}
                                >
                                    {cat.text}
                                </MenuItem>
                            </Grid>
                        ))}
                    </Menu>
                </div>
            </Container>
        );
    }

    return (
        <Container
            maxWidth="lg"
            sx={{
                padding: aboveLG ? "0px 128px !important" : 0,
                margin: aboveLG ? "32px 16px" : "32px 16px",
            }}
        >
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                {subcategories.map((cat, i) => (
                    <Grid item key={cat.id}>
                        <Chip
                            key={i}
                            label={cat.text}
                            onClick={() => toggleSubcategory(module, cat.id)}
                            color={module === cat.id ? "primary" : "default"}
                            onDelete={
                                module === cat.id
                                    ? () => {
                                          setModule(null);
                                          setArticles([]);
                                          setPage(1);
                                      }
                                    : undefined
                            } // Set to null when delete icon clicked
                        />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default FaqFilters;
