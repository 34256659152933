import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Paper, Typography } from "@mui/material";
import SectorIconLoader from "../../../../components/boxes/SectorIconLoader";
import { useTenderSearchContext } from "../../../../components/guides/tenderWizard/WizardContext";
import { FilterWithValues } from "../FilterModal";

interface Props {
    sectorValues: FilterWithValues[];
    subsectorValues: FilterWithValues[];
}

const Sectorfilter: React.FC<Props> = (props) => {
    const { sector, setSector, checkedSub, setCheckedSub, filters, setFilters, sectorList } = useTenderSearchContext(); // Use the context hook to access context value and function

    const [pendingFilters, setPendingFilters] = useState(filters);

    // Update filters
    useEffect(() => {
        const updatedFilters = { ...pendingFilters };

        // Update the sectors filter if needed
        if (sector.length > 0) {
            updatedFilters.sectors = sector;
        } else {
            delete updatedFilters.sectors;
        }

        // Update the subSectors filter if needed
        if (checkedSub.length > 0) {
            updatedFilters.subSectors = checkedSub;
        } else {
            delete updatedFilters.subSectors;
        }

        // Set the filters only if they have changed
        if (JSON.stringify(updatedFilters) !== JSON.stringify(pendingFilters)) {
            setPendingFilters(updatedFilters);
            setFilters(updatedFilters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sector, checkedSub, filters, setFilters]);

    // Check if array includes all items of other items
    const checker = (arr: any, target: any) => target.every((v: any) => arr.includes(v));

    // Function to handle click on parent
    const handleClickParent = (parentId: string) => {
        setCheckedSub((prevClickedChildIds) => {
            const newClickedChildIds = [...prevClickedChildIds];
            // Remove child IDs associated with this parent from clickedChildIds
            const parent = sectorList.find((parent) => parent?.id === parentId);
            parent?.children?.forEach((child) => {
                const index = newClickedChildIds.indexOf(parseInt(child.id));
                if (index !== -1) {
                    newClickedChildIds.splice(index, 1);
                }
            });

            return newClickedChildIds;
        });

        setSector((prevClickedParentIds) => {
            const newClickedParentIds = [...prevClickedParentIds];
            const index = newClickedParentIds.indexOf(parseInt(parentId));
            if (index !== -1) {
                newClickedParentIds.splice(index, 1);
            } else {
                newClickedParentIds.push(parseInt(parentId));
            }

            return newClickedParentIds;
        });
    };

    // Function to handle click on child
    const handleClickChild = (childId: string) => {
        // Find the parent of the clicked child
        const childParent = sectorList.find((parent) => parent?.children?.some((child) => child.id === childId));
        if (!childParent) return;

        setCheckedSub((prevClickedChildIds) => {
            // Toggle the clicked child's ID in the array of checked child IDs
            const newClickedChildIds = prevClickedChildIds.includes(parseInt(childId))
                ? prevClickedChildIds.filter((id) => id !== parseInt(childId))
                : [...prevClickedChildIds, parseInt(childId)];

            // Get all child IDs of the parent
            const allChildIds = childParent?.children?.map((child) => parseInt(child.id));
            // Filter out clicked child IDs that are present in all child IDs
            const clickedChildIds = newClickedChildIds.filter((id) => allChildIds?.includes(id));

            // set chosen subsectors

            // Check if all children are clicked
            if (clickedChildIds.length === allChildIds?.length) {
                // If all children are clicked, add the parent to the list of clicked parent IDs
                setSector((prevClickedParentIds) => {
                    if (!prevClickedParentIds.includes(parseInt(childParent.id))) {
                        const newSet = [...prevClickedParentIds, parseInt(childParent.id)];
                        return newSet;
                    }
                    return prevClickedParentIds;
                });

                return newClickedChildIds;
            } else {
                // If not all children are clicked, remove the parent from the list of clicked parent IDs
                setSector((prevClickedParentIds) => prevClickedParentIds.filter((id) => id !== parseInt(childParent.id)));
                return newClickedChildIds;
            }
        });
    };

    return (
        <>
            <Paper square elevation={0} sx={{ padding: "16px" }}>
                <Box>
                    {/* Textual helper for user */}
                    <List
                        dense
                        sx={{
                            paddingTop: 0,
                            width: "100%",
                            height: "100%",
                            overflow: "auto",
                            backgroundColor: (theme) => theme.palette.background.paper,
                        }}
                    >
                        {sectorList.map((value, index) => {
                            const labelId = `checkbox-list-secondary-label-${value}`;
                            const btnLabel = checker(
                                checkedSub,
                                value?.children?.map((i) => parseInt(i.id))
                            );

                            const count = checkedSub.filter((i) => value?.children?.map((i) => parseInt(i.id)).includes(i)).length;

                            return (
                                <React.Fragment key={value?.id}>
                                    <ListSubheader sx={{ display: "flex" }}>
                                        <span>{value?.name}</span>
                                        <FormControlLabel
                                            sx={{ marginLeft: "16px" }}
                                            label=""
                                            control={
                                                <Checkbox
                                                    checked={sector.find((i) => i === parseInt(value?.id as string)) || btnLabel}
                                                    indeterminate={count > 0 && count !== value?.children?.length}
                                                    onChange={() => {
                                                        handleClickParent(value?.id as string);
                                                    }}
                                                />
                                            }
                                        />
                                    </ListSubheader>
                                    {value?.children?.map((c) => {
                                        return (
                                            <ListItemButton key={c.id} onClick={() => handleClickChild(c.id)}>
                                                <ListItemText
                                                    inset
                                                    id={labelId}
                                                    primary={
                                                        <Typography style={{ display: "flex", alignItems: "center" }}>
                                                            <div style={{ marginRight: 12, display: "flex" }}>
                                                                <SectorIconLoader sector_id={c.id} />{" "}
                                                            </div>{" "}
                                                            {c.name}
                                                        </Typography>
                                                    }
                                                />
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={
                                                            checkedSub.includes(parseInt(c.id)) ||
                                                            Boolean(sector.find((i) => i === parseInt(value?.id as string)))
                                                        }
                                                        inputProps={{ "aria-labelledby": labelId }}
                                                    />
                                                </ListItemIcon>
                                            </ListItemButton>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </List>
                </Box>
            </Paper>
        </>
    );
};

export default Sectorfilter;
