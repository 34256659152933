import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { defaultMentionStyle, defaultStyle } from "./Notes";
import {
    single_project_notes_project_comments_replies,
    single_project_notes_project_comments_user,
} from "../../../../__generated__/single_project_notes";
import ReplyList from "./ReplyList";
import { Clear, Close, Edit, Reply } from "@mui/icons-material";
import EditExistingComment from "./EditExistingComment";
import { UPDATE_COMMENT_IN_PROJECT } from "../../../../graphql/mutationDefProjects";
import { updateComment, updateCommentVariables } from "../../../../__generated__/updateComment";
import { useMutation } from "@apollo/client";
import { SINGLE_PROJECT, SINGLE_PROJECT_NOTES } from "../../../../graphql/queryDefProjects";
import { Disable } from "react-disable";
import { useTranslation } from "react-i18next";
import { parseComment } from "../../../../hooks/commentParser";
import MentionButton from "./MentionButton";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../../models/MatomoEvent";
import { sanitizeInput } from "../../../../utils/sanitizeInput";

interface Props {
    initials: string;
    comment: string;
    user: single_project_notes_project_comments_user;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    commentValue: string;
    setCommentValue: (str: string) => void;
    replyToCommentAndReset: () => void;
    mentionList: { id: string; display: string }[];
    replies: (single_project_notes_project_comments_replies | null)[] | null;
    comment_id: string;
    owner: string;
    showReply: boolean;
    setShowReply: (bln: boolean) => void;
    onDelete: () => void;
    loadDelete: boolean;
    userRemovedFromProject: boolean;
}

const SingleComment: React.FC<Props> = ({
    userRemovedFromProject,
    user,
    initials,
    comment,
    comment_id,
    created_at,
    updated_at,
    deleted_at,
    commentValue,
    setCommentValue,
    replyToCommentAndReset,
    mentionList,
    replies,
    showReply,
    onDelete,
    setShowReply,
    loadDelete,
    owner,
}) => {
    const { trackEvent } = useMatomo();
    const trackClick = (event: MatomoEvent) => {
        trackEvent(event);
    };
    const [editMode, setEditMode] = useState<boolean>(false);
    const [hoverState, setHoverState] = useState<string | boolean>(false);
    const [updateComment, { loading }] = useMutation<updateComment, updateCommentVariables>(UPDATE_COMMENT_IN_PROJECT);
    const { t } = useTranslation();

    /**
     * Create new note
     * @param taskId id of task
     * @param priority priority of task
     */
    const onSaveNewComment = async (str: string) => {
        if (str.length > 2) {
            try {
                updateComment({
                    variables: {
                        comment_id: comment_id,
                        comment: str,
                    },

                    refetchQueries: [SINGLE_PROJECT, SINGLE_PROJECT_NOTES],
                });
            } catch (e) {}
        }
    };

    const text = parseComment(comment);

    const textToShowOnDelete = comment === "valIsDeleted" ? `${t("valIsDeleted")} ${user.employee.name}` : text;

    // Mention button
    const handleAddAtSymbol = () => {
        setCommentValue(commentValue + " @");
        trackClick({ category: "Project", action: "Use-mention-button" });

        const textField = document.getElementById(`showReply${comment_id}`);
        if (textField) {
            textField.focus();
        }
    };

    return (
        <div>
            <Disable disabled={loading || comment === "valIsDeleted" || loadDelete || userRemovedFromProject}>
                <ListItem
                    dense
                    disablePadding
                    disableGutters
                    onMouseEnter={() => setHoverState(comment_id)}
                    onMouseLeave={() => setHoverState(false)}
                >
                    <ListItemAvatar sx={{ minWidth: 0 }}>
                        <Avatar
                            sx={{
                                width: "28px",
                                height: "28px",
                                marginRight: "12px",
                            }}
                        >
                            {/*
                             *
                             */}
                            <Typography sx={{ fontSize: "0.8rem" }}>{initials}</Typography>
                        </Avatar>
                    </ListItemAvatar>
                    {/* Note
                     * primary: Text with highlighting if user is mentioned
                     * secondary: Timestamp
                     */}
                    {editMode ? (
                        <EditExistingComment
                            currentComment={comment}
                            updateAndReset={(str) => {
                                onSaveNewComment(str);
                                trackClick({ category: "Project", action: "Update-existing-comment" });
                                setEditMode(false);
                            }}
                            mentionList={mentionList}
                            cancelEdit={setEditMode}
                        />
                    ) : (
                        <ListItemText
                            primary={
                                <Typography
                                    sx={{ marginBottom: "-4px" }}
                                    dangerouslySetInnerHTML={{
                                        __html: textToShowOnDelete.replace(/\n\r?/g, "<br />"),
                                    }}
                                />
                            }
                            secondary={
                                <div style={{ display: "flex" }}>
                                    <Typography variant="caption" sx={{ color: "#707070", fontSize: "0.65rem" }}>
                                        {comment === "valIsDeleted"
                                            ? `${t("Deleted")}: ${moment(deleted_at).format("LLL")}`
                                            : updated_at !== created_at
                                            ? `${t("Changed")}: ${moment(updated_at).format("LLL")}`
                                            : moment(created_at).format("LLL")}
                                    </Typography>
                                    {hoverState === comment_id && (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Tooltip disableInteractive placement="right" title={showReply ? "Annuleren" : "Beantwoorden"}>
                                                <IconButton
                                                    onClick={() => {
                                                        setShowReply(!showReply);
                                                    }}
                                                    sx={{ padding: 0, margin: "0px 8px" }}
                                                    size="small"
                                                >
                                                    <Reply sx={{ height: "16px", width: "16px" }} />
                                                </IconButton>
                                            </Tooltip>

                                            {/* editmode */}
                                            {owner === localStorage.getItem("user_id") && (
                                                <Tooltip disableInteractive placement="right" title={editMode ? "Annuleren" : "Bewerken"}>
                                                    <IconButton
                                                        onClick={() => {
                                                            setEditMode(!editMode);
                                                        }}
                                                        sx={{ padding: 0, marginRight: "8px" }}
                                                        size="small"
                                                    >
                                                        <Edit sx={{ height: "16px", width: "16px" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            )}

                                            {/* delete */}
                                            {owner === localStorage.getItem("user_id") && (
                                                <Tooltip disableInteractive placement="right" title={"Verwijderen"}>
                                                    <IconButton
                                                        onClick={() => {
                                                            onDelete();
                                                        }}
                                                        sx={{ padding: 0 }}
                                                        size="small"
                                                    >
                                                        <Close sx={{ height: "16px", width: "16px" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </div>
                                    )}
                                </div>
                            }
                        />
                    )}
                </ListItem>
            </Disable>

            {showReply && (
                <div>
                    <div style={{ width: "100%", paddingLeft: "40px", display: "flex", alignItems: "center" }}>
                        <MentionsInput
                            id={`showReply${comment_id}`}
                            singleLine
                            style={defaultStyle}
                            placeholder="Reageren..."
                            value={commentValue}
                            onChange={(e) => {
                                const sanitizedValue = sanitizeInput(e.target.value, true);
                                setCommentValue(sanitizedValue);
                            }}
                            onKeyPress={(event) => {
                                if (event.key === "Enter" && commentValue.length > 0) {
                                    replyToCommentAndReset();
                                }
                            }}
                        >
                            <Mention style={defaultMentionStyle} trigger="@" data={mentionList} appendSpaceOnAdd />
                        </MentionsInput>
                        {/* cancel */}
                        <Tooltip title="Annuleren" placement="top">
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setShowReply(false);
                                    setCommentValue("");
                                }}
                            >
                                <Clear fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "40px", height: "8px" }} />
                        <MentionButton handleAddAtSymbol={handleAddAtSymbol}>@</MentionButton>
                    </div>
                </div>
            )}
            <div style={{ marginLeft: "40px" }}>
                <ReplyList replyArr={replies} mentionList={mentionList} />
            </div>
        </div>
    );
};

export default SingleComment;
