import { gql } from "@apollo/client";

// ? get conversationgroup of current user
export const QUERY_CONVERSATION_GROUP = gql`
    query GetConversationGroup($id: ID!, $modelType: String!) {
        conversationGroup(id: $id, modelType: $modelType) {
            id
            conversations {
                id: hashid
                unreadChatMessages {
                    id
                }
                team {
                    id
                    name
                }
                users {
                    id
                    employee {
                        id
                        givenname
                        familyname
                    }
                }
                chatMessages: chat_messages {
                    id
                    message
                    send_at
                    from {
                        id
                        name
                    }
                }
            }
        }
    }
`;

// ? get all colleagues of current user
export const QUERY_COLLEAGUES = gql`
    query GetColleagues {
        currentUser {
            id
            employee {
                id
                organization {
                    id
                    teams {
                        id
                        name
                        users {
                            id
                            email
                            employee {
                                id
                                familyname
                                givenname
                            }
                        }
                    }
                }
            }
        }
    }
`;

// ? get all colleagues of current user
export const QUERY_CA_OF_TENDERCHAT_TO_OPEN = gql`
    query GetCaOfTenderChatToOpen($tender_id: ID!) {
        tender(id: $tender_id) {
            id
            clients {
                id
                name
                pivot {
                    category
                }
            }
            published_opdrachtgever
        }
    }
`;

export const QUERY_CA_OF_OPPORTUNITY_TO_OPEN = gql`
    query GetCaOfOpportunityToOpen($id: ID!) {
        opportunityFile(id: $id) {
            id
            # AD data
            contractingAuthority {
                id
                name
            }
        }
    }
`;

// ? get clicked conversation
export const QUERY_CONVERSATION = gql`
    query GetConversation($id: ID!) {
        conversation(id: $id) {
            unreadChatMessages {
                id
            }
            chatMessages: chat_messages {
                id
                message
                sender_deleted
                from {
                    id
                    name
                    email
                    employee {
                        id
                        givenname
                        middlename
                        familyname
                    }
                }
                send_at
            }
        }
    }
`;

export const SENDTENDERMESSAGE = gql`
    mutation SendChatMessage($message: String!, $conversation: ID, $to_user: ID, $to_team: ID, $conversation_group_id: ID) {
        sendMessage(
            message: $message
            conversation: $conversation
            to_user: $to_user
            to_team: $to_team
            conversation_group_id: $conversation_group_id
        ) {
            id: hashid
            chatMessages: chat_messages {
                id
                message
                sender_deleted
                from {
                    id
                    name
                    email
                    employee {
                        id
                        givenname
                        middlename
                        familyname
                    }
                }
                send_at
            }
            unreadChatMessages {
                id
                message
            }
        }
    }
`;

export const MARKMESSAGEASREAD = gql`
    mutation setChatMessageAsRead($message_id: ID, $conversation_id: ID) {
        setChatMessageRead(message_id: $message_id, conversation_id: $conversation_id)
    }
`;
