import React, { useContext } from "react";
import SingleReply from "./SingleReply";
import moment from "moment";
import { ProjectToOpenContext } from "../../../../components/layout/MainLayout";
import { Disable } from "react-disable";

interface Props {
    replyArr: any;
    mentionList: { id: string; display: string }[];
}

const ReplyList: React.FC<Props> = ({ replyArr, mentionList }) => {
    const { project } = useContext(ProjectToOpenContext);
    const replies = replyArr ? [...replyArr] : [];

    const userExists = (userId: string) => {
        return project.users.some((user) => user.id === userId);
    };

    return (
        <React.Fragment>
            {replies
                .sort((a, b) => (moment(b?.created_at ? b.created_at : "").isBefore(moment(a?.created_at ? a.created_at : "")) ? -1 : 1))
                .map((reply, i) => {
                    if (reply && reply.replies.length > 0) {
                        return (
                            <Disable key={reply.id} disabled={Boolean(reply.user_deleted)}>
                                <div key={reply.id}>
                                    <SingleReply
                                        project_id={project.projectID}
                                        owner={reply.user.id}
                                        updated_at={reply.updated_at}
                                        deleted_at={reply.deleted_at}
                                        user={reply.user}
                                        reply_id={reply.id}
                                        comment={reply.comment}
                                        created_at={reply.created_at}
                                        mentionList={mentionList}
                                        replies={reply.replies}
                                        userRemoved={!userExists(reply.user.id)}
                                        initials={`${reply.user.employee.name.slice(0, 1)}${reply.user.employee.familyname.slice(0, 1)}`}
                                    />
                                    <div
                                        style={{
                                            paddingLeft: "8px",
                                            marginLeft: "13px",
                                            borderLeft: replies.length - 1 !== i ? "1px solid #e1e1e1" : undefined,
                                        }}
                                    >
                                        <ReplyList replyArr={reply.replies} mentionList={mentionList} />
                                    </div>
                                </div>
                            </Disable>
                        );
                    }
                    if (reply !== null) {
                        return (
                            <SingleReply
                                project_id={project.projectID}
                                owner={reply.user.id}
                                reply_id={reply.id}
                                key={reply.id}
                                initials={`${reply.user.employee.name.slice(0, 1)}${reply.user.employee.familyname.slice(0, 1)}`}
                                comment={reply.comment}
                                created_at={reply.created_at}
                                mentionList={mentionList}
                                updated_at={reply.updated_at}
                                deleted_at={reply.deleted_at}
                                user={reply.user}
                                userRemoved={!userExists(reply.user.id)}
                                replies={null}
                            />
                        );
                    }
                    return <></>;
                })}
        </React.Fragment>
    );
};

export default ReplyList;
