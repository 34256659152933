import React, { useState, useEffect } from "react";

// Import Material UI
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

// Import components
// import TenderOverview from "./OverviewModule/TenderOverview";
// import Mapview from "./MapViewModule/Mapview";
import OrgTenderFullList from "./ListViewModule/OrgTenderFullList";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import WidgetBox from "../../../../components/WidgetBox";
import { SortOrder, TenderFilterInput, TenderFilterSource, TenderFilterTarget, TenderSearchInput } from "../../../../__generated__/globalTypes";
import TenderSearchField from "../../../../components/input/TenderSearchField";
import SplitView from "../../../tenders/splitview/SplitView";
import {
    FilterWorklistSplitView,
    FilterWorklistSplitViewVariables,
    FilterWorklistSplitView_filterTenders_results_data,
} from "../../../../__generated__/FilterWorklistSplitView";
import { FilterWorklistOverview_filterTenders_results_paginatorInfo } from "../../../../__generated__/FilterWorklistOverview";
import OrgTenderFilterBar from "./OrgTenderFilterBar";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import { DateRange } from "../../../../components/FilterDrawer/TopFilterBar";
import { Disable } from "react-disable";
import {
    FilterTendersOverviewOnMarketPartyPageMinimum,
    FilterTendersOverviewOnMarketPartyPageMinimumVariables,
    FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_filters,
    FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_results,
} from "../../../../__generated__/FilterTendersOverviewOnMarketPartyPageMinimum";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../../models/MatomoEvent";
import { QUERY_TENDERS_OF_MP_WITH_FILTER_MINIMUM, QUERY_WORKLIST_SPLIT } from "../../../../graphql/queryDefinitions";
import SortingChanger, { defaultTenderSortingOptions, getFilterValue } from "../../../../components/tableComponents/SortingChanger";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface Props {
    org_id: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`${index}`}>
            {value === index && <Box p={0}>{children}</Box>}
        </div>
    );
}

const filters: TenderFilterInput = {};
const defaultSearch: TenderSearchInput = {
    source: TenderFilterSource.ALL_TENDERS,
    target: TenderFilterTarget.SUMMARY,
    filters,
};
const defaultVariables: FilterTendersOverviewOnMarketPartyPageMinimumVariables = {
    page: 1,
    count: 10,
    orderBy: [{ column: "updated_at", order: SortOrder.DESC }],
    search: defaultSearch,
};

const TenderTabMenuModule: React.FC<Props> = ({ org_id }) => {
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [value, setValue] = useState(0);
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();
    // setvalue to clicked value, value is number of tab that is clicked
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    /**
     * Initialize the state for `orderBy` with a default sorting condition
     */
    const [orderBy, setOrderBy] = useState(defaultVariables.orderBy);
    const [page, setPage] = useState(defaultVariables.page);
    const [rowsPerPage, setRowsPerPage] = useState(defaultVariables.count);
    const [searchInput, setSearchInput] = useState<TenderFilterInput>({ contractors: [parseInt(org_id)] });
    const [nextEndDateContract, setNextEndDateContract] = useState<DateRange>({ from: null, to: null });
    const [endDateInc, setEndDateInc] = useState<DateRange>({ from: null, to: null });
    const [selectedId, setSelectedId] = useState<string>("");

    const [
        source,
        // setSource
    ] = useState<TenderFilterSource>(defaultSearch.source);
    const [query, setQuery] = useState<string>("");
    const [target, setTarget] = useState<TenderFilterTarget>(defaultSearch.target);
    const [filterData, setFilterData] = useState<FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_filters[] | null>([]);

    const [tendersData, setTendersData] = useState<FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_results | null>();

    // array of tenders based on active filter(s) -> splitview
    const [tendersDataSplit, setTendersDataSplit] = useState<FilterWorklistSplitView_filterTenders_results_data[] | null>();
    // paginator info
    const [filteredPagination, setFilteredPagination] = useState<FilterWorklistOverview_filterTenders_results_paginatorInfo>();

    // Variables for the GQL query
    const variables: FilterTendersOverviewOnMarketPartyPageMinimumVariables = {
        page: page,
        count: rowsPerPage,
        orderBy: orderBy,
        search: {
            source: source,
            target: target,
            query: query,
            filters: searchInput,
        },
    };

    // Track event
    const trackOrgTenderTab = (event: MatomoEvent) => {
        trackEvent(event);
    };

    useEffect(() => {
        // listview settings
        if (value === 0) {
            setPage(1);
            setRowsPerPage(10);
        }
        // splitview settings
        if (value === 1) {
            setPage(1);
            setRowsPerPage(20);
        }
    }, [value]);

    /**
     * Define the GQL query
     */
    const [run, { loading, data: dataList }] = useLazyQuery<
        FilterTendersOverviewOnMarketPartyPageMinimum,
        FilterTendersOverviewOnMarketPartyPageMinimumVariables
    >(QUERY_TENDERS_OF_MP_WITH_FILTER_MINIMUM, {
        variables: variables,
        fetchPolicy: "network-only",
    });

    // Define the GQL query splitlist
    const [runSplit, { error: errorSplit, loading: loadingSplit, data: dataSplit }] = useLazyQuery<
        FilterWorklistSplitView,
        FilterWorklistSplitViewVariables
    >(QUERY_WORKLIST_SPLIT, {
        variables: variables,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (value === 0) {
            if (dataList && dataList.filterTenders) {
                setFilterData(dataList.filterTenders.filters);
                setTendersData(dataList.filterTenders.results);
            }
        }
        if (value === 1) {
            if (dataSplit && dataSplit.filterTenders !== null && dataSplit.filterTenders.results !== null) {
                setFilterData(dataSplit.filterTenders.filters);
                setTendersDataSplit(dataSplit.filterTenders.results.data);
                setFilteredPagination(dataSplit.filterTenders.results.paginatorInfo);
                if (selectedId === "" && dataSplit.filterTenders.results.data.length > 0) {
                    setSelectedId(dataSplit.filterTenders.results.data[0].id);
                }
            }
        }
    }, [dataList, dataSplit, selectedId, value]);

    /**
     * Run lazyquery based on view
     */
    useEffect(() => {
        // listview
        if (value === 0) {
            run({
                variables: {
                    page: page,
                    count: rowsPerPage,
                    orderBy: orderBy,
                    search: {
                        source: source,
                        target: target,
                        query: query,
                        filters: searchInput,
                    },
                },
            });
        }
        // splitview
        if (value === 1) {
            runSplit({
                variables: {
                    page: page,
                    count: rowsPerPage,
                    orderBy: orderBy,
                    search: {
                        source: source,
                        target: target,
                        query: query,
                        filters: searchInput,
                    },
                },
            });
        }
    }, [run, runSplit, page, rowsPerPage, searchInput, query, target, source, t, value, orderBy]);

    /**
     * Handle pagination change: -page change, -rows per page change
     */
    const handlePaginationChange = (page: number, rowsPerPage: number) => {
        setPage(page);
        setRowsPerPage(rowsPerPage);
    };

    /**
     * find filtername of columnheader
     * @param column column header
     * @param direction direction to sort
     */
    const handleTableSort = (column: string, direction: SortOrder) => {
        const col = getFilterValue(column, defaultTenderSortingOptions) as string;

        handleSortChange(col, direction);
    };

    const handleSortChange = (column: string, order: SortOrder) => {
        setOrderBy((prevOrderBy) => {
            // Check if the clicked column already exists in the orderBy array
            const existingIndex = prevOrderBy.findIndex((item) => item.column === column);

            // If the clicked column already exists
            if (existingIndex !== -1) {
                const currentOrder = prevOrderBy[existingIndex].order;

                // If the clicked column exists and the order is the same, reset to default sorting
                if (currentOrder === order) {
                    // Reset to default order (this can be your default order logic, e.g., {updated_at, DESC})
                    return defaultVariables.orderBy;
                }

                // If the order is different, toggle the order direction
                const updatedOrderBy = [...prevOrderBy];
                updatedOrderBy[existingIndex] = { column, order: order };

                return updatedOrderBy;
            }

            // If it's a new column, reset the array with only the new column and updated_at
            return [
                { column, order },
                { column: "updated_at", order: SortOrder.DESC }, // Keep updated_at always last
            ];
        });
    };

    const hasKeys = Object.keys(searchInput);
    const showResetBtn = hasKeys.length === 1 && hasKeys.includes("contractors");

    return (
        <WidgetBox header="" highlight="blue" padding={0}>
            <div>
                <AppBar position="static" style={{ background: "white", color: "black", boxShadow: "none" }}>
                    <Tabs
                        sx={{
                            "& .MuiTab-root.Mui-selected": {
                                color: "#173357",
                            },
                        }}
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{ sx: { background: "#173357" } }}
                    >
                        {/* <Tab label={t("Overview")} /> */}
                        <Tab
                            sx={{ color: "#173357", width: "160px" }}
                            label={t("List")}
                            onClick={() => trackOrgTenderTab({ category: "Organizations", action: "Click-organization-tender-listview" })}
                        />
                        {!onlyMobile && (
                            <Tab
                                sx={{ color: "#173357", width: "160px" }}
                                label={t("Split")}
                                onClick={() => trackOrgTenderTab({ category: "Organizations", action: "Click-organization-tender-splitview" })}
                            />
                        )}

                        {/* <Tab label={t("Map")} /> */}
                    </Tabs>
                </AppBar>

                {/* Overview */}
                {/* <TabPanel value={value} index={0}>
                    <TenderOverview org_id={org_id} setValue={setValue} />
                </TabPanel> */}

                {/* ListView */}
                <TabPanel value={value} index={0}>
                    <Disable disabled={loading}>
                        <div style={{ margin: 8, marginTop: 0 }}>
                            <TenderSearchField
                                query={query}
                                target={target}
                                onChangeQuery={(q) => {
                                    setQuery(q);
                                    setPage(1);
                                }}
                                onChangeTarget={(target) => {
                                    setTarget(target);
                                    setPage(1);
                                }}
                                onSearch={() => {
                                    run({
                                        variables,
                                    });
                                }}
                                visible={false}
                            />
                        </div>
                        <div
                            style={
                                onlyMobile
                                    ? { margin: 8, marginTop: 16 }
                                    : {
                                          margin: 8,
                                          marginTop: 16,
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                      }
                            }
                        >
                            {" "}
                            <div>
                                {/* Filterbar */}
                                <OrgTenderFilterBar
                                    filterData={filterData ? filterData : []}
                                    resetPage={() => setPage(1)}
                                    onChange={setSearchInput}
                                    endDateInc={endDateInc}
                                    setEndDateInc={setEndDateInc}
                                    nextEndDateContract={nextEndDateContract}
                                    setNextEndDateContract={setNextEndDateContract}
                                    startSearch={() => {
                                        setPage(1);
                                        run({ variables });
                                    }}
                                    searchInput={searchInput}
                                />
                                {!showResetBtn && (
                                    <Button
                                        startIcon={<Close />}
                                        size="small"
                                        onClick={() => {
                                            setSearchInput({ contractors: [parseInt(org_id)] });
                                            setPage(1);

                                            // setCaSearchQuery("");
                                            // setResetCustomFilter(true);
                                        }}
                                        style={{ color: "grey", marginBottom: 8 }}
                                    >
                                        {t("filter.reset")}
                                    </Button>
                                )}{" "}
                            </div>
                            <div>
                                <SortingChanger
                                    options={[
                                        { label: "Status", name: "status", filter: "status", visible: true },
                                        { label: "Deadline", name: "deadline_inschrijving", filter: "deadline_inschrijving", visible: true },
                                        { label: "Type", name: "type", filter: "type", visible: true },
                                        { label: "TypeProcedure", name: "type_procedure", filter: "type_procedure", visible: false },
                                        { label: "DurationStart", name: "looptijd_start", filter: "startdate_contract", visible: true },
                                        { label: "DurationEnd", name: "looptijd_einde", filter: "enddate_contract", visible: true },
                                        { label: "FirstEndDate", name: "next_looptijd_einde", filter: "next_enddate_contract", visible: true },
                                        { label: "Postal_Code", name: "published_postcode", filter: "postalcode", visible: false },
                                        { label: "City", name: "published_vestigingsplaats", filter: "city", visible: false },
                                        { label: "Status_Contract", name: "status_contract", filter: "contract_state", visible: true },
                                        { label: "Award_date", name: "datum_gunning", filter: "award_date", visible: true },
                                        {
                                            label: "Date_incl_options",
                                            name: "looptijd_einde_incl_opties",
                                            filter: "enddate_contract_incl_options",
                                            visible: true,
                                        },
                                        { label: "First_dispatchdate", name: "first_dispatchdate", filter: "first_publication", visible: true },
                                        { label: "Last_update", name: "updated_at", filter: "updated_at", visible: true },
                                    ]}
                                    defaultSorting={{
                                        direction: defaultVariables.orderBy[0].order,
                                        field: defaultVariables.orderBy[0].column,
                                    }}
                                    onSort={handleSortChange}
                                    currentSorting={orderBy[0]}
                                />
                            </div>
                        </div>
                        <OrgTenderFullList
                            target={target}
                            loading={loading}
                            data={tendersData?.data || []}
                            paginatorInfo={tendersData?.paginatorInfo}
                            onChangePagination={handlePaginationChange}
                            onTableChange={(column, direction) => handleTableSort(column, direction.toUpperCase() as SortOrder)}
                            sortOrder={{
                                name: orderBy[0].column,
                                direction: orderBy[0].order.toLocaleLowerCase() as "desc" | "asc",
                            }}
                            org_id={org_id}
                        />
                    </Disable>
                </TabPanel>

                {/* Splitview */}
                {!onlyMobile && (
                    <TabPanel value={value} index={1}>
                        <Disable disabled={loadingSplit}>
                            <div style={{ margin: 8, marginTop: 0 }}>
                                <TenderSearchField
                                    query={query}
                                    target={target}
                                    onChangeQuery={(q) => {
                                        setQuery(q);
                                        setPage(1);
                                        setSelectedId("");
                                    }}
                                    onChangeTarget={(target) => {
                                        setTarget(target);
                                        setPage(1);
                                        setSelectedId("");
                                    }}
                                    onSearch={() => {
                                        runSplit({ variables });
                                        setSelectedId("");
                                    }}
                                    visible={false}
                                />
                            </div>

                            <div
                                style={{
                                    margin: 8,
                                    marginTop: 16,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                                    {/* Filterbar */}
                                    <OrgTenderFilterBar
                                        filterData={filterData ? filterData : []}
                                        resetPage={() => setPage(1)}
                                        onChange={setSearchInput}
                                        endDateInc={endDateInc}
                                        setEndDateInc={setEndDateInc}
                                        nextEndDateContract={nextEndDateContract}
                                        setNextEndDateContract={setNextEndDateContract}
                                        startSearch={() => {
                                            setPage(1);
                                            setSelectedId("");
                                            runSplit({ variables });
                                        }}
                                        searchInput={searchInput}
                                    />
                                    {!showResetBtn && (
                                        <Button
                                            startIcon={<Close />}
                                            size="small"
                                            onClick={() => {
                                                setSearchInput({ contractors: [parseInt(org_id)] });
                                                setPage(1);

                                                // setCaSearchQuery("");
                                                // setResetCustomFilter(true);
                                            }}
                                            style={{ color: "grey", marginBottom: 8 }}
                                        >
                                            {t("filter.reset")}
                                        </Button>
                                    )}
                                </div>
                                <div>
                                    <SortingChanger
                                        options={[
                                            { label: "Status", name: "status", filter: "status", visible: true },
                                            { label: "Deadline", name: "deadline_inschrijving", filter: "deadline_inschrijving", visible: true },
                                            { label: "Type", name: "type", filter: "type", visible: true },
                                            { label: "TypeProcedure", name: "type_procedure", filter: "type_procedure", visible: false },
                                            { label: "DurationStart", name: "looptijd_start", filter: "startdate_contract", visible: true },
                                            { label: "DurationEnd", name: "looptijd_einde", filter: "enddate_contract", visible: true },
                                            { label: "FirstEndDate", name: "next_looptijd_einde", filter: "next_enddate_contract", visible: true },
                                            { label: "Postal_Code", name: "published_postcode", filter: "postalcode", visible: false },
                                            { label: "City", name: "published_vestigingsplaats", filter: "city", visible: false },
                                            { label: "Status_Contract", name: "status_contract", filter: "contract_state", visible: true },
                                            { label: "Award_date", name: "datum_gunning", filter: "award_date", visible: true },
                                            {
                                                label: "Date_incl_options",
                                                name: "looptijd_einde_incl_opties",
                                                filter: "enddate_contract_incl_options",
                                                visible: true,
                                            },
                                            { label: "First_dispatchdate", name: "first_dispatchdate", filter: "first_publication", visible: true },
                                            { label: "Last_update", name: "updated_at", filter: "updated_at", visible: true },
                                        ]}
                                        defaultSorting={{
                                            direction: defaultVariables.orderBy[0].order,
                                            field: defaultVariables.orderBy[0].column,
                                        }}
                                        onSort={handleSortChange}
                                        currentSorting={orderBy[0]}
                                    />
                                </div>
                            </div>
                        </Disable>
                        <div style={{ padding: 8, marginTop: 0 }}>
                            <SplitView
                                target={target}
                                elevation={1}
                                setSelectedId={setSelectedId}
                                selectedId={selectedId}
                                page={rowsPerPage as number}
                                setPage={setRowsPerPage}
                                rows={tendersDataSplit}
                                loading={loadingSplit}
                                error={errorSplit}
                                mp={org_id}
                                paginatorInfo={
                                    filteredPagination
                                        ? filteredPagination
                                        : {
                                              count: 0,
                                              currentPage: 0,
                                              hasMorePages: false,
                                              firstItem: null,
                                              lastItem: null,
                                              lastPage: 1,
                                              perPage: 10,
                                              total: 0,
                                              __typename: "PaginatorInfo",
                                          }
                                }
                            />
                        </div>
                    </TabPanel>
                )}

                {/* Mapview */}
                {/* <TabPanel value={value} index={2}>
                <Mapview />
            </TabPanel> */}
            </div>
        </WidgetBox>
    );
};
export default TenderTabMenuModule;
