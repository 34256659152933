import React from "react";
import OrgTenderFullTable from "./OrgTenderFullTable";
import {
    FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_results_data,
    FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_results_paginatorInfo,
} from "../../../../../__generated__/FilterTendersOverviewOnMarketPartyPageMinimum";
import { TenderFilterTarget } from "../../../../../__generated__/globalTypes";

interface Props {
    /**
     * onChange page or rows per page
     */
    onChangePagination(page: number, rowsPerPage: number): void;
    data: FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_results_data[];
    paginatorInfo: FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_results_paginatorInfo | undefined;
    loading: boolean;
    org_id: string;
    onTableChange: (changedColumn: string, direction: "asc" | "desc") => void;
    sortOrder: { name: string; direction: "asc" | "desc" };
    target: TenderFilterTarget;
}

const OrgTenderFullList: React.FC<Props> = ({ paginatorInfo, data, onChangePagination, onTableChange, loading, org_id, sortOrder, target }) => {
    return (
        <OrgTenderFullTable
            target={target}
            org_id={org_id}
            loading={loading}
            bids={data}
            paginatorInfo={
                paginatorInfo
                    ? paginatorInfo
                    : {
                          __typename: "PaginatorInfo",
                          count: 0,
                          currentPage: 0,
                          hasMorePages: false,
                          firstItem: 0,
                          lastItem: 0,
                          lastPage: 0,
                          perPage: 0,
                          total: 0,
                      }
            }
            onChangePagination={onChangePagination}
            onTableChange={onTableChange}
            sortOrder={sortOrder}
        />
    );
};

export default OrgTenderFullList;
