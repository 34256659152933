import React, { useEffect } from "react";
import { Tooltip, IconButton, Badge } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import { useTranslation } from "react-i18next";
import { useTenderChat } from "../../components/contextProviders/TenderChatContext";
import { gql, useQuery } from "@apollo/client";
import { GetOppUnreadMessageCount, GetOppUnreadMessageCountVariables } from "../../__generated__/GetOppUnreadMessageCount";

interface Props {
    opp_id: string;
    name: string;
}

const SmallOppMenu: React.FC<Props> = ({ opp_id, name }) => {
    const { t } = useTranslation();
    const { setOpenModal, setChatTarget, messageSent, openModal } = useTenderChat();

    /**
     * Get message data
     */
    const { data, refetch } = useQuery<GetOppUnreadMessageCount, GetOppUnreadMessageCountVariables>(GET_OPP_UNREAD_MESSAGE_COUNT, {
        variables: { id: opp_id },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        messageSent === true && openModal === false && refetch();
    }, [refetch, opp_id, messageSent, openModal]);

    return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {/*
             * Button to open chat modal.
             * Available in all tabs
             * */}
            <Tooltip enterNextDelay={100} title={t("dashboardPage.Chat") as string} placement="top">
                <Badge
                    sx={{
                        ".MuiBadge-standard": {
                            maxWidth: "16px",
                            minWidth: "16px",
                            minHeight: "16px",
                            maxHeight: "16px",
                            fontSize: "0.6rem",
                            marginRight: "7px",
                            marginTop: "7px",
                        },
                    }}
                    badgeContent={data?.opportunityFile?.unreadChatsCount}
                    color="primary"
                    max={999}
                >
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            // Prevent the tab iself being clicked,
                            // we want to execute the click on the close button
                            e.stopPropagation();
                            setChatTarget(opp_id, "App\\Models\\Opportunity\\File", name || "", "");
                            setOpenModal(true);
                        }}
                    >
                        <MessageIcon fontSize="small" sx={{ color: data?.opportunityFile?.hasConversations === true ? "grey" : "lightgrey" }} />
                    </IconButton>
                </Badge>
            </Tooltip>
        </div>
    );
};

export default SmallOppMenu;

//? get last message and boolean
export const GET_OPP_UNREAD_MESSAGE_COUNT = gql`
    query GetOppUnreadMessageCount($id: ID!) {
        opportunityFile(id: $id) {
            id
            name
            unreadChatsCount
            hasConversations
        }
    }
`;
