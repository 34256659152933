import React, { useCallback, useEffect, useRef, useState } from "react";
import { Checkbox, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { FilterType, useTenderSearchContext } from "../../../../components/guides/tenderWizard/WizardContext";
import update from "immutability-helper";
import { debounce } from "lodash";
import Searchbar from "./Searchbar";
import { TenderFilterInput } from "../../../../__generated__/globalTypes";

interface Props {
    filterValues: any;
    filter: string;
}

const CpvFilter: React.FC<Props> = ({ filterValues, filter }) => {
    const recon = filterValues.map((option: any) => ({ key: option.identifier, label: option.label, identifier: parseInt(option.identifier) }));
    const { cpvs, setCpvs, filters, setFilters } = useTenderSearchContext(); // Use the context hook to access context value and function
    const [staticValue, setStaticValue] = useState<string>("");
    const [staticLoad, setStaticLoad] = useState<boolean>(false);
    const inputGqlSearch = useRef<HTMLInputElement | null>(null); // if you are referring to an input element
    const [searchQuery, setSearchQuery] = useState<string>("");

    useEffect(() => {
        if (filters.cpvs && filters.cpvs.length === 0) {
            // Unset 'country' if it's an empty array
            setFilters(update(filters, { $unset: ["cpvs" as keyof TenderFilterInput] }));
        } else if (filters.cpvs) {
            const updatedCpvs = filters.cpvs
                .map((id: string) => recon.find((item: any) => item.identifier === parseInt(id))) // Find objects in recon
                .filter(Boolean); // Remove undefined values

            // Only update cpvs if it actually changed
            if (JSON.stringify(cpvs) !== JSON.stringify(updatedCpvs)) {
                setCpvs(updatedCpvs);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.cpvs, recon]); // Only runs when filters.cpvs or recon changes

    // Toggle CPV selection
    const onToggleAType = (type: FilterType) => {
        const parsedId = type.identifier;

        setCpvs((prev) => {
            const isSelected = prev.some((ad) => ad.identifier === parsedId);

            let updatedAd;
            if (isSelected) {
                // Remove the CPV
                updatedAd = prev.filter((ad) => ad.identifier !== parsedId);
            } else {
                // Find the full object in recon and add it
                const newEntry = recon.find((item: any) => item.identifier === parsedId);
                updatedAd = newEntry ? [...prev, newEntry] : prev;
            }

            // Update filters.cpvs (string array)
            setFilters(update(filters, { cpvs: { $set: updatedAd.map((ad) => ad.identifier.toString()) } }));

            return updatedAd;
        });
    };

    // Type the ref properly with `DebouncedFunc`
    const debounced = useRef(
        debounce((value: string) => {
            if (value.length === 0 || value.length >= 3) {
                setSearchQuery(value); // directly call setGqlSearch
                setStaticLoad(false);
            }
        }, 900)
    );

    const updateUserValue = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        debounced.current(value); // call debounced function
        setStaticValue(value); // set the user value immediately
        setStaticLoad(true);
    }, []);

    // Filter the values based on the search query
    const filteredValues = recon.filter(
        (option: any) =>
            option.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
            option.key.toString().includes(searchQuery) ||
            option.identifier.toString().includes(searchQuery)
    );

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", padding: "16px 0px 16px 16px" }}>
            <div>
                <Searchbar
                    queryToShow={staticValue}
                    ref={inputGqlSearch}
                    query={searchQuery ?? ""}
                    handleSearchChange={updateUserValue}
                    reset={() => {
                        setSearchQuery("");
                        setStaticValue("");
                    }}
                    loading={staticLoad}
                />
            </div>

            <List dense sx={{ flex: 1, overflow: "auto", marginTop: "16px" }}>
                {filteredValues?.map((c: any) => {
                    return (
                        <ListItemButton
                            key={c.identifier}
                            onClick={() => {
                                onToggleAType(c);
                            }}
                        >
                            <ListItemText
                                primary={<Typography style={{ display: "flex", alignItems: "center" }}>{c.label}</Typography>}
                                secondary={
                                    <Typography variant="caption" style={{ display: "flex", alignItems: "center" }}>
                                        {c.identifier}
                                    </Typography>
                                }
                            />
                            <ListItemIcon>
                                <Checkbox edge="start" checked={Boolean(cpvs.find((ca) => ca.identifier === c.identifier))} />
                            </ListItemIcon>
                        </ListItemButton>
                    );
                })}
            </List>
        </div>
    );
};

export default CpvFilter;
