import React, { useState } from "react";
import { Box, Button, Checkbox, InputAdornment, ListItemButton, ListItemIcon, ListItemText, Popover, TextField, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";

import { gql, useMutation, useQuery } from "@apollo/client";
import { GetSubscriptionUsers } from "../../../__generated__/GetSubscriptionUsers";
import { QUERY_SUBSCRIPTION_USERS } from "../../../graphql/queryDefUserAndTeams";
import { useCurrentUserOrganization } from "../../../components/contextProviders/CurrentUserOrganization";
import { UserInTeam } from "../UserAndTeams/UsersAndTeams";
import truncate from "truncate";
import { shareTenderSearch, shareTenderSearchVariables } from "../../../__generated__/shareTenderSearch";
import { shareOppSearch, shareOppSearchVariables } from "../../../__generated__/shareOppSearch";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import { sanitizeInput } from "../../../utils/sanitizeInput";

interface Props {
    anchorEl: any;
    handleCloseSub: (e: any) => void;
    shareOpened: boolean;
    teamsComponent: JSX.Element[];
    colleaguesComponents: JSX.Element[];
    searchID: string;
    type: "tender" | "opportunity";
    search?: any;
}

const CopySearchLine: React.FC<Props> = ({ anchorEl, handleCloseSub, shareOpened, teamsComponent, colleaguesComponents, searchID, type, search }) => {
    const { org_id } = useCurrentUserOrganization();
    const [userList, setUserList] = useState<UserInTeam[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [checked, setChecked] = useState<number[]>([]);

    const [share_tndr_search, { loading: loadingMutation, error: errorMutation }] = useMutation<shareTenderSearch, shareTenderSearchVariables>(
        SHARE_TENDER_SEARCH
    );
    const [share_opp_search, { loading: loadingOppMutation, error: errorOppMutation }] = useMutation<shareOppSearch, shareOppSearchVariables>(
        SHARE_OPP_SEARCH
    );

    /**
     * Archive all selected tenders
     */
    const shareTenderSearch = () => {
        /**
         * foreach user id in checked => send mutation
         */
        if (checked.length > 0) {
            checked.forEach(async (user_id: number) => {
                // Function to like organization
                if (loadingMutation) {
                }
                if (errorMutation) {
                    // toast.error("Fout tijdens opslaan (error)");
                }
                try {
                    await share_tndr_search({
                        variables: {
                            user_id: user_id,
                            search_id: parseInt(searchID),
                        },
                        onCompleted: () => {
                            toast.success("Kopie van zoekregel gedeeld", { autoClose: 1500 });
                        },
                    });
                } catch (e) {
                    // toast.error("Fout tijdens opslaan (catch)");
                } finally {
                    setChecked([]);
                }
            });
        }
    };

    /**
     * Archive all selected tenders
     */
    const shareOppSearch = () => {
        /**
         * foreach user id in checked => send mutation
         */
        if (checked.length > 0) {
            checked.forEach(async (user_id: number) => {
                // Function to like organization
                if (loadingOppMutation) {
                }
                if (errorOppMutation) {
                    // toast.error("Fout tijdens opslaan (error)");
                }
                try {
                    await share_opp_search({
                        variables: {
                            user_id: user_id,
                            search_id: parseInt(searchID),
                        },
                        onCompleted: () => {
                            toast.success("Kopie van zoekregel gedeeld", { autoClose: 1500 });
                        },
                    });
                } catch (e) {
                    // toast.error("Fout tijdens opslaan (catch)");
                } finally {
                    setChecked([]);
                }
            });
        }
    };

    const { loading: loadingUsers, error: errorUsers } = useQuery<GetSubscriptionUsers>(QUERY_SUBSCRIPTION_USERS, {
        variables: {
            org_id: org_id,
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.organization && data.organization.users) {
                // Exclude tender search owner or
                if (type === "tender") {
                    const myOwn = search.owner.id === localStorage.getItem("user_id");
                    setUserList(
                        myOwn
                            ? data.organization.users.filter((user) => user.id !== localStorage.getItem("user_id"))
                            : data.organization.users.filter((u) => u.id !== search.owner.id)
                    );
                }
                // exclude current user
                else {
                    const myOwn = data.organization.users.filter((user) => user.id !== localStorage.getItem("user_id"));
                    setUserList(myOwn);
                }
            }
        },
    });

    /**
     * handle user input in searchbox to search a user on his givenname
     * @param event Value from textfield to search a user in the list.
     */
    const handleSearchUser = (event: any) => {
        event.stopPropagation();
        const {
            target: { value },
        } = event;
        const inputvalue = sanitizeInput(value, false);
        const val = DOMPurify.sanitize(inputvalue);
        setSearchTerm(val);
    };

    /**
     * Array that gives users back that match with input in searchfield.
     * - match based on input and givenname
     */
    const results = !searchTerm ? userList : userList.filter((item) => item.employee.name?.toLowerCase().includes(searchTerm.toLocaleLowerCase()));

    /**
     * @param value user id
     * @returns return checked or unchecked state of user
     */
    const handleToggle = (e: any, value: number) => {
        e.stopPropagation();
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    /**
     * If loading => show popper with loading text
     */
    if (loadingUsers) {
        return loadOrError(
            { anchorEl, shareOpened, handleCloseSub, teamsComponent, colleaguesComponents, searchID, type },
            handleSearchUser,
            searchTerm,
            "Laden..."
        );
    }

    /**
     * If an error occurs => show popper with loading text
     */
    if (errorUsers) {
        return loadOrError(
            { anchorEl, shareOpened, handleCloseSub, teamsComponent, colleaguesComponents, searchID, type },
            handleSearchUser,
            searchTerm,
            "Er ging iets mis"
        );
    }
    return (
        <Popover
            anchorEl={anchorEl}
            open={shareOpened}
            onClose={handleCloseSub}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            /**
             * Width and height for chat/knowledge base
             */
            slotProps={{
                paper: {
                    sx: {
                        width: "300px",
                    },
                },
            }}
            // PaperProps={{
            //     style: { width: "300px" },
            // }}
        >
            <div>
                {/*
                 * Searchbox
                 */}
                <Box style={{ width: "100%", display: "flex" }}>
                    <TextField
                        onClick={(e) => e.stopPropagation()}
                        sx={{ margin: "10px", flexGrow: 1 }}
                        placeholder="Zoek een gebruiker..."
                        size="small"
                        variant="outlined"
                        onChange={(e) => {
                            e.stopPropagation();
                            handleSearchUser(e);
                        }}
                        value={searchTerm}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box sx={{ height: "310px", overflow: "auto" }}>
                    {teamsComponent}
                    {colleaguesComponents}

                    {results.map((usert) => {
                        const user = usert.employee;
                        /**
                         * UserListItem component for details of user with edit and delete option
                         */
                        return (
                            <ListItemButton key={user.id} onClick={(e) => handleToggle(e, parseInt(usert.id))} dense disableRipple>
                                {/* Checkbox */}
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked.indexOf(parseInt(usert.id)) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        color="primary"
                                    />
                                </ListItemIcon>
                                {/* Listitem with userdetails. */}
                                <ListItemText
                                    /**
                                     * Combination of user givenname and familyname. line through when user is inactive
                                     */
                                    primary={
                                        <>
                                            <Typography>{truncate(user.name ? user.name : "", 40)}</Typography>
                                        </>
                                    }
                                    /**
                                     * Second line in useritem, linethrough when state is inactive.
                                     */
                                    secondary={
                                        <>
                                            <Typography style={{ fontSize: "0.875rem" }}>{usert.email}</Typography>
                                        </>
                                    }
                                />
                            </ListItemButton>
                        );
                    })}
                </Box>

                {/* Buttons to cancel or share */}
                <Box style={{ margin: 8, display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        variant="text"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCloseSub(e);
                        }}
                    >
                        Annuleren
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCloseSub(e);
                            if (type === "tender") {
                                shareTenderSearch();
                            }
                            if (type === "opportunity") {
                                shareOppSearch();
                            }
                        }}
                    >
                        Kopieren
                    </Button>
                </Box>
            </div>
        </Popover>
    );
};

export default CopySearchLine;

/**
 * @param param0 state props + close handler
 * @param handleSearchUser searchbar handler
 * @param searchTerm searchinput
 * @param text Text to show on error or loading state
 * @returns loading/ error view
 */
const loadOrError = ({ anchorEl, shareOpened, handleCloseSub }: Props, handleSearchUser: any, searchTerm: string, text: string) => {
    return (
        <Popover
            anchorEl={anchorEl}
            open={shareOpened}
            onClose={handleCloseSub}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            /**
             * Width and height for chat/knowledge base
             */
            slotProps={{
                paper: {
                    sx: {
                        width: "300px",
                        height: "310px",
                    },
                },
            }}
        >
            {/*
             * Searchbox
             */}
            <Box style={{ width: "100%", display: "flex" }}>
                <TextField
                    sx={{ margin: "10px", flexGrow: 1 }}
                    placeholder="Zoek een gebruiker..."
                    size="small"
                    variant="outlined"
                    onChange={handleSearchUser}
                    value={searchTerm}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Box style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>{text}</Box>
        </Popover>
    );
};

export const SHARE_TENDER_SEARCH = gql`
    mutation shareTenderSearch($user_id: Int!, $search_id: Int!) {
        initiateCopyTenderSearchToUser(user_id: $user_id, search_id: $search_id)
    }
`;

export const SHARE_OPP_SEARCH = gql`
    mutation shareOppSearch($user_id: Int!, $search_id: Int!) {
        initiateCopyOpportunitySearchToUser(user_id: $user_id, search_id: $search_id)
    }
`;
