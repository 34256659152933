import React from "react";
import update from "immutability-helper";
import { useTranslation } from "react-i18next";
import { Moment } from "moment";
import { IntRange } from "../../../../components/FilterDrawer/IntRangeFilterItem";
import FilterDropdownStringVersion from "../../../../components/TopFilterBar/FilterDropdownStringVersion";
import FilterDropdown from "../../../../components/TopFilterBar/FilterDropdown";
import { useQuery } from "@apollo/client";
import { GetUserSearchRules } from "../../../../__generated__/GetUserSearchRules";
import PeriodFilter from "../../../../components/TopFilterBar/PeriodFilter";
import { QUERY_SEARCHRULES } from "../../../../graphql/queryDefCurrentUser";

export type DateRange = { from: Moment | null; to: Moment | null };
interface Props {
    deadline?: DateRange;
    setDeadline?(deadline: DateRange): void;
    firstPublication?: DateRange;
    setFirstPublication?(firstPublication: DateRange): void;
    endDate?: DateRange;
    setEndDate?(endDate: DateRange): void;
    endDateInc?: DateRange;
    setEndDateInc?(endDateInc: DateRange): void;
    awardDate?: DateRange;
    setAwardDate?(awardDate: DateRange): void;
    startDateContract?: DateRange;
    setStartDateContract?(startDateContract: DateRange): void;
    nextEndDateContract?: DateRange;
    setNextEndDateContract?(nextEndDateContract: DateRange): void;
    updatedAt?: DateRange;
    setUpdatedAt?(updatedAt: DateRange): void;
    ratedDate?: DateRange;
    setRatedDate?(ratedDate: DateRange): void;
    filterData: any[];
    resetPage: () => void;
    onChange(searchInput: any): void;
    startSearch(): void;
    searchInput: any;
    setOpen?: (open: boolean) => void;
    // publication date opportunity
    publishedOppDate?: DateRange;
    setPublishedOppDate?(ratedDate: DateRange): void;
    // personal opportunity year
    personalYear?: DateRange;
    setPersonalYear?(personalYear: DateRange): void;
    // document year_from filter opportunity
    yearFrom?: DateRange;
    setYearFrom?(yearFrom: DateRange): void;
    // last reviewdate opportunity
    documentLastReviewed?: DateRange;
    setDocumentLastReviewed?(documentLastReviewed: DateRange): void;
    IntRangeState?: IntRange;
    setIntRangeState?(IntRangeState: IntRange): void;
    setCaSearchQuery?(caSearch: string): void;
    caSearchQuery?: string;
    resetCustomFilter?: boolean;
    setResetCustomFilter?(bool: boolean): void;
}

const OrgTenderFilterBar: React.FC<Props> = ({
    filterData,
    resetPage,
    onChange,
    searchInput,
    startSearch,
    endDateInc,
    setEndDateInc,
    nextEndDateContract,
    setNextEndDateContract,
}) => {
    const { t } = useTranslation();

    // shadow copy of filterdata
    const filterCopy = [...filterData];

    // Fetch searchrules (tenders & opportunities)
    const { data, loading: loadSearches } = useQuery<GetUserSearchRules>(QUERY_SEARCHRULES);

    // Filters to hide in GUI for all modules/tabs
    const array_to_show = filterCopy.filter(function (filter) {
        return (
            filter.title === "searches" ||
            filter.title === "regions" ||
            filter.title === "contractingAuthorityTypes" ||
            filter.title === "type" ||
            filter.title === "next_enddate_contract" ||
            filter.title === "enddate_contract_incl_options" ||
            filter.title === "enddate_contract_quarters"
        );
    });

    return (
        <>
            {/*
             * *******
             * FILTERS TO USE ON ORGANIZATION DETAILPAGE
             * ******
             * */}
            {array_to_show
                ?.sort((a, b) => sortingArray.indexOf(a.title) - sortingArray.indexOf(b.title))
                .map((filter) => {
                    // change test to filter.title
                    const test = filter.title;
                    const filterItems = searchInput?.[test as keyof any] as any[];

                    /**
                     * Searchrule filter
                     * with own query to fetch usersearches
                     */
                    if (test === "searches" && loadSearches === false && data?.currentUser.searches !== undefined) {
                        return (
                            <FilterDropdown
                                helpertext={loadSearches ? "Zoekregels ophalen..." : ""}
                                key={filter.title}
                                disabled={
                                    false
                                    // filter.values.length === 0
                                }
                                allowSearch
                                label={`${t(`filter.${filter.title}`)}`}
                                values={filterItems || []}
                                onChange={(target) => {
                                    // If target has over 0 items. add filter to searchInput
                                    if (target.length > 0) {
                                        onChange(update(searchInput, { [test]: { $set: target } }));
                                    }
                                    // If target.length = 0 -> remove empty filter from searchInput
                                    if (target.length === 0) {
                                        onChange(update(searchInput, { $unset: [test as keyof any] }));
                                    }

                                    // Execute startsearch each time
                                    // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                    startSearch();
                                }}
                                options={data?.currentUser.searches.map((search) => ({
                                    key: search.color as string,
                                    label: search.name,
                                    id: parseInt(search.id),
                                }))}
                                width={275}
                            />
                        );
                    }

                    /**
                     * Quarter filter
                     */
                    if (test === "enddate_contract_quarters") {
                        return (
                            <FilterDropdownStringVersion
                                key={filter.title}
                                disabled={
                                    false
                                    // filter.values.length === 0
                                }
                                allowSearch
                                label={`${t(`filter.${filter.title}`)}`}
                                values={filterItems || []}
                                onChange={(target) => {
                                    // If target has over 0 items. add filter to searchInput
                                    if (target.length > 0) {
                                        onChange(update(searchInput, { [test]: { $set: target } }));
                                    }
                                    // If target.length = 0 -> remove empty filter from searchInput
                                    if (target.length === 0) {
                                        onChange(update(searchInput, { $unset: [test as keyof any] }));
                                    }
                                    // Execute startsearch each time
                                    // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                    startSearch();
                                }}
                                options={filter.values
                                    .filter((f: any) => f.amountOfResults > 0)
                                    .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                        key: fltr.label,
                                        label: `${fltr.label} (${fltr.amountOfResults})`,
                                        id: fltr.identifier,
                                    }))}
                                width={275}
                            />
                        );
                    }

                    /**
                     * next_enddate_contract filter
                     */
                    if (test === "next_enddate_contract" && nextEndDateContract !== undefined && setNextEndDateContract !== undefined) {
                        return (
                            <PeriodFilter
                                key="next_enddate_contract"
                                active={filter.active}
                                label="next_enddate_contract"
                                dateRangeState={nextEndDateContract}
                                setDateRange={setNextEndDateContract}
                                onChange={onChange}
                                searchInput={searchInput}
                            />
                        );
                    }

                    /**
                     * Regions filter
                     */
                    if (test === "regions") {
                        return (
                            <FilterDropdownStringVersion
                                key={filter.title}
                                disabled={
                                    false
                                    // filter.values.length === 0
                                }
                                allowSearch
                                label={`${t(`filter.${filter.title}`)}`}
                                values={filterItems || []}
                                onChange={(target) => {
                                    // If target has over 0 items. add filter to searchInput
                                    if (target.length > 0) {
                                        onChange(update(searchInput, { [test]: { $set: target } }));
                                    }
                                    // If target.length = 0 -> remove empty filter from searchInput
                                    if (target.length === 0) {
                                        onChange(update(searchInput, { $unset: [test as keyof any] }));
                                    }
                                    // Execute startsearch each time
                                    // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                    startSearch();
                                }}
                                options={filter.values
                                    .filter((f: any) => f.amountOfResults > 0)
                                    .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                        key: fltr.label,
                                        label: `${fltr.label} (${fltr.amountOfResults})`,
                                        id: fltr.identifier,
                                    }))}
                                width={275}
                            />
                        );
                    }

                    /**
                     * enddate_contract_incl_options filter
                     */
                    if (test === "enddate_contract_incl_options" && endDateInc !== undefined && setEndDateInc !== undefined) {
                        return (
                            <PeriodFilter
                                key="enddate_contract_incl_options"
                                active={filter.active}
                                label="enddate_contract_incl_options"
                                dateRangeState={endDateInc}
                                setDateRange={setEndDateInc}
                                onChange={onChange}
                                searchInput={searchInput}
                            />
                        );
                    }

                    return (
                        <FilterDropdown
                            key={filter.title}
                            disabled={
                                false
                                // filter.values.length === 0
                            }
                            allowSearch
                            label={`${t(`filter.${filter.title}`)}`}
                            values={filterItems || []}
                            onChange={(target) => {
                                // If target has over 0 items. add filter to searchInput
                                if (target.length > 0) {
                                    onChange(update(searchInput, { [test]: { $set: target } }));
                                }
                                // If target.length = 0 -> remove empty filter from searchInput
                                if (target.length === 0) {
                                    onChange(update(searchInput, { $unset: [test as keyof any] }));
                                }
                                if (target.length === 0 && test === "reviews") {
                                    onChange(update(searchInput, { [test]: { $set: [1] } }));
                                }

                                // Execute startsearch each time
                                // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                startSearch();
                            }}
                            options={filter.values
                                .filter((f: any) => f.amountOfResults > 0)
                                .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                    key: fltr.label,
                                    label: `${fltr.label} (${fltr.amountOfResults})`,
                                    id: parseInt(fltr.identifier),
                                }))}
                            width={filter.title === "subSectors" ? 420 : filter.title === "sectors" ? 340 : 275}
                        />
                    );
                })}
        </>
    );
};

export default OrgTenderFilterBar;

export const sortingArray = [
    "searches",
    "reviews",
    "country",
    "sectors",
    "subSectors",
    "status",
    "regions",
    "contractingAuthorityTypes",
    "contractingAuthorities",
    "city",
    "type",
    "cpvs",
    "deadline_inschrijving",
    "first_publication",
    "updated_at",
    "award_date",
    "startdate_contract",
    "next_enddate_contract",
    "enddate_contract",
    "enddate_contract_incl_options",
    "rated_date",
    "enddate_contract_quarters",
    "agenda",
    "contractors",
    "last_update_type",
    "platform",
    "scopeMax",
    "scopeMin",
    "type_procedure",
    "city",
    "contracting_authorities",
    "contracting_authority_type",
    "contracting_authority_city",
    "contracting_authority_state",
    "document_last_reviewed",
    "publication_date",
    "file_type",
    "document_unreviewed_page_count",
    "year_from",
    "personal_opportunity_count",
    "personal_value",
    "personal_year",
    "result_count",
    "text",
];
