import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { CardActionArea, Checkbox, Grid, Paper, Typography } from "@mui/material";
import { FilterType, useTenderSearchContext } from "../../../../components/guides/tenderWizard/WizardContext";
import update from "immutability-helper";
import { TenderFilterInput } from "../../../../__generated__/globalTypes";

interface Props {
    filterValues: any;
    filter: string;
}

const TypeFilter: React.FC<Props> = ({ filter, filterValues }) => {
    const recon = filterValues.map((option: any) => ({ key: option.id, label: option.label, identifier: parseInt(option.identifier) }));

    const { types, setTypes, filters, setFilters } = useTenderSearchContext(); // Use the context hook to access context value and function

    useEffect(() => {
        if (filters.type) {
            const updated = filters.type
                .map((id: number) => recon.find((item: any) => item.identifier === id)) // Find objects in recon
                .filter(Boolean); // Remove undefined values

            // Only update cpvs if it actually changed
            if (JSON.stringify(types) !== JSON.stringify(updated)) {
                setTypes(updated);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.type, recon]); // Only runs when filters.cpvs or recon changes

    // This onChange function toggles the country on/off and updates filters
    const onToggleAD = (type: FilterType) => {
        const parsedId = type.identifier;

        setTypes((prev) => {
            const isSelected = prev.some((ad) => ad.identifier === parsedId);

            let updatedAd;
            if (isSelected) {
                // If the country is selected, remove it (deselect)
                updatedAd = prev.filter((ad) => ad.identifier !== parsedId);

                // Update filters if the country array changes
                if (updatedAd.length === 0) {
                    // If the country array is empty, unset the country filter
                    setFilters(update(filters, { $unset: [filter as keyof TenderFilterInput] }));
                } else {
                    // If the country array is not empty, set the country filter
                    setFilters(update(filters, { type: { $set: updatedAd.map((ad) => ad.identifier) } }));
                }
            } else {
                // If the country is not selected, add it (select)
                updatedAd = [...prev, { key: type.key, label: type.label, identifier: parsedId }];

                // Update filters if the country array changes
                setFilters(update(filters, { type: { $set: updatedAd.map((ad) => ad.identifier) } }));
            }

            return updatedAd;
        });
    };
    return (
        <Grid container spacing={2} sx={{ padding: "16px" }}>
            {recon.map((value: any) => {
                return <ItemCard key={value.identifier} value={value} onToggleAD={onToggleAD} data={types} />;
            })}
        </Grid>
    );
};

export default TypeFilter;

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    paddingRight: "8px",
    // textAlign: "center",
    display: "flex", // changed to flex for horizontal layout
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.text.secondary,
    maxWidth: "100%", // ensure the container doesn't expand beyond available space
}));

interface ItemCardProps {
    value: FilterType;
    data: FilterType[];
    onToggleAD: (type: FilterType) => void;
}

const ItemCard: React.FC<ItemCardProps> = ({ value, onToggleAD, data }) => {
    return (
        <Grid key={value.identifier} item xs={4}>
            <CardActionArea onClick={() => onToggleAD(value)}>
                <Item variant="outlined">
                    <Typography
                        noWrap={false}
                        sx={{ flexGrow: 1, wordWrap: "break-word", maxWidth: "calc(100% - 24px)", display: "flex", alignItems: "center" }}
                    >
                        {value.label}
                    </Typography>
                    <Checkbox checked={Boolean(data.find((val) => val.identifier === value.identifier))} />
                </Item>
            </CardActionArea>
        </Grid>
    );
};
