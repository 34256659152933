import React from "react";
import { Avatar, Button, Card, CardActions, CardContent, CardMedia, Chip, Typography } from "@mui/material";
import { Today } from "@mui/icons-material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { getAllArticles_articles_tags } from "../../__generated__/getAllArticles";

interface Props {
    cardMediaHeight?: number;
    index: number;
    title: string;
    id: string;
    date: string;
    author_name: string;
    author_picture: string;
    author_org: string;
    author_function: string;
    tags: getAllArticles_articles_tags[];
}

interface Position {
    x: number;
    y: number;
}

const ColumnGridArticle: React.FC<Props> = ({
    cardMediaHeight = 250,
    index,
    title,
    id,
    date,
    author_name,
    author_org,
    author_picture,
    author_function,
    tags,
}) => {
    const hasImage = false;
    const highlight = [{ name: "" }, { name: moment(date).format("LL") }, { name: "Column" }, { name: title }, { name: author_name }];
    const navigate = useNavigate();

    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    // Define an array of predefined positions (5 positions in this case)
    const predefinedPositions: Position[] = [
        { x: 10, y: 0 },
        { x: 230, y: 130 },
        { x: -10, y: 140 },
        { x: 50, y: 75 },
        { x: 250, y: 0 },
    ];

    // Define an array with 5 different background style objects
    const backgroundStyles = [
        {
            backgroundImage: "url('https://storage.googleapis.com/tenderguide-public/img_to_use_as_bg.png')",
            filter: `grayscale(100%) contrast(0.5)`,
        },
        {
            backgroundImage: "url('https://storage.googleapis.com/tenderguide-public/img_to_use_as_bg.png')",
            filter: `grayscale(100%) contrast(0.5)`,
            backgroundSize: "cover",
        },
        {
            backgroundImage: "url('https://storage.googleapis.com/tenderguide-public/img_to_use_as_bg.png')",
            backgroundPosition: "bottom",
            filter: `grayscale(100%) contrast(0.5)`,
        },
        {
            backgroundImage: "url('https://storage.googleapis.com/tenderguide-public/img_to_use_as_bg.png')",
            backgroundSize: "100%",
            backgroundPosition: "bottom left",
            filter: `grayscale(100%) contrast(0.5)`,
        },
        {
            backgroundImage: "url('https://storage.googleapis.com/tenderguide-public/img_to_use_as_bg.png')",
            backgroundPosition: "left 44%",
            filter: `grayscale(100%) contrast(0.5)`,
        },
    ];

    const bgColor = ["#82b6a040", "#70a4b7a3", "#17335778", "#70a4b7d1", "#82b6a085"];

    const wordstoshow = tags.length > 0 ? tags : highlight;

    return (
        <Card
            onClick={() => {
                navigate(`/knowledge-hub/column/${id}`);
                trackevents({ category: "Column", action: `Click column: ${title} (${id})` });
            }}
            sx={{
                borderRadius: "16px",
                position: "relative",
                transition: "transform 0.3s ease", // Smooth transition for scaling
                cursor: "pointer",
                "&:hover": {
                    transform: "scale(1.02)", // Scale the card by 1.2 on hover
                },
            }}
        >
            <div
                style={{
                    position: "absolute",
                    margin: "8px 0px 0px 8px",
                    zIndex: 4,
                }}
            >
                <Chip
                    variant="filled"
                    icon={<Today sx={{ color: "#fff !important", fontSize: "18px" }} />}
                    label={moment(date).format("LL")}
                    sx={{ zIndex: 4, backgroundColor: "#70a4b7", fontWeight: 600, color: "white" }}
                />
            </div>
            {hasImage ? (
                <CardMedia sx={{ height: cardMediaHeight }} image="" />
            ) : (
                <div
                    style={{
                        background: bgColor[index % 5],
                        width: "100%",
                        height: cardMediaHeight,
                        overflow: "hidden",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                    }}
                >
                    <div style={{ position: "absolute", width: "100%", height: "100%", mixBlendMode: "overlay", ...backgroundStyles[index % 5] }} />
                    {wordstoshow.map((word, index) => {
                        return (
                            <span
                                key={index}
                                style={{
                                    position: "absolute",
                                    top: 0, // Centered at the top
                                    left: 0, // Centered at the left
                                    transform: `translate(${predefinedPositions[index].x}px, ${predefinedPositions[index].y}px) `, //rotate(${rotation}deg)
                                    fontSize: `${Math.random() * 16 + 24}px`, // Random font size
                                    fontWeight: "bold",
                                    color: "#fff", // Apply the RGBA color with opacity
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {word.name}
                            </span>
                        );
                    })}
                </div>
            )}
            <CardContent sx={{ paddingTop: "8px", paddingBottom: "0px" }}>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", overflow: "hidden" }}>
                    <Avatar src={author_picture} />
                    <div
                        style={{
                            marginLeft: "8px",
                        }}
                    >
                        <Typography fontWeight={500} sx={{ lineHeight: 1 }}>
                            {author_name}
                        </Typography>
                        <Typography variant="caption" sx={{ lineHeight: 1.3, display: "flex" }}>
                            {author_function}
                        </Typography>
                    </div>
                </div>

                <Typography variant="h5" component="div" fontWeight={500} sx={{ height: "72px", overflow: "hidden", marginTop: "8px" }}>
                    {title}
                </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button
                    size="small"
                    onClick={() => {
                        navigate(`/knowledge-hub/column/${id}`);
                        trackevents({ category: "Column", action: `Click column: ${title} (${id})` });
                    }}
                >
                    Lees meer
                </Button>
            </CardActions>
        </Card>
    );
};

export default ColumnGridArticle;
