import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// Material UI components
import { Grid, Paper, Typography } from "@mui/material";

// Custom Components
import TenderViewer from "../../tenders/splitview/TenderViewer";
import SplitList from "../../tenders/splitview/SplitList";
import { FilterSearchRuleTenders_filterTenders_results_data } from "../../../__generated__/FilterSearchRuleTenders";
import { ApolloError, useLazyQuery } from "@apollo/client";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import { ContractingAuthoritiesFilterSource, SortOrder, TenderFilterInput } from "../../../__generated__/globalTypes";
import FilterDropdown from "../../../components/TopFilterBar/FilterDropdown";
import update from "immutability-helper";
import { Disable } from "react-disable";
import { sortingArray } from "../../../components/FilterDrawer/TopFilterBar";
import FilterDropdownStringVersion from "../../../components/TopFilterBar/FilterDropdownStringVersion";
import {
    GetContractingAuthorityFilterData,
    GetContractingAuthorityFilterDataVariables,
    GetContractingAuthorityFilterData_filterContractingAuthorities_results,
} from "../../../__generated__/GetContractingAuthorityFilterData";
import { QUERY_CA_FILTER_DATA } from "../../../graphql/opportunityQueries/queryDefinitions";
import FilterDropdownCA from "../../../components/TopFilterBar/FilterDropdownCA";
import { useCustomSearchOptions } from "../../../components/contextProviders/SearchRuleCustomOptionProvider";
import FilterDropdownCAWithEdit from "../../../components/TopFilterBar/newCaFilter.tsx/FilterDropdownCAWithEdit";
import { useTenderSearchContext } from "../../../components/guides/tenderWizard/WizardContext";

interface Props {
    tenderData: FilterSearchRuleTenders_filterTenders_results_data[] | null | undefined;
    filterData: any[] | null;
    loading: boolean;
    error: ApolloError | undefined;
    totalItems: number | undefined;
    startSearch(): void;
    count: number | undefined;
    loadingText: string;
}

const ResultsModal: React.FC<Props> = ({ tenderData, loading, error, filterData, totalItems, startSearch, count, loadingText }) => {
    const { t } = useTranslation();
    const { setCheckedSub, setSector, filters, setFilters, setChosenADs, setCpvs, setTypes, setCaTypes, setRegions, setCountry } =
        useTenderSearchContext(); // Use the context hook to access context value and function

    const [selectedIndex, setSelectedIndex] = useState<string | undefined>(tenderData?.[0]?.id);
    const [caSearchQuery, setCaSearchQuery] = useState<string>("");
    const [caFilterSet, setCaFilterSet] = useState<GetContractingAuthorityFilterData_filterContractingAuthorities_results | null>();
    //custom options for ca filter
    const { setCustomOptions, customOptions } = useCustomSearchOptions();

    // fetch ca's based on userinput query in searchbox (filter)
    const [searchCA, { loading: loadCaFilterset }] = useLazyQuery<GetContractingAuthorityFilterData, GetContractingAuthorityFilterDataVariables>(
        QUERY_CA_FILTER_DATA,
        {
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (data && data.filterContractingAuthorities) {
                    setCaFilterSet(data.filterContractingAuthorities.results);
                }
            },
        }
    );
    // const [showSearch, setShowSearch] = useState(false);
    /**
     *
     * @param id set id clicked item
     */
    const handleListItemClick = (id: string) => {
        setSelectedIndex(id);
    };

    /**
     * Set first result as default (opened detailview)
     */
    useEffect(() => {
        setSelectedIndex(tenderData?.[0]?.id);
    }, [tenderData]);

    useEffect(() => {
        if (caSearchQuery === undefined || (caSearchQuery !== undefined && caSearchQuery.length === 0)) {
            setCaFilterSet(undefined);
        }
        if (caSearchQuery !== undefined && caSearchQuery.length > 2) {
            searchCA({
                variables: {
                    page: 1,
                    count: 1000,
                    orderField: "last_update_date_timestamp",
                    order: SortOrder.DESC,
                    search: {
                        source: ContractingAuthoritiesFilterSource.ALL_CONTRACTING_AUTHORITIES,
                        query: `"${caSearchQuery}"`,
                        filters: {
                            // tenderCount: 0,
                        },
                    },
                },
            });
        }
    }, [searchCA, caSearchQuery]);

    return (
        <Grid
            container
            sx={{
                width: {
                    xs: "90vw",
                    xxl: "70vw",
                },
            }}
        >
            {/* Left column, suggestions*/}
            <Grid
                item
                xs={12}
                style={{ display: "flex", alignItems: "center", width: "100%", marginTop: 8, marginBottom: 8 }}
                data-tut="reactour__resultsFilter"
            >
                <Disable disabled={loading}>
                    <div style={{ height: 40 }}>
                        {/* Dynamic filters based on active filters and results */}
                        {filterData
                            ?.sort((a, b) => sortingArray.indexOf(a.title) - sortingArray.indexOf(b.title))
                            .filter((filter) => filter.searchRuleFilter === true)
                            .map((filter, i) => {
                                // change test to filter.title
                                const test = filter.title;
                                const filterItems = filters?.[test as keyof TenderFilterInput] as any[];
                                if (test === "cpvs") {
                                    return (
                                        <FilterDropdownStringVersion
                                            key={filter.title}
                                            disabled={
                                                false
                                                // filter.values.length === 0
                                            }
                                            allowSearch
                                            label={`${t(`filter.${filter.title}`)}`}
                                            values={filterItems || []}
                                            onChange={(target) => {
                                                // If target has over 0 items. add filter to searchInput
                                                if (target.length > 0) {
                                                    setFilters(update(filters, { [test]: { $set: target } }));
                                                    setCpvs(target);
                                                }

                                                // If target.length = 0 -> remove empty filter from searchInput
                                                if (target.length === 0) {
                                                    setFilters(update(filters, { $unset: [test as keyof TenderFilterInput] }));
                                                    setCpvs([]);
                                                }
                                                // Execute startsearch each time
                                                // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                                startSearch();
                                            }}
                                            options={filter.values.map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                                key: fltr.label,
                                                label: `${fltr.label}`,
                                                id: fltr.identifier,
                                            }))}
                                            width={325}
                                        />
                                    );
                                }

                                /**
                                 * Regions has string as input
                                 * Tender CAS filter
                                 */
                                if (test === "contractingAuthorities") {
                                    const optionsToShow =
                                        caSearchQuery !== undefined &&
                                        caSearchQuery.length > 0 &&
                                        caFilterSet?.paginatorInfo.total !== undefined &&
                                        caFilterSet?.paginatorInfo.total > 0
                                            ? caFilterSet?.data.map((ca) => ({
                                                  key: ca.name,
                                                  label: ca.name,
                                                  id: parseInt(ca.id),
                                              }))
                                            : filter.values
                                                  .sort(function (a: any, b: any) {
                                                      return parseInt(b.key) - parseInt(a.key);
                                                  })
                                                  .map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                                      key: fltr.label,
                                                      label: `${fltr.label}`,
                                                      id: parseInt(fltr.identifier as string),
                                                  }));
                                    if (filters.contractingAuthorities) {
                                        return (
                                            <FilterDropdownCAWithEdit
                                                key={filter.title}
                                                helpertext={caFilterSet?.data.length === 0 ? "Geen resultaat gevonden" : ""}
                                                disabled={false}
                                                allowSearch={false}
                                                allowGqlSearch={true}
                                                setGqlSearch={setCaSearchQuery}
                                                gqlSearch={caSearchQuery}
                                                gqlLoading={loadCaFilterset}
                                                label={`${t(`filter.${filter.title}`)}`}
                                                /**
                                                 * Custom setter to make it possible to search in query and in filterdata to select ca's
                                                 */
                                                setCustomChosenOptions={setCustomOptions}
                                                customChosenOptions={customOptions}
                                                onChange={(target) => {
                                                    // If target has over 0 items. add filter to searchInput
                                                    if (target.length > 0) {
                                                        setFilters(update(filters, { [test]: { $set: target } }));
                                                    }
                                                    // If target.length = 0 -> remove empty filter from searchInput
                                                    if (target.length === 0) {
                                                        setFilters(update(filters, { $unset: [test as keyof TenderFilterInput] }));
                                                        setChosenADs([]);
                                                    }
                                                    // Execute startsearch each time
                                                    // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                                    startSearch();
                                                }}
                                                alreadyChosenIDs={filters.contractingAuthorities || []}
                                                options={optionsToShow}
                                                width={330}
                                            />
                                        );
                                    }
                                    return (
                                        <FilterDropdownCA
                                            key={filter.title}
                                            helpertext={caFilterSet?.data.length === 0 ? "Geen resultaat gevonden" : ""}
                                            disabled={false}
                                            allowSearch={false}
                                            allowGqlSearch={true}
                                            setGqlSearch={setCaSearchQuery}
                                            gqlSearch={caSearchQuery}
                                            gqlLoading={loadCaFilterset}
                                            label={`${t(`filter.${filter.title}`)}`}
                                            /**
                                             * Custom setter to make it possible to search in query and in filterdata to select ca's
                                             */
                                            setCustomChosenOptions={setCustomOptions}
                                            customChosenOptions={customOptions}
                                            onChange={(target) => {
                                                // If target has over 0 items. add filter to searchInput
                                                if (target.length > 0) {
                                                    setFilters(update(filters, { [test]: { $set: target } }));
                                                }
                                                // If target.length = 0 -> remove empty filter from searchInput
                                                if (target.length === 0) {
                                                    setFilters(update(filters, { $unset: [test as keyof TenderFilterInput] }));
                                                }
                                                // Execute startsearch each time
                                                // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                                startSearch();
                                            }}
                                            options={optionsToShow}
                                            width={330}
                                        />
                                    );
                                }

                                /**
                                 * Regions has string as input
                                 */
                                if (test === "regions") {
                                    return (
                                        <FilterDropdownStringVersion
                                            key={filter.title}
                                            disabled={
                                                false
                                                // filter.values.length === 0
                                            }
                                            allowSearch
                                            label={`${t(`filter.${filter.title}`)}`}
                                            values={(filters?.[test as keyof TenderFilterInput] as any[]) || []}
                                            onChange={(target) => {
                                                // If target has over 0 items. add filter to searchInput
                                                if (target.length > 0) {
                                                    setFilters(update(filters, { [test]: { $set: target } }));
                                                }
                                                // If target.length = 0 -> remove empty filter from searchInput
                                                if (target.length === 0) {
                                                    setFilters(update(filters, { $unset: [test as keyof TenderFilterInput] }));
                                                    setRegions([]);
                                                }
                                                // Execute startsearch each time
                                                // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                                startSearch();
                                            }}
                                            options={filter.values.map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                                key: fltr.label,
                                                label: `${fltr.label}`,
                                                id: fltr.identifier,
                                            }))}
                                            width={275}
                                        />
                                    );
                                }
                                return (
                                    <FilterDropdown
                                        key={i}
                                        disabled={false}
                                        allowSearch
                                        label={`${t(`filter.${filter.title}`)}`}
                                        values={filterItems || []}
                                        onChange={(target) => {
                                            // If target has over 0 items. add filter to searchInput
                                            if (target.length > 0) {
                                                setFilters(update(filters, { [test]: { $set: target } }));
                                                if (test === "sectors") {
                                                    setSector(target);
                                                }
                                                if (test === "subSectors") {
                                                    setCheckedSub(target);
                                                }
                                            }
                                            // If target.length = 0 -> remove empty filter from searchInput
                                            if (target.length === 0) {
                                                setFilters(update(filters, { $unset: [test as keyof TenderFilterInput] }));
                                                if (test === "sectors") {
                                                    setSector([]);
                                                }
                                                if (test === "subSectors") {
                                                    setCheckedSub([]);
                                                }
                                                if (test === "country") {
                                                    setCountry([]);
                                                }
                                                if (test === "type") {
                                                    setTypes([]);
                                                }
                                                if (test === "country") {
                                                    setCountry([]);
                                                }
                                                if (test === "contractingAuthorityTypes") {
                                                    setCaTypes([]);
                                                }
                                            }
                                            // Execute startsearch each time
                                            // If a filter was set and now removed -> startsearch has to search again because the filter is removed
                                            startSearch();
                                        }}
                                        options={filter.values.map((fltr: { label: string; amountOfResults: string; identifier: string }) => ({
                                            key: fltr.label,
                                            label: `${fltr.label}`,
                                            id: parseInt(fltr.identifier),
                                        }))}
                                        width={test === "type" ? 250 : test === "country" ? 250 : 325}
                                    />
                                );
                            })}
                    </div>
                </Disable>
            </Grid>

            <Grid item container style={{ height: "73.6vh" }}>
                <Grid xs={12} md={5} item style={{ height: "100%", overflow: "auto" }} data-tut="reactour__resultsResults">
                    {/*
                     * List with tenderitems
                     * data = all tenders in worklist
                     * onitemclick = get id of clicked tender
                     * selectedindex = push id of selected tender to set clicked bg color
                     */}
                    {loading ? (
                        <Paper elevation={1} style={{ height: "100%", justifyContent: "center", alignItems: "center" }}>
                            <FullPageSpinner loadingText={loadingText} />
                        </Paper>
                    ) : (
                        <SplitList data={tenderData} onItemClick={handleListItemClick} selectedIndex={selectedIndex} />
                    )}
                </Grid>

                {/* Grid with detailview of selected item */}
                <Grid xs={12} md={7} item style={{ height: "100%", paddingLeft: "16px" }} data-tut="reactour__resultsDetails">
                    <TenderViewer selectedTender={selectedIndex} data={tenderData} totalTenders={totalItems} loading={loading} />
                </Grid>
            </Grid>

            <Grid item container xs={12} style={{ marginTop: 8 }}>
                <Grid item xs={5} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    {/* <Paper square className={classes.root} style={{ width: showSearch ? 400 : 43 }}>
                        <IconButton className={classes.iconButton} onClick={() => setShowSearch(!showSearch)} aria-label="search">
                            <Search />
                        </IconButton>
                        {showSearch && <Divider className={classes.divider} orientation="vertical" />}
                        <InputBase className={classes.input} placeholder="Zoeken in resultaten" />
                    </Paper> */}
                    {!loading && (
                        <Typography style={{ marginRight: 4, fontWeight: 500 }}>
                            {count} van {totalItems} resultaten
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ResultsModal;
