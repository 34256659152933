import React from "react";
import { Container, Typography } from "@mui/material";
import Layout from "./Layout";
import { useParams } from "react-router-dom";
import { arrayWithCategories } from "./CategoryOverview";
import ButtonBack from "./ButtonBack";
import ListOfArticles from "./ListOfArticles";
import { useQuery } from "@apollo/client";
import {
    filterArticles,
    filterArticles_filterArticles_data,
    filterArticles_filterArticles_paginatorInfo,
    filterArticlesVariables,
} from "../../__generated__/filterArticles";
import { QUERY_FILTER_ARTICLES } from "../../graphql/queryDefFaq";
import { useArticleFilter } from "../../components/contextProviders/ArticleFilterContext";
import history from "../tendenz/editions/history.json";
import { KnowledgebaseLanguage } from "../../__generated__/globalTypes";
import FaqFilters from "./FaqFilters";
import ColumnFilters from "./ColumnFilters";

interface Props {
    title: string;
    subtext: React.ReactNode;
    showSearch: boolean;
    textBelowSearchBar: React.ReactNode;
    overview?: boolean;
}

const SingleCatPage: React.FC<Props> = ({ showSearch, textBelowSearchBar, overview, title, subtext }) => {
    const { categoryName } = useParams();
    const categorie = arrayWithCategories.find((cat) => cat.url === categoryName);
    const { query, setArticles, articles, module, page, setPage, setQuery, setModule } = useArticleFilter();
    console.log("🚀 ~ articles:", page, articles);

    const overviewvariables: filterArticlesVariables = {
        first: 10,
        page,
        search: query,
    };

    const variables: filterArticlesVariables = {
        first: 10,
        page,
        search: query,
        ...(categorie ? { category: categorie?.category } : {}),
        ...(module ? { module } : {}),
    };
    const variablesToUse = overview ? overviewvariables : variables;

    const { loading, data, fetchMore } = useQuery<filterArticles, filterArticlesVariables>(QUERY_FILTER_ARTICLES, {
        variables: variablesToUse,
        fetchPolicy: "network-only", // Ensures data is stored in cache
        notifyOnNetworkStatusChange: true, // Allows tracking of loading state
        onCompleted: (newData) => {
            // setArticles([]);
            if (newData && newData.filterArticles && newData.filterArticles) {
                if (categoryName === "tendenz") {
                    // Function to map event data to `filterArticles_filterArticles_data`
                    const mappedArticles: filterArticles_filterArticles_data[] = history.editions.map((edition) => ({
                        __typename: "KnowledgebaseArticle",
                        id: edition.event_id,
                        title: edition.name,
                        active: true, // Assuming all events are active
                        category: "tendenz", // Assuming a fixed category
                        language: KnowledgebaseLanguage.NL, // Assuming Dutch as a default language
                        module: edition.type,
                        slug: edition.name.toLowerCase().replace(/\s+/g, "-"), // Convert name to slug
                        top_item: false, // Default value
                        content: edition.description, // Map description to content
                        created_at: edition.date, // Use current date for created_at
                        tags: [], // Using location as a tag
                        author: null, // Placeholder author
                    }));

                    const sortedMappedArticles = mappedArticles.sort((a, b) => {
                        const dateA = new Date(a.created_at);
                        const dateB = new Date(b.created_at);

                        // Sort in descending order (newest first)
                        return dateB.getTime() - dateA.getTime();
                    });

                    setArticles(sortedMappedArticles);
                }
                setArticles((prev) => [...prev, ...newData.filterArticles.data]); // ✅ Merge new data
            }
        },
    });

    const loadMore = () => {
        fetchMore({
            variables: { ...variablesToUse, page: page + 1 },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult?.filterArticles?.data) return prevResult;

                // Combine previous data with new data, filtering out duplicates
                const newArticles = fetchMoreResult.filterArticles.data.filter(
                    (article) => !prevResult.filterArticles.data.some((prevArticle) => prevArticle.id === article.id)
                );

                // Merge the old data and the new data in the state
                // setArticles((prevArticles) => [...prevArticles, ...newArticles]);
                setArticles((prevArticles) => {
                    // Create a set of the existing article IDs for quick lookup
                    const existingArticleIds = new Set(prevArticles.map((article) => article.id));

                    // Filter out new articles that already exist in prevArticles
                    const uniqueNewArticles = newArticles.filter((article) => !existingArticleIds.has(article.id));

                    // Return the updated state with unique new articles
                    return [...prevArticles, ...uniqueNewArticles];
                });

                // Return the updated result for the cache
                return {
                    ...prevResult,
                    filterArticles: {
                        ...fetchMoreResult.filterArticles,
                        data: [...prevResult.filterArticles.data, ...newArticles],
                    },
                };
            },
        });

        setPage((prevPage) => prevPage + 1); // Increment the page for the next fetch
    };

    return (
        <Layout
            showSearch={showSearch}
            title={overview ? title : (categorie?.title as string)}
            subtext={
                overview ? (
                    subtext
                ) : (
                    <Typography variant="h3" sx={{ fontWeight: 500 }}>
                        {categorie?.subText as string}
                    </Typography>
                )
            }
            textBelowSearchBar={
                categorie?.category === "support_content" ? (
                    <FaqFilters />
                ) : categorie?.category === "column" ? (
                    <ColumnFilters />
                ) : articles.length === 0 && !loading ? (
                    <Typography variant="h3" sx={{ fontWeight: 500, margin: "32px" }}>
                        Geen artikelen gevonden
                    </Typography>
                ) : (
                    <Typography variant="h3" sx={{ fontWeight: 500, margin: "32px" }}>
                        {textBelowSearchBar}
                    </Typography>
                )
            }
        >
            <Container maxWidth="lg">
                {!overview && (
                    <ButtonBack
                        navigateTo={`/knowledge-hub`}
                        category={null}
                        clicked={() => {
                            setQuery("");
                            setModule(null);
                        }}
                    />
                )}
                <ListOfArticles
                    overview={overview}
                    loadMore={loadMore}
                    loading={loading}
                    paginatorInfo={data?.filterArticles.paginatorInfo as filterArticles_filterArticles_paginatorInfo}
                />
            </Container>
        </Layout>
    );
};

export default SingleCatPage;
