import React, { useState } from "react";
import { Box, Checkbox, InputAdornment, ListItem, ListItemIcon, ListItemText, Popover, TextField, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";

import truncate from "truncate";
import { UserInTeam } from "../UserAndTeams/UsersAndTeams";
import DOMPurify from "dompurify";
import { sanitizeInput } from "../../../utils/sanitizeInput";

interface Props {
    anchorEl: any;
    handleCloseSub: (e: any) => void;
    shareOpened: boolean;
    users: UserInTeam[];
    checked: number[];
    setChecked: React.Dispatch<React.SetStateAction<number[]>>;
}

const SearchUsers: React.FC<Props> = ({ anchorEl, handleCloseSub, shareOpened, users, checked, setChecked }) => {
    const [searchTerm, setSearchTerm] = useState<string>("");

    /**
     * handle user input in searchbox to search a user on his givenname
     * @param event Value from textfield to search a user in the list.
     */
    const handleSearchUser = (event: any) => {
        event.stopPropagation();
        const {
            target: { value },
        } = event;
        const inputvalue = sanitizeInput(value, false);
        const val = DOMPurify.sanitize(inputvalue);
        setSearchTerm(val);
    };

    /**
     * Array that gives users back that match with input in searchfield.
     * - match based on input and givenname
     */
    const results = !searchTerm ? users : users.filter((item) => item.employee.name?.toLowerCase().includes(searchTerm.toLocaleLowerCase()));

    /**
     * @param value user id
     * @returns return checked or unchecked state of user
     */
    const handleToggle = (e: any, value: number) => {
        e.stopPropagation();
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        <Popover
            anchorEl={anchorEl}
            open={shareOpened}
            onClose={handleCloseSub}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            /**
             * Width and height for chat/knowledge base
             */
            slotProps={{
                paper: {
                    sx: {
                        width: "300px",
                    },
                },
            }}
            // PaperProps={{
            //     style: { width: "300px" },
            // }}
        >
            <div>
                {/*
                 * Searchbox
                 */}
                <Box style={{ width: "100%", display: "flex" }}>
                    <TextField
                        onClick={(e) => e.stopPropagation()}
                        sx={{ margin: "10px", flexGrow: 1 }}
                        placeholder="Zoek een gebruiker..."
                        size="small"
                        variant="outlined"
                        onChange={(e) => {
                            e.stopPropagation();
                            handleSearchUser(e);
                        }}
                        value={searchTerm}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box sx={{ height: "310px", overflow: "auto" }}>
                    {results.map((usert) => {
                        const user = usert.employee;
                        /**
                         * UserListItem component for details of user with edit and delete option
                         */
                        return (
                            <ListItem key={user.id} onClick={(e) => handleToggle(e, parseInt(usert.id))} dense button disableRipple>
                                {/* Checkbox */}
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked.indexOf(parseInt(usert.id)) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        color="primary"
                                    />
                                </ListItemIcon>
                                {/* Listitem with userdetails. */}
                                <ListItemText
                                    /**
                                     * Combination of user givenname and familyname. line through when user is inactive
                                     */
                                    primary={
                                        <>
                                            <Typography>{truncate(user.name ? user.name : "", 40)}</Typography>
                                        </>
                                    }
                                    /**
                                     * Second line in useritem, linethrough when state is inactive.
                                     */
                                    secondary={
                                        <>
                                            <Typography style={{ fontSize: "0.875rem" }}>{usert.email}</Typography>
                                        </>
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </Box>
            </div>
        </Popover>
    );
};

export default SearchUsers;
