import React from "react";
import { Grid, IconButton, MenuItem, Pagination, Select, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TenderFilterTarget } from "../__generated__/globalTypes";
import { InfoOutlined } from "@mui/icons-material";

interface Props {
    onChangePagination: (page: number, rowsPerPage: number) => void;
    currentPage: number;
    perPage: number;
    total: number;
    lastItemIndex: number | null;
    firstItemIndex: number | null;
    target?: TenderFilterTarget;
}

const TableNavigation: React.FC<Props> = ({ onChangePagination, currentPage, perPage, total, lastItemIndex, firstItemIndex, target }) => {
    const { t } = useTranslation();
    const amountPages = Math.ceil(total / perPage);
    return (
        <Grid container spacing={2} style={{ padding: 20 }} alignItems="center" justifyContent="space-between">
            {/* Left section
             *  number of total results found
             */}
            <Grid item sm={12} md={3} style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                {total === 0 ? 0 : firstItemIndex?.toLocaleString()}-{lastItemIndex?.toLocaleString()} {t("of")} {total.toLocaleString()}
                {target === "ALL_DOCS" && (
                    <Tooltip
                        title={
                            "Deze resultaten werden gevonden in de samenvatting en in alle documenten samen. Vanwege een prestatie beperking wordt alleen gezocht in documenten van de laatste 12 maanden. Als je de zoekregel opslaat, wordt wel onbeperkt gezocht in alle documenten."
                        }
                    >
                        <IconButton sx={{ width: "0.7em", height: "0.7em", margin: "0 8px" }}>
                            <InfoOutlined sx={{ width: "0.7em", height: "0.7em" }} />
                        </IconButton>
                    </Tooltip>
                )}
            </Grid>

            {/* Middle section,
             *  Show amount of pages with back/next arrow
             */}
            <Grid item sm={12} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Pagination page={currentPage} count={amountPages} onChange={(_, page) => onChangePagination(page, perPage)} />
            </Grid>

            {/* Right section
             *  Selectable field with option to show more/less rows per page
             */}
            <Grid item sm={12} md={3} style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                <Select
                    variant="standard"
                    value={perPage}
                    onChange={(e) => onChangePagination(currentPage, e.target.value as number)}
                    style={{ marginRight: 4 }}
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                </Select>

                {t("resultsPerPage")}
            </Grid>
        </Grid>
    );
};

export default TableNavigation;
