import React, { useContext, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { Disable } from "react-disable";
import { single_project_tasks_project_tasks } from "../../../../__generated__/single_project_tasks";
import { Close, RadioButtonUnchecked, TaskAlt, Visibility } from "@mui/icons-material";
import moment from "moment";
import EditableTaskField from "../EditableTaskField";
import TaskDatePicker from "./TaskDatePicker";
import UserForTask from "./UserForTask";
import TaskPriorityChip from "./TaskPriorityChip";
import { Priority, ProjectToOpenContext } from "../../../../components/layout/MainLayout";
import { sanitizeInput } from "../../../../utils/sanitizeInput";

interface Props {
    task: single_project_tasks_project_tasks;
    taskOfCu: boolean;
    hoverBox: string;
    setHoverBox: (str: string) => void;
    clickedBox: string;
    onChangeBoolean: (taskId: string, boolean: boolean) => Promise<void>;
    onChangesaveTaskDeadline: (taskId: string, deadline: moment.Moment | null) => Promise<void>;
    onChangesaveTaskDescription: (taskId: string, description: string) => Promise<void>;
    onChangePriority: (taskId: string, priority: Priority) => Promise<void>;
    onDelete: (taskId: string) => Promise<void>;
}

const MobileTask: React.FC<Props> = ({
    task,
    taskOfCu,
    hoverBox,
    setHoverBox,
    clickedBox,
    onChangeBoolean,
    onChangesaveTaskDeadline,
    onChangesaveTaskDescription,
    onChangePriority,
    onDelete,
}) => {
    const { project } = useContext(ProjectToOpenContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [open, setOpen] = useState<string | false>("");

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = (value: string) => {
        setOpen(value);
    };

    return (
        <React.Fragment>
            <Dialog fullWidth onClose={handleClose} open={open === task.id}>
                <Card sx={{ display: "flex" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                        <CardContent sx={{ display: "flex", alignItems: "center" }}>
                            <div>
                                {!taskOfCu ? (
                                    <div>
                                        <Disable disabled>
                                            <Checkbox
                                                edge="start"
                                                size="small"
                                                checked={task.task_completed}
                                                icon={
                                                    task.task_completed === true ? (
                                                        <TaskAlt fontSize="small" sx={{ color: "#2E76FF4d" }} />
                                                    ) : (
                                                        <RadioButtonUnchecked fontSize="small" sx={{ color: "#cccccc" }} />
                                                    )
                                                }
                                                checkedIcon={<TaskAlt sx={{ color: "#2E76FF" }} />}
                                            />
                                        </Disable>
                                    </div>
                                ) : (
                                    <Checkbox
                                        onMouseOver={() => setHoverBox(task.id)}
                                        onMouseOut={() => setHoverBox("")}
                                        icon={
                                            clickedBox === task.id ? (
                                                <TaskAlt fontSize="small" sx={{ color: "#2E76FF" }} />
                                            ) : hoverBox === task.id ? (
                                                <TaskAlt fontSize="small" sx={{ color: "#2E76FF4d" }} />
                                            ) : (
                                                <RadioButtonUnchecked fontSize="small" />
                                            )
                                        }
                                        checkedIcon={<TaskAlt sx={{ color: "#2E76FF" }} />}
                                        edge="start"
                                        size="small"
                                        checked={task.task_completed}
                                        disableRipple
                                        // setState to checked value
                                        onChange={(e) => {
                                            if (task.users && task.users.filter((u) => u.id === localStorage.getItem("user_id"))) {
                                                onChangeBoolean(task.id, e.target.checked);
                                            }
                                        }}
                                    />
                                )}
                            </div>
                            {/* Tasks text */}
                            <ListItemText
                                sx={{ textDecoration: task.task_completed ? "line-through" : "none" }}
                                primary={
                                    <EditableTaskField
                                        disabled={task.task_completed}
                                        tasktext={task.task_description}
                                        setTasktext={(txt) => {
                                            const sanitizedValue = sanitizeInput(txt, false);
                                            if (taskOfCu) {
                                                onChangesaveTaskDescription(task.id, sanitizedValue);
                                            }
                                        }}
                                        taskID={task.id}
                                        bossOfProject={taskOfCu}
                                    />
                                }
                            />
                        </CardContent>
                        <Box sx={{ display: "flex", alignItems: "center", p: 1, justifyContent: "space-around" }}>
                            {/* DatePicker */}
                            <Disable disabledOpacity={task.task_completed ? 0.6 : 0.8} disabled={task.task_completed || !taskOfCu}>
                                <TaskDatePicker
                                    date={task.task_deadline !== null ? moment(task.task_deadline) : null}
                                    updateDate={(date) => {
                                        if (taskOfCu) {
                                            onChangesaveTaskDeadline(task.id, date);
                                        }
                                    }}
                                />
                            </Disable>
                            <div style={{ minWidth: "8px" }} />
                            {/* Users linked to task */}
                            <Disable
                                disabledOpacity={project.users.length === 1 ? 1 : 0.7}
                                disabled={task.task_completed || project.users.length === 1}
                            >
                                <UserForTask chosenUserId={task.users as any} task_id={task.id} />
                            </Disable>
                            <div style={{ minWidth: "8px" }} />
                            {/* Prioritychip  */}
                            <Disable disabledOpacity={task.task_completed ? 0.6 : 0.8} disabled={task.task_completed}>
                                <TaskPriorityChip
                                    dot={isMobile ? true : false}
                                    disabled={task.task_completed || !taskOfCu}
                                    priority={task.task_priority}
                                    updatePrio={(prio) => {
                                        if (taskOfCu) {
                                            onChangePriority(task.id, prio as Priority);
                                        }
                                    }}
                                />
                            </Disable>

                            <IconButton
                                sx={{ "&:hover": { color: (theme) => theme.palette.error.main } }}
                                size="small"
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                    onDelete(task.id);
                                }}
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        </Box>
                    </Box>
                </Card>
            </Dialog>
            <ListItem
                disablePadding
                dense
                sx={{
                    paddingRight: "32px",
                }}
            >
                {/*
                 * Icon to show
                 * - Disable state if task is not for current user
                 */}
                <ListItemIcon>
                    {!taskOfCu ? (
                        <div>
                            <Disable disabled>
                                <Checkbox
                                    edge="start"
                                    size="small"
                                    checked={task.task_completed}
                                    sx={{ marginLeft: "4px" }}
                                    icon={
                                        task.task_completed === true ? (
                                            <TaskAlt fontSize="small" sx={{ color: "#2E76FF4d" }} />
                                        ) : (
                                            <RadioButtonUnchecked fontSize="small" sx={{ color: "#cccccc" }} />
                                        )
                                    }
                                    checkedIcon={<TaskAlt sx={{ color: "#2E76FF" }} />}
                                />
                            </Disable>
                        </div>
                    ) : (
                        <Checkbox
                            onMouseOver={() => setHoverBox(task.id)}
                            onMouseOut={() => setHoverBox("")}
                            icon={
                                clickedBox === task.id ? (
                                    <TaskAlt fontSize="small" sx={{ color: "#2E76FF" }} />
                                ) : hoverBox === task.id ? (
                                    <TaskAlt fontSize="small" sx={{ color: "#2E76FF4d" }} />
                                ) : (
                                    <RadioButtonUnchecked fontSize="small" />
                                )
                            }
                            checkedIcon={<TaskAlt sx={{ color: "#2E76FF" }} />}
                            edge="start"
                            size="small"
                            checked={task.task_completed}
                            disableRipple
                            sx={{ marginLeft: "4px" }}
                            // setState to checked value
                            onChange={(e) => {
                                if (task.users && task.users.filter((u) => u.id === localStorage.getItem("user_id"))) {
                                    onChangeBoolean(task.id, e.target.checked);
                                }
                            }}
                        />
                    )}
                </ListItemIcon>

                {/* Tasks text */}
                <ListItemText
                    sx={{ textDecoration: task.task_completed ? "line-through" : "none" }}
                    primary={
                        <EditableTaskField
                            disabled={task.task_completed}
                            tasktext={task.task_description}
                            setTasktext={(txt) => {
                                if (taskOfCu) {
                                    const sanitizedValue = sanitizeInput(txt, false);
                                    onChangesaveTaskDescription(task.id, sanitizedValue);
                                }
                            }}
                            taskID={task.id}
                            bossOfProject={taskOfCu}
                        />
                    }
                />

                <Disable disabledOpacity={project.users.length === 1 ? 1 : 0.7} disabled={task.task_completed || project.users.length === 1}>
                    <UserForTask chosenUserId={task.users as any} task_id={task.id} />
                </Disable>
                <div style={{ minWidth: "8px" }} />
                {/* Delete task */}
                <ListItemSecondaryAction
                    sx={{
                        right: "8px",
                    }}
                >
                    <IconButton
                        sx={{ "&:hover": { color: (theme) => theme.palette.error.main } }}
                        size="small"
                        edge="end"
                        onClick={() => handleOpen(task.id)}
                    >
                        <Visibility fontSize="small" />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </React.Fragment>
    );
};

export default MobileTask;
