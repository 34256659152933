import React from "react";
import Layout from "./Layout";
import { Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useArticleFilter } from "../../components/contextProviders/ArticleFilterContext";
import SingleCatPage from "./SingleCatPage";

interface Props {
    a?: number;
}

const CategoryOverview: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const { setCategory, query } = useArticleFilter();

    if (query.length > 0) {
        return (
            <SingleCatPage
                overview
                title={"Welkom bij onze kennis hub"}
                subtext={
                    <Typography variant="h3" sx={{ fontWeight: 500 }}>
                        Hier hebben we allerlei nuttige informatie verzameld op één plek.
                    </Typography>
                }
                showSearch
                textBelowSearchBar={<></>}
            />
        );
    }
    return (
        <Layout
            showSearch
            title={"Welkom bij onze kennis hub"}
            subtext={
                <Typography variant="h3" sx={{ fontWeight: 500 }}>
                    Hier hebben we allerlei nuttige informatie verzameld op één plek.
                </Typography>
            }
            textBelowSearchBar={
                <Typography variant="h3" sx={{ fontWeight: 500, margin: "32px" }}>
                    Kies een informatiebron
                </Typography>
            }
        >
            <Container maxWidth="lg" sx={{ overflow: "auto", height: "100%" }}>
                <Grid container spacing={4}>
                    <style>
                        {`
                            div::-webkit-scrollbar {
                                display: none;
                            }
                        `}
                    </style>
                    {arrayWithCategories.map((cat) => {
                        return (
                            <Grid key={cat.id} item xs={12} md={6} lg={4} sx={{ display: "flex" }}>
                                <div
                                    onClick={() => {
                                        navigate(cat.url);
                                        setCategory(cat.category);
                                    }}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "100%",
                                        borderRadius: "24px",
                                        background: "#70a4b726",
                                        width: "100%",
                                        position: "relative",
                                        overflow: "hidden",
                                    }}
                                >
                                    {/* Image Container with Overlay */}
                                    <div
                                        style={{
                                            borderRadius: "24px",
                                            backgroundColor: "#70a4b726",
                                            height: "200px",
                                            cursor: "pointer",
                                            overflow: "hidden",
                                            margin: "4px",
                                        }}
                                        onMouseEnter={(e) => {
                                            const imgElement = e.currentTarget.querySelector("img") as HTMLElement;
                                            const overlayElement = e.currentTarget.querySelector("div") as HTMLElement;

                                            if (imgElement) imgElement.style.transform = "scale(1.2)";
                                            if (overlayElement) overlayElement.style.background = "rgba(0, 0, 0, 0.4)";
                                        }}
                                        onMouseLeave={(e) => {
                                            const imgElement = e.currentTarget.querySelector("img") as HTMLElement;
                                            const overlayElement = e.currentTarget.querySelector("div") as HTMLElement;

                                            if (imgElement) imgElement.style.transform = "scale(1)";
                                            if (overlayElement) overlayElement.style.background = "rgba(0, 0, 0, 0)";
                                        }}
                                    >
                                        <img
                                            draggable="false"
                                            src={cat.img}
                                            alt={cat.title}
                                            style={{
                                                borderRadius: "20px",
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                                transition: "transform 0.3s ease-in-out",
                                            }}
                                        />

                                        {/* Black overlay */}
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                background: "rgba(0, 0, 0, 0)",
                                                transition: "background 0.3s ease-in-out",
                                                borderRadius: "24px",
                                            }}
                                        />
                                    </div>

                                    <div style={{ padding: "32px" }}>
                                        <Typography textAlign="center" gutterBottom variant="h2" fontWeight={700}>
                                            {cat.title}
                                        </Typography>
                                        <Typography textAlign="center" sx={{ fontSize: "18px", lineHeight: 1.2 }}>
                                            {cat.subText}
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
        </Layout>
    );
};

export default CategoryOverview;

export const arrayWithCategories = [
    {
        id: 1,
        title: "Veelgestelde vragen",
        subText: "Hier vind je antwoord op de meest gestelde vragen",
        img: "https://storage.googleapis.com/tenderguide-marketing-assets/faq_placeholder_white.png",
        url: "support_content",
        category: "support_content",
        tekst: "veelgestelde vragen",
    },
    {
        id: 2,
        title: "Blogs & Columns",
        subText: "Lees onze columns om inspiratie op te doen voor jouw dagelijkse praktijk",
        img: "https://storage.googleapis.com/tenderguide-marketing-assets/blogs_and_columns_placeholder_final.png",
        url: "column",
        category: "column",
        tekst: "blogs & columns",
    },
    {
        id: 3,
        title: "In gesprek met",
        subText: "Met regelmaat gaan we in gesprek met experts voor extra verdieping",
        img: "https://storage.googleapis.com/tenderguide-marketing-assets/in_gesprek_met_placeholder.png",
        url: "interview",
        category: "interview",
        tekst: "in gesprek met",
    },
    {
        id: 4,
        title: "TendenZ",
        subText: "Hier lees je meer over onze community events",
        img: "https://storage.googleapis.com/tenderguide-marketing-assets/TendenZ_static.png",
        url: "tendenz",
        category: "tendenz",
        tekst: "tendenZ",
    },
    {
        id: 5,
        title: "30 seconden  deep dive",
        subText: "In deze deep dive-videos laten we zien hoe je TenderGuide kunt gebruiken",
        img: "https://storage.googleapis.com/tenderguide-marketing-assets/deepdive.png",
        url: "deepdive",
        category: "deepdive",
        tekst: "30 seconden  deep dive",
    },
    // {
    //     id: 6,
    //     title: "Projecten met impact",
    //     subText: "Organisaties uit de community vertellen over hun project met impact",
    //     img: "https://lh3.googleusercontent.com/gGORONloh2SSMBO05sP2gCdGr39QhbtRcDt6nZNR_hf0HhAG_VlH6e1UPvyUEXCeXtifwsyBvCqoTRQAvJVP6B26LcScxAVN4NlwDXRbLtiPINkJ61Ke=w960",
    //     url: "projects",
    //     category: "projects_with_impact",
    //     tekst: "projecten met impact",
    // },
    // {
    //     id: 7,
    //     title: "Achtergrondinformatie",
    //     subText: "Meer informatie over regels voor aanbesteden",
    //     img: "https://lh3.googleusercontent.com/mCqqIfxaJ8IY7JjyvRUDgf26Q5bYVnpAJTQIlH1eresdLmToSp_F8qrOm0r5iqKUjJV4hPj3i2d6camRD1vKD1vA3gbC_pmlIZXlMKxX9GPa9GCIWOI=w960",
    //     url: "background_information",
    //     category: "background_information",
    //     tekst: "achtergrondinformatie",
    // },
    // {
    //     id: 8,
    //     title: "Uitspraken",
    //     subText: "Lees de laatste uitspraken binnen het Aanbestedingsrecht",
    //     img: "https://lh3.googleusercontent.com/nv-x3DNfNXIiqgGq8HbaDX1Td6rc1zDfQG0bDtywnnz4TQeaLmEgi27LQGJXvuqiJw5CRF9o2Ehmaiw4a78R8ci-pTzcQLITH4LAac1IqDZARVHfkg=w960",
    //     url: "judgments",
    //     category: "judgments",
    //     tekst: "uitspraken",
    // },
];
