import React from "react";
import { IconButton, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useArticleFilter } from "../../components/contextProviders/ArticleFilterContext";

interface Props {
    navigateTo: string;
    category: string | null;
    clicked?: () => void;
}

const ButtonBack: React.FC<Props> = ({ navigateTo, category, clicked }) => {
    const navigate = useNavigate();
    const { setCategory, setArticles, setPage } = useArticleFilter();
    const theme = useTheme();
    const aboveMobile = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <div style={{ position: "fixed", top: aboveMobile ? 32 : 64, left: aboveMobile ? 96 : 16, zIndex: 50 }}>
            <Tooltip title="Terug" placement="right">
                <IconButton
                    onClick={() => {
                        clicked && clicked();
                        navigate(navigateTo);
                        setCategory(category);
                        setArticles([]);
                        setPage(1);
                    }}
                >
                    <ArrowBack />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default ButtonBack;
