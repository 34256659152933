import React, { useState } from "react";
import { Box } from "@mui/material";

import { Check, Clear, Phone } from "@mui/icons-material";
import { IconButton, TextField, Typography } from "@mui/material";
import { GetCurrentUserSettings_currentUser } from "../../../../__generated__/GetCurrentUserSettings";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../../models/MatomoEvent";
import DOMPurify from "dompurify";
import { sanitizeInput } from "../../../../utils/sanitizeInput";

interface Props {
    onSave(): void;
    personalinfo: GetCurrentUserSettings_currentUser | undefined;
    setPersonalInfo(info: GetCurrentUserSettings_currentUser): void;
}

const PhoneInput: React.FC<Props> = ({ onSave, personalinfo, setPersonalInfo }) => {
    const [phone, setPhone] = useState(false);
    const { t } = useTranslation();

    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const saveSetting = () => {
        onSave();
        trackevents({ category: "Personal", action: "Click-add-phone" });
        setPhone(false);
    };

    return (
        //  {/* phonenumber number */}
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", marginTop: 6 }}>
            <Phone sx={{ marginRight: "12px", color: "#707070" }} />
            {phone ? (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <TextField
                        autoFocus
                        inputProps={{ maxLength: 12 }}
                        // helperText="Onjuist telefoonnummer"
                        name={"phonenumber"}
                        label={`${t("personal.phone")}`}
                        variant={"outlined"}
                        fullWidth
                        size={"small"}
                        value={personalinfo?.employee.phonenumber}
                        // onchange handler to change phonenumber and push with mutation to server
                        onChange={(e) => {
                            const inputvalue = sanitizeInput(e.target.value, false);
                            const val = DOMPurify.sanitize(inputvalue);
                            if (personalinfo !== undefined)
                                setPersonalInfo({
                                    ...personalinfo,
                                    employee: { ...personalinfo.employee, phonenumber: val },
                                });
                        }}
                    />
                    <IconButton size="small" onClick={() => saveSetting()}>
                        <Check />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => {
                            setPhone(false);
                            trackevents({ category: "Personal", action: "Click-cancel-add-phone" });
                        }}
                    >
                        <Clear />
                    </IconButton>
                </div>
            ) : (
                <Box
                    onClick={() => setPhone(true)}
                    sx={{
                        padding: "10.5px 14px 10.5px 14px",
                        height: "37.63px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        "&:hover": {
                            backgroundColor: "#f4f4f4",
                            cursor: "pointer",
                            borderRadius: "4px",
                            width: "100%",
                        },
                    }}
                >
                    <Typography>{personalinfo?.employee.phonenumber ? personalinfo?.employee.phonenumber : ""}</Typography>
                </Box>
            )}
        </div>
    );
};

export default PhoneInput;
