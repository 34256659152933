import React, { useRef } from "react";
import { Collapse, IconButton, ListItemIcon, MenuItem, Popover, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Help, Send, LibraryBooks, DesktopWindowsOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MatomoEvent from "../../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import NavBarGuide from "../../guides/elements/NavBarGuide";
import { useFaq } from "../../contextProviders/FaqOpenerContext";

interface Props {
    setOpenSupport: (openChat: boolean) => void;
    openSupport: boolean;
    // navbar hover state
    checked?: boolean;
}

const SupportMenu: React.FC<Props> = ({ setOpenSupport, openSupport, checked }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { trackEvent } = useMatomo();
    const mobileOnly = useMediaQuery(theme.breakpoints.down("md"));
    const notificationsMenuAnchor = useRef<HTMLButtonElement>(null);
    const { setOpenFaq, setOpenChat } = useFaq();

    const navigate = useNavigate();

    const handleClose = () => {
        setOpenSupport(false);
    };

    // track help items
    const trackHelp = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        <>
            {/* <Tooltip title={t("Help") as string} placement="right"> */}
            <div
                style={{ display: "flex" }}
                onClick={() => {
                    setOpenSupport(true);
                    trackHelp({ category: "Help", action: "Open-help-modal" });
                }}
            >
                <IconButton sx={{ height: "48px", width: "48px" }} data-tut="reactour__navbarhelpopen" disabled={false} ref={notificationsMenuAnchor}>
                    <Help htmlColor="#e6e6e6" />
                </IconButton>
                <div style={{ width: "100%", display: "flex", alignItems: "center", marginLeft: "8px", overflow: "hidden" }}>
                    <Collapse timeout={500} mountOnEnter unmountOnExit in={checked} orientation="horizontal">
                        <Typography variant="h6" fontWeight={500} color={"#fff"} sx={{ "&:hover": { fontWeight: 600 } }}>
                            {t("Help") as string}
                        </Typography>
                    </Collapse>
                </div>
            </div>
            {/* </Tooltip> */}
            <Popover
                anchorEl={notificationsMenuAnchor.current}
                open={openSupport}
                onClose={() => setOpenSupport(false)}
                anchorOrigin={{
                    vertical: mobileOnly ? "bottom" : "bottom",
                    horizontal: mobileOnly ? "center" : 70,
                }}
                transformOrigin={{
                    vertical: mobileOnly ? -20 : "top",
                    horizontal: mobileOnly ? "center" : "left",
                }}
                /**
                 * Width and height for chat/knowledge base
                 */
                slotProps={{
                    paper: {
                        sx: { width: "200px" },
                    },
                }}
                // PaperProps={{
                //     style: { width: "200px" },
                // }}
            >
                <div>
                    <NavBarGuide handleClose={handleClose} />

                    <MenuItem
                        style={{ padding: 12 }}
                        onClick={() => {
                            trackHelp({ category: "Help", action: "Open-knowledgebase" });
                            setOpenFaq(true);
                            setOpenChat(false);
                            handleClose();
                        }}
                    >
                        <ListItemIcon style={{ minWidth: 32 }}>
                            <Send fontSize="small" />
                        </ListItemIcon>
                        Support
                    </MenuItem>
                    <MenuItem
                        style={{ padding: 12 }}
                        onClick={() => {
                            navigate("/knowledge-hub");
                            trackHelp({ category: "Help", action: "Go-to-knowledge-hub" });
                            handleClose();
                        }}
                    >
                        <ListItemIcon style={{ minWidth: 32 }}>
                            <LibraryBooks fontSize="small" />
                        </ListItemIcon>
                        Kennis hub
                    </MenuItem>
                    {/* <MenuItem
                        style={{ padding: 12 }}
                        onClick={() => {
                            trackHelp({ category: "Help", action: "Open-send-message-to-support" });
                            setOpenFaq(true);
                            setOpenChat(true);
                            handleClose();
                        }}
                    >
                        <ListItemIcon style={{ minWidth: 32 }}>
                            <Send fontSize="small" />
                        </ListItemIcon>
                        Bericht sturen
                    </MenuItem> */}
                    <MenuItem
                        style={{ padding: 12 }}
                        onClick={() => {
                            navigate("/request-support");
                            trackHelp({ category: "Help", action: "Go-to-support" });
                            handleClose();
                        }}
                    >
                        <ListItemIcon style={{ minWidth: 32 }}>
                            <DesktopWindowsOutlined fontSize="small" />
                        </ListItemIcon>
                        Schermdeelsessie
                    </MenuItem>
                </div>
            </Popover>
            {/* <NewUserGuide navToSearch={false} resetOpener={setNavTour} /> */}
        </>
    );
};

export default SupportMenu;
