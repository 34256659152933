import React, { useEffect } from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ColumnDetailContainer from "../columns/ColumnDetailContainer";
import { styled, useTheme } from "@mui/material/styles";
import { Today } from "@mui/icons-material";
import moment from "moment";
import ColumnContent from "../columns/ColumnContent";
import { useQuery } from "@apollo/client";
import { QueryInitials } from "../../__generated__/QueryInitials";
import { QUERY_INITIALS } from "../../graphql/queryDefCurrentUser";
import ColumnDots from "../columns/ColumnDots";
// import ColumnFooter from "../columns/ColumnFooter";
import { useArticleFilter } from "../../components/contextProviders/ArticleFilterContext";
import ButtonBack from "./ButtonBack";
import { SingleArticle, SingleArticleVariables } from "../../__generated__/SingleArticle";
import { QUERY_GETSINGLEARTICLE } from "../../graphql/queryDefFaq";
import { arrayWithCategories } from "./CategoryOverview";

interface Props {
    a?: number;
}

// Styled Paper component
const StyledPaper = styled(Grid)(({ theme }) => ({
    height: "auto",
    position: "relative",
}));

const DetailPage: React.FC<Props> = (props) => {
    const { categoryName, id } = useParams();
    const { setArticle, article } = useArticleFilter();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { data: user } = useQuery<QueryInitials>(QUERY_INITIALS);
    const name = user?.currentUser.employee.name?.replace(/\s/g, "%20");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]); // Runs every time the route changes

    const theme = useTheme();
    const aboveMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const aboveTablet = useMediaQuery(theme.breakpoints.up("md"));

    useQuery<SingleArticle, SingleArticleVariables>(QUERY_GETSINGLEARTICLE, {
        skip: article !== null,
        // skip: columnID === undefined,
        fetchPolicy: "network-only",
        variables: { id: id as string },
        onCompleted: (a) => {
            if (a && a.article) {
                if (arrayWithCategories.find((art) => art.category === a.article?.category)?.url === categoryName) {
                    setArticle(a.article);
                } else {
                    navigate("/knowledge-hub");
                }
            }
        },
    });

    // Only columns not blogs
    if (article?.module === "column") {
        return <ColumnDetailContainer />;
    }

    /**
     * Get sanitized content and replace the html tags (<icon_name>) with material icons
     */
    const showIcons = article?.content.replace(/&lt;/g, `<span class="material-icons">`).replace(/&gt;/g, `</span>`);

    // Create a temporary element to parse the HTML string
    // const tempElement = document.createElement("div");
    // tempElement.innerHTML = showIcons;

    // Assuming data?.article?.content contains the string
    const replacingContent =
        article?.id === "138" && showIcons
            ? showIcons.replace("Neem dan snel contact met ons op voor meer informatie of een kennismakingsgesprek.", "<CONTACT/>")
            : showIcons;

    return (
        <StyledPaper container>
            {/* HEADER GRID */}
            <Grid item container xs={12}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        borderRadius: "26% 26% 49% 51% / 0% 0% 50% 50%",
                        backgroundColor: "#70a4b726",
                        padding: aboveTablet ? "128px 0px" : aboveMobile ? "64px 0px" : "16px 0px",
                    }}
                >
                    <div>
                        <Typography
                            sx={{
                                fontSize: aboveTablet ? "40px" : aboveMobile ? "1.5rem !important" : "1.3rem !important",
                                fontWeight: 600,
                                textAlign: "center",
                                padding: aboveMobile ? 0 : "0px 16px",
                            }}
                        >
                            {article?.title}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px", height: "28px" }}>
                        <Today
                            sx={{
                                height: aboveMobile ? "24px" : "16px",
                                width: aboveMobile ? "24px" : "16px",
                                marginRight: "8px",
                                color: "#0000008a",
                            }}
                        />
                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                            {moment(article?.created_at).format("LL")}
                        </Typography>
                    </div>
                    <ButtonBack navigateTo={`/knowledge-hub/${categoryName}`} category={categoryName as string} />
                </Grid>

                <Grid item container columns={14} pt={aboveTablet ? 12 : aboveMobile ? 6 : 4}>
                    <Grid item xs={14} md={3}>
                        {/* COLUMN TO FILL WITH SHAPES */}
                    </Grid>
                    {/* CONTENT BOX */}
                    <Grid
                        item
                        xs={14}
                        md={7}
                        paddingBottom={0}
                        px={aboveTablet ? 0 : "16px"}
                        sx={{ fontSize: aboveMobile ? " 15px" : "medium", overflowX: "hidden" }}
                    >
                        <ColumnContent text={replacingContent || ""} column_id={article?.id || ""} username={name || ""} />
                    </Grid>
                    <Grid item xs={14} md={4} px={aboveMobile ? 0 : 2} />
                </Grid>
                {/* Left spacing grid */}
                {/* <Grid item xs={12} md={2} /> */}

                {/* GRID WITH ALL COLUMNS */}
                {/* <Grid item xs={12} md={12} padding={aboveMobile ? 12 : 0} pt={aboveMobile ? 8 : 0} marginBottom={"64px"}> */}
                {/* <ColumnCarousel currentID={article?.id as string} /> */}
                {/* </Grid> */}

                {/* DOTS */}
                {aboveTablet && <ColumnDots position="fixed" />}

                {/* FOOTER */}
                {/* <ColumnFooter /> */}
            </Grid>
        </StyledPaper>
    );
};

export default DetailPage;
