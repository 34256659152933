import React, { useEffect, useState } from "react";
import { IconButton, List, ListItemIcon, Menu, MenuItem, MenuProps, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowDownward, ArrowUpward, Close, SwapVert } from "@mui/icons-material";
import { OrderByClause, SortOrder } from "../../__generated__/globalTypes";

interface Props {
    defaultSorting: {
        direction: SortOrder;
        field: string;
    };
    onSort: (field: string, direction: SortOrder) => void;
    options?: SortingOption[];
    currentSorting: OrderByClause;
}

export interface SortingOption {
    label: string;
    name: string;
    filter: string;
    visible: boolean;
}

// Styled menu with anchors to show menu underneath button
const StyledMenu = (props: MenuProps) => (
    <Menu
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
);

/**
 *
 * @param name column header
 * @param options array to find value to filter
 * @returns filtervalue
 */
export const getFilterValue = (name: string, options: SortingOption[]): string | undefined => {
    const foundOption = options.find((option) => option.name === name);
    return foundOption ? foundOption.filter : undefined;
};

// Default sorting options
export const defaultTenderSortingOptions: SortingOption[] = [
    { label: "Status", name: "status", filter: "status", visible: true },
    { label: "Deadline", name: "deadline_inschrijving", filter: "deadline_inschrijving", visible: true },
    { label: "Type", name: "type", filter: "type", visible: true },
    { label: "TypeProcedure", name: "type_procedure", filter: "type_procedure", visible: true },
    { label: "DurationStart", name: "looptijd_start", filter: "startdate_contract", visible: true },
    { label: "DurationEnd", name: "looptijd_einde", filter: "enddate_contract", visible: true },
    { label: "FirstEndDate", name: "next_looptijd_einde", filter: "next_enddate_contract", visible: true },
    { label: "Postal_Code", name: "published_postcode", filter: "postalcode", visible: true },
    { label: "City", name: "published_vestigingsplaats", filter: "city", visible: true },
    { label: "Status_Contract", name: "status_contract", filter: "contract_state", visible: true },
    { label: "Award_date", name: "datum_gunning", filter: "award_date", visible: true },
    { label: "Date_incl_options", name: "looptijd_einde_incl_opties", filter: "enddate_contract_incl_options", visible: true },
    { label: "First_dispatchdate", name: "first_dispatchdate", filter: "first_publication", visible: true },
    { label: "Last_update", name: "updated_at", filter: "updated_at", visible: true },
];

const SortingChanger: React.FC<Props> = ({ defaultSorting, onSort, options, currentSorting }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentLabel, setCurrentLabel] = useState<string>(currentSorting.column); // Default label
    const [sortOrder, setSortOrder] = useState<SortOrder>(currentSorting.order); // Default sort order

    // Use useEffect to update currentLabel and sortOrder when currentSorting changes
    useEffect(() => {
        setCurrentLabel(currentSorting.column);
        setSortOrder(currentSorting.order);
    }, [currentSorting]); // This effect runs whenever currentSorting changes

    // Use the options if they are provided, otherwise use the default options
    const optionstoUse = options ? options : defaultTenderSortingOptions;
    // Filter out the options that are not visible
    const visibleOptions = optionstoUse.filter((option) => option.visible);

    /**
     * @param event handler to open menu to change columns
     */
    const handleOpenColumnPopper = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    /**
     * handler to close menu
     */
    const handleCloseColumnPopper = () => {
        setAnchorEl(null);
    };

    // Handler to update sort state based on clicked label and direction
    const handleSortChange = (label: string, order: SortOrder) => {
        onSort(label, order);
        setCurrentLabel(label);
        setSortOrder(order);
        handleCloseColumnPopper();
    };

    // Sort options so that defaultSorting.field appears first
    const sortingOptions = [...visibleOptions].sort((a, b) => (a.filter === defaultSorting.field ? -1 : b.filter === defaultSorting.field ? 1 : 0));

    return (
        <React.Fragment>
            <Tooltip
                disableInteractive
                enterNextDelay={100}
                placement="top"
                // title shows the current sorting and order
                title={`${t("sorting")}: ${t(`filter.${currentSorting.column}`)} - ${t(currentSorting.order)}`}
            >
                <IconButton aria-label="event" size="small" style={{ margin: 1 }} onClick={handleOpenColumnPopper}>
                    <SwapVert fontSize="medium" />
                </IconButton>
            </Tooltip>

            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseColumnPopper}
                MenuListProps={{ sx: { p: 0 } }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "8px 16px 0px",
                    }}
                >
                    <Typography fontWeight={500}>{t("sort")}</Typography>
                    <IconButton size="small" onClick={handleCloseColumnPopper}>
                        <Close fontSize="small" />
                    </IconButton>
                </div>
                {/*
                 * List to add maxHeight with overflow to shorten the list
                 */}
                <List style={{ maxHeight: 385, width: 275, overflow: "auto", padding: 0, paddingTop: "8px" }}>
                    {/* Other sortable items */}
                    {sortingOptions.map((option) => {
                        return (
                            <SortableMenuItem
                                key={option.name}
                                label={option.label}
                                sortname={option.filter}
                                sortOrder={sortOrder}
                                onSortChange={handleSortChange}
                                isActive={currentLabel === option.filter}
                            />
                        );
                    })}
                </List>
            </StyledMenu>
        </React.Fragment>
    );
};

// Define the types for the props
interface SortableMenuItemProps {
    label: string;
    isActive: boolean;
    sortOrder: SortOrder | null;
    onSortChange: (label: string, order: SortOrder) => void;
    sortname: string;
}

const SortableMenuItem: React.FC<SortableMenuItemProps> = ({ label, isActive, sortOrder, onSortChange, sortname }) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const { t } = useTranslation();
    const clickAsc = () => {
        onSortChange(sortname, SortOrder.ASC);
    };

    const clickDesc = () => {
        onSortChange(sortname, SortOrder.DESC);
    };

    return (
        <MenuItem
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: isHovered ? "#f7f7f7" : isActive ? "#f4f4f4" : "transparent", // Highlight active item
            }}
        >
            <Typography
                style={{
                    flexGrow: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontWeight: isActive ? 500 : "normal", // Bold for active item
                }}
            >
                {t(`Column_Header.${label}`)}
            </Typography>

            {/* Arrows only visible on hover and if the item is active */}
            <ListItemIcon
                style={{
                    minWidth: 0,
                    display: "flex",
                    opacity: isHovered || isActive ? 1 : 0, // Show arrows on hover or for active item
                    transition: "opacity 0.3s",
                }}
            >
                {/* DESC */}
                <Tooltip title="Aflopend" placement="top" disableInteractive>
                    <IconButton
                        size="small"
                        onClick={clickDesc} // Use the same toggle handler
                        disabled={sortOrder === "DESC" && isActive} // Disable if already sorted DESC
                        style={{
                            color: isActive && sortOrder === "DESC" ? "#173357" : isHovered ? "gray" : "transparent", // Blue for DESC, gray on hover
                        }}
                    >
                        <ArrowDownward fontSize="small" />
                    </IconButton>
                </Tooltip>

                {/* ASC */}
                <Tooltip title="Oplopend" placement="top" disableInteractive>
                    <IconButton
                        size="small"
                        onClick={clickAsc} // Use the same toggle handler
                        disabled={sortOrder === "ASC" && isActive} // Disable if already sorted ASC
                        style={{
                            color: isActive && sortOrder === "ASC" ? "#173357" : isHovered ? "gray" : "transparent", // Blue for ASC, gray on hover
                        }}
                    >
                        <ArrowUpward fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ListItemIcon>
        </MenuItem>
    );
};

export default SortingChanger;
