import React, { useRef, useEffect, useState } from "react";
import { MenuItem, Typography, Popover, MenuList, useTheme, useMediaQuery, Badge, IconButton, CircularProgress, Collapse } from "@mui/material";
import { useTranslation } from "react-i18next";
import WidgetBoxMenu from "../WidgetBoxMenu";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GetNotificationsCurrentUser, GetNotificationsCurrentUser_currentUser_notifications } from "../../__generated__/GetNotificationsCurrentUser";
import { GET_AMOUNT_OF_UREAD_NOTIFICATIONS_CURRENT_USER, GET_NOTIFICATIONS_CURRENT_USER } from "../../graphql/queryDefCurrentUser";
import { Notifications } from "@mui/icons-material";
import { useNotification } from "../contextProviders/NotificationOpenerContext";
import { markAllNotificationsAsRead } from "../../__generated__/markAllNotificationsAsRead";
import { markNotificationsAsRead, markNotificationsAsReadVariables } from "../../__generated__/markNotificationsAsRead";
import FullPageSpinner from "../loading/FullPageSpinner";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import _ from "lodash";
import DateDivider from "./notificationElements/DateDivider";
import NotificationTypeSelector from "./notificationElements/NotificationTypeSelector";
import { GetAmountOfUnreadNotificationsCurrentUser } from "../../__generated__/GetAmountOfUnreadNotificationsCurrentUser";
import MatomoEvent from "../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useSocket } from "../../utils/websocketChannels";
import { useUserId } from "../contextProviders/UserIdContext";
import client from "../../graphql/gqlClient";

interface Props {
    checked?: boolean;
}

const NotificationsMenu: React.FC<Props> = ({ checked = false }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { trackEvent } = useMatomo();
    const notificationsMenuAnchor = useRef<HTMLButtonElement>(null);
    const { openNotifications, setOpenNotifications } = useNotification();
    const [anchorElement, setAnchorEl] = useState<null | HTMLElement>(null);
    const mobileOnly = useMediaQuery(theme.breakpoints.down("md"));
    const [amountOfNotifications, setAmountOfNotifications] = useState(20);
    const [allNotifications, setAllNotifications] = useState<GetNotificationsCurrentUser_currentUser_notifications>();
    const { userId } = useUserId();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // track navigation items
    const trackNotificationClick = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const [read_all, { error: errorAll, loading: loadingAll }] = useMutation<markAllNotificationsAsRead>(MARK_ALL_NOTIFICATIONS_AS_READ);

    const [read_single, { error: errorSingle, loading: loadingSingle }] = useMutation<markNotificationsAsRead, markNotificationsAsReadVariables>(
        MARK_NOTIFICATION_AS_READ
    );

    // Function to like a tender
    const markAllNotifications = async () => {
        if (loadingAll) return;
        if (errorAll) {
        }
        try {
            await read_all({
                // Refetch get_dashboard_worklist to update the worklist
                refetchQueries: [GET_NOTIFICATIONS_CURRENT_USER, GET_AMOUNT_OF_UREAD_NOTIFICATIONS_CURRENT_USER],
                awaitRefetchQueries: true,
            });
        } catch (e) {}
    };

    // Function to set notification to read
    const markSingleNotification = async (id: string) => {
        if (loadingSingle) return;
        if (errorSingle) {
        }
        try {
            await read_single({
                variables: {
                    id: id,
                },
                // Refetch get_dashboard_worklist to update the worklist
                refetchQueries: [GET_NOTIFICATIONS_CURRENT_USER, GET_AMOUNT_OF_UREAD_NOTIFICATIONS_CURRENT_USER],
                awaitRefetchQueries: true,
            });
        } catch (e) {}
        setOpenNotifications(false);
    };

    /**
     * Batch to set to read
     */
    const markBatchOfNotifications = (ids: string[]) => {
        /**
         * for each id in array => send mutation to set notification as read
         */
        if (ids.length > 0) {
            ids.forEach(async (item: string, index: number) => {
                if (loadingSingle) return;
                if (errorSingle) {
                }
                try {
                    await read_single({
                        variables: {
                            id: item,
                        },
                        // Refetch get_dashboard_worklist to update the worklist
                        refetchQueries: [GET_NOTIFICATIONS_CURRENT_USER, GET_AMOUNT_OF_UREAD_NOTIFICATIONS_CURRENT_USER],
                        awaitRefetchQueries: true,
                    });
                } catch (e) {}

                // Check if this is the last item in the array
                if (index === ids.length - 1) {
                    setOpenNotifications(false);
                    // Refetch here
                    // Refetch other queries or do any other action needed after the last item
                    refetch();
                }
            });
        }
    };

    /**
     * @param amount Number of notfications with state read:null
     * @param menu Optional menu node
     * @param content Node contains data or loading/error message
     * @returns
     */
    const renderPage = (amount: number, menu: React.ReactNode | null, content: React.ReactNode) => (
        <>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Badge
                    badgeContent={amount}
                    showZero={false}
                    invisible={amount === 0}
                    max={99}
                    sx={{
                        "& .MuiBadge-badge": {
                            backgroundColor: (theme) => theme.palette.error.main,
                            color: "#ffffff",
                        },
                    }}
                    // color="secondary"
                    overlap="circular"
                >
                    <IconButton
                        sx={{ height: "48px", width: "48px" }}
                        data-tut="reactour__navbar_notifications"
                        disabled={false}
                        ref={notificationsMenuAnchor}
                        onClick={() => {
                            trackNotificationClick({ category: "Personal", action: "Open-notification-modal" });
                            setOpenNotifications(true);
                            run({
                                variables: {
                                    notiFirst: amountOfNotifications,
                                    notiPage: 1,
                                },
                            });
                        }}
                    >
                        <Notifications htmlColor="#e6e6e6" />
                    </IconButton>
                </Badge>
                {/* Collapse shows text on hover navbar */}
                <div
                    style={{ width: "100%", display: "flex", alignItems: "center", marginLeft: "8px", overflow: "hidden" }}
                    onClick={() => {
                        trackNotificationClick({ category: "Personal", action: "Open-notification-modal" });
                        setOpenNotifications(true);
                        run({
                            variables: {
                                notiFirst: amountOfNotifications,
                                notiPage: 1,
                            },
                        });
                    }}
                >
                    <Collapse timeout={500} mountOnEnter unmountOnExit in={checked} orientation="horizontal">
                        <Typography variant="h6" fontWeight={500} color={"#fff"} sx={{ "&:hover": { fontWeight: 600 } }}>
                            {t("notifications") as string}
                        </Typography>
                    </Collapse>
                </div>
            </div>
            <Popover
                anchorEl={notificationsMenuAnchor.current}
                keepMounted
                open={openNotifications}
                onClose={() => setOpenNotifications(false)}
                anchorOrigin={{
                    vertical: mobileOnly ? "bottom" : "center",
                    horizontal: mobileOnly ? "center" : 70,
                }}
                transformOrigin={{
                    vertical: mobileOnly ? -70 : "top",
                    horizontal: mobileOnly ? "center" : "left",
                }}
                /**
                 * Width and height for chat/knowledge base Paper component
                 */
                slotProps={{
                    paper: { sx: { width: "500px", height: "580px" } },
                }}
            >
                <div>
                    <div
                        className="header"
                        style={{ padding: "22px 20px 8px", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                    >
                        <Typography variant="h3" style={{ fontSize: 18, fontWeight: 500 }}>
                            {t("notifications")}
                        </Typography>
                        {menu}
                    </div>
                    <>{content}</>
                </div>
            </Popover>
        </>
    );

    /**
     * Query amount of unread notifications
     */
    const { data, refetch } = useQuery<GetAmountOfUnreadNotificationsCurrentUser>(GET_AMOUNT_OF_UREAD_NOTIFICATIONS_CURRENT_USER, {
        // pollInterval: 10000,
        // skip: openNotifications ? true : false,
        fetchPolicy: "network-only",
    });

    /**
     * Query all notifications of current user
     */
    const [run, { loading, error, refetch: refetchNew }] = useLazyQuery<GetNotificationsCurrentUser>(GET_NOTIFICATIONS_CURRENT_USER, {
        variables: {
            notiFirst: amountOfNotifications,
            notiPage: 1,
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.currentUser.notifications) {
                setAllNotifications(data.currentUser.notifications);

                // Check if the notification type is "App\\Notifications\\FullSearchFinishedNotification"
                const fullSearchNotification = data.currentUser.notifications.data.find(
                    (notification) => notification.type === "App\\Notifications\\FullSearchFinishedNotification" && notification.read_at === null
                );

                // If such a notification exists, refetch GetUserSearchRules
                if (fullSearchNotification) {
                    client.refetchQueries({
                        include: ["GetUserSearchRules"],
                    });
                }
            }
        },
    });

    /**
     * Whenever new notifications are published on the socket channel,
     * fetch notifications
     */
    useSocket(`user.notifications.${userId}`, ".Illuminate\\Notifications\\Events\\BroadcastNotificationCreated", (e) => {
        refetchNew();
        refetch();
    });

    /**
     * Rerun query when amountOfNotifications changes
     * (Fetch data on scroll)
     */
    useEffect(() => {
        run({
            variables: {
                notiFirst: amountOfNotifications,
                notiPage: 1,
            },
        });
    }, [amountOfNotifications, run]);

    /**
     * Show spinner on initial data fetch
     */
    if (loading && allNotifications === undefined)
        return renderPage(
            // 0 notifications
            data?.currentUser.unreadNotificationsCount || 0,
            // no menu
            null,
            // Loading spinner
            <div style={{ width: "100%", height: 500, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <FullPageSpinner />
            </div>
        );

    /**
     * Show message 'Geen nieuwe meldingen' on error or data = 0
     */
    if (error || !allNotifications || !allNotifications.data || allNotifications.paginatorInfo.total === 0)
        return renderPage(
            // 0 notifications
            data?.currentUser.unreadNotificationsCount || 0,
            // no menu
            null,
            // message no data
            <div style={{ width: "100%", height: 500, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <Notifications
                    sx={{
                        color: "#f2f2f2",
                        height: "9rem",
                        width: "9rem",
                    }}
                />
                <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                    Geen nieuwe meldingen
                </Typography>
            </div>
        );

    /**
     * First and last item in array has to be a unique value. otherwise those items will be filtered from array
     * Created to merge chat notifications
     */
    const uniqueArr = _.uniqWith(
        allNotifications.data.map((notification) => ({ ...notification })), // Clone objects
        (arrVal: any, othVal: any) => {
            // Skip if data arrays are empty
            if (!arrVal.data.length || !othVal.data.length) return false;

            // Handle deduplication for NewSearchResultNotification
            if (
                arrVal.type === "App\\Notifications\\NewSearchResultNotification" &&
                othVal.type === "App\\Notifications\\NewSearchResultNotification"
            ) {
                // Check if they refer to the same tender (data[1] is the unique tender ID)
                if (arrVal.data[1] === othVal.data[1]) {
                    // Initialize search_ids if not already present
                    if (!arrVal.search_ids) arrVal.search_ids = [arrVal.data[0]];
                    if (!othVal.search_ids) othVal.search_ids = [othVal.data[0]];

                    // Initialize notification_ids if not already present
                    if (!arrVal.notification_ids) arrVal.notification_ids = [arrVal.id];
                    if (!othVal.notification_ids) othVal.notification_ids = [othVal.id];

                    // Merge unique search_ids and assign to both items
                    const mergedSearchIds = _.uniq([...arrVal.search_ids, ...othVal.search_ids]);
                    arrVal.search_ids = mergedSearchIds;
                    othVal.search_ids = mergedSearchIds;

                    // Merge the notification_ids for both items
                    const mergedNotificationIds = _.uniq([...arrVal.notification_ids, ...othVal.notification_ids]);
                    arrVal.notification_ids = mergedNotificationIds;
                    othVal.notification_ids = mergedNotificationIds;

                    return true; // Treat as duplicate
                }
            }

            // For other types of notifications, compare data arrays' first and last values
            const firstArrVal = arrVal.data[0];
            const lastArrVal = arrVal.data[arrVal.data.length - 1];
            const firstOthVal = othVal.data[0];
            const lastOthVal = othVal.data[othVal.data.length - 1];

            return firstArrVal === firstOthVal && lastArrVal === lastOthVal;
        }
    );

    // After deduplication, ensure search_ids is added for all unique items
    uniqueArr.forEach((notification: any) => {
        if (notification.type === "App\\Notifications\\NewSearchResultNotification" && !notification.search_ids) {
            notification.search_ids = [notification.data[0]];
            notification.notification_ids = [notification.id];
        }
    });

    /**
     * Create a grouped array based on created_at property
     */
    // const groupedArray = _.chain(allNotifications.data)
    const groupedArray = _.chain(uniqueArr)
        // Group the elements of Array based on `created_at' property
        .groupBy((row) => moment(row.created_at).format("L"))
        // `key` is group's name (date), `value` is the array of objects (notifications)
        .map((value, key) => ({ date: key, notifications: value }))
        .value();

    /**
     * Filter all chatnotifications to check the amount of
     */
    // const filterChatNotifications = allNotifications.data.filter(
    //     (d) => d.type === "App\\Notifications\\NewChatMessageReceived" || d.type === "App\\Notifications\\NewSearchResultNotification"
    // );

    return renderPage(
        // Notification count
        data?.currentUser.unreadNotificationsCount || 0,
        // Notification menu
        <WidgetBoxMenu
            anchorEl={anchorElement}
            onClose={() => {
                setAnchorEl(null);
            }}
            onButtonClick={handleClick}
        >
            <MenuItem
                onClick={() => {
                    if (data?.currentUser.unreadNotificationsCount && data?.currentUser.unreadNotificationsCount > 0) {
                        trackNotificationClick({ category: "Personal", action: "Click-mark-all-notifications-as-read" });
                        markAllNotifications();
                        refetch();
                    }
                    setOpenNotifications(false);
                    setAnchorEl(null);
                }}
            >
                Alles markeren als gelezen
            </MenuItem>

            {/* <MenuItems
                onClick={() => {
                setAnchorEl(null);
                setOpenNotifications(false);
            }}
                component={Link}
                to="/profile/log"
                >
                Toon alle meldingen
                </MenuItems> */}
        </WidgetBoxMenu>,

        // Notificationslist
        <InfiniteScroll
            height={520}
            /**
             * Fetch more data if 95% is scrolled
             */
            scrollThreshold={0.95}
            /**
             * Id of menulist to target the menu as window instead of whole window
             */
            scrollableTarget="notificationlist"
            /**
             * This is important field to render the next data.
             */
            dataLength={allNotifications.data.length} //This is important field to render the next data
            /**
             * Function to get next data
             */
            next={() => setAmountOfNotifications(amountOfNotifications + 10)}
            /**
             * Boolean to check if more data is available
             */
            hasMore={allNotifications.paginatorInfo.hasMorePages}
            /**
             * Loader component
             */
            loader={
                loading && (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress classes={{ root: "spinner" }} size={18} />
                    </div>
                )
            }
            /**
             * message when bottom is reached
             * In this case no message is defined
             */
            endMessage={<p style={{ textAlign: "center" }} />}
        >
            <MenuList id="notificationlist">
                {groupedArray.map((notification, i: number) => {
                    return (
                        <>
                            {/* Return divider based on notification date */}
                            <DateDivider date={notification.date} index={i} />

                            {/* Map over array of notifications in a grouped set */}
                            {notification.notifications.map((singleNotification, i) => {
                                return (
                                    <NotificationTypeSelector
                                        notification={singleNotification}
                                        index={i}
                                        key={i}
                                        markBatchOfNotifications={(notification_ids: string[] | undefined) => {
                                            notification_ids && markBatchOfNotifications(notification_ids);
                                            // refetch();
                                        }}
                                        markSingleNotification={(id) => {
                                            trackNotificationClick({
                                                category: "Personal",
                                                action: "Click-notification-item",
                                                name: singleNotification.id as string,
                                            });

                                            if (singleNotification.read_at === null) {
                                                markSingleNotification(id);
                                            }
                                            refetch();
                                        }}
                                        setOpenNotifications={setOpenNotifications}
                                    />
                                );
                            })}
                        </>
                    );
                })}
            </MenuList>
        </InfiniteScroll>
    );
};

export default NotificationsMenu;

export const MARK_NOTIFICATION_AS_READ = gql`
    mutation markNotificationsAsRead($id: ID!) {
        markNotificationsAsRead(id: $id)
    }
`;

export const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
    mutation markAllNotificationsAsRead {
        markAllNotificationsAsRead
    }
`;
