import React, { useEffect, useState } from "react";
import { SortOrder, TenderFilterInput, TenderFilterSource, TenderFilterTarget, TenderSearchInput } from "../../../__generated__/globalTypes";
import TenderSearch from "../../../components/TenderSearchComponent/TenderSearch";
// import ColumnView from "../columnView/ColumnView";
// import EventView from "../eventView/EventView";
import SplitView from "../splitview/SplitView";
import setTitle from "../../../utils/setTitle";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import queryString from "query-string";
import {
    FilterWorklistMinimumOverview,
    FilterWorklistMinimumOverviewVariables,
    FilterWorklistMinimumOverview_filterTenders_filters,
    FilterWorklistMinimumOverview_filterTenders_results_data,
    FilterWorklistMinimumOverview_filterTenders_results_paginatorInfo,
} from "../../../__generated__/FilterWorklistMinimumOverview";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import WorklistTenderTable from "./WorklistTenderTable";
import { ColumnContextTndrWrklst } from "../../../components/contextProviders/ColumnContextTndrWrklst";
import { ColumnProvider } from "../../../components/contextProviders/ColumnProvider";
import { TenderColumns } from "./TenderColumns";
import { View } from "../../../components/TenderSearchComponent/ViewMenu";
import {
    FilterWorklistSplitView,
    FilterWorklistSplitViewVariables,
    FilterWorklistSplitView_filterTenders_results_data,
} from "../../../__generated__/FilterWorklistSplitView";
import TendersContent from "../../../components/ModuleInfoBox/TendersContent";
import ModuleInformation from "../../../components/ModuleInfoBox/ModuleInformation";
import { QUERY_FULL_WORKLIST_MINIMUM, QUERY_WORKLIST_SPLIT } from "../../../graphql/queryDefinitions";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { defaultTenderSortingOptions, getFilterValue } from "../../../components/tableComponents/SortingChanger";

interface Props {
    a?: number;
}

const filters: TenderFilterInput = {};
const defaultSearch: TenderSearchInput = {
    source: TenderFilterSource.WORKLIST,
    target: TenderFilterTarget.SUMMARY,
    filters,
};
const defaultVariables: FilterWorklistMinimumOverviewVariables = {
    page: 1,
    count: 10,
    orderBy: [{ column: "first_publication", order: SortOrder.DESC }],
    search: defaultSearch,
};

const WorklistPage: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    // filterset
    const [searchInput, setSearchInput] = useState<TenderFilterInput>({});
    // active view
    const defaultView = JSON.parse(localStorage.getItem("twv") as string);

    /**
     * Initialize the state for `orderBy` with a default sorting condition
     */
    const [orderBy, setOrderBy] = useState(defaultVariables.orderBy);
    const [view, setView] = useState<View | 0>(defaultView);
    // handler to change view
    const handleView = (view: number) => [setView(view)];
    // selected rows
    const [selectedRows, setSelectedRows] = useState([]);
    // active page (table)
    const [page, setPage] = useState(defaultVariables.page);
    // amount of rows per page
    const [rowsPerPage, setRowsPerPage] = useState(defaultVariables.count);
    // section to search: All tenders | reviewed tenders | worklist
    const [source, setSource] = useState<TenderFilterSource>(defaultSearch.source);
    // User input (query)
    const [query, setQuery] = useState<string>("");
    // section to search: summary | relevant docs | all docs
    const [target, setTarget] = useState<TenderFilterTarget>(defaultSearch.target);
    // array of filters
    const [filterData, setFilterData] = useState<FilterWorklistMinimumOverview_filterTenders_filters[] | null>([]);
    // paginator info
    const [filteredPagination, setFilteredPagination] = useState<FilterWorklistMinimumOverview_filterTenders_results_paginatorInfo>();
    // array of tenders based on active filter(s) -> listview
    const [tendersData, setTendersData] = useState<FilterWorklistMinimumOverview_filterTenders_results_data[] | null>();

    // array of tenders based on active filter(s) -> splitview
    const [tendersDataSplit, setTendersDataSplit] = useState<FilterWorklistSplitView_filterTenders_results_data[] | null>();

    // Check if user changes view
    const [changeView, setChangeView] = useState<boolean>(false);
    // state to check if chatmodal is open and a new message is sent.
    // states needed to refetch last message
    const [selectedId, setSelectedId] = useState<string>("");

    // Variables for the GQL query
    const variables: FilterWorklistMinimumOverviewVariables = {
        page: page,
        count: rowsPerPage,
        orderBy: [{ column: "first_publication", order: SortOrder.DESC }],
        search: {
            source: source,
            target: target,
            query: query,
            filters: searchInput,
        },
    };
    /**
     * Initialize state from url parameters
     */
    useEffect(() => {
        // set localstorage for worklistview
        if (localStorage.getItem("twv")) {
            setView(parseInt(JSON.parse(localStorage.getItem("twv") || "3")));
        }
        // worklist rows per page (local storage)
        if (localStorage.getItem("wtr")) {
            setRowsPerPage(parseInt(JSON.parse(localStorage.getItem("wtr") || "10")));
        }
        // active page in table (session storage)
        if (sessionStorage.getItem("wtp")) {
            setPage(parseInt(JSON.parse(sessionStorage.getItem("wtp") || "1")));
        }
        // active filters (session storage)
        if (sessionStorage.getItem("twf")) {
            setSearchInput(JSON.parse(sessionStorage.getItem("twf") || "{}"));
        }
        // user query input (session storage)
        if (sessionStorage.getItem("wtq")) {
            setQuery(JSON.parse(sessionStorage.getItem("wtq") || ""));
        }
        if (sessionStorage.getItem("swtt")) {
            // set active filters to sessionstorage or empty if it isn't set
            setOrderBy(JSON.parse(atob(sessionStorage.getItem("swtt") || "{}")));
        }

        // Initial setup using query parameters
        if (window.location.search) {
            const query = queryString.parse(window.location.search);
            // check if url has query params for year, month and likedTender.
            // If so, set the state and filters accordingly.
            if (typeof query.year === "string" && typeof query.month === "string" && typeof query.likedTenders === "string") {
                setSource(TenderFilterSource.ALL_TENDERS);
                setTarget(TenderFilterTarget.SUMMARY);
                setSearchInput({});
            }
        }
    }, []);

    /**
     * Check if user changes view to a view that is not preferred.
     * if changeview is true => show infotooltip with helptext
     */
    useEffect(() => {
        if (view !== parseInt(JSON.parse(localStorage.getItem("twv") || "3"))) {
            setChangeView(true);
        } else setChangeView(false);
    }, [view]);

    // Set variables based on current view
    useEffect(() => {
        // listview settings
        if (view === 0) {
            if (sessionStorage.getItem("wtp")) {
                // set current page to sessionstorage value or 1 if sessionstorage isn't set
                setPage(parseInt(JSON.parse(sessionStorage.getItem("wtp") || "1")));
            }
            setRowsPerPage(10);
        }
        // splitview settings
        if (view === 3) {
            setPage(1);
            setRowsPerPage(20);
        }
    }, [view]);
    // Define the GQL query
    const [run, { error, loading, data: dataList }] = useLazyQuery<FilterWorklistMinimumOverview, FilterWorklistMinimumOverviewVariables>(
        QUERY_FULL_WORKLIST_MINIMUM,
        {
            variables: variables,
            fetchPolicy: "network-only",
        }
    );

    // Define the GQL query splitlist
    const [runSplit, { error: errorSplit, loading: loadingSplit, data: dataSplit }] = useLazyQuery<
        FilterWorklistSplitView,
        FilterWorklistSplitViewVariables
    >(QUERY_WORKLIST_SPLIT, {
        variables: variables,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (view === 3) {
            if (dataSplit && dataSplit.filterTenders !== null && dataSplit.filterTenders.results !== null) {
                setFilterData(dataSplit.filterTenders.filters);
                setTendersDataSplit(dataSplit.filterTenders.results.data);
                setFilteredPagination(dataSplit.filterTenders.results.paginatorInfo);
                if (dataSplit.filterTenders.results.data.length > 0) {
                    setSelectedId(dataSplit.filterTenders.results.data[0].id);
                }
            }
        }
        if (view === 0) {
            if (dataList && dataList.filterTenders !== null && dataList.filterTenders.results !== null) {
                setFilterData(dataList.filterTenders.filters);
                setTendersData(dataList.filterTenders.results.data);
                setFilteredPagination(dataList.filterTenders.results.paginatorInfo);
            }
        }
    }, [dataSplit, dataList, view]);

    useEffect(() => {
        sessionStorage.setItem("wtq", JSON.stringify(query));

        // if query is empty, or only whitespace -> set target to summary
        // relevant docs and all docs will be disabled as filter
        if (typeof query === "string" && query.trim().length === 0) {
            setTarget(TenderFilterTarget.SUMMARY);
        }
    }, [query]);

    // Set page title
    useEffect(() => {
        setTitle(t("Worklist"));
        sessionStorage.setItem("twf", JSON.stringify(searchInput));
        sessionStorage.setItem("swtt", btoa(JSON.stringify(orderBy)));
        // setview to listview if user is on mobile device
        onlyMobile === true ? setView(0) : setView(view);

        if (view === 0) {
            localStorage.setItem("wtr", JSON.stringify(rowsPerPage));
            sessionStorage.setItem("wtp", JSON.stringify(page));
            const variables = {
                page: page,
                count: rowsPerPage,
                orderBy: orderBy,
                search: {
                    source: source,
                    target: target,
                    query: query,
                    filters: searchInput,
                },
            };
            run({ variables });
        }

        if (view === 3) {
            sessionStorage.setItem("twf", JSON.stringify(searchInput));
            const variables = {
                page: page,
                count: rowsPerPage,
                orderBy: orderBy,
                search: {
                    source: source,
                    target: target,
                    query: query,
                    filters: searchInput,
                },
            };
            runSplit({ variables });
        }
    }, [run, runSplit, orderBy, page, rowsPerPage, searchInput, query, target, source, t, view, onlyMobile]);

    // handle pagination change: -page change, -rows per page change
    const handlePaginationChange = (page: number, rows: number) => {
        setPage(page);
        setRowsPerPage(rows);
        if (view === 0 && rows !== rowsPerPage) {
            setPage(1);
        }
    };

    /**
     * find filtername of columnheader
     * @param column column header
     * @param direction direction to sort
     */
    const handleTableSort = (column: string, direction: SortOrder) => {
        const col = getFilterValue(column, defaultTenderSortingOptions) as string;

        handleSortChange(col, direction);
    };

    const handleSortChange = (column: string, order: SortOrder) => {
        setOrderBy((prevOrderBy) => {
            // Check if the clicked column already exists in the orderBy array
            const existingIndex = prevOrderBy.findIndex((item) => item.column === column);

            // If the clicked column already exists
            if (existingIndex !== -1) {
                const currentOrder = prevOrderBy[existingIndex].order;

                // If the clicked column exists and the order is the same, reset to default sorting
                if (currentOrder === order) {
                    // Reset to default order (this can be your default order logic, e.g., {updated_at, DESC})
                    return defaultVariables.orderBy;
                }

                // If the order is different, toggle the order direction
                const updatedOrderBy = [...prevOrderBy];
                updatedOrderBy[existingIndex] = { column, order: order };

                return updatedOrderBy;
            }

            // If it's a new column, reset the array with only the new column and updated_at
            return [
                { column, order },
                { column: "updated_at", order: SortOrder.DESC }, // Keep updated_at always last
            ];
        });
    };

    const renderPage = (content: React.ReactNode) => (
        <ColumnProvider storeKey="cutcw" defaultState={TenderColumns}>
            <ColumnContextTndrWrklst.Provider value={{ selectedRows, setSelectedRows }}>
                <Box component="div" id="tender-root" sx={{ padding: { xs: "20px 8px", md: "20px" }, overflow: "scroll" }}>
                    <TenderSearch
                        changeView={changeView}
                        totalTenders={filteredPagination?.total || 0}
                        columns={TenderColumns}
                        resetFilters={() => {
                            setSearchInput({});
                            setPage(1);
                        }}
                        orderField={"updated_at"}
                        order={SortOrder.DESC}
                        loading={loading || loadingSplit}
                        query={query}
                        target={target}
                        setQuery={setQuery}
                        setTarget={setTarget}
                        resetPage={() => setPage(1)}
                        filterData={filterData}
                        // props to show multiple views
                        viewButtons={true}
                        onViewClick={handleView}
                        activeView={view}
                        views={[View.List, View.Split]}
                        visible={false}
                        searchInput={searchInput}
                        onChange={setSearchInput}
                        startSearch={() => {
                            setPage(1);
                            if (view === 0) {
                                run({ variables });
                            }
                            if (view === 3) {
                                runSplit({ variables });
                            }
                        }}
                        handleSorting={handleSortChange}
                        currentSorting={orderBy[0]}
                    />
                    {content}
                </Box>
            </ColumnContextTndrWrklst.Provider>
            <ModuleInformation title={"Aanbestedingen"}>
                <TendersContent />
            </ModuleInformation>
        </ColumnProvider>
    );

    if (!tendersData && view === 0 && loading) return renderPage(<FullPageSpinner />);
    if (error || (!tendersData && view === 0))
        return renderPage(
            <WorklistTenderTable
                target={target}
                loading={loading}
                error={error}
                rows={[]}
                paginatorInfo={{
                    count: 0,
                    currentPage: 0,
                    hasMorePages: false,
                    firstItem: 0,
                    lastItem: 0,
                    lastPage: 1,
                    perPage: 10,
                    total: 0,
                    __typename: "PaginatorInfo",
                }}
                onChangePagination={handlePaginationChange}
                onTableChange={(column, direction) => handleTableSort(column, direction.toUpperCase() as SortOrder)}
                sortOrder={{
                    name: orderBy[0].column,
                    direction: orderBy[0].order.toLocaleLowerCase() as "desc" | "asc",
                }}
            />
        );

    const getWorklistView = (view: View) => {
        switch (view) {
            case View.List:
                return (
                    <WorklistTenderTable
                        target={target}
                        loading={loading}
                        rows={tendersData ? tendersData : []}
                        paginatorInfo={
                            filteredPagination
                                ? filteredPagination
                                : {
                                      count: 0,
                                      currentPage: 0,
                                      hasMorePages: false,
                                      firstItem: null,
                                      lastItem: null,
                                      lastPage: 1,
                                      perPage: 10,
                                      total: 0,
                                      __typename: "PaginatorInfo",
                                  }
                        }
                        onChangePagination={handlePaginationChange}
                        onTableChange={(column, direction) => handleTableSort(column, direction.toUpperCase() as SortOrder)}
                        sortOrder={{
                            name: orderBy[0].column,
                            direction: orderBy[0].order.toLocaleLowerCase() as "desc" | "asc",
                        }}
                    />
                );
            case View.Split:
                return (
                    <SplitView
                        target={target}
                        setSelectedId={setSelectedId}
                        selectedId={selectedId}
                        page={rowsPerPage as number}
                        setPage={setRowsPerPage}
                        rows={tendersDataSplit}
                        loading={loadingSplit}
                        error={errorSplit}
                        paginatorInfo={
                            filteredPagination
                                ? filteredPagination
                                : {
                                      count: 0,
                                      currentPage: 0,
                                      hasMorePages: false,
                                      firstItem: null,
                                      lastItem: null,
                                      lastPage: 1,
                                      perPage: 10,
                                      total: 0,
                                      __typename: "PaginatorInfo",
                                  }
                        }
                    />
                );
            default:
                return (
                    <SplitView
                        target={target}
                        setSelectedId={setSelectedId}
                        selectedId={selectedId}
                        page={rowsPerPage as number}
                        setPage={setRowsPerPage}
                        rows={tendersDataSplit}
                        loading={loadingSplit}
                        error={errorSplit}
                        paginatorInfo={
                            filteredPagination
                                ? filteredPagination
                                : {
                                      count: 0,
                                      currentPage: 0,
                                      hasMorePages: false,
                                      firstItem: null,
                                      lastItem: null,
                                      lastPage: 1,
                                      perPage: 10,
                                      total: 0,
                                      __typename: "PaginatorInfo",
                                  }
                        }
                    />
                );
        }
    };
    /**
     * render active view for worklist
     */
    return renderPage(
        <div id="tender-table" style={{ marginTop: "12px" }}>
            {getWorklistView(view)}
        </div>
    );
};

export default WorklistPage;
