import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CardActionArea, Checkbox, Divider, Grid, Paper, styled, Typography } from "@mui/material";
import FaviconBox from "../../../../components/FaviconBox";
import {
    GetContractingAuthorityFilterData,
    GetContractingAuthorityFilterData_filterContractingAuthorities_results,
    GetContractingAuthorityFilterDataVariables,
} from "../../../../__generated__/GetContractingAuthorityFilterData";
import { ContractingAuthoritiesFilterSource, SortOrder, TenderFilterInput } from "../../../../__generated__/globalTypes";
import { useLazyQuery } from "@apollo/client";
import { QUERY_CA_FILTER_DATA } from "../../../../graphql/opportunityQueries/queryDefinitions";
import { debounce } from "lodash";
import { FilterType, useTenderSearchContext } from "../../../../components/guides/tenderWizard/WizardContext";
import update from "immutability-helper";
import { useProfile } from "../../../../components/contextProviders/ProfileOpenerContext";
import { Star } from "@mui/icons-material";
import Searchbar from "./Searchbar";
import ChosenCaOption from "./ChosenCaOption";

interface Props {
    filterValues: any;
    filter: string;
    type: "tender" | "opportunity";
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    paddingRight: "8px",
    // textAlign: "center",
    display: "flex", // changed to flex for horizontal layout
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.text.secondary,
    maxWidth: "100%", // ensure the container doesn't expand beyond available space
}));

const CaFilter: React.FC<Props> = ({ filterValues, filter, type }) => {
    const { chosenADs, setChosenADs, filters, setFilters } = useTenderSearchContext(); // Use the context hook to access context value and function
    const { myCAS } = useProfile();

    const myCasitems = myCAS.map((option) => ({ key: option.name, label: option.name, identifier: parseInt(option.id) }));

    const [caFilterSet, setCaFilterSet] = useState<GetContractingAuthorityFilterData_filterContractingAuthorities_results | null>();
    const [caSearchQuery, setCaSearchQuery] = useState<string | undefined>(undefined);
    const [staticValue, setStaticValue] = useState<string>("");
    const filterSet = useMemo(() => {
        const checkingPath = type === "opportunity" ? { opportunityFileCount: 0 } : { tenderCount: 0 };
        return checkingPath;
    }, [type]);

    // debounce function to prevent request if characters is < 3 except 0

    const inputGqlSearch = useRef<HTMLInputElement | null>(null); // if you are referring to an input element

    // Type the ref properly with `DebouncedFunc`
    const debounced = useRef(
        debounce((value: string) => {
            if (value.length === 0 || value.length >= 3) {
                setCaSearchQuery(value); // directly call setGqlSearch
            }
        }, 900)
    );

    const updateUserValue = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        debounced.current(value); // call debounced function
        setStaticValue(value); // set the user value immediately
    }, []);

    // Variables for the GQL query
    const variables: GetContractingAuthorityFilterDataVariables = {
        page: 1,
        count: 1000,
        orderField: "last_update_date_timestamp",
        order: SortOrder.DESC,
        search: {
            source: ContractingAuthoritiesFilterSource.ALL_CONTRACTING_AUTHORITIES,
            query: `"${caSearchQuery}"`,
            filters: filterSet,
        },
    };

    const [searchCA, { loading: loadCA }] = useLazyQuery<GetContractingAuthorityFilterData, GetContractingAuthorityFilterDataVariables>(
        QUERY_CA_FILTER_DATA,
        {
            variables: variables,
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (data && data.filterContractingAuthorities) {
                    setCaFilterSet(data.filterContractingAuthorities.results);
                }
            },
        }
    );

    useEffect(() => {
        if (filters.contractingAuthorities && filters.contractingAuthorities.length > 0) {
            const updatedChosenADs = filters.contractingAuthorities.map((id: number) => ({
                identifier: id,
                key: id.toString(), // Adjust based on your data structure
                label: "", // Replace with the actual label if available
            }));

            // Only update if `chosenADs` is actually different
            if (JSON.stringify(chosenADs) !== JSON.stringify(updatedChosenADs)) {
                setChosenADs(updatedChosenADs);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.contractingAuthorities, setChosenADs]);

    useEffect(() => {
        if (caSearchQuery === undefined || (caSearchQuery !== undefined && caSearchQuery.length === 0)) {
            setCaFilterSet(undefined);
        }
        if (caSearchQuery !== undefined && caSearchQuery.length > 2) {
            searchCA({
                variables: variables,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchCA, caSearchQuery]);

    const dataToShow =
        caSearchQuery !== undefined &&
        caSearchQuery.length > 0 &&
        caFilterSet?.paginatorInfo.total !== undefined &&
        caFilterSet?.paginatorInfo.total > 0
            ? caFilterSet?.data.map((ca) => ({
                  key: ca.name,
                  label: ca.name,
                  identifier: parseInt(ca.id),
                  chosen: false,
              }))
            : filterValues.map((t: any) => ({
                  key: t.label,
                  label: t.label,
                  identifier: parseInt(t.identifier),
                  chosen: false,
              }));

    const chosenADIdentifiers = chosenADs.map((ad) => ad.identifier);

    // Filter out items from myCasitems and filteredDataToShow that are already in chosenADs
    const filteredMyCasitems = myCasitems.filter((item) => !chosenADIdentifiers.includes(item.identifier));
    // Filter out items from filteredDataToShow that are already in chosenADs
    // Also filter out items from filteredDataToShow that are present in myCasitems
    const filteredDataToShow = dataToShow.filter(
        (value: any) => !chosenADIdentifiers.includes(value.identifier) && !myCasitems.some((item) => item.identifier === value.identifier)
    );

    const onToggleAD = (type: FilterType) => {
        const parsedId = type.identifier;

        setChosenADs((prev) => {
            const isSelected = prev.some((ad) => ad.identifier === parsedId);
            let updatedAd;

            if (isSelected) {
                // Remove the selected item
                updatedAd = prev.filter((ad) => ad.identifier !== parsedId);
            } else {
                // Add the selected item
                updatedAd = [...prev, { key: type.key, label: type.label, identifier: parsedId }];
            }

            // If `updatedAd` is empty, remove `contractingAuthorities` from filters
            setFilters(
                updatedAd.length === 0
                    ? update(filters, { $unset: ["contractingAuthorities" as keyof TenderFilterInput] })
                    : update(filters, { contractingAuthorities: { $set: updatedAd.map((ad) => ad.identifier) } })
            );

            return updatedAd;
        });
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", padding: "16px 0px 16px 16px" }}>
            <div>
                <Searchbar
                    queryToShow={staticValue}
                    ref={inputGqlSearch}
                    query={caSearchQuery ?? ""}
                    handleSearchChange={updateUserValue}
                    reset={() => {
                        setCaSearchQuery("");
                        setStaticValue("");
                    }}
                    loading={loadCA}
                />
            </div>
            <Grid container spacing={2} sx={{ flex: 1, overflow: "auto", marginTop: "16px", paddingRight: "16px" }}>
                {/* CHOSEN ADS */}
                {chosenADs.map((value: any) => {
                    return (
                        <Grid key={value.identifier} item xs={4}>
                            <ChosenCaOption
                                key={value.identifier}
                                value={value}
                                chosenADIdentifiers={chosenADIdentifiers}
                                starredCAS={myCasitems.map((ca) => ca.identifier)}
                                onToggleAD={onToggleAD}
                            />
                        </Grid>
                    );
                })}
                {chosenADs.length > 0 && (
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                )}

                {/* STARRED ADS + NOT CHOSEN */}
                {filteredMyCasitems.map((value: any) => {
                    return (
                        <ItemCard
                            key={value.identifier}
                            value={value}
                            chosenADIdentifiers={chosenADIdentifiers}
                            starredCAS={myCasitems.map((ca) => ca.identifier)}
                            onToggleAD={onToggleAD}
                        />
                    );
                })}

                {/* OTHERS */}
                {filteredDataToShow.map((value: any) => {
                    return (
                        <ItemCard
                            key={value.identifier}
                            value={value}
                            chosenADIdentifiers={chosenADIdentifiers}
                            starredCAS={myCasitems.map((ca) => ca.identifier)}
                            onToggleAD={onToggleAD}
                        />
                    );
                })}
            </Grid>
        </div>
    );
};

interface ItemCardProps {
    value: FilterType;
    starredCAS: number[];
    chosenADIdentifiers: number[];
    onToggleAD: (type: FilterType) => void;
}

const ItemCard: React.FC<ItemCardProps> = ({ value, chosenADIdentifiers, onToggleAD, starredCAS }) => {
    return (
        <Grid key={value.identifier} item xs={4}>
            <CardActionArea onClick={() => onToggleAD(value)}>
                <Item variant="outlined">
                    <div>
                        <FaviconBox favicon="" name={value.label} color={"#F57117"} id={value.identifier.toString()} />
                    </div>
                    <Typography
                        noWrap={false}
                        sx={{ flexGrow: 1, wordWrap: "break-word", maxWidth: "calc(100% - 24px)", display: "flex", alignItems: "center" }}
                    >
                        {value.label}
                        {starredCAS.includes(value.identifier) && (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Star sx={{ color: "#F57117", marginLeft: "8px", width: "20px", height: "20px" }} />
                            </div>
                        )}
                    </Typography>
                    <Checkbox checked={chosenADIdentifiers.includes(value.identifier)} />
                </Item>
            </CardActionArea>
        </Grid>
    );
};

export default CaFilter;
