import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    MenuItem,
    Checkbox,
    ListItemIcon,
    Typography,
    Grid,
    Tooltip,
} from "@mui/material";
import { GridInput, OpportunityFilterInput, OpportunitySearchSource, SortOrder } from "../../__generated__/globalTypes";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import LinearLoader from "../loading/LinearLoader";
import { Disable } from "react-disable";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { currentUserEmail } from "../../__generated__/currentUserEmail";
import { GET_USER_EMAIL } from "../../graphql/queryDefCurrentUser";
import { ExportChosenOpportunityColumns, ExportChosenOpportunityColumnsVariables } from "../../__generated__/ExportChosenOpportunityColumns";
import { EXPORT_CHOSEN_OPPORTUNITY_COLUMNS } from "../../graphql/opportunityQueries/queryDefinitions";

interface Props {
    onClose: () => void;
    dialogTitle: string;
    dialogText: string;
    open: boolean;
    columnExport: GridInput;
    searchInput: OpportunityFilterInput;
    query: string;
    order: SortOrder;
    orderField: string;
    columns: any[];
}

const OpportunityExportModal: React.FC<Props> = ({
    onClose,
    open,
    dialogTitle,
    dialogText,
    columnExport,
    searchInput,
    order,
    orderField,
    query,
    columns,
}) => {
    const [columnsToExport, setColumnsToExport] = useState<any[]>([]);
    const [completed, setCompleted] = useState<boolean>(false);
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const { data: email } = useQuery<currentUserEmail>(GET_USER_EMAIL);

    const source =
        pathname === "/opportunities/reviews"
            ? OpportunitySearchSource.MY_OPPORTUNITY_FILES
            : pathname === "/opportunities/worklist"
            ? OpportunitySearchSource.MY_WORKLIST
            : OpportunitySearchSource.ALL_OPPORTUNITY_FILES;

    useEffect(() => {
        setColumnsToExport([]);
        // Tender reviewtable possible columns to export
        if (columnExport === "OPPORTUNITY_REVIEWS") {
            const removeExcludedColumns = columns
                .filter((i: any) => i.options.display !== "excluded")
                .filter((i: any) => i.label !== "Actions")
                .filter((i: any) => i.label !== "Title")
                .filter((i: any) => i.name !== "filetype")
                .filter((i: any) => i.name !== "ca_type")
                .filter((i: any) => i.name !== "last_review")
                .filter((i: any) => i.label !== "Published")
                .filter((i: any) => i.name !== "found_res")
                .filter((i: any) => i.label !== "CreatedOpps")
                .filter((i: any) => i.label !== "AD")
                .filter((i: any) => i.name !== "city")
                .filter((i: any) => i.name !== "lastPrivateNote");
            setColumnsToExport(
                [
                    { label: "Title", name: "name", options: { display: true, sort: true } },
                    { label: "CA", name: `contractingAuthority.name`, options: { display: true, sort: true } },
                    {
                        label: "filetype",
                        name: "type.name",
                        options: { display: columns.find((t) => t.name === "filetype").options.display, sort: true },
                    },

                    {
                        label: "ca_type",
                        name: "contractingAuthority.types",
                        options: { display: columns.find((t) => t.name === "ca_type").options.display, sort: true },
                    },
                    {
                        label: "Published",
                        name: "created_at",
                        options: { display: columns.find((t) => t.name === "date").options.display, sort: true },
                    },
                    {
                        label: "last_review",
                        name: "lastReviewed",
                        options: { display: columns.find((t) => t.name === "last_review").options.display, sort: true },
                    },
                    {
                        label: "Found_res",
                        name: "personalResultsCount",
                        options: { display: columns.find((t) => t.name === "found_res").options.display, sort: true },
                    },
                    {
                        label: "CreatedOpps",
                        name: "personalOpportunitiesCount",
                        options: { display: columns.find((t) => t.name === "createdOpps").options.display, sort: true },
                    },
                    {
                        label: "City",
                        name: "contractingAuthority.city",
                        options: { display: columns.find((t) => t.name === "city").options.display, sort: true },
                    },
                    {
                        label: "LastPrivateNote",
                        name: "lastPrivateNote",
                        options: { display: columns.find((t) => t.name === "lastPrivateNote").options.display, sort: true },
                    },
                ].concat(removeExcludedColumns)
                // removeExcludedColumns
            );
        }
    }, [setColumnsToExport, columnExport, columns]);

    /**
     * function to show/hide columns
     * used filteredcolumns to create a shadow copy because this is a filtered array of the original where display: 'excluded' is removed.
     */
    const changeColumn = (index: number, change: boolean) => {
        // 1. Make a shallow copy of the items
        const items = [...columnsToExport];
        // 2. Make a shallow copy of the item you want to mutate
        const item = { ...columnsToExport[index] };
        // 3. Replace the property you're intested in
        item.options.display = change;
        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        items[index] = item;
        // 5. Set the state to our new copy
        setColumnsToExport(items);
    };
    // Get names of columns
    const get_names_only = columnsToExport.filter((i: any) => i.options.display === true).map((c: any) => c.name);

    // Sort get_names_only based on sortingArray order
    const sorted_names = sortingArray.map((s) => s.name).filter((name) => get_names_only.includes(name));

    // Get names of checked columns and map them to the NL names -> shows dutch headers in export file
    const checkedBoxesNameNL = sortingArray.filter((e) => (sorted_names.indexOf(e.name) > -1 ? true : false)).map((n) => n.nl);

    /**
     * Define the mutation to save user preferences
     */
    const [runExport, { loading }] = useMutation<ExportChosenOpportunityColumns, ExportChosenOpportunityColumnsVariables>(
        EXPORT_CHOSEN_OPPORTUNITY_COLUMNS,
        {
            variables: {
                search: {
                    query: query,
                    filters: searchInput,
                },
                source: source,
                headers: checkedBoxesNameNL,
                columns: get_names_only,
                order: order,
                orderField: orderField,
            },
            onCompleted: (data) => {
                setCompleted(true);
            },
        }
    );

    const sortedArr = sortingArray.map((i) => i.name);

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose();
                setColumnsToExport([]);
            }}
            fullWidth
            maxWidth="md"
        >
            <LinearLoader loading={loading} color="green" />
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                {completed === false && <DialogContentText>{dialogText}</DialogContentText>}
                {completed === true ? (
                    <Grid container style={{ height: 450, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <CloudDownloadIcon style={{ color: "#f2f2f2", height: "12rem", width: "12rem" }} />
                            <Typography variant="h5" style={{ color: "#9c9c9c9c" }}>
                                De export is succesvol aangevraagd.
                            </Typography>
                            <Typography variant="h5" style={{ color: "#9c9c9c9c" }}>
                                Deze wordt verstuurd naar {email?.currentUser.email ? email?.currentUser.email : "je e-mailadres"}
                            </Typography>
                        </div>
                    </Grid>
                ) : (
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            {/*
                             * 3 menu items that are disabled
                             * Info for user that created opps will be exported as default
                             */}
                            <Tooltip
                                PopperProps={{
                                    style: { marginBottom: -16 },
                                }}
                                placement="top"
                                title={"Deze optie wordt standaard in de export meegenomen"}
                            >
                                <div>
                                    <MenuItem dense disabled={true}>
                                        <ListItemIcon style={{ minWidth: 0 }}>
                                            <Checkbox size="small" edge="start" disableRipple checked={true} />
                                        </ListItemIcon>
                                        <Typography>{t(`Column_Header.Diy_opp_description`)}</Typography>
                                    </MenuItem>
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Tooltip
                                PopperProps={{
                                    style: { marginBottom: -16 },
                                }}
                                placement="top"
                                title={"Deze optie wordt standaard in de export meegenomen"}
                            >
                                <div>
                                    <MenuItem dense disabled={true}>
                                        <ListItemIcon style={{ minWidth: 0 }}>
                                            <Checkbox size="small" edge="start" disableRipple checked={true} />
                                        </ListItemIcon>
                                        <Typography>{t(`Column_Header.Diy_opp_expected_year`)}</Typography>
                                    </MenuItem>
                                </div>
                            </Tooltip>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Tooltip
                                PopperProps={{
                                    style: { marginBottom: -16 },
                                }}
                                placement="top"
                                title={"Deze optie wordt standaard in de export meegenomen"}
                            >
                                <div>
                                    <MenuItem dense disabled={true}>
                                        <ListItemIcon style={{ minWidth: 0 }}>
                                            <Checkbox size="small" edge="start" disableRipple checked={true} />
                                        </ListItemIcon>
                                        <Typography>{t(`Column_Header.Diy_opp_expected_value`)}</Typography>
                                    </MenuItem>
                                </div>
                            </Tooltip>{" "}
                        </Grid>

                        {columnsToExport
                            .sort((a, b) => sortedArr.indexOf(a.name) - sortedArr.indexOf(b.name))
                            .map((c: any, i: number) => {
                                return (
                                    <Grid key={i} item xs={12} md={4}>
                                        <Disable disabled={loading}>
                                            <MenuItem
                                                dense
                                                onClick={() => {
                                                    changeColumn(i, !c.options.display);
                                                }}
                                            >
                                                <ListItemIcon style={{ minWidth: 0 }}>
                                                    <Checkbox
                                                        size="small"
                                                        edge="start"
                                                        disableRipple
                                                        checked={c.options.display}
                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                    />
                                                </ListItemIcon>
                                                <Typography>{t(`Column_Header.${c.label}`)}</Typography>
                                            </MenuItem>
                                        </Disable>
                                    </Grid>
                                );
                            })}
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={loading}
                    onClick={() => {
                        onClose();
                        setColumnsToExport([]);
                        setCompleted(false);
                    }}
                    color="primary"
                    variant={completed === false ? "text" : "contained"}
                >
                    {t("advancedSearch.Close")}
                </Button>
                {completed === false && (
                    <Button disabled={loading} onClick={() => runExport()} variant="contained" color="primary">
                        exporteren
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default OpportunityExportModal;

export const sortingArray = [
    { name: "name", nl: "Titel" },
    { name: "contractingAuthority.city", nl: "Plaats" },
    { name: "type.name", nl: "Type document" },
    { name: "contractingAuthority.name", nl: "Aanbestedende dienst" },
    { name: "contractingAuthority.types", nl: "Type aanbestedende dienst" },
    { name: "created_at", nl: "Gepubliceerd" },
    { name: "personalOpportunitiesCount", nl: "Toegevoegde kansen" },
    { name: "personalResultsCount", nl: "Gevonden resultaten" },
    { name: "lastReviewed", nl: "Laatste beoordeling" },
    { name: "year_from", nl: "Document jaar" },
    { name: "lastPrivateNote", nl: "Laatste notitie" },
];
