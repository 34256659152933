import React, { useState } from "react";
import { IconButton, List, ListItemButton, ListItemSecondaryAction, ListItemText, Paper, Typography } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { FilterWithValues } from "../FilterModal";
import { TenderFilterInput } from "../../../../__generated__/globalTypes";

interface Props {
    filterList: FilterWithValues[];
    onClickOption: React.Dispatch<React.SetStateAction<string>>;
    chosenFilter: string;
    filters: TenderFilterInput;
}

const FilterList: React.FC<Props> = ({ filterList, onClickOption, chosenFilter, filters }) => {
    const { t } = useTranslation();
    const [hoverState, setHoverState] = useState<number | false>(false);

    // Pairs of filters that should be bolded together
    const filterPairs = [
        { primary: "country", secondary: "regions" },
        { primary: "sectors", secondary: "subSectors" },
    ];

    // Function to check if the filter title should be bolded
    const isBold = (filterTitle: string) => {
        // Check if the filterTitle is part of any defined pair
        const relatedPair = filterPairs.find(
            (pair) => (pair.primary === filterTitle || pair.secondary === filterTitle) && (pair.primary in filters || pair.secondary in filters)
        );

        // If a related pair is found and one of the pair filters is selected, bold both
        if (relatedPair) {
            return filterTitle === relatedPair.primary || filterTitle === relatedPair.secondary;
        }

        // If no related pair, only bold the selected filter
        return filterTitle in filters;
    };

    return (
        <div style={{ width: "100%", height: "100%" }}>
            {/*
             * header for searchterms un bucket
             */}
            <h3>{t("allFilters")}</h3>

            {/*
             * Bucket with searchterms, now chosenWords is prefilled so its possible to use all the different searchmethodes
             */}
            <Paper
                // square
                elevation={0}
                variant="outlined"
                sx={(theme) => ({
                    width: "100%",
                    height: `calc(100% - 56px) !important`,
                    display: "flex",
                    flexDirection: "column",
                })}
            >
                {/* //  Show all keywords of current user in reverse order. newest on top */}
                <List sx={{ width: "100%", overflow: "auto", padding: 0 }}>
                    {filterList.map((filter, i) => {
                        return (
                            <ListItemButton
                                key={filter.title}
                                style={{
                                    backgroundColor:
                                        chosenFilter === filter.title ? "#1733573b" : hoverState === i ? "rgba(0, 0, 0, 0.04)" : "transparent",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onClickOption(filter.title);
                                }}
                                onMouseEnter={() => setHoverState(i)}
                                onMouseLeave={() => setHoverState(false)}
                            >
                                <ListItemText
                                    key={filter.title}
                                    primary={
                                        <React.Fragment>
                                            <Typography
                                                style={{
                                                    // fontWeight: filter.chosenCount > 0 ? "bold" : "normal",
                                                    fontWeight: isBold(filter.title) ? "bold" : "normal",

                                                    // color: value.keyword.includes("*") || value.keyword.includes("?") ? "#c0392b" : "canvastext",
                                                    color: "canvastext",
                                                }}
                                            >
                                                {filter.title === "country" ? "Gebied" : `${t(`filter.${filter.title}`)}`}{" "}
                                                {filter.chosenCount > 0 && `(${filter.chosenCount})`}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    style={{ textDecoration: "none" }}
                                />

                                <ListItemSecondaryAction
                                    style={{
                                        // right: 30,
                                        cursor: "pointer",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    // onMouseEnter={() => setHoverState(i)}
                                    // onMouseLeave={() => setHoverState(false)}
                                    // onClick={(e) => {
                                    //     if (subSector === false) {
                                    //         e.stopPropagation();
                                    //         onSelect(value.keyword);
                                    //     }
                                    // }}
                                >
                                    <IconButton>
                                        <KeyboardArrowRight />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        );
                    })}
                </List>
            </Paper>
        </div>
    );
};

export default FilterList;
