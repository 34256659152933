import React, { useCallback, useEffect, useRef, useState } from "react";
import { Checkbox, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { FilterType, useTenderSearchContext } from "../../../../components/guides/tenderWizard/WizardContext";
import update from "immutability-helper";
import { TenderFilterInput } from "../../../../__generated__/globalTypes";
import { debounce } from "lodash";
import Searchbar from "./Searchbar";

interface Props {
    filterValues: any;
    filter: string;
}

const CaTypeFilter: React.FC<Props> = ({ filterValues, filter }) => {
    const recon = filterValues.map((option: any) => ({ key: option.identifier, label: option.label, identifier: parseInt(option.identifier) }));
    const { caTypes, setCaTypes, filters, setFilters } = useTenderSearchContext(); // Use the context hook to access context value and function
    const [staticValue, setStaticValue] = useState<string>("");
    const [staticLoad, setStaticLoad] = useState<boolean>(false);
    const inputGqlSearch = useRef<HTMLInputElement | null>(null); // if you are referring to an input element
    const [searchQuery, setSearchQuery] = useState<string>("");

    useEffect(() => {
        if (filters.contractingAuthorityTypes) {
            const updated = filters.contractingAuthorityTypes
                .map((id: number) => recon.find((item: any) => item.identifier === id)) // Find objects in recon
                .filter(Boolean); // Remove undefined values

            // Only update cpvs if it actually changed
            if (JSON.stringify(caTypes) !== JSON.stringify(updated)) {
                setCaTypes(updated);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.contractingAuthorityTypes, recon]); // Only runs when filters.cpvs or recon changes

    // This onChange function toggles the country on/off and updates filters
    const onToggleAType = (type: FilterType) => {
        const parsedId = type.identifier;

        setCaTypes((prev) => {
            const isSelected = prev.some((ad) => ad.identifier === parsedId);

            let updatedAd;
            if (isSelected) {
                // If the country is selected, remove it (deselect)
                updatedAd = prev.filter((ad) => ad.identifier !== parsedId);

                // Update filters if the country array changes
                if (updatedAd.length === 0) {
                    // If the country array is empty, unset the country filter
                    setFilters(update(filters, { $unset: [filter as keyof TenderFilterInput] }));
                } else {
                    // If the country array is not empty, set the country filter
                    setFilters(update(filters, { contractingAuthorityTypes: { $set: updatedAd.map((ad) => ad.identifier) } }));
                }
            } else {
                // If the country is not selected, add it (select)
                updatedAd = [...prev, { key: type.key, label: type.label, identifier: parsedId }];

                // Update filters if the country array changes
                setFilters(update(filters, { contractingAuthorityTypes: { $set: updatedAd.map((ad) => ad.identifier) } }));
            }

            return updatedAd;
        });
    };

    // Type the ref properly with `DebouncedFunc`
    const debounced = useRef(
        debounce((value: string) => {
            if (value.length === 0 || value.length >= 3) {
                setSearchQuery(value); // directly call setGqlSearch
                setStaticLoad(false);
            }
        }, 900)
    );

    const updateUserValue = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        debounced.current(value); // call debounced function
        setStaticValue(value); // set the user value immediately
        setStaticLoad(true);
    }, []);

    // Filter the values based on the search query
    const filteredValues = recon.filter(
        (option: any) =>
            option.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
            option.key.toString().includes(searchQuery) ||
            option.identifier.toString().includes(searchQuery)
    );

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", padding: "16px 0px 16px 16px" }}>
            <div>
                <Searchbar
                    queryToShow={staticValue}
                    ref={inputGqlSearch}
                    query={searchQuery ?? ""}
                    handleSearchChange={updateUserValue}
                    reset={() => {
                        setSearchQuery("");
                        setStaticValue("");
                    }}
                    loading={staticLoad}
                />
            </div>
            <List dense sx={{ flex: 1, overflow: "auto", marginTop: "16px" }}>
                {filteredValues?.map((c: any) => {
                    return (
                        <ListItemButton
                            key={c.identifier}
                            onClick={() => {
                                onToggleAType(c);
                            }}
                        >
                            <ListItemText primary={<Typography style={{ display: "flex", alignItems: "center" }}>{c.label}</Typography>} />
                            <ListItemIcon>
                                <Checkbox edge="start" checked={Boolean(caTypes.find((ca) => ca.identifier === c.identifier))} />
                            </ListItemIcon>
                        </ListItemButton>
                    );
                })}
            </List>
        </div>
    );
};

export default CaTypeFilter;
