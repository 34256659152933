import React, { useEffect } from "react";
import { IconButton } from "@mui/material";
import LikeSelectedTenders from "../LikeSelectedTenders";
import { Disable } from "react-disable";
import ColumnChanger from "../tableComponents/ColumnChanger";
import { GridInput, OrderByClause, SortOrder, TenderFilterInput, TenderFilterTarget } from "../../__generated__/globalTypes";
import { useColumnSetterWrkLst } from "../contextProviders/ColumnContextTndrWrklst";
import { useColumnSetterInt } from "../contextProviders/ColumnContextTndrInt";
import DislikeSelectedTenders from "../DislikeSelectedTenders";
import { TenderColumns as AllColumns } from "../../routes/tenders/allTenders/TenderColumns";
import { TenderColumns as WorklistColumns } from "../../routes/tenders/worklistModule/TenderColumns";
import { TenderColumns as InterestingColumns } from "../../routes/tenders/interesting/TenderColumns";
import RowTenderExportButton from "../tableComponents/RowTenderExportButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import UnarchiveSelectedTenderButton from "../UnArchiveSelectedTenders";
import ArchiveSelectedTenderButton from "../ArchiveSelectedTenders";
import SweepWorklist from "./SweepWorklist";
import SortingChanger from "../tableComponents/SortingChanger";

interface Props {
    activeView?: number;
    worklist: boolean;
    reviewPage: boolean;
    loading: boolean;
    allpage: boolean;
    totalTenders?: number;
    searchInput: TenderFilterInput;
    order: SortOrder;
    orderField: string;
    query: string;
    target: TenderFilterTarget;
    columns: any[];
    handleSorting: (field: string, direction: SortOrder) => void;
    currentSorting: OrderByClause;
}

const TenderTableActions: React.FC<Props> = ({
    handleSorting,
    currentSorting,
    activeView,
    worklist,
    reviewPage,
    loading,
    allpage,
    totalTenders,
    order,
    orderField,
    searchInput,
    query,
    target,
    columns,
}) => {
    const { selectedRows: wrklstRows, setSelectedRows: setWrklstRows } = useColumnSetterWrkLst();
    const { selectedRows: IntRows, setSelectedRows: setIntRows } = useColumnSetterInt();

    useEffect(() => {
        // If searchinput changes -> empty selection
        setIntRows([]);
    }, [searchInput, setIntRows]);

    const renderSwitch = () => {
        switch (true) {
            /**
             * If filter is set to 'interesting' and selection is minimum of 1
             */
            case IntRows !== undefined && IntRows.length > 0 && searchInput?.reviews?.length === 1 && searchInput?.reviews?.includes(1):
                return (
                    <React.Fragment>
                        {/*
                         * Archive button
                         */}
                        <ArchiveSelectedTenderButton setSelection={setIntRows} selection={IntRows} buttonSize={"small"} iconSize={"1.3rem"} />
                        {/*
                         * Dislike button
                         */}
                        {/*
                         <DislikeSelectedTenders setSelection={setIntRows} selection={IntRows} buttonSize={"small"} iconSize={"1.3rem"} />
                          */}
                    </React.Fragment>
                );

            /**
             * If filter is set to 'archived' and selection is minimum of 1
             */
            case IntRows !== undefined && IntRows.length > 0 && searchInput?.reviews?.length === 1 && searchInput?.reviews?.includes(2):
                return (
                    <React.Fragment>
                        {/*
                         * Unarchive button
                         */}
                        <UnarchiveSelectedTenderButton setSelection={setIntRows} selection={IntRows} buttonSize={"small"} iconSize={"1.3rem"} />
                    </React.Fragment>
                );

            /**
             * If filter is set to 'not interesting' and selection is minimum of 1
             */
            case IntRows !== undefined && IntRows.length > 0 && searchInput?.reviews?.length === 1 && searchInput?.reviews?.includes(0):
                return (
                    <React.Fragment>
                        {/*
                         * Like button
                         */}
                        <LikeSelectedTenders setSelection={setIntRows} selection={IntRows} buttonSize={"small"} iconSize={"1.3rem"} />
                    </React.Fragment>
                );

            /**
             * If filter is set to 'not rated' and selection is minimum of 1
             */
            case IntRows !== undefined && IntRows.length > 0 && searchInput?.reviews?.length === 1 && searchInput?.reviews?.includes(-1):
                return (
                    <React.Fragment>
                        {/*
                         * like button
                         */}
                        <LikeSelectedTenders setSelection={setIntRows} selection={IntRows} buttonSize={"small"} iconSize={"1.3rem"} />

                        {/*
                         * Dislike button
                         */}
                        <DislikeSelectedTenders setSelection={setIntRows} selection={IntRows} buttonSize={"small"} iconSize={"1.3rem"} />
                    </React.Fragment>
                );

            // Fallback
            default:
                return <></>;
        }
    };

    return (
        <Disable disabled={loading} disabledOpacity={1}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {/* buttons show up on worklist page */}

                {worklist && wrklstRows.length === 0 && <SweepWorklist buttonSize={"small"} iconSize={"1.3rem"} />}
                {worklist && wrklstRows !== undefined && wrklstRows.length > 0 && (
                    <React.Fragment>
                        {/* Like */}
                        {activeView === 0 && (
                            <React.Fragment>
                                {/* Like button */}

                                <LikeSelectedTenders setSelection={setWrklstRows} selection={wrklstRows} buttonSize={"small"} iconSize={"1.3rem"} />

                                {/* dislike button */}

                                <DislikeSelectedTenders
                                    setSelection={setWrklstRows}
                                    selection={wrklstRows}
                                    buttonSize={"small"}
                                    iconSize={"1.3rem"}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}

                {/*
                 * Table actions to show on review page based on chosen subset
                 */}
                {renderSwitch()}

                {/* Button shows up on every page, user can click this button to show/hide columns */}
                {/* ADDED ACTIVEVIEW = 2  */}
                {activeView !== 1 && activeView !== 2 && activeView !== 3 && (
                    <React.Fragment>
                        {reviewPage && (
                            <React.Fragment>
                                {loading ? (
                                    <Disable disabled>
                                        <IconButton disabled={true} aria-label="event" size="small" style={{ margin: 1 }}>
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </Disable>
                                ) : (
                                    <RowTenderExportButton
                                        columnExport={GridInput.TENDER_REVIEWS}
                                        total={totalTenders || 0}
                                        order={order}
                                        orderField={orderField}
                                        searchInput={searchInput}
                                        query={query}
                                        target={target}
                                        columns={columns}
                                    />
                                )}
                            </React.Fragment>
                        )}
                        {worklist && (
                            <React.Fragment>
                                {loading ? (
                                    <Disable disabled>
                                        <IconButton disabled={true} aria-label="event" size="small" style={{ margin: 1 }}>
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </Disable>
                                ) : (
                                    <RowTenderExportButton
                                        columnExport={GridInput.TENDER_WORKLIST}
                                        total={totalTenders || 0}
                                        order={order}
                                        orderField={orderField}
                                        searchInput={searchInput}
                                        query={query}
                                        target={target}
                                        columns={columns}
                                    />
                                )}
                            </React.Fragment>
                        )}

                        {/* Component to hide/show columns */}
                        {reviewPage && <ColumnChanger columns={InterestingColumns} location={"/tenders/reviews"} />}
                        {allpage && <ColumnChanger columns={AllColumns} location={"/tenders"} />}
                        {worklist && <ColumnChanger columns={WorklistColumns} location={"/tenders/worklist"} />}
                    </React.Fragment>
                )}

                {/*
                 * Sorting component
                 * Default sorting is set to 'updated_at' descending
                 * can be changed for specific pages or views
                 */}
                {allpage ? (
                    <SortingChanger
                        onSort={handleSorting}
                        defaultSorting={{
                            direction: SortOrder.DESC,
                            field: "updated_at",
                        }}
                        currentSorting={currentSorting}
                        options={[
                            { label: "Status", name: "status", filter: "status", visible: true },
                            { label: "Deadline", name: "deadline_inschrijving", filter: "deadline_inschrijving", visible: true },
                            { label: "Type", name: "type", filter: "type", visible: true },
                            { label: "TypeProcedure", name: "type_procedure", filter: "type_procedure", visible: true },
                            { label: "Status_Contract", name: "status_contract", filter: "contract_state", visible: true },
                            { label: "Award_date", name: "datum_gunning", filter: "award_date", visible: true },
                            { label: "First_dispatchdate", name: "first_dispatchdate", filter: "first_publication", visible: true },
                            { label: "Last_update", name: "updated_at", filter: "updated_at", visible: true },
                        ]}
                    />
                ) : (
                    <SortingChanger
                        onSort={handleSorting}
                        defaultSorting={{
                            direction: SortOrder.DESC,
                            field: "updated_at",
                        }}
                        currentSorting={currentSorting}
                    />
                )}
            </div>
        </Disable>
    );
};

export default TenderTableActions;
