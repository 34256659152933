import React from "react";
import { Paper, createTheme, Theme, ThemeProvider, useTheme, useMediaQuery } from "@mui/material";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { useTranslation } from "react-i18next";
import { ApolloError } from "@apollo/client";
import { Disable } from "react-disable";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { TenderColumns } from "./TenderColumns";
import LinearLoader from "../../../components/loading/LinearLoader";
import {
    FilterWorklistMinimumOverview_filterTenders_results_data,
    FilterWorklistMinimumOverview_filterTenders_results_paginatorInfo,
} from "../../../__generated__/FilterWorklistMinimumOverview";
import { useColumnSetterWrkLst } from "../../../components/contextProviders/ColumnContextTndrWrklst";
import { useSelectedColumns } from "../../../components/contextProviders/ColumnProvider";
import MobileTableNavigation from "../../../components/MobileTableNavigation";
import TableNavigation from "../../../components/TableNavigation";
import { TenderFilterTarget } from "../../../__generated__/globalTypes";

interface Props {
    /**
     * Data to be displayed in table
     */
    rows: FilterWorklistMinimumOverview_filterTenders_results_data[];
    /**
     * Pagination info
     */
    paginatorInfo: FilterWorklistMinimumOverview_filterTenders_results_paginatorInfo;
    /**
     * onChange page or rows per page
     */
    onChangePagination(page: number, rowsPerPage: number): void;
    /**
     * Loading state
     */
    loading: boolean;
    /**
     * Apollo error
     */
    error?: ApolloError | undefined;
    onTableChange: (changedColumn: string, direction: "asc" | "desc") => void;
    sortOrder: { name: string; direction: "asc" | "desc" };
    target: TenderFilterTarget;
}

const getMuiTheme = (theme: Theme) =>
    createTheme({
        palette: {
            primary: {
                main: "#173357",
            },
        },
        typography: {
            fontSize: 14,
            fontFamily: "Maven Pro, system-ui, sans-serif",
            body1: {
                fontSize: "0.875rem",
                "@media (max-width: 800px)": {
                    fontSize: "0.8rem",
                },
            },
        },
        components: {
            // MUIDataTable: {
            //     tableRoot: {
            //         width: "100% !important",
            //     },
            // },

            // Hide toolbar
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        display: "none !important",
                    },
                },
            },

            // Custom hovercolor: opacity 7% -> add 12 to hexcode
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        "&:hover": {
                            backgroundColor: `${"#173357"}12 !important`,
                        },
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        textAlign: "start",
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: 8,
                    },
                },
            },
            // MUIDataTableHeadCell: {
            //     data: {
            //         textAlign: "start",
            //     },
            // },
        },
    });

const WorklistTenderTable: React.FC<Props> = ({ rows, paginatorInfo, onChangePagination, onTableChange, loading, sortOrder, target }) => {
    const { t } = useTranslation();
    const { setSelectedRows, selectedRows } = useColumnSetterWrkLst();
    const { items } = useSelectedColumns(TenderColumns);
    const loc = useLocation();
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

    /**
     * Data reformat definition, each container is a columnsection in table.
     */
    const convertedData = rows.map((item) => {
        const container: any = {};
        const id = "id";
        const tender = "tender";
        const contractingAuthorities = "contractingAuthorities";
        const favicon = "favicon";
        const ca_id = "ca_id";
        const deadline_inschrijving = "deadline_inschrijving";
        const type_procedure = "type_procedure";
        const type = "type";
        const status = "status";
        const next_looptijd_einde = "next_looptijd_einde";
        const looptijd_start = "looptijd_start";
        const looptijd_einde = "looptijd_einde";
        const published_vestigingsplaats = "published_vestigingsplaats";
        const country = "country";
        const published_adres = "published_adres";
        const published_postcode = "published_postcode";
        const datum_gunning = "datum_gunning";
        const published_host = "published_host";
        const status_contract = "status_contract";
        const published_contactpersoon = "published_contactpersoon";
        const published_email = "published_email";
        const published_telefoon = "published_telefoon";
        const first_dispatchdate = "first_dispatchdate";
        const looptijd_einde_incl_opties = "looptijd_einde_incl_opties";
        const sector_name = "sector_name";
        const subsector_name = "subsector_name";
        const updated_at = "updated_at";
        const type_update = "type_update";
        const market_party_name = "market_party_name";
        const bid = "bid";
        const optionsMonth = "optionsMonth";
        const contractDescription = "contractDescription";
        const initialDuration = "initialDuration";
        const userStatus = "userStatus";
        const searches = "searches";
        const hasConversations = "hasConversations";
        const lastPrivateNote = "lastPrivateNote";
        const scopeValue = "scopeValue";
        const reviews = "reviews";
        // Get client with CA category 'A'
        const clientA = item.clients.find((ca) => ca.pivot?.category === "A");

        // initial run properties
        container[id] = item.id;
        container[ca_id] = clientA?.id;
        container[tender] = item.namegiventocontract;
        container[searches] = item.searchresults.map((i: any) => i);
        container[deadline_inschrijving] = item.deadline_inschrijving ? moment(item.deadline_inschrijving).format("L LT") : "";
        container[country] = item.country?.alpha2 ? item.country.alpha2 : null;
        container[contractingAuthorities] =
            clientA?.name !== undefined ? clientA?.name : item.published_opdrachtgever !== null ? item.published_opdrachtgever : "";
        container[favicon] = clientA?.favicon_resized ?? clientA?.favicon;
        container[hasConversations] = item.hasConversations;
        container[status] = item.status;
        container[userStatus] = item.userStatus;
        container[reviews] = item.id;

        // tender properties
        container[type_procedure] = item.id;
        container[type] = item.id;
        container[status_contract] = item.id;
        container[sector_name] = item.id;
        container[subsector_name] = item.id;
        container[type_update] = item.id;
        container[optionsMonth] = item.id;
        container[contractDescription] = item.id;
        container[initialDuration] = item.id;
        container[lastPrivateNote] = item.id;
        container[scopeValue] = item.id;
        // END initial run

        // Datestamps of tender -> fallback is empty string (empty field)
        container[looptijd_start] = item.id;
        container[looptijd_einde] = item.id;
        container[next_looptijd_einde] = item.id;
        container[datum_gunning] = item.id;
        container[looptijd_einde_incl_opties] = item.id;
        container[updated_at] = item.id;
        container[first_dispatchdate] = item.id;

        // published fields of tender
        container[published_vestigingsplaats] = item.id;
        container[published_adres] = item.id;
        container[published_postcode] = item.id;
        container[published_host] = item.id;
        container[published_contactpersoon] = item.id;
        container[published_telefoon] = item.id;
        container[published_email] = item.id;

        // tender bids properties ((x,i,arr)=>(i<arr.length-1)?x+',':x)
        container[market_party_name] = item.id;

        container[bid] = item.id;

        return container;
    });

    /**
     * Fuction to get the selected rows and add id's of item inside row to context provider. now its possible to use the data inside to export,archive etc.
     */
    const onRowSelectionChange = (currentRowsSelected: Array<any>, allRowsSelected: Array<any>, rowsSelected: Array<any> | undefined) => {
        if (rowsSelected !== undefined) {
            const ids = rowsSelected.map((index) => convertedData[index].id);
            setSelectedRows(ids);
        }
    };

    // TODO: sortfunction doesnt work
    // sortcompare function maybe ?
    const options: MUIDataTableOptions = {
        elevation: 0,
        filter: false,
        download: false,
        print: false,
        pagination: false,
        search: false,
        filterType: "checkbox",
        draggableColumns: {
            enabled: false,
            transitionTime: 200,
        },
        serverSide: true,
        selectableRowsOnClick: true,
        selectToolbarPlacement: "none",
        onRowSelectionChange: onRowSelectionChange,
        rowsSelected: selectedRows.map((id: string) => convertedData.findIndex((item) => item.id === id)),
        // onColumnSortChange: onOrderChange,
        fixedSelectColumn: false,
        responsive: "standard",
        selectableRows: loc.pathname === "/tenders" ? "none" : "multiple",

        /**
         * Textual helpers with translation
         * Header tooltip text
         */
        textLabels: {
            body: {
                noMatch: t("TableHelpers.NoMatch"),
                toolTip: t("TableHelpers.Sort"),
                columnHeaderTooltip: (column) => `${t(`Column_Header.${column.label}`)}`,
            },
        },

        sortOrder: sortOrder,
        onColumnSortChange: onTableChange,

        // TODO: Defined some data that is commented (doesnt work for now, bug in tenderGQL)
        customFooter() {
            if (onlyMobile) {
                return (
                    <MobileTableNavigation
                        perPage={paginatorInfo.perPage}
                        total={paginatorInfo.total}
                        firstItemIndex={paginatorInfo.firstItem as number}
                        lastItemIndex={paginatorInfo.lastItem as number}
                        currentPage={paginatorInfo.currentPage}
                        onChangePagination={onChangePagination}
                    />
                );
            }
            return (
                <TableNavigation
                    target={target}
                    perPage={paginatorInfo.perPage}
                    total={paginatorInfo.total}
                    firstItemIndex={paginatorInfo.firstItem as number}
                    lastItemIndex={paginatorInfo.lastItem as number}
                    currentPage={paginatorInfo.currentPage}
                    onChangePagination={onChangePagination}
                />
            );
        },
    };

    return (
        <React.Fragment>
            <LinearLoader loading={loading} color="blue" />
            <Disable disabledOpacity={0.6} disabled={loading}>
                <Paper square>
                    {/* Added paper element around table to use the square variant instead of the default, 
                - in MuidatatableOptons: elevation -> 0 */}
                    <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable title="Alle aanbestedingen" data={convertedData} columns={items} options={options} />
                    </ThemeProvider>
                </Paper>
            </Disable>
        </React.Fragment>
    );
};

export default WorklistTenderTable;
