import React, { useState } from "react";
import { Container } from "@mui/material";
import ProfileLayout from "../ProfileLayout";
import { useTranslation } from "react-i18next";
import PersonalAccordion from "./PersonalAccordion";
import PersonalDetails from "./PersonalDetails";
import SecurityContainer from "./securityAccordion/SecurityContainer";
import { Person, Lock } from "@mui/icons-material";

interface Props {
    a?: number;
}

const PersonalPageContainer: React.FC<Props> = (props) => {
    const { t } = useTranslation();

    const [expanded, setExpanded] = useState<string | false>("personal");

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <ProfileLayout title={<h3>{t("PersonalInfo")} </h3>}>
            <Container
                maxWidth="md"
                // sx={(theme) => ({
                //     marginTop: "60px",
                //     [theme.breakpoints.up("md")]: {
                //         marginTop: "30px",
                //     },
                //     [theme.breakpoints.down("sm")]: {
                //         marginTop: "20px",
                //     },
                // })}
            >
                <PersonalAccordion
                    panelID={"personal"}
                    expanded={expanded === "personal"}
                    handleChange={handleChange}
                    title={"Persoonlijke gegevens"}
                    subTitle={""}
                    icon={Person}
                >
                    <PersonalDetails />
                </PersonalAccordion>
                <PersonalAccordion
                    icon={Lock}
                    panelID={"Beveiliging"}
                    expanded={expanded === "Beveiliging"}
                    handleChange={handleChange}
                    title="Toegang & beveiliging"
                    subTitle={""}
                >
                    <SecurityContainer />
                </PersonalAccordion>
            </Container>
        </ProfileLayout>
    );
};

export default PersonalPageContainer;
